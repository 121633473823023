/**
 * get current questionnaire price
 * @param  {Object[]} options.questionnairePricing
 * @param  {Object} options.survey
 * @return {String}
 */
export const getCurrentQuestionnairePrice = ({ questionnairePricing, survey }) =>
  questionnairePricing?.find(
    ({ questions }) => questions === survey.questions.filter(({ _delete }) => !_delete).length
  );

/**
 * get current questionnaire price
 * @param  {Object[]} options.samplePricing
 * @param  {Object} options.survey
 * @return {String}
 */
export const getCurrentSamplePrice = ({ samplePricing, survey }) => {
  const activeGroups = survey.groups.filter(({ _delete }) => !_delete);

  return samplePricing?.find(
    ({ quotaGroups, responses }) =>
      quotaGroups === activeGroups.length &&
      responses === activeGroups.reduce((acc, { quotaLimit }) => acc + quotaLimit, 0)
  );
};
