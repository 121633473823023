import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { middleware as thunkMiddleware } from 'redux-saga-thunk';
import LogRocket from 'logrocket';

import rootReducer from '../slices';
import rootSaga from '../sagas';
import websocketMiddleware from '../middleware/websocket';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunkMiddleware, sagaMiddleware, websocketMiddleware];

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_LOGROCKET) {
  middlewares.push(LogRocket.reduxMiddleware());
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
});

sagaMiddleware.run(rootSaga);

export default store;
