/**
 * gets input colors based on various data structures of charts
 * @param  {String[]} options.colors       base colors given to highcharts
 * @param  {Object[]} options.series       default data structure for most charts
 * @return {Object[]}
 */
export const getInputs = ({ colors, series }) => {
  if (series[0]?.showInLegend) {
    return series[0]?.data?.map((series, index) => {
      return {
        color: colors?.[index],
        name: series?.label,
      };
    });
  }

  if (series[0]?.data[0]?.color) {
    return colors?.map((color, index) => {
      return {
        color: colors?.[index],
        name: `Color ${index + 1}`,
      };
    });
  }

  return series
    ?.filter((series) => series.type !== 'arearange')
    ?.map((series, index) => {
      return {
        color: colors?.[index],
        name: series?.name,
      };
    });
};
