import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Mustache from 'mustache';
import PropTypes from 'prop-types';

import {
  AskWhyIcon,
  getFormattedLongDate,
  OverlayPopover,
  OverlayTriggerTooltip,
} from '@utilities';
import { confirmSurvey, createSurvey } from '@api/surveys';

import ModalAskWhy from '../../../../../ModalAskWhy';
import ModalConfirmAskWhy from '../../../../../ModalConfirmAskWhy';

import { getMustachePrompts } from './utilities/helpers';

import styles from './_index.module.scss';

const AskWhy = ({ className, onAskWhySubmit, report, shouldOpenPopover, ...props }) => {
  const [confirmError, setConfirmError] = useState(null);
  const [isAskWhyOpen, setIsAskWhyOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const popoverRef = useRef(null);
  const [selectedPeopleGroup, setSelectedPeopleGroup] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [surveyData, setSurveyData] = useState(null);

  const canAccessAskWhy = useSelector((state) => state.user.data?.permissions?.canAccessAskWhy);
  const mustachePrompts = getMustachePrompts(report.answers);
  const navigate = useNavigate();

  const selectGroupAndOpenModal = (group) => {
    setSelectedPeopleGroup(group);
    setIsAskWhyOpen(true);
    setIsPopoverOpen(false);
  };

  const handleSubmit = async ({ selectedGroup, selectedQuestions }) => {
    const survey = {
      answers: report.answers,
      askWhyJobId: report.id,
      askWhyPeopleGroupTypeId: selectedGroup.value,
      documentId: 'ask-why-instant-survey-type',
      questions: selectedQuestions,
      title: `AskWhy for ${selectedGroup.label} - ${
        mustachePrompts.categories || mustachePrompts.product || mustachePrompts.place
      } - ${getFormattedLongDate(new Date())}`,
    };

    setIsConfirming(true);
    try {
      const { prices, validationKey } = await confirmSurvey(survey);
      survey.validationKey = validationKey;

      if (canAccessAskWhy?.canPayWithLicense) {
        setSurveyData(survey);
        setIsAskWhyOpen(false);
        setIsConfirmationOpen(true);
        setIsConfirming(false);
        return;
      }

      navigate('/dashboard/checkout', {
        state: {
          cartItems: [
            {
              description: selectedGroup.label,
              name: `AskWhy for ${report.name}`,
              notes: selectedQuestions.map(({ text }) =>
                Mustache.render(text, { prompt: mustachePrompts })
              ),
              prices: {
                credits: prices?.credits,
                USD: prices?.USD,
              },
            },
          ],
          survey,
        },
      });
    } catch (error) {
      setConfirmError(error);
      setIsConfirming(false);
    }
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      await createSurvey(surveyData);
      setIsConfirmationOpen(false);
      setIsSubmitting(false);

      if (onAskWhySubmit) {
        return onAskWhySubmit();
      }
      navigate(`/dashboard/report/${report.id}/ask-why`);
    } catch (error) {
      setSubmitError(error);
      setIsSubmitting(false);
    }
  };

  const handleConfirmClose = () => {
    setIsConfirmationOpen(false);
    setIsAskWhyOpen(true);
  };

  return (
    <>
      <div ref={popoverRef}>
        <OverlayTriggerTooltip aria-label="AskWhy" content="AskWhy">
          <button
            className={className}
            data-log="AskWhy_Popover"
            onClick={shouldOpenPopover ? () => setIsPopoverOpen(true) : () => setIsAskWhyOpen(true)}
            {...props}
          >
            <AskWhyIcon />
          </button>
        </OverlayTriggerTooltip>
        <OverlayPopover
          className={styles['ask-why']}
          header="AskWhy"
          isActive={isPopoverOpen}
          onHide={() => setIsPopoverOpen(false)}
          target={popoverRef?.current}
        >
          <ul data-testid="ask-why-group-popover">
            {report?.askWhyPeopleGroups?.map((askWhyGroup) => (
              <li key={askWhyGroup.id}>
                <button
                  data-log={`AskWhy_Visit_${askWhyGroup.id}`}
                  id={`ask-why-popover-${askWhyGroup.id}`}
                  onClick={() => selectGroupAndOpenModal(askWhyGroup)}
                >
                  {askWhyGroup?.name}
                </button>
              </li>
            ))}
          </ul>
        </OverlayPopover>
      </div>

      {isAskWhyOpen && (
        <ModalAskWhy
          askWhyGroupId={selectedPeopleGroup?.id}
          isSubmitting={isConfirming}
          mustachePrompts={mustachePrompts}
          onClose={() => setIsAskWhyOpen(false)}
          onSubmit={handleSubmit}
          report={report}
          submitError={confirmError}
          submitText={canAccessAskWhy?.canPayWithLicense ? 'Send Questions' : 'Continue'}
        />
      )}
      {isConfirmationOpen && (
        <ModalConfirmAskWhy
          isSubmitting={isSubmitting}
          onClose={handleConfirmClose}
          onSubmit={handleConfirm}
          panelistCount={100}
          paymentKey="license"
          submitError={submitError}
          submitText="Send Questions"
        />
      )}
    </>
  );
};

AskWhy.propTypes = {
  className: PropTypes.string,
  onAskWhySubmit: PropTypes.func,
  report: PropTypes.object.isRequired,
  shouldOpenPopover: PropTypes.bool,
};

export default AskWhy;
