import cloneDeep from 'lodash/cloneDeep';
import { nanoid } from 'nanoid';

import {
  defaultGroupName,
  defaultResponseCount,
  placeholderImage,
  questionLogicOperators,
  surveyDatePromptIds,
  surveyDescriptionDefault,
  surveyDescriptionLong,
  surveyDescriptionShort,
  surveyLengthLong,
  surveyLengthShort,
  surveyTypeQuestionId,
} from './constants';

import { transformPrompt } from '@src/app/pages/Dashboard/components/Builder/utilities/helpers.js';

/**
 * @typedef {Object} Question
 * @property {Object[]} [answers]
 * @property {String|Null} caption
 * @property {Number} id
 * @property {Object} image
 * @property {String} [image.imageBucketName]
 * @property {String} image.imageCdnUrl
 * @property {String} [image.imageObjectKey]
 * @property {String} [image.imageObjectType]
 * @property {Boolean} [isFolder]
 * @property {Number|Null} [parentId]
 * @property {String} text
 * @property {String} type
 */

/**
 * transformAskWhyQuestions
 * @param {Object[]} questions
 * @param {String|Null} questions[].caption
 * @param {Number} questions[].id
 * @param {String} questions[].question_type
 * @param {String} questions[].text
 * @param {String} [search]
 * @return {Question[]}
 */
export const transformAskWhyQuestions = (questions) =>
  questions.map(({ caption, id, question_type: type, text }) => ({
    caption,
    id,
    text,
    type,
  }));

/**
 * transformConfirmSurvey
 * @param {Object} options
 * @param {String} options.complete_datetime
 * @param {String} options.name
 * @param {Object} options.prices
 * @param {Number} options.user_credits_remaining
 * @param {Number} options.validation_pk
 * @return {Object}
 */
export const transformConfirmSurvey = ({
  complete_datetime: completeDatetime,
  name: title,
  prices,
  user_credits_remaining: userCreditsRemaining,
  validation_pk: validationKey,
}) => ({
  completeDatetime,
  prices: {
    credits: prices?.credits ? Number(prices.credits) : undefined,
    USD: prices?.USD ? Number(prices.USD) : undefined,
  },
  title,
  userCreditsRemaining,
  validationKey,
});

/**
 * transformCreateSurvey
 * @param {Object} data.instant_survey
 * @param {String} [data.stripe_checkout_url]
 * @param {Number} [data.user_credits_remaining]
 * @return {Object}
 */
export const transformCreateSurvey = ({
  instant_survey: instantSurvey,
  stripe_checkout_url: stripeCheckoutUrl,
  user_credits_remaining: userCreditsRemaining,
}) => ({
  instantSurvey,
  stripeCheckoutUrl,
  userCreditsRemaining,
});

/**
 * @typedef {Object} Answer
 * @property {Number} id
 * @property {String} imageCdnUrl
 * @property {Boolean} isAnchored
 * @property {Boolean} isExclusive
 * @property {Boolean} isNoneOfTheAbove
 * @property {Boolean} isOther
 * @property {String} text
 */

/**
 * transformQuestionAnswer
 * @param {Boolean} answer.anchored
 * @param {Boolean} answer.exclusive
 * @param {Number} answer.id
 * @param {String} answer.image_cdn_url
 * @param {Boolean} answer.none_of_the_above
 * @param {Boolean} answer.other
 * @param {String} answer.text
 * @return {Answer}
 */
export const transformQuestionAnswer = ({
  anchored: isAnchored,
  exclusive: isExclusive,
  id,
  image_cdn_url: imageCdnUrl,
  none_of_the_above: isNoneOfTheAbove,
  other: isOther,
  text,
}) => ({
  id,
  ...(imageCdnUrl && {
    imageCdnUrl: imageCdnUrl.indexOf('http://localhost') !== -1 ? placeholderImage : imageCdnUrl,
  }),
  isAnchored,
  isExclusive,
  isNoneOfTheAbove,
  isOther,
  text,
});

/**
 * transformQuestionLogic
 * @param {Object} options.questionLogic
 * @param {Question[]} options.questions
 * @return {Object}
 */
export const transformQuestionLogic = ({ questionLogic, questions }) => {
  let hasQuestionLogicError = false;
  const modifiedQuestionLogic = cloneDeep(questionLogic);
  for (const questionId in modifiedQuestionLogic) {
    const question = modifiedQuestionLogic[questionId];
    if (question.disqualify) {
      question.disqualify.operator = questionLogicOperators[question.disqualify.operator];
      const disqualifyQuestion = questions.find((question) => question.id === parseInt(questionId));
      const initialAnswersLength = question.disqualify.answers.length;
      question.disqualify.answers = question.disqualify.answers
        .map((answerId) => {
          return disqualifyQuestion.answers.find((answer) => answer.id === answerId);
        })
        .filter((answer) => !!answer);
      if (
        question.disqualify.answers.length === 0 ||
        question.disqualify.answers.length !== initialAnswersLength
      ) {
        hasQuestionLogicError = true;
      }
    }
    if (question.skip) {
      question.skip.operator = questionLogicOperators[question.skip.operator];
      // eslint-disable-next-line no-loop-func
      question.skip.logic = question.skip.logic.map((row) => {
        const skipQuestion = questions.find((question) => question.id === row.question);
        const answers = row.answers
          .map((answerId) => {
            return skipQuestion.answers.find((answer) => answer.id === answerId);
          })
          .filter((answer) => !!answer);
        if (answers.length === 0 || answers.length !== row.answers.length) {
          hasQuestionLogicError = true;
        }

        return {
          answers,
          operator: questionLogicOperators[row.operator],
          question: skipQuestion,
        };
      });
    }
  }
  return { hasQuestionLogicError, questionLogic: modifiedQuestionLogic };
};

/**
 * transformQuestionLogicValidation
 * @param {Object} questionLogic
 * @return {Object}
 */
export const transformQuestionLogicValidation = (questionLogic) => {
  const modifiedQuestionLogic = cloneDeep(questionLogic);
  for (const questionId in questionLogic) {
    const question = modifiedQuestionLogic[questionId];

    if (Object.keys(question).length > 0) {
      if (question.disqualify) {
        question.disqualify.operator = question.disqualify.operator.value;
        question.disqualify.answers = question.disqualify.answers.map((answer) => answer.id);
      }

      if (question.skip) {
        question.skip.operator = question.skip.operator.value;
        question.skip.logic = question.skip.logic.map((row) => {
          return {
            answers: row.answers.map((answer) => answer.id),
            operator: row.operator.value,
            question: row.question?.id,
          };
        });
      }
    } else {
      delete modifiedQuestionLogic[questionId];
    }
  }
  return modifiedQuestionLogic;
};

/**
 * transformQuestion
 * @param {Object} question
 * @param {String} question.caption
 * @param {Number} question.id
 * @param {String} question.image_bucket_name
 * @param {String} question.image_cdn_url
 * @param {String} question.image_object_key
 * @param {String} question.image_object_type
 * @param {String[]} question.options
 * @param {Number} question.parent_id
 * @param {String} question.question_type
 * @param {Boolean} question.randomize_options
 * @param {String} question.text
 * @return {Question}
 */
export const transformQuestion = ({
  caption,
  id,
  image_bucket_name: imageBucketName,
  image_cdn_url: imageCdnUrl,
  image_object_key: imageObjectKey,
  image_object_type: imageObjectType,
  options: answers,
  parent_id: parentId,
  question_type: type,
  randomize_options: randomizeOptions,
  text,
}) => ({
  answers: answers.map(transformQuestionAnswer),
  caption,
  id,
  image: {
    imageBucketName,
    imageCdnUrl:
      imageCdnUrl && imageCdnUrl.indexOf('http://localhost') !== -1
        ? placeholderImage
        : imageCdnUrl,
    imageObjectKey,
    imageObjectType,
  },
  isFolder: false,
  parentId,
  randomizeOptions,
  text,
  type,
});

/**
 * transformQuestionImage
 * @param {Object} questionImage
 * @param {String} questionImage.image_bucket_name
 * @param {String} questionImage.image_cdn_url
 * @param {String} questionImage.image_object_key
 * @param {String} questionImage.image_object_type
 * @param {String} questionImage.url
 * @return {Object}
 */
export const transformQuestionImage = ({
  image_bucket_name: imageBucketName,
  image_cdn_url: imageCdnUrl,
  image_object_key: imageObjectKey,
  image_object_type: imageObjectType,
  url,
}) => ({
  imageBucketName,
  imageCdnUrl,
  imageObjectKey,
  imageObjectType,
  url,
});

/**
 * transformQuestions
 * @param {Object[]} questions
 * @param {String|Null} questions[].caption
 * @param {Number} questions[].entry_id
 * @param {Object} questions[].image_cdn_url
 * @param {Boolean} questions[].is_folder
 * @param {String} questions[].name
 * @param {Object[]} questions[].options
 * @param {Number|Null} questions[].parent_id
 * @param {String} questions[].question_type
 * @param {String} [search]
 * @return {Question[]}
 */
export const transformQuestions = (questions, search) => {
  const searchLowercase = search?.toLowerCase();
  return questions
    .filter(({ id }, index, self) => index === self.findIndex((question) => question.id === id)) // INSRV-310: de-dupe question list
    .filter(({ caption, name }) => {
      // TODO: temporary frontend fix, search string is being ignored on backend (INSRV-52)
      return !search
        ? true
        : caption?.toLowerCase().includes(searchLowercase) ||
            name.toLowerCase().includes(searchLowercase);
    })
    .map(
      ({
        options: answers,
        caption,
        entry_id: id,
        image_cdn_url: imageCdnUrl,
        is_folder: isFolder,
        name: text,
        parent_id: parentId,
        question_type: type,
      }) => ({
        answers: answers?.map(transformQuestionAnswer),
        caption,
        id,
        image: {
          imageCdnUrl:
            imageCdnUrl && imageCdnUrl.indexOf('http://localhost') !== -1
              ? placeholderImage
              : imageCdnUrl,
        },
        isFolder,
        parentId,
        text,
        type,
      })
    );
};

/**
 * transformSampleValidation
 * @param {Object} validation
 * @param {Number} validation.expected_completes
 * @param {Boolean} validation.finished
 * @param {Number} validation.total_potential_users
 * @return {Object}
 */
export const transformSampleValidation = ({
  expected_completes,
  finished,
  total_potential_users,
}) => ({
  expectedCompletes: expected_completes,
  finished,
  totalPotentialUsers: total_potential_users,
});

/**
 * @typedef {Object} Survey
 * @property {Object} answers
 * @property {String} [atlasInstantSurveyRef]
 * @property {String} created_datetime
 * @property {String} creator
 * @property {Number} id
 * @property {String} instantSurveyTypeId
 * @property {String} instantSurveyTypeName
 * @property {Boolean} isLocked
 * @property {String} name
 * @property {Object} questionLogic
 * @property {Array} questions
 * @property {Number} status
 * @property {String} validationPk
 */

/**
 * transformSurvey
 * @param {Object} survey
 * @param {Object} survey.answers
 * @param {String} [survey.atlas_instant_survey_ref]
 * @param {Number} [survey.completed]
 * @param {String} survey.created_datetime
 * @param {String} survey.id
 * @param {String} survey.instant_survey_type_id
 * @param {String} survey.instant_survey_type_name
 * @param {String} survey.is_owner
 * @param {Number} [survey.issued]
 * @param {Number} [survey.number_of_responses_requested]
 * @param {Object} survey.question_logic
 * @param {Number} survey.status
 * @param {String} survey.survey_source_id
 * @param {String} survey.title
 * @param {Object} survey.user
 * @param {Number} survey.validation_pk
 * @return {Survey}
 */
export const transformSurvey = ({
  answers = {},
  atlas_instant_survey_ref: atlasInstantSurveyRef,
  completed,
  created_datetime,
  id,
  instant_survey_type_id: instantSurveyTypeId,
  instant_survey_type_name: instantSurveyTypeName,
  is_owner: isOwner,
  issued,
  number_of_responses_requested: numberOfResponsesRequested,
  question_logic: questionLogic,
  status,
  survey_source_id: surveySourceId,
  title,
  user,
  validation_pk: validationKey,
}) => {
  const questions = (answers[surveyTypeQuestionId[instantSurveyTypeId]] || []).map(
    ({
      caption,
      id,
      image_cdn_url: imageCdnUrl,
      name: text,
      options: answers = [], // INSRV-1607: some v1 survey questions do not have options when retrieved
      parent_id: parentId,
      question_type: type,
      randomize_options: randomizeOptions,
    }) => ({
      answers: answers.map(transformQuestionAnswer),
      caption,
      id: Number(id.split(':')[1]),
      image: {
        imageCdnUrl:
          imageCdnUrl && imageCdnUrl.indexOf('http://localhost') !== -1
            ? placeholderImage
            : imageCdnUrl,
      },
      isFolder: false,
      parentId,
      randomizeOptions,
      text,
      type,
    })
  );
  delete answers[surveyTypeQuestionId[instantSurveyTypeId]];

  return {
    atlasInstantSurveyRef,
    completed,
    created_datetime, // this is not camelCased due to the ListView component
    creator: `${user?.last_name}, ${user?.first_name}`,
    groups: [
      {
        answers,
        completed,
        id: nanoid(),
        issued,
        name: defaultGroupName,
        responseCount: numberOfResponsesRequested || defaultResponseCount,
      },
    ],
    id,
    instantSurveyTypeId,
    instantSurveyTypeName,
    isLocked: !isOwner,
    issued,
    questions,
    status,
    surveySourceId,
    title,
    validationKey,
    ...transformQuestionLogic({ questionLogic, questions }),
  };
};

/**
 * transformSurveys
 * @param {Object} response
 * @param {Number} response.count
 * @param {String} response.next
 * @param {String} response.previous
 * @param {Object[]} response.results
 * @return {Object}
 */
export const transformSurveys = ({ count: total, next, previous, results }) => {
  return {
    items: results
      // INSRV-353: filter out AskWhy surveys from list view (temporary until v2 of /surveys endpoint)
      ?.filter(
        ({ instant_survey_type_id }) => instant_survey_type_id !== 'ask-why-instant-survey-type'
      )
      .map(transformSurvey),
    next,
    previous,
    total,
  };
};

/**
 * transformSurveyType
 * @param {Object} surveyType
 * @param {String} surveyType.id
 * @param {Object[]} [surveyType.instant_survey_pricechart_sample]
 * @param {Object[]} [surveyType.instant_survey_pricechart_questionnaire]
 * @param {Object[]} surveyType.prompts
 * @param {String} surveyType.prompts_madlib
 * @return {Object}
 */
export const transformSurveyType = ({
  id,
  instant_survey_pricechart_sample = [],
  instant_survey_pricechart_questionnaire = [],
  prompts,
  prompts_madlib,
  ...data
}) => {
  const dateRangeIndex = prompts.findIndex((prompt) => surveyDatePromptIds.includes(prompt.id));
  if (dateRangeIndex >= 0) {
    prompts[dateRangeIndex].max = 1; // date range max is missing for instant survey types
  }

  const maxQuestions = Math.max(
    ...instant_survey_pricechart_questionnaire.map(({ number_of_questions }) => number_of_questions)
  );
  const questionnairePricing = instant_survey_pricechart_questionnaire.map(
    ({ credits_cost_questionnaire: amount, id, number_of_questions: questions }) => ({
      amount: Number(amount),
      description:
        questions <= surveyLengthShort
          ? surveyDescriptionShort
          : questions >= surveyLengthLong
          ? surveyDescriptionLong
          : surveyDescriptionDefault,
      id,
      questions,
    })
  );
  const samplePricing = instant_survey_pricechart_sample.map(
    ({
      ask_why: isAskWhy,
      id,
      max_responses: responses,
      number_of_quota_groups: quotaGroups,
      payment_amount: amount,
      payment_type: type,
    }) => ({
      amount: Number(amount),
      id,
      isAskWhy,
      quotaGroups,
      responses,
      type,
    })
  );

  const questionsIndex = prompts.findIndex((prompt) => prompt.id === surveyTypeQuestionId[id]);
  // remove questions from survey prompts
  if (questionsIndex >= 0) {
    let madlibIndex = 0;
    return {
      ...data,
      advancedPrompts: prompts
        .filter(({ advanced }, index) => advanced && index !== questionsIndex)
        .map(transformPrompt),
      default_answers: {}, // fix for date ranges being all pre-selected
      id,
      maxQuestions,
      prompts: prompts
        .filter(({ advanced }, index) => !advanced && index !== questionsIndex)
        .map(transformPrompt),
      prompts_madlib: prompts_madlib.replace(/\{x\}/g, (match) => {
        const returnString = madlibIndex === questionsIndex ? 'questions' : match;
        madlibIndex++;
        return returnString;
      }),
      questionnairePricing,
      samplePricing,
    };
  }

  return {
    ...data,
    advancedPrompts: prompts.filter(({ advanced }) => advanced).map(transformPrompt),
    default_answers: {},
    id,
    maxQuestions,
    prompts: prompts.filter(({ advanced }) => !advanced).map(transformPrompt),
    prompts_madlib,
    questionnairePricing,
    samplePricing,
  };
};
