import PropTypes from 'prop-types';

import { ActionDetailsIcon, IconCTA, OverlayTriggerTooltip } from '@utilities';

const Details = ({ hasException, isHoverAction, row, selectedRows }) => {
  const isVisible = selectedRows.length === 1 && isHoverAction && !hasException;

  if (!isVisible) return null;

  return (
    <>
      <OverlayTriggerTooltip content={isHoverAction ? 'Details' : ''}>
        <IconCTA
          data-testid="details-cta"
          icon={<ActionDetailsIcon />}
          onClick={() => row.getToggleRowExpandedProps().onClick()}
          text={isHoverAction ? '' : 'Details'}
        />
      </OverlayTriggerTooltip>
    </>
  );
};

Details.propTypes = {
  hasException: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  row: PropTypes.object,
  selectedRows: PropTypes.array,
};

export default Details;
