import AutomatedReports from '../pages/Workspace/pages/AutomatedReports';
import Checkout from '../pages/Checkout';
import CheckoutCancel from '../pages/Checkout/pages/Cancel';
import CheckoutSuccess from '../pages/Checkout/pages/Success';
import CreatePeopleGroup from '../pages/CreatePeopleGroup';
import CreateBreakout from '../pages/CreateBreakout';
import CreateInstantSurvey from '../pages/CreateInstantSurvey';
import CreateReport from '../pages/CreateReport';
import CreateTripGroup from '../pages/CreateTripGroup';
import Demographics from '../pages/Dashboards/pages/Demographics';
import Email from '../pages/User/pages/Email';
import Home from '../pages/Home';
import Info from '../pages/User/pages/Info';
import PeopleGroups from '../pages/Workspace/pages/PeopleGroups';
import ProductGroups from '../pages/Workspace/pages/ProductGroups';
import Products from '../pages/Foresights/pages/Products';
import Profile from '../pages/User/pages/Profile';
import Report from '../pages/Report';
import { default as ReportList } from '../pages/Reports';
import Reports from '../pages/Workspace/pages/Reports';
import Stores from '../pages/Dashboards/pages/Stores';
import StoreGroups from '../pages/Workspace/pages/StoreGroups';
import StoresForesights from '../pages/Foresights/pages/Stores';
import Summary from '../pages/Dashboards/pages/Summary';
import { default as SurveyList } from '../pages/Surveys';
import Survey from '../pages/Survey';
import Surveys from '../pages/Workspace/pages/Surveys';
import TripGroups from '../pages/Workspace/pages/TripGroups';

import { requestSurveyResponses } from '@api/surveys';

/**
 * gets first available route that is not locked
 * @param  {Array}  routes
 * @return {String} returns path of first available route
 */
export const getFirstAvailableRoute = (routes) => {
  return routes.find((route) => route?.is_locked !== true && route?.is_hidden !== true)?.path;
};

/**
 * gets component for route
 * @param  {Object} options.path
 * @param  {Object} options.route
 * @return {Node}
 */
export const getRouteComponent = ({ path, route }) => {
  switch (path) {
    case 'dashboard/checkout':
      return <Checkout {...route} />;
    case 'dashboard/checkout/cancel':
      return <CheckoutCancel {...route} />;
    case 'dashboard/checkout/success':
      return <CheckoutSuccess {...route} />;
    case 'dashboard/create-breakout':
    case 'dashboard/create-breakout/:custom_group_id':
      return <CreateBreakout {...route} />;
    case 'dashboard/create-people-group':
      return <CreatePeopleGroup {...route} />;
    case 'dashboard/create-report/:report':
      return <CreateReport {...route} />;
    case 'dashboard/create-survey/:instant_survey_type_id':
      return <CreateInstantSurvey {...route} />;
    case 'dashboard/create-trip-group':
      return <CreateTripGroup {...route} />;
    case 'dashboard/dashboards/demographics':
      return <Demographics />;
    case 'dashboard/dashboards/stores':
      return <Stores />;
    case 'dashboard/dashboards/summary':
      return <Summary />;
    case 'dashboard/forecasting/product':
      return <Products />;
    case 'dashboard/forecasting/store':
      return <StoresForesights />;
    case 'dashboard/homepage':
      return <Home {...route} />;
    case 'dashboard/report/:job_id':
    case 'dashboard/report/:job_id/:tab_id':
      return <Report />;
    case 'dashboard/reports/all-reports':
    case 'dashboard/reports/behavior':
    case 'dashboard/reports/brand':
    case 'dashboard/reports/diagnostics':
    case 'dashboard/reports/ecomm':
    case 'dashboard/reports/innovation':
    case 'dashboard/reports/labs':
    case 'dashboard/reports/new-item':
    case 'dashboard/reports/people':
    case 'dashboard/reports/portfolio':
    case 'dashboard/reports/promo':
    case 'dashboard/reports/retailer':
    case 'dashboard/reports/scorecards':
    case 'dashboard/reports/shopper':
    case 'dashboard/reports/tools':
    case 'dashboard/reports/trips':
    case 'dashboard/reports/truview':
      return <ReportList {...route} />;
    case 'dashboard/survey/:survey_id':
    case 'dashboard/survey/:survey_id/:tab_id':
      return <Survey />;
    case 'dashboard/surveys':
      return <SurveyList {...route} />;
    case 'dashboard/user/email':
      return <Email {...route} />;
    case 'dashboard/user/info':
      return <Info {...route} />;
    case 'dashboard/user/profile':
      return <Profile {...route} />;
    case 'dashboard/workspace/automated-reports':
      return <AutomatedReports {...route} />;
    case 'dashboard/workspace/people-groups':
    case 'dashboard/workspace/people-groups/:folder_id':
      return <PeopleGroups {...route} />;
    case 'dashboard/workspace/product-groups':
    case 'dashboard/workspace/product-groups/:group_id':
      return <ProductGroups {...route} />;
    case 'dashboard/workspace/reports':
    case 'dashboard/workspace/reports/:folder_id':
      return <Reports {...route} />;
    case 'dashboard/workspace/store-groups':
    case 'dashboard/workspace/store-groups/:group_id':
      return <StoreGroups {...route} />;
    case 'dashboard/workspace/surveys':
      return <Surveys {...route} />;
    case 'dashboard/workspace/trip-groups':
    case 'dashboard/workspace/trip-groups/:folder_id':
      return <TripGroups {...route} />;
    default:
      return null;
  }
};

/**
 * defines which url path should display breadcrumbs or not
 * @param  {String}  path
 * @return {Boolean}
 */
export const hasBreadcrumbs = (path) => {
  switch (path) {
    case 'dashboard/create-breakout':
    case 'dashboard/create-breakout/:custom_group_id':
    case 'dashboard/create-people-group':
    case 'dashboard/create-report/:report':
    case 'dashboard/create-trip-group':
    case 'dashboard/report/:job_id':
    case 'dashboard/report/:job_id/:tab_id':
      return true;
    default:
      return false;
  }
};

/**
 * Navigates to the most recent Report for the given InstantSurveyId
 * @param {function(Object): void} navigate
 * @param {Object} requestData
 * @param {number} requestData.id
 * @param {Boolean} requestData.forceNewJobRun
 * @return {Promise<void>}
 */
export const navigateToSurveyReport = async (navigate, requestData) => {
  try {
    const { data } = await requestSurveyResponses(requestData);
    if (data?.jobId) {
      navigate({
        pathname: `/dashboard/report/${data.jobId}`,
      });
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * return proficiency tooltip content
 * @return {JSX}
 */
export const proficiencyTooltipContent = (
  <>
    <p>
      <strong>Beginner</strong> Limited to no knowledge of panel data.
    </p>
    <br />
    <p>
      <strong>Intermediate</strong> Understands data methodology, what is panel data and <br />
      difference between other data sets like POS.
    </p>
    <br />
    <p>
      <strong>Advanced</strong> In-depth knowledge of panel methodology and nuances of panel
      <br /> & POS metrics and formulas.
    </p>
  </>
);
