import PropTypes from 'prop-types';
import { useMatch } from 'react-router-dom';

import { HelpIcon, OverlayTriggerTooltip, Tabs as TabsComponent, Toolbar } from '@utilities';

import styles from './_index.module.scss';

const Tabs = ({ children, route }) => {
  const { pathnameBase } = useMatch('/dashboard/:type/*');

  const routes = route?.routes;

  return (
    <div className={styles['tabs']}>
      <Toolbar
        tools={[
          ...(route?.help_url
            ? [
                <OverlayTriggerTooltip aria-label="Help" content="Help">
                  <a
                    data-testid="help-link-button"
                    href={route?.help_url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <HelpIcon />
                  </a>
                </OverlayTriggerTooltip>,
              ]
            : []),
        ]}
      >
        <h1>{route?.headline}</h1>
      </Toolbar>
      <TabsComponent
        routes={routes?.map((route) => {
          return {
            ...route,
            to: `${pathnameBase}/${route?.id}`,
          };
        })}
      />
      {children}
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  route: PropTypes.object,
};

export default Tabs;
