import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Announcement,
  Button,
  IconCTA,
  Input,
  isMaxNameLength,
  JoinIcon,
  ModalV2 as Modal,
  Radio,
} from '@utilities';

import {
  getAnswers,
  getDefaultName,
  OPTIONS_JOIN_THREE,
  OPTIONS_JOIN_TWO,
} from './utilities/helpers';

import Groups from './components/Groups';
import Orders from './components/Orders';

import styles from './_index.module.scss';

export const Join = ({ createJoin, hasException, name: groupName, onSubmit, selectedRows }) => {
  const [error, setError] = useState([]);
  const [groups, setGroups] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [selectedVennOption, setSelectedVennOption] = useState(null);

  useEffect(() => {
    setGroups(selectedRows);
  }, [selectedRows]);

  const isJoiningTwoGroups = selectedRows?.length === 2;
  const isJoiningThreeGroups = selectedRows?.length === 3 && groupName !== 'trip_group';
  const options = isJoiningTwoGroups ? OPTIONS_JOIN_TWO : OPTIONS_JOIN_THREE;

  const isVisible = (isJoiningTwoGroups || isJoiningThreeGroups) && !hasException;

  useEffect(() => {
    if (isVisible) {
      setName(
        getDefaultName({
          groups,
          selectedVennOption,
        })
      );
    }
  }, [isActive, groups, selectedVennOption]);

  useEffect(() => {
    setError([]);
    setSelectedVennOption(options[0]);
  }, [isActive]);

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const answers = getAnswers({
        groupName,
        groups,
        operators: selectedVennOption.operators,
      });

      await createJoin({ answers, id: 'join', name });
      setIsActive(false);
      onSubmit({ resetItemsPage: true });
    } catch (error) {
      console.error(error);
      setError(error?.response?.data?.promptErrors || error?.response?.data);
      setIsLoading(false);
    }
  };

  if (!isVisible) return null;

  return (
    <>
      <IconCTA
        data-testid="join-cta"
        icon={<JoinIcon />}
        onClick={() => setIsActive(true)}
        text="Join"
      />
      <Modal
        buttons={[
          <Button
            key="cancel-btn"
            onClick={() => setIsActive(false)}
            text="Cancel"
            variant="secondary"
          />,
          <Button
            key="submit-btn"
            isDisabled={!name?.trim() || isMaxNameLength(name)}
            isLoading={isLoading}
            onClick={handleSubmit}
            text="Save"
          />,
        ]}
        className={styles['join']}
        isActive={isActive}
        onClose={() => setIsActive(false)}
        size="large"
        title="Join Groups"
      >
        {error && error?.length > 0 && <Announcement text={error[0]} variant="error" />}
        <p className={styles['join-description']}>
          Define how to join the selected groups. Never fear, the groups you're joining won't be
          deleted.
        </p>
        <Input
          error={
            isMaxNameLength(name)
              ? 'Maximum number of characters reached. Try something shorter.'
              : ''
          }
          label="Name"
          onChange={setName}
          placeholder="Enter name"
          value={name}
        />
        <div className={styles['join-content']}>
          <div className={styles['join-order']}>
            <h3>
              <strong>Join Order</strong>
            </h3>
            <div className={styles['join-order-groups']}>
              <Orders isJoiningThreeGroups={isJoiningThreeGroups} />
              <Groups groups={groups} setGroups={setGroups} />
            </div>
          </div>
          <div className={styles['join-type']}>
            <h3>
              <strong>Join Type</strong>
            </h3>
            <ul>
              {options.map((option, index) => (
                <li key={`option-${index}`}>
                  <Radio
                    isChecked={selectedVennOption.label === option.label}
                    label={
                      <>
                        <div className={styles['join-type-icon']}>{option.icon}</div>
                        {option.label}
                      </>
                    }
                    onChange={() => setSelectedVennOption(option)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

Join.propTypes = {
  createJoin: PropTypes.func,
  hasException: PropTypes.bool,
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  selectedRows: PropTypes.array,
};

export default Join;
