import axios from './api';

import { QUESTIONS_PER_PAGE } from './utilities/constants';
import { transformQuestionFolder } from './utilities/helpers';

/**
 * read question folder
 * @param {Object} options
 * @param {Number} [options.folder]
 * @param {String} [options.instantSurveyType]
 * @param {Number} [options.limit]
 * @param {Number} [options.offset]
 * @param {String} [options.ordering]
 * @param {String} [options.search]
 * @param {AbortSignal} options.signal
 * @return {Promise}
 */

export const readQuestionFolder = async ({
  folder,
  instantSurveyType: instant_survey_type,
  limit = QUESTIONS_PER_PAGE,
  offset,
  ordering,
  search,
  signal,
}) => {
  return axios({
    method: 'get',
    params: { folder, instant_survey_type, limit, offset, ordering, search },
    signal,
    url: `/question_folder`,
  }).then(({ data }) => transformQuestionFolder(data));
};
