import classNames from 'classnames';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { OverlayTrigger } from 'react-bootstrap';

import Tooltip from '../Tooltip';

import styles from './_index.module.scss';

const Tag = ({ className, icon, label, placement, tooltip, ...props }) => {
  const tagClasses = classNames(styles['tag'], className);

  if (!tooltip) {
    const labelId = nanoid();
    return (
      <i aria-labelledby={labelId} className={tagClasses} role="img" {...props}>
        {icon}
        {label && <span id={labelId}>{label}</span>}
      </i>
    );
  }

  return (
    <OverlayTrigger
      delayShow={400}
      overlay={<Tooltip id={nanoid()}>{tooltip}</Tooltip>}
      placement={placement}
    >
      <i aria-label={tooltip} className={tagClasses} role="img" {...props}>
        {icon}
      </i>
    </OverlayTrigger>
  );
};

Tag.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element.isRequired,
  label: PropTypes.string,
  placement: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
  tooltip: PropTypes.string,
};

export default Tag;
