import { reportStatus } from '@helpers';

import clamp from 'lodash/clamp';

/**
 * gets report status message to display
 * @param  {Object}  options.data
 * @param  {Boolean} options.showTruncatedMessage
 * @return {String}
 */
export const getReportStatusMessage = ({ data, showTruncatedMessage }) => {
  const tabLength = data?.numberCompleteTabs + data?.numberIncompleteTabs;

  let message;

  switch (true) {
    case data?.status < reportStatus.SETUP:
      message = 'In Queue';
      break;
    case data?.status >= reportStatus.SETUP && data?.status < reportStatus.COMPLETED:
      message =
        (showTruncatedMessage ? '' : 'Processing ') +
        `(${clamp(data?.numberCompleteTabs + 1, 1, tabLength)}/${tabLength})`;
      break;
    case data?.status === reportStatus.COMPLETED:
      return 'Complete';
    case data?.status === reportStatus.EXCEPTION:
      return 'Failed';
    case data?.status === reportStatus.CANCELLED:
      return 'Canceled';
    case data?.status === reportStatus.INVALIDATED:
      return 'Invalidated';
    case data?.status === reportStatus.OUT_OF_DATE:
      return 'Out of Date';
    default:
      message = '';
  }

  return message;
};
