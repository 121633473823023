import { useNavigate } from 'react-router-dom';

import ListPage from '../../components/ListPage';

import Delete from '../../components/ListPage/components/ActionsToolbar/components/Delete';
import Rename from '../../components/ListPage/components/ActionsToolbar/components/Rename';

import {
  deleteSurveys,
  readSurveys,
  surveyPageSize,
  surveyStatus,
  updateSurveyTitle,
} from '@api/surveys';

import { FILTER_OPTIONS, ITEM_COLUMNS } from './utilities/helpers.js';

const Surveys = () => {
  const navigate = useNavigate();

  const expandSurveyRow = ({ atlasInstantSurveyRef, id, instantSurveyTypeId, status }) => {
    switch (status) {
      case surveyStatus.DRAFT:
        const params = { id };
        if (atlasInstantSurveyRef) params.ref = atlasInstantSurveyRef;
        navigate({
          pathname: `/dashboard/create-survey/${instantSurveyTypeId}`,
          search: new URLSearchParams(params).toString(),
        });
        break;
      case surveyStatus.COMPLETED:
      case surveyStatus.MET_SAMPLE:
        navigate({ pathname: `/dashboard/survey/${id}` });
        break;
      default:
        break;
    }
  };

  return (
    <ListPage
      filterOptions={FILTER_OPTIONS}
      itemActions={[
        <Rename type="Survey" updateName={updateSurveyTitle} />,
        <Delete deleteItems={deleteSurveys} />,
      ]}
      itemColumns={ITEM_COLUMNS}
      itemSize={surveyPageSize}
      onCreate={() => navigate('/dashboard/surveys/instant-surveys')}
      onExpandItemRow={expandSurveyRow}
      readItems={readSurveys}
      type="Survey"
    />
  );
};

export default Surveys;
