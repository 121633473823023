import PropTypes from 'prop-types';

import styles from './_index.module.scss';

const Footnote = ({ children, header }) => {
  return (
    <div className={styles[`footnote`]}>
      <h3>{header}</h3>
      <div className="wysiwyg">
        <ul>{children}</ul>
      </div>
    </div>
  );
};

Footnote.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  header: PropTypes.string,
};

export default Footnote;
