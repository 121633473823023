import {
  JoinAndNotThree,
  JoinAndThree,
  JoinAndTwo,
  JoinNotTwo,
  JoinOrNotThree,
  JoinOrNotTwo,
  JoinOrThree,
  JoinOrTwo,
  MAX_NAME_LENGTH,
} from '@utilities';

const MAX_TEMPLATE_CHARS_LENGTH = 18;

export const MAX_CHARS_JOINEE_NAME = (MAX_NAME_LENGTH - MAX_TEMPLATE_CHARS_LENGTH) / 3;

/**
 * join options three groups
 * @type {Array}
 */
export const OPTIONS_JOIN_THREE = [
  {
    icon: <JoinAndThree />,
    label: 'A AND B AND C',
    operators: [
      {
        attributeId: 'group_join_operators',
        id: 'group_join_operators:intersect',
        name: 'AND',
        prompt_desc: 'AND',
      },
    ],
    type: 'AND',
  },
  {
    icon: <JoinOrThree />,
    label: 'A OR B OR C',
    operators: [
      {
        attributeId: 'group_join_operators',
        id: 'group_join_operators:union',
        name: 'OR',
        prompt_desc: 'OR',
      },
    ],
    type: 'OR',
  },
  {
    icon: <JoinAndNotThree />,
    label: '(A AND B) NOT C',
    operators: [
      {
        attributeId: 'group_join_operators',
        id: 'group_join_operators:intersect',
        name: 'AND',
        prompt_desc: 'AND',
      },
      {
        attributeId: 'group_join_operators',
        id: 'group_join_operators:except',
        name: 'NOT',
        prompt_desc: 'NOT',
      },
    ],
    type: 'AND-NOT',
  },
  {
    icon: <JoinOrNotThree />,
    label: '(A OR B) NOT C',
    operators: [
      {
        attributeId: 'group_join_operators',
        id: 'group_join_operators:union',
        name: 'OR',
        prompt_desc: 'OR',
      },
      {
        attributeId: 'group_join_operators',
        id: 'group_join_operators:except',
        name: 'NOT',
        prompt_desc: 'NOT',
      },
    ],
    type: 'OR-NOT',
  },
];

/**
 * join options two groups
 * @type {Array}
 */
export const OPTIONS_JOIN_TWO = [
  {
    icon: <JoinAndTwo />,
    label: 'A AND B',
    operators: [
      {
        attributeId: 'group_join_operators',
        id: 'group_join_operators:intersect',
        name: 'AND',
        prompt_desc: 'AND',
      },
    ],
    type: 'AND',
  },
  {
    icon: <JoinOrTwo />,
    label: 'A OR B',
    operators: [
      {
        attributeId: 'group_join_operators',
        id: 'group_join_operators:union',
        name: 'OR',
        prompt_desc: 'OR',
      },
    ],
    type: 'OR',
  },
  {
    icon: <JoinNotTwo />,
    label: 'A NOT B',
    operators: [
      {
        attributeId: 'group_join_operators',
        id: 'group_join_operators:except',
        name: 'NOT',
        prompt_desc: 'NOT',
      },
    ],
    type: 'NOT',
  },
  {
    icon: <JoinOrNotTwo />,
    label: 'A OR B NOT both',
    operators: [
      {
        attributeId: 'group_join_operators',
        id: 'group_join_operators:xor',
        name: 'OR (NOT BOTH)',
        prompt_desc: 'OR (NOT BOTH)',
      },
    ],
    type: 'OR-NOT',
  },
];

const groupObject = ({ groupName, group }) => ({
  attributeId: groupName,
  id: `${groupName}:${group.id}`,
  name: group.name,
  prompt_desc: group.name,
});

export const getAnswers = ({ groupName, groups, operators }) => {
  return {
    group_join_operators: operators,
    [groupName]: groups.map((group) => groupObject({ group, groupName })),
  };
};

/**
 * Clip the name if it exceeds the maximum length
 * @param {string} name
 * @returns {string}
 */
const clipName = (name) => {
  const clippedLength = MAX_CHARS_JOINEE_NAME - 3;
  return name?.length > clippedLength ? name?.substring(0, clippedLength) + '...' : name;
};

/**
 * Get the default name based on groups and selected Venn option
 * @param {Object} options
 * @param {Array} options.groups
 * @param {Object} options.selectedVennOption
 * @returns {string}
 */
export const getDefaultName = ({ groups, selectedVennOption }) => {
  const [groupA, groupB, groupC] = groups;
  const isJoiningThreeGroups = groups.length === 3;

  const clippedLeft = clipName(groupA?.name);
  const clippedMiddle = clipName(groupB?.name);
  const clippedRight = isJoiningThreeGroups ? clipName(groupC?.name) : null;

  const type = selectedVennOption?.type;

  if (isJoiningThreeGroups) {
    switch (type) {
      case 'AND':
      case 'OR':
        return `${clippedLeft} ${type} ${clippedMiddle} ${type} ${clippedRight}`;
      case 'AND-NOT':
        return `( ${clippedLeft} AND ${clippedMiddle} ) NOT ${clippedRight}`;
      case 'OR-NOT':
        return `( ${clippedLeft} OR ${clippedMiddle} ) NOT ${clippedRight}`;
      default:
        return '';
    }
  } else {
    switch (type) {
      case 'AND':
      case 'OR':
      case 'NOT':
        return `${clippedLeft} ${type} ${clippedMiddle}`;
      case 'OR-NOT':
        return `${clippedLeft} OR ${clippedMiddle} NOT BOTH`;
      default:
        return '';
    }
  }
};
