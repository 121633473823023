import PropTypes from 'prop-types';
import classNames from 'classnames';

import Layout from '../Layout';

import styles from './_index.module.scss';

const MaintenanceView = ({ className, heading, logo }) => {
  return (
    <Layout.Flex.Column className={classNames(styles['maintenance-view'], className)}>
      {logo && <div className={styles['maintenance-view-logo']}>{logo}</div>}
      <h2>{heading}</h2>
    </Layout.Flex.Column>
  );
};

MaintenanceView.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  logo: PropTypes.node,
};

export default MaintenanceView;
