import { useEffect } from 'react';

export const useOutsideClick = ({ ref, onCallback }) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref?.current && !ref?.current.contains(event.target)) {
        onCallback();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [ref]);
};
