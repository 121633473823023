import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import { CheckMarkIcon, CopyIcon } from '../../icons';
import OverlayTriggerTooltip from '../../components/OverlayTriggerTooltip';

import styles from './_index.module.scss';

const CopyToClipboard = ({ text, value, ...props }) => {
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);

  const handleClick = () => {
    setIsCopiedToClipboard(true);
    navigator.clipboard.writeText(value);
    setTimeout(() => setIsCopiedToClipboard(false), 2000);
  };

  return (
    <OverlayTriggerTooltip
      aria-label="Copy"
      content={isCopiedToClipboard ? '' : 'Copy to clipboard'}
    >
      <Button
        className={styles[`copy-to-clipboard`]}
        icon={isCopiedToClipboard ? <CheckMarkIcon /> : <CopyIcon />}
        isDisabled={isCopiedToClipboard}
        onClick={handleClick}
        text={text}
        variant="secondary"
        {...props}
      />
    </OverlayTriggerTooltip>
  );
};

CopyToClipboard.defaultProps = {
  'data-testid': 'copy-to-clipboard',
  text: '',
};

CopyToClipboard.propTypes = {
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default CopyToClipboard;
