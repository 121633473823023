/* eslint-disable react/prop-types */

import { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useExpanded, useFlexLayout, useSortBy, useTable } from 'react-table';

import Checkbox from '../../components/Checkbox';
import LoaderSkeleton from '../../components/LoaderSkeleton';
import Pagination from '../../components/Pagination';

import SortIndicator from '../SortIndicator';

import styles from './_index.module.scss';

const TableList = ({
  columns,
  currentPage,
  data,
  expandedRow,
  initialExpandedRow,
  initialSortBy,
  isDisabled,
  isLoading,
  onExpandRow,
  renderRowHovered,
  pageCount,
  renderEmptyMessage,
  renderRowExpanded,
  selectedRows,
  setPage,
  setSelectedRows,
  setSortBy,
  size,
}) => {
  const [visibleActionsCount, setVisibleActionsCount] = useState(null);

  const memoizedColumns = useMemo(
    () => [
      {
        id: 'selection',
        Header: () => null,
        Cell: ({ row }) => {
          const isChecked = selectedRows?.some(
            (selectedRow) => selectedRow?.id === row?.original?.id
          );

          return (
            <Checkbox
              isChecked={isChecked}
              isDisabled={isDisabled}
              onChange={(value) => {
                if (isChecked) {
                  setSelectedRows([
                    ...selectedRows.filter((selectedRow) => selectedRow?.id !== value?.id),
                  ]);
                } else {
                  setSelectedRows([...selectedRows, value]);
                }
              }}
              value={row?.original}
            />
          );
        },
        disableSortBy: true,
        maxWidth: 30,
        minWidth: 45,
        width: 30,
      },
      ...columns,
    ],
    [columns, selectedRows]
  );

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    state: { sortBy },
  } = useTable(
    {
      columns: memoizedColumns,
      data,
      initialState: {
        expanded: { [initialExpandedRow]: true },
        sortBy: initialSortBy || [{ desc: true, id: columns[0]?.accessor }],
      },
      manualSortBy: true,
    },
    useFlexLayout,
    useSortBy,
    useExpanded
  );

  const onPageChange = (event) => {
    if (setPage) setPage(event?.selected + 1);
  };

  useEffect(() => {
    if (sortBy !== initialSortBy) {
      const newSortBy = sortBy?.length === 0 && initialSortBy ? initialSortBy : sortBy;
      setSortBy(newSortBy);
    }
  }, [sortBy]);

  return (
    <div className={styles['table-list']}>
      <div {...getTableProps()}>
        <div className={styles['table-list-thead']}>
          {headerGroups.map((headerGroup) => (
            <div className={styles['table-list-tr']} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup?.headers?.map((column) => (
                <div
                  className={classNames(styles['table-list-th'], {
                    [styles['is-sortable']]: !column?.disableSortBy,
                  })}
                  {...column?.getHeaderProps(
                    column?.getSortByToggleProps({
                      title: typeof column?.Header === 'string' ? column?.Header : '',
                    })
                  )}
                >
                  <p>{column.render('Header')}</p>
                  <SortIndicator
                    direction={column?.isSortedDesc ? 'desc' : 'asc'}
                    isActive={column?.isSorted}
                    isDisabled={!column?.canSort}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        {isLoading && (
          <div className={styles['table-list-tbody']}>
            {[...Array(size)].map((row, index) => (
              <div
                className={classNames(styles['table-list-tr'], {
                  [styles['is-loading']]: isLoading,
                })}
                key={`row-${index}`}
              >
                <div className={styles['table-list-td']}>
                  <LoaderSkeleton height={19.5} width={2000}>
                    <rect x="0" y="0" rx="2" ry="2" width="100%" height="19.5" />
                  </LoaderSkeleton>
                </div>
              </div>
            ))}
          </div>
        )}
        {!isLoading && data?.length > 0 && (
          <div className={styles['table-list-tbody']}>
            {rows?.map((row, index) => {
              prepareRow(row);

              return (
                <Fragment key={index}>
                  <div
                    className={classNames(
                      styles['table-list-tr'],
                      {
                        [styles['is-active']]:
                          row?.isExpanded || row?.original?.id === expandedRow?.id,
                      },
                      {
                        [styles['is-disabled']]: row?.original?.hasException,
                      }
                    )}
                    {...row.getRowProps()}
                  >
                    {row?.cells?.map((cell, cellIndex) => {
                      return (
                        <div className={styles['table-list-td']} {...cell.getCellProps({})}>
                          {cellIndex === 1 ? (
                            <span
                              onClick={
                                onExpandRow
                                  ? () => onExpandRow(row?.original)
                                  : () => row.getToggleRowExpandedProps().onClick()
                              }
                              title={typeof cell?.value === 'string' ? cell?.value : ''}
                            >
                              {cell.render('Cell')}
                            </span>
                          ) : (
                            <span
                              className={classNames({
                                [styles['table-list-td-hidden']]:
                                  visibleActionsCount !== 0 &&
                                  (cellIndex === row?.cells?.length - 1 ||
                                    cellIndex === row?.cells?.length - 2),
                              })}
                            >
                              {cell.render('Cell')}
                            </span>
                          )}
                        </div>
                      );
                    })}
                    {renderRowHovered && visibleActionsCount !== 0 && (
                      <div className={styles['table-list-td-actions']}>
                        {renderRowHovered({ row, setVisibleActionsCount })}
                      </div>
                    )}
                  </div>
                  {row?.isExpanded && (
                    <div
                      className={classNames(styles['table-list-tr'], styles['is-expanded'])}
                      {...row.getRowProps()}
                      key="expanded"
                    >
                      <div
                        className={styles['table-list-td']}
                        {...row?.cells[0].getCellProps({})}
                      />
                      <div className={styles['table-list-td']}>{renderRowExpanded({ row })}</div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
      {data?.length > 0 && pageCount > 1 && (
        <Pagination
          className={styles['table-list-pagination']}
          data-testid="list-pagination"
          forcePage={currentPage - 1}
          onPageChange={onPageChange}
          pageCount={pageCount}
          show={pageCount > 1}
        />
      )}
      {!isLoading && data?.length === 0 && (
        <div className={styles['table-list-empty-message']}>{renderEmptyMessage()}</div>
      )}
    </div>
  );
};

TableList.defaultProps = {
  renderEmptyMessage: () => {},
  renderRowExpanded: () => {},
  selectedRows: [],
  setPage: () => {},
  setSelectedRows: () => {},
  setSortBy: () => {},
  size: 5,
};

TableList.propTypes = {
  columns: PropTypes.array.isRequired,
  currentPage: PropTypes.number,
  data: PropTypes.array.isRequired,
  expandedRow: PropTypes.object,
  initialExpandedRow: PropTypes.number,
  initialSortBy: PropTypes.array,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onExpandRow: PropTypes.func,
  renderRowHovered: PropTypes.func,
  pageCount: PropTypes.number,
  renderEmptyMessage: PropTypes.func.isRequired,
  renderRowExpanded: PropTypes.func,
  selectedRows: PropTypes.array.isRequired,
  setPage: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  setSortBy: PropTypes.func,
  size: PropTypes.number,
};

export default TableList;
