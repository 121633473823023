import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import { DragDropIcon } from '@utilities';
import { MAX_CHARS_JOINEE_NAME } from '../../utilities/helpers';

import styles from './_index.module.scss';

const Groups = ({ groups, setGroups }) => {
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedGroups = Array.from(groups);
    const [removed] = reorderedGroups.splice(result.source.index, 1);
    reorderedGroups.splice(result.destination.index, 0, removed);

    setGroups(reorderedGroups);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div className={styles['groups']} {...provided.droppableProps} ref={provided.innerRef}>
            <ul>
              {groups.map((group, index) => {
                const groupName =
                  group.name.length > MAX_CHARS_JOINEE_NAME
                    ? group.name.substring(0, MAX_CHARS_JOINEE_NAME) + '...'
                    : group.name;
                return (
                  <Draggable
                    draggableId={`group-${index}`}
                    key={`group-${index}`}
                    index={index}
                    isDragDisabled={false}
                  >
                    {(provided) => {
                      return (
                        <li
                          ref={provided.innerRef}
                          title={group.name}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DragDropIcon />
                          <span>{groupName}</span>
                        </li>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

Groups.propTypes = {
  groups: PropTypes.array,
  setGroups: PropTypes.func,
};

export default Groups;
