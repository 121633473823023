import axios from '@api';
import { transformFilters, transformTableData } from './utilities/helpers';

/**
 * read data for products global filters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readProductsGlobalFiltersData = ({ signal }) => {
  return axios({
    method: 'get',
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: '/v2/api/forecasting/products-tab/global-filters',
    withCredentials: true,
  });
};

/**
 * read data for products line chart
 * @param  {Object} options.chartsFilters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readProductsLineChart = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      breakout: globalFilters?.breakout?.value,
      metric: chartsFilters?.metric?.value,
      products: chartsFilters?.product?.map((filter) => filter.value).join(';'),
      product_level_one: globalFilters?.product_level_one?.value,
    },
    signal,
    url: '/v2/api/forecasting/products-tab/line-graph',
    withCredentials: true,
  });
};

/**
 * read data for products local filters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readProductsLocalFiltersData = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      breakout: globalFilters?.breakout?.value,
      metric: chartsFilters?.metric?.value,
      product_level_one: globalFilters?.product_level_one?.value,
    },
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: '/v2/api/forecasting/products-tab/local-filters',
    withCredentials: true,
  });
};

/**
 * read data for products table
 * @param  {Object} options.globalFilters
 * @param  {Object} options.tableFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readProductsTable = ({ globalFilters, tableFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      breakout: globalFilters?.breakout?.value,
      metric: tableFilters?.metric?.value,
      product_level_one: globalFilters?.product_level_one?.value,
      products: tableFilters?.product?.map((filter) => filter.value).join(';'),
    },
    signal,
    transformResponse: [(data) => transformTableData(JSON.parse(data))],
    url: '/v2/api/forecasting/products-tab/table',
    withCredentials: true,
  });
};

/**
 * read data for stores global filters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readStoresGlobalFiltersData = ({ signal }) => {
  return axios({
    method: 'get',
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: '/v2/api/forecasting/stores-tab/global-filters',
    withCredentials: true,
  });
};

/**
 * read data for stores line chart
 * @param  {Object} options.chartsFilters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readStoresLineChart = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      metric: chartsFilters?.metric?.value,
      product_level_one: globalFilters?.product_level_one?.value,
      product_level_three: chartsFilters?.product_level_three?.value,
      store_level_one: chartsFilters?.store_level_one?.map((filter) => filter.value).join(';'),
    },
    signal,
    url: '/v2/api/forecasting/stores-tab/line-graph',
    withCredentials: true,
  });
};

/**
 * read data for stores local filters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readStoresLocalFiltersData = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      metric: chartsFilters?.metric?.value,
      product_level_one: globalFilters?.product_level_one?.value,
      product_level_three: chartsFilters?.product_level_three?.value,
    },
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: '/v2/api/forecasting/stores-tab/local-filters',
    withCredentials: true,
  });
};

/**
 * read data for stores table
 * @param  {Object} options.globalFilters
 * @param  {Object} options.tableFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readStoresTable = ({ globalFilters, tableFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      metric: tableFilters?.metric?.value,
      product_level_one: globalFilters?.product_level_one?.value,
      product_level_three: tableFilters?.product_level_three?.value,
      store_level_one: tableFilters?.store_level_one?.map((filter) => filter.value).join(';'),
    },
    signal,
    transformResponse: [(data) => transformTableData(JSON.parse(data))],
    url: '/v2/api/forecasting/stores-tab/table',
    withCredentials: true,
  });
};
