import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './_index.module.scss';

const Fieldset = ({ children, className, legend, ...props }) => {
  return (
    <fieldset className={classNames(styles['fieldset'], className)} {...props}>
      {legend && <legend className={styles['legend']}>{legend}</legend>}
      {children}
    </fieldset>
  );
};

Fieldset.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  legend: PropTypes.string,
};

export default Fieldset;
