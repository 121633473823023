/**
 * @typedef {Object} Item
 * @property {string} attributeId
 * @property {boolean} isRequired
 * @property {string} pillLabel
 * @property {string} promptId
 * @property {string} type
 */
export const items = [
  {
    attributeId: 'parentbrand',
    isRequired: true,
    pillLabel: 'Brand',
    promptId: 'product',
    type: 'Brand',
  },
  {
    attributeId: 'majorcat',
    isRequired: true,
    pillLabel: 'Major Category',
    promptId: 'categories',
    type: 'Category',
  },
  {
    attributeId: 'retailer',
    isRequired: false,
    pillLabel: 'Retailer',
    promptId: 'place',
    type: 'Retailer',
  },
];
