import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AsteriskIcon, HelpIcon, Input, OverlayTriggerTooltip, Select } from '@utilities';

import { readProfileOptions } from '@api/user';

import { proficiencyTooltipContent } from '../../../../utilities/helpers';

import styles from './_index.module.scss';

const Profile = ({ selectedProfile, setSelectedProfile }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [profileOptions, setProfileOptions] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await readProfileOptions();
        setProfileOptions(response?.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };
    getData();
  }, []);

  return (
    <div className={styles['profile']}>
      <h1>Welcome to Numerator Insights!</h1>
      <p>
        We just need a few details to improve your user experience, and then you'll be on your way
        to infinite insights.
      </p>
      <ul>
        <li>
          <Select
            className={styles['profile-select']}
            isLoading={isLoading}
            isRequired={true}
            label="Industry"
            onChange={(selectedIndustry) =>
              setSelectedProfile({ ...selectedProfile, industry: selectedIndustry })
            }
            options={profileOptions?.industries}
            placeholder="Select an industry"
            value={selectedProfile?.industry}
          />
        </li>
        <li>
          <Select
            className={styles['profile-select']}
            isLoading={isLoading}
            isRequired={true}
            label="Discipline"
            onChange={(selectedDiscipline) =>
              setSelectedProfile({ ...selectedProfile, discipline: selectedDiscipline })
            }
            options={profileOptions?.disciplines}
            placeholder="Select a discipline"
            value={selectedProfile?.discipline}
          />
        </li>
        <li>
          <Input
            className={styles['profile-input']}
            isRequired={true}
            label="Job Title"
            onChange={(jobTitle) => setSelectedProfile({ ...selectedProfile, jobTitle })}
            placeholder="Enter job title"
            value={selectedProfile?.jobTitle || ''}
          />
        </li>
        <li>
          <div className={styles['profile-select-label-container']}>
            <label className={styles['profile-select-label']}>
              Proficiency with Panel Data
              <AsteriskIcon />
            </label>
            <OverlayTriggerTooltip content={proficiencyTooltipContent} placement="right">
              <HelpIcon />
            </OverlayTriggerTooltip>
          </div>
          <Select
            className={styles['profile-select']}
            isLoading={isLoading}
            onChange={(selectedProficiency) =>
              setSelectedProfile({ ...selectedProfile, proficiency: selectedProficiency })
            }
            options={profileOptions?.proficiencies}
            placeholder="Select a proficiency"
            value={selectedProfile?.proficiency}
          />
        </li>
      </ul>
    </div>
  );
};

Profile.propTypes = {
  selectedProfile: PropTypes.object,
  setSelectedProfile: PropTypes.func,
};

export default Profile;
