import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import OutsideClickHandler from 'react-outside-click-handler';

import Input from '../Input';

import styles from './_index.module.scss';

const InputColor = ({ label, onChange, value }) => {
  const [color, setColor] = useState({ color: value });
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    onChange(color?.color);
  }, [color]);

  return (
    <div className={styles['input-color-wrapper']}>
      <OutsideClickHandler onOutsideClick={() => setIsActive(false)}>
        <div className={styles['input-color-input-wrapper']} onClick={() => setIsActive(!isActive)}>
          <div className={styles['input-color-swatch']}>
            <div style={{ backgroundColor: color?.color }} />
          </div>
          <Input className={styles['input-color-input']} label={label} value={color?.color} />
        </div>
        {isActive && (
          <SketchPicker
            color={color?.color}
            onChange={(updatedColor) => {
              setColor({ color: updatedColor.hex });
            }}
          />
        )}
      </OutsideClickHandler>
    </div>
  );
};

InputColor.defaultProps = {
  onChange: () => {},
  value: '#00e36b',
};

InputColor.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default InputColor;
