import PropTypes from 'prop-types';
import LogRocket from 'logrocket';

import { OverlayPopover, useThemePreference, THEMES } from '@utilities';

const ThemeDropdown = ({ isActive, setActiveDropdown, target }) => {
  const [themePreference, setThemePreference] = useThemePreference();

  if (!isActive) return null;

  const updateThemePreference = (newThemePreference) => {
    LogRocket.track('Theme_Enable', { theme: newThemePreference });
    setThemePreference(newThemePreference);
  };

  return (
    <OverlayPopover
      data-testid="theme-dropdown"
      header="Theme"
      isActive={true}
      onHide={() => setActiveDropdown('')}
      target={target}
    >
      <ul>
        {THEMES.map((theme) => (
          <li key={theme.value}>
            <button
              aria-selected={themePreference === theme.value}
              onClick={() => updateThemePreference(theme.value)}
              role="option"
            >
              {theme.icon}
              <span>{theme.label}</span>
            </button>
          </li>
        ))}
      </ul>
    </OverlayPopover>
  );
};

ThemeDropdown.propTypes = {
  isActive: PropTypes.bool,
  setActiveDropdown: PropTypes.func.isRequired,
  target: PropTypes.object,
};

export default ThemeDropdown;
