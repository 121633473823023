import axios from '@api';
import {
  transformPeopleGroupFolders,
  transformPeopleGroupPrompts,
  transformPeopleGroups,
  transformStaticGroups,
} from './utilities/helpers';

/**
 * people group folder type id
 * @type {Number}
 */
const folderTypeId = 2;

/**
 * create people group
 * @param  {Object}  options.answers
 * @param  {Array}   [options.dateRange]
 * @param  {String}  options.id
 * @param  {Boolean} [options.isFrozen]
 * @param  {Boolean} [options.isSmart]
 * @param  {String}  options.name
 * @param  {Number} options.reportId
 * @param  {String}  [options.staticGroup]
 * @return {Promise}
 */
export const createPeopleGroup = ({
  answers,
  dateRange = [],
  id,
  isFrozen = false,
  isSmart = false,
  name,
  reportId,
  staticGroup = '',
}) => {
  return axios({
    data: {
      answers,
      date_range: dateRange,
      is_frozen: isFrozen,
      is_smart: isSmart,
      job_id: reportId,
      name,
      static_group: staticGroup,
      type_id: id,
    },
    method: 'post',
    url: '/v2/api/peoplegroups',
    withCredentials: true,
  });
};

/**
 * create people group folder
 * @param  {String}  options.name
 * @return {Promise}
 */
export const createPeopleGroupFolder = ({ name }) => {
  return axios({
    data: {
      folder_type: folderTypeId,
      parent_entry_id: 'root',
      name,
    },
    method: 'post',
    url: '/v2/api/folders',
    withCredentials: true,
  });
};

/**
 * creates frozen people group
 * @param  {Array}  options.dateRange
 * @param  {Number} options.id
 * @param  {String} options.name
 * @param  {String} options.staticGroup
 * @return {Promise}
 */
export const createPeopleGroupFreeze = ({ dateRange, id, name, staticGroup }) => {
  return axios({
    data: {
      date_range: dateRange,
      name,
      static_group: staticGroup,
    },
    method: 'post',
    url: `/api/people-groups/${id}/freeze`,
    withCredentials: true,
  });
};

/**
 * join people group
 * @param  {Array}  options.answers
 * @param  {Number} options.id
 * @param  {String} options.name
 * @return {Promise}
 */
export const createPeopleGroupJoin = ({ answers, id, name }) => {
  // FIXME: join actions are still using v1 API because the 3+ group join is only implemented there
  return axios({
    data: {
      name,
      answers,
      type_id: id,
    },
    method: 'post',
    url: '/api/people-groups',
    withCredentials: true,
  });
};

/**
 * delete people group folders
 * @param  {Array} options.ids
 * @return {Promise}
 */
export const deletePeopleGroupFolders = ({ ids }) => {
  return axios({
    data: ids,
    method: 'delete',
    url: '/v2/api/folders',
    withCredentials: true,
  });
};

/**
 * delete people groups
 * @param  {Array} options.ids
 * @return {Promise}
 */
export const deletePeopleGroups = ({ ids }) => {
  return axios({
    data: ids,
    method: 'delete',
    url: '/v2/api/peoplegroups',
    withCredentials: true,
  });
};

/**
 * read people group answers
 * @param  {Number} options.id
 * @param  {object} options.signal
 * @return {Promise}
 */
export const readPeopleGroupAnswers = ({ id, signal }) => {
  return axios({
    method: 'get',
    signal,
    transformResponse: [
      (data) => {
        const response = JSON.parse(data);

        return { answers: response?.answers, detailsString: response?.details_string };
      },
    ],
    url: `/v2/api/peoplegroups/${id}`,
    withCredentials: true,
  });
};

/**
 * read people group list V2
 * @param  {Number} options.folderId
 * @param  {String} options.order   "asc" || "desc" || undefined
 * @param  {Number} options.page
 * @param  {String} options.query
 * @param  {Number} options.signal  AbortController signal
 * @param  {Number} options.size
 * @param  {String} options.sortBy
 * @param  {Array}  options.tags    [is_locked, is_shared]
 * @return {Promise}
 */
export const readPeopleGroupItems = ({
  folderId,
  order,
  page,
  query,
  signal,
  size,
  sortBy,
  tags,
}) => {
  return axios({
    method: 'get',
    params: {
      folder_id: folderId,
      order_by: sortBy ? order : undefined,
      page,
      query: query?.length > 0 ? query : undefined,
      size,
      sort_by: sortBy,
      tags: tags?.length > 0 ? tags?.join(',') : undefined,
    },
    signal,
    transformResponse: [(data) => transformPeopleGroups(JSON.parse(data))],
    url: '/v2/api/peoplegroups/',
    withCredentials: true,
  });
};

/**
 * gets people group folder items
 * @param  {Number} options.id
 * @return {Promise}
 */
export const readPeopleGroupFolderItems = ({ id }) => {
  return axios({ method: 'get', url: `/v2/api/peoplegroups/folders/${id}`, withCredentials: true });
};

/**
 * read people group folders
 * @param  {String} options.order   "asc" || "desc" || undefined
 * @param  {Number} options.page
 * @param  {String} options.query
 * @param  {Number} options.signal  AbortController signal
 * @param  {Number} options.size
 * @param  {String} options.sortBy
 * @param  {Array}  options.tags    [is_locked, is_shared]
 * @return {Promise}
 */
export const readPeopleGroupFolders = ({ order, page, query, signal, size, sortBy, tags }) => {
  return axios({
    method: 'get',
    params: {
      order_by: sortBy ? order : undefined,
      page,
      query: query?.length > 0 ? query : undefined,
      size,
      sort_by: sortBy,
      tags: tags?.length > 0 ? tags?.join(',') : undefined,
    },
    signal,
    transformResponse: [(data) => transformPeopleGroupFolders(JSON.parse(data))],
    url: '/v2/api/peoplegroups/folders/',
    withCredentials: true,
  });
};

/**
 * read people group prompts
 * @param  {String}  options.search
 * @param  {Boolean} options.transform
 * @return {Promise}
 */
export const readPeopleGroupPrompts = ({ search = '', transform = true }) => {
  return axios({
    method: 'get',
    transformResponse: [
      (data) => {
        if (!transform) return JSON.parse(data);

        return transformPeopleGroupPrompts(JSON.parse(data));
      },
    ],
    url: `/api/people-group-prompts${search}`,
    withCredentials: true,
  });
};

/**
 * read static group list
 * @return {Promise}
 */
export const readStaticGroups = () => {
  return axios({
    method: 'get',
    transformResponse: [(data) => transformStaticGroups(JSON.parse(data))],
    url: '/api/prompts/static_group/search?attributeId=static_options',
    withCredentials: true,
  });
};

/**
 * shares group with all users under a client
 * @param  {Number} clientId
 * @param  {Array} ids
 * @return {Promise}
 */
export const sharePeopleGroupWithClient = ({ clientId, ids }) => {
  return axios({
    data: {
      pg_ids: ids,
      recipient_client_id: clientId,
    },
    method: 'post',
    url: `/v2/api/peoplegroups/client_share`,
    withCredentials: true,
  });
};

/**
 * shares groups with array of users
 * @param  {Array} options.ids
 * @param  {Boolean} options.skipEmail
 * @param  {Array} options.userIds
 * @return {Promise}
 */
export const sharePeopleGroupsWithUsers = ({ ids, skipEmail = false, userIds }) => {
  return axios({
    data: {
      pg_ids: ids,
      recipient_ids: userIds,
      skip_email: skipEmail,
    },
    method: 'post',
    url: '/v2/api/peoplegroups/user_share',
    withCredentials: true,
  });
};

/**
 * shares group folders with all users under a client
 * @param  {Number} clientId
 * @param  {Array} folderIds
 * @return {Promise}
 */
export const sharePeopleGroupFoldersWithClient = ({ clientId, folderIds }) => {
  return axios({
    data: {
      client_id: clientId,
      shared_folder_ids: folderIds,
    },
    method: 'post',
    url: '/v2/api/folders/client_share',
    withCredentials: true,
  });
};

/**
 * shares group folders with array of users
 * @param  {Array} options.folderIds
 * @param  {Boolean} options.skipEmail
 * @param  {Array}  options.userIds
 * @return {Promise}
 */
export const sharePeopleGroupFoldersWithUsers = ({ folderIds, skipEmail = false, userIds }) => {
  return axios({
    data: {
      shared_folder_ids: folderIds,
      shared_with_user_ids: userIds,
      skip_email: skipEmail,
    },
    method: 'post',
    url: '/v2/api/folders/user_share',
    withCredentials: true,
  });
};

/**
 * add groups into any folder
 * @param  {Number} folderId
 * @param  {Array}  ids
 * @return {Promise}
 */
export const updatePeopleGroupFolder = ({ folderId, ids }) => {
  return axios({
    data: { folder_type: folderTypeId, ids, parent_entry_id: folderId },
    method: 'put',
    url: '/v2/api/folders/move',
    withCredentials: true,
  });
};

/**
 * update people group name
 * @param  {Number} options.id
 * @param  {String} options.name
 * @return {Promise}
 */
export const updatePeopleGroupName = ({ id, name }) => {
  return axios({
    data: { name },
    method: 'put',
    url: `/v2/api/peoplegroups/${id}/rename`,
    withCredentials: true,
  });
};

/**
 * rename people group folder
 * @param  {Number} id
 * @param  {String} name
 * @return {Promise}
 */
export const updatePeopleGroupFolderName = ({ id, name }) => {
  return axios({
    data: { name },
    method: 'put',
    url: `/v2/api/folders/${id}/rename`,
    withCredentials: true,
  });
};
