/**
 * transformSurveyGPT
 * @param {Object} response
 * @param {Boolean} response.active
 * @param {Number} response.id
 * @param {String} response.output
 * @param {String} response.status
 * @param {String} [response.status_message] supplied when an error occurs
 * @param {Boolean} [response.user_left_feedback]
 * @param {Boolean} [response.user_left_negative_feedback]
 * @return {Object}
 */
export const transformSurveyGPT = ({
  active: isActive,
  id,
  output,
  status,
  status_message: statusMessage,
  user_left_feedback: userLeftFeedback,
  user_left_negative_feedback: userLeftNegativeFeedback,
}) => ({
  id,
  isActive,
  output,
  status,
  statusMessage,
  userLeftFeedback,
  userLeftNegativeFeedback,
});
