import axios from '@api';

import { transformAutomatedReportsData } from './utilities/helpers';

/**
 * deletes automated reports
 * @param {Array} options.ids
 * @return {Promise}
 */
export const deleteAutomatedReports = ({ ids }) => {
  return axios({
    method: 'delete',
    url: `/v2/api/schedules/${ids[0]}`,
    withCredentials: true,
  });
};

/**
 * gets automated report list
 * @param {String} [options.order]
 * @param {String} [options.search]
 * @param {String} [options.sortBy]
 * @param {Attay} [options.tags]
 * @return {Promise}
 */
export const readAutomatedReports = ({ order, query, sortBy, tags }) => {
  return axios({
    method: 'get',
    params: {
      order_by: sortBy ? order : undefined,
      query: query?.length > 0 ? query : undefined,
      sort_by: sortBy,
      tags: tags?.length > 0 ? tags?.join(',') : undefined,
    },
    transformResponse: [
      (data) => transformAutomatedReportsData(JSON.parse(data), { order, query, sortBy, tags }),
    ],
    url: `/api/schedule/`,
    withCredentials: true,
  });
};
