import PropTypes from 'prop-types';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';

import { columnChartConfiguration } from './utilities/helpers';

const ColumnChart = ({ series, xAxis, yAxis, ...props }) => {
  return (
    <Chart
      options={merge({}, columnChartConfiguration, {
        series,
        type: 'column',
        xAxis,
        yAxis,
        ...props,
      })}
      {...props}
    />
  );
};

ColumnChart.propTypes = {
  series: PropTypes.array,
  xAxis: PropTypes.object,
  yAxis: PropTypes.object,
};

export default ColumnChart;
