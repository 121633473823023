import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import { ChevronBoldIcon } from '../../icons/ui';

import styles from './_index.module.scss';

const Accordion = ({ children, className, icon, id = nanoid(), isDefaultOpen, label }) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  return (
    <div className={classNames(styles['accordion'], className)}>
      <h6>
        <button
          aria-controls={`${id}-region`}
          aria-expanded={isOpen}
          className={styles['accordion-button']}
          id={id}
          onClick={() => setIsOpen(!isOpen)}
        >
          {icon && <span className={styles['accordion-icon']}>{icon}</span>}
          {label}
          <span className={styles['accordion-chevron']}>
            <ChevronBoldIcon />
          </span>
        </button>
      </h6>
      <div
        aria-labelledby={id}
        className={styles['accordion-region']}
        hidden={!isOpen}
        id={`${id}-region`}
        role="region"
      >
        {children}
      </div>
    </div>
  );
};

Accordion.defaultProps = {
  isDefaultOpen: false,
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
  id: PropTypes.string,
  isDefaultOpen: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default Accordion;
