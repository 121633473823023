import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MinusIcon, PlusIcon } from '../../icons';

import styles from './_index.module.scss';

const MinusPlusButtons = ({
  disableMinusButton,
  disablePlusButton,
  hasMinusButton,
  hasPlusButton,
  isNeutral,
  onClickMinus,
  onClickPlus,
}) => {
  return (
    <span className={styles['minus-plus-buttons']}>
      {hasMinusButton && (
        <button
          className={classNames(styles[`button-minus-icon`], { [styles['is-neutral']]: isNeutral })}
          disabled={disableMinusButton}
          onClick={onClickMinus}
          data-testid="button-minus-icon"
        >
          <MinusIcon />
        </button>
      )}
      {hasPlusButton && (
        <button
          className={classNames(styles[`button-plus-icon`], { [styles['is-neutral']]: isNeutral })}
          disabled={disablePlusButton}
          onClick={onClickPlus}
          data-testid="button-plus-icon"
        >
          <PlusIcon />
        </button>
      )}
    </span>
  );
};

MinusPlusButtons.defaultProps = {
  disableMinusButton: false,
  disablePlusButton: false,
  hasMinusButton: true,
  hasPlusButton: true,
  isNeutral: false,
  onClickMinus: () => {},
  onClickPlus: () => {},
};

MinusPlusButtons.propTypes = {
  disableMinusButton: PropTypes.bool,
  disablePlusButton: PropTypes.bool,
  hasMinusButton: PropTypes.bool,
  hasPlusButton: PropTypes.bool,
  isNeutral: PropTypes.bool,
  onClickMinus: PropTypes.func,
  onClickPlus: PropTypes.func,
};
export default MinusPlusButtons;
