import axios from 'axios';

import { AUTH_HEADER } from './utilities/constants';
import { readApiServiceToken } from '../tokens';

/**
 * Instant Survey service api instance requiring JWT auth header (setup in redux/sagas/user)
 * @type {Object}
 */
const api = axios.create({
  baseURL: `${process.env.REACT_APP_INSTANT_SURVEY_ENDPOINT}/instantsurvey`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
});

/**
 * On a service token expiration error (401), request a new token then retry the initial request
 */
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        const newToken = await readApiServiceToken();

        error.config.headers[AUTH_HEADER] = newToken;
        setInstantSurveyAuthToken(newToken);

        return api(error.config);
      } catch (tokenError) {
        throw tokenError;
      }
    }
    return Promise.reject(error);
  }
);

export const setInstantSurveyAuthToken = (token) => {
  api.defaults.headers.common[AUTH_HEADER] = token;
};

export default api;
