export const tabOptions = {
  ALL_COLLEAGUES: 'all-colleagues',
  FIND_COLLEAGUES: 'find-colleagues',
  SHARE_PUBLICLY: 'share-publicly',
};

/**
 * transforms and formats comment data
 * @param  {String} activeTab the tab of the modal the user is on
 * @param  {String} comment text
 * @param  {Array} selectedUsers array of the selected users to share to
 * @param  {Number} selectedValue the value of the dropdown for all users tab
 * @return {String} combines transformed userIds ( @{{userId}} to display for mentions ) and comment into string
 */
export const getCommentText = ({ activeTab, comment, selectedUsers, selectedValue }) => {
  let mentions = '';
  if (activeTab === tabOptions.ALL_COLLEAGUES) {
    mentions = selectedValue === 1 ? '@{{all-current-users}}' : '@{{all-current-and-future-users}}';
  }
  if (activeTab === tabOptions.FIND_COLLEAGUES) {
    mentions = selectedUsers.map((userId, index) => {
      return `@{{${userId.value}}}`;
    });
  }
  return mentions + ' ' + comment;
};

export const attemptToShare = async ({
  activeTab,
  allUserIds,
  clientId,
  isFolder,
  ids,
  selectedUserIds,
  selectedValue,
  shareFoldersWithClients,
  shareFoldersWithUsers,
  shareItemWithClients,
  shareItemWithUsers,
}) => {
  if (activeTab === tabOptions.ALL_COLLEAGUES) {
    if (selectedValue === 1) {
      isFolder
        ? shareFoldersWithUsers({
            folderIds: ids,
            skipEmail: true,
            userIds: allUserIds,
          })
        : await shareItemWithUsers({
            ids,
            skipEmail: true,
            userIds: allUserIds,
          });
    }
    if (selectedValue === 2) {
      isFolder
        ? await shareFoldersWithClients({
            clientId,
            folderIds: ids,
          })
        : await shareItemWithClients({ clientId, ids });
    }
  }

  if (activeTab === tabOptions.FIND_COLLEAGUES) {
    isFolder
      ? await shareFoldersWithUsers({
          folderIds: ids,
          userIds: selectedUserIds,
        })
      : await shareItemWithUsers({
          ids,
          userIds: selectedUserIds,
        });
  }
};
