import { useState } from 'react';
import PropTypes from 'prop-types';

import { IconCTA, OverlayTriggerTooltip, ShareIcon } from '@utilities';

import ModalShare from '@src/app/pages/Dashboard/components/ModalShare';

const Share = ({
  hasException,
  hasSharePublicly,
  isHoverAction,
  isSelectedFolders,
  isSelectedItems,
  onSubmit,
  selectedRows,
  shareWithClient,
  shareWithUsers,
}) => {
  const [isActive, setIsActive] = useState(false);

  const isVisible = !hasException;

  if (!isVisible) return null;

  const selectedRowsIds = selectedRows?.map((selectedRow) =>
    isSelectedFolders ? selectedRow?.folderId : selectedRow?.id
  );

  return (
    <>
      <OverlayTriggerTooltip content={isHoverAction ? 'Share' : ''}>
        <IconCTA
          data-testid="share-cta"
          icon={<ShareIcon />}
          onClick={() => setIsActive(true)}
          text={isHoverAction ? '' : 'Share'}
        />
      </OverlayTriggerTooltip>
      {isActive && (
        <ModalShare
          hasSharePublicly={hasSharePublicly}
          ids={selectedRowsIds}
          isFolder={isSelectedFolders}
          onSubmit={() =>
            onSubmit({ refreshItems: isSelectedItems, refreshFolders: isSelectedFolders })
          }
          setShowModalShare={setIsActive}
          shareFoldersWithClients={shareWithClient}
          shareFoldersWithUsers={shareWithUsers}
          shareItemWithClients={shareWithClient}
          shareItemWithUsers={shareWithUsers}
        />
      )}
    </>
  );
};

Share.defaultProps = {
  hasSharePublicly: false,
};

Share.propTypes = {
  hasException: PropTypes.bool,
  hasSharePublicly: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  isSelectedFolders: PropTypes.bool,
  isSelectedItems: PropTypes.bool,
  onSubmit: PropTypes.func,
  selectedRows: PropTypes.array,
  shareWithClient: PropTypes.func,
  shareWithUsers: PropTypes.func,
};

export default Share;
