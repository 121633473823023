import { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { logout } from '@redux/slices/user';

import api from '@api';

const Interceptors = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const responses = useRef(null);

  useMemo(() => {
    api.interceptors.response.eject(responses.current);

    responses.current = api.interceptors.response.use(
      (response) => response,
      (error) => {
        switch (error?.response?.status) {
          case 401:
            dispatch(logout({ isAuthenticated: false, location, navigate }));
            break;
          default:
            break;
        }
        return Promise.reject(error);
      }
    );
  }, [location]);

  return null;
};

export default Interceptors;
