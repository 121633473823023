import PropTypes from 'prop-types';
import { TableData } from '@utilities';

import styles from './_index.module.scss';

const ResultTable = ({ conditions, isLoading, tableData, type }) => {
  const modifiedTableData = tableData.map((obj) => {
    const modifiedObj = {};
    for (const [key, value] of Object.entries(obj)) {
      modifiedObj[key] = value.name;
    }
    return modifiedObj;
  });

  const renderColumns = () => {
    switch (type) {
      case 'product':
        return [
          {
            accessor: 'sector',
            format: 'text',
            Header: 'Sector',
          },
          {
            accessor: 'dept',
            format: 'text',
            Header: 'Department',
          },
          {
            accessor: 'majorcat',
            format: 'text',
            Header: 'Major Category',
          },
          {
            accessor: 'category',
            format: 'text',
            Header: 'Category',
          },
          {
            accessor: 'subcat',
            format: 'text',
            Header: 'Subcategory',
          },
          {
            accessor: 'manufacturer',
            format: 'text',
            Header: 'Manufacturer',
          },
          {
            accessor: 'parentbrand',
            format: 'text',
            Header: 'Parent Brand',
          },
          {
            accessor: 'brand',
            format: 'text',
            Header: 'Brand',
          },
        ];
      case 'store':
        return [
          {
            accessor: 'parentchannel',
            format: 'text',
            Header: 'Parent Channel',
          },
          {
            accessor: 'channel',
            format: 'text',
            Header: 'Channel',
          },
          {
            accessor: 'expanded_online_channel',
            format: 'text',
            Header: 'Sub Channel',
          },
          {
            accessor: 'retailer',
            format: 'text',
            Header: 'Retailer',
          },
          {
            accessor: 'banner',
            format: 'text',
            Header: 'Banner',
          },
        ];
      default:
        return;
    }
  };

  return (
    <div className={styles['result-table']}>
      <TableData
        columns={renderColumns()}
        data={modifiedTableData}
        isLoading={isLoading}
        renderEmptyMessage={() =>
          conditions?.breakoutConditions?.length > 0 ? (
            <>
              <h3>No results found.</h3>
              <p>Try refining your statements.</p>
            </>
          ) : (
            <>
              <h3>Results will appear here.</h3>
              <p>Add statements to see what is included.</p>
            </>
          )
        }
      />
    </div>
  );
};

ResultTable.propTypes = {
  conditions: PropTypes.object,
  isLoading: PropTypes.bool,
  tableData: PropTypes.array,
  type: PropTypes.string,
};

export default ResultTable;
