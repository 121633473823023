import PropTypes from 'prop-types';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';
import { barStackChartConfiguration } from './utilities/helpers';

const BarStackChart = ({ series, xAxis, yAxis, ...props }) => {
  return (
    <Chart
      options={merge({}, barStackChartConfiguration, {
        series,
        type: 'bar',
        xAxis,
        yAxis,
        ...props,
      })}
      {...props}
    />
  );
};

BarStackChart.propTypes = {
  series: PropTypes.array,
  xAxis: PropTypes.object,
  yAxis: PropTypes.object,
};

export default BarStackChart;
