import uniqBy from 'lodash/uniqBy';

/**
 * gets default column filter option
 * @param  {Object} column
 * @return {Object}
 */
export const getColumnFilterDefaultOption = (column) => {
  return {
    label: column?.label,
    option: column?.options[0],
  };
};

/**
 * gets column filter options
 * @param  {Array}  options.filters
 * @param  {String} options.label
 * @return {Array}
 */
export const getColumnFilterOptions = ({ filters, label }) => {
  return filters?.find((filter) => filter?.label === label)?.options;
};

/**
 * gets filter options based on id
 * @param  {Array} sets
 * @return {Array}
 */
export const getIdFilterOptions = (sets) => {
  return sets.map((set) => {
    return {
      label: set?.name,
      value: set?.id,
    };
  });
};

/**
 * gets default filter option based on id
 * @param  {Number} options.id
 * @param  {Array}  options.sets
 * @return {Object}
 */
export const getIdFilterDefaultOption = ({ id, sets }) => {
  const options = getIdFilterOptions(sets);

  return id ? options[0] : false;
};

/**
 * gets group filter options based on group
 * @param  {String} options.group
 * @param  {Array}  options.sets
 * @param  {String} options.type
 * @return {Object}
 */
export const getGroupFilterOptions = ({ group, sets, type }) => {
  const options = uniqBy(
    sets?.map((set) => {
      return {
        label: set?.metadata?.[type][group],
        value: set?.metadata?.[type][group],
      };
    }),
    'value'
  );

  return {
    group,
    options,
  };
};

/**
 * gets group filter default option based on group
 * @param  {String} options.group
 * @param  {Array}  options.sets
 * @param  {String} options.type
 * @return {Object}
 */
export const getGroupFilterDefaultOption = ({ group, sets, type }) => {
  return {
    group,
    option: getGroupFilterOptions({ group, sets, type })?.options[0],
  };
};

/**
 * gets row filter options based on field
 * @param  {String} options.field
 * @param  {Array}  options.set
 * @return {Object}
 */
export const getRowFilterOptions = ({ field, set }) => {
  const options = uniqBy(
    set?.data?.map((row) => {
      return {
        label: row[field],
        value: row[field],
      };
    }),
    'value'
  );

  return {
    field,
    options,
  };
};

/**
 * gets row filter default option based on field
 * @param  {String} options.field
 * @param  {Array} options.set
 * @return {Object}
 */
export const getRowFilterDefaultOption = ({ field, set }) => {
  return {
    field,
    option: getRowFilterOptions({ field, set })?.options[0],
  };
};

/**
 * gets row filter options based on field for Bullet chart
 * @param  {String} axis
 * @param  {Array}  data
 * @return {Object}
 */
export const getBulletChartAxisOptions = ({ axis, data }) => {
  const axisList = axis.split(',');
  const categories = data?.map((row) => {
    if (axisList.length > 1) {
      return `${row[axisList[0]]} and ${row[axisList[1]]}`;
    } else {
      return row[axisList[0]];
    }
  });
  return [{ categories }];
};

/**
 * gets row filter options based on field
 * @param  {String} axis
 * @param  {Array}  data
 * @return {Object}
 */
export const getChartAxisOptions = ({ axis, data }) => {
  const categories = data?.map((row) => {
    if (row[axis] === 'NAN_SENTINEL') return '-';
    return row[axis];
  });
  return [{ categories }];
};

/**
 *
 * @param {String} reportType type of report e.g. Shopper Profile
 * @param {String} reportName user-provided name e.g. Andrew's Shopper Analysis
 * @param {String} tabName name of tab in focus e.g. Histogram Chart
 * @returns {String} filename for download
 */
export const getSanitizedFilename = ({ reportType = '', reportName = '', tabName = '' }) => {
  let reportDocumentName = `${reportType}${reportName ? `_${reportName}` : ''}_${tabName}`.replace(
    /\s/g,
    '_'
  );

  reportDocumentName = reportDocumentName.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  reportDocumentName = reportDocumentName.replace(/[^a-zA-Z0-9_-]/g, '');

  return reportDocumentName;
};
