export const mapSurveyTypeToRoute = ({ description, help_url, id, name }) => ({
  description,
  help_url,
  id,
  label: name,
  path: id,
});

export const SURVEY_TABS = [
  {
    id: 'instant-surveys',
    label: 'Instant Surveys',
    path: 'instant-surveys',
    target: 'package.instant_surveys',
    type: 'survey',
  },
  {
    id: 'survey-lab',
    label: 'Survey Labs',
    path: 'survey-lab',
    target: 'package.survey_lab',
    type: 'survey',
  },
];
