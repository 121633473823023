import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import sha1 from 'crypto-js/sha1';
import LogRocket from 'logrocket';
import { loadIntercom } from './utilities/helpers';

const Intercom = () => {
  const { data, isDataLoaded } = useSelector((state) => state.user);
  const { clientInfo, email, firstName, id, lastName } = data;
  useEffect(() => {
    if (!window.Intercom) {
      loadIntercom();
    }
    if (isDataLoaded) {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM,
        company: {
          id: sha1(clientInfo?.name).toString(),
          name: clientInfo?.name,
        },
        email,
        name: `${firstName} ${lastName}`,
        user_id: sha1(id.toString()).toString(),
      });
      LogRocket.getSessionURL((sessionURL) => {
        window.Intercom('trackEvent', 'LogRocket', { sessionURL });
      });

      return () => window.Intercom('shutdown');
    }
  }, [isDataLoaded]);

  return null;
};

export default Intercom;
