import { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MeatballsIcon } from '../../icons';
import OverlayPopover from '../../components/OverlayPopover';

import styles from './_index.module.scss';

const ActionOverlayPopover = ({ children, hasArrow, isActive, isDisabled, onClick, onHide }) => {
  const popoverRef = useRef(null);

  return (
    <div className={styles['action-overlay-popover']}>
      <div
        className={classNames(styles['action-overlay-popover-ellipsis'], {
          [styles['is-active']]: isActive,
          [styles['is-disabled']]: isDisabled,
        })}
        data-testid="action-overlay-popover-icon"
        onClick={isDisabled ? () => {} : onClick}
        ref={popoverRef}
      >
        <MeatballsIcon />
      </div>
      {isActive && (
        <OverlayPopover
          hasArrow={hasArrow}
          isActive={isActive}
          onHide={onHide}
          target={popoverRef?.current}
        >
          {children}
        </OverlayPopover>
      )}
    </div>
  );
};

ActionOverlayPopover.defaultProps = {
  onClick: () => {},
};

ActionOverlayPopover.propTypes = {
  children: PropTypes.node.isRequired,
  hasArrow: PropTypes.bool,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  onHide: PropTypes.func,
};

export default ActionOverlayPopover;
