/**
 * gets cleave options based on type prop
 * @param  {String} type
 * @return {Object}
 */
export const getCleaveOptions = (type) => {
  switch (type) {
    case 'decimal':
      return {
        numeral: true,
        numeralDecimalScale: 2,
        numeralPositiveOnly: true,
      };
    case 'dollar':
      return {
        numeral: true,
        numeralDecimalScale: 2,
        numeralPositiveOnly: true,
        prefix: '$',
        rawValueTrimPrefix: true,
        stripLeadingZeroes: true,
      };
    case 'percentage':
      return {
        numeral: true,
        numeralDecimalScale: 0,
        numeralIntegerScale: 2,
        numeralPositiveOnly: true,
      };
    default:
      return {};
  }
};

/**
 * gets default placeholder based on type prop
 * @param  {String} type
 * @return {Object}
 */
export const getPlaceholder = (type) => {
  switch (type) {
    case 'decimal':
      return '--';
    case 'dollar':
      return '$';
    case 'percentage':
      return '%';
    default:
      return '';
  }
};
