export const surveyDatePromptIds = [
  'instant_survey_date_range',
  'instant_survey_date_range_no_lag',
];

export const surveyDescriptionDefault = 'Standard Survey (6-15 Questions)';
export const surveyDescriptionLong = 'Long Survey (16-20 Questions)';
export const surveyDescriptionShort = 'Short Survey (1-5 Questions)';

export const surveyLengthLong = 16;
export const surveyLengthShort = 5;

export const surveyStatus = {
  CREATED: 1,
  PUBLISHING: 2,
  PUBLISHED: 3,
  FIELDING: 4,
  FIELDED: 5,
  COMPLETED: 6,
  EXCEPTION: 7,
  LOW_SAMPLE: 8,
  INCOMPLETE: 9,
  MET_SAMPLE: 10,
  IN_REVIEW: 11,
  DRAFT: 12,
};
export const surveyTypeQuestionId = {
  'ask-why-instant-survey-type': 'ask_why_questions',
  'buyer-instant-survey-type': 'buyer_questions',
  'buyer-v2-instant-survey-type': 'buyer_questions',
  'concept-screening-instant-survey-type': 'test_panel_questions',
  'concept-screening-v2-instant-survey-type': 'test_panel_questions',
  'lapsed-repeat-new-instant-survey-type': 'lapsed_repeat_new_questions',
  'lapsing-brand-instant-survey-type': 'lapsing_brand_survey_questions',
  'lapsing-brand-v2-instant-survey-type': 'lapsing_brand_survey_questions',
  'leakage-instant-survey-type': 'leakage_survey_questions',
  'leakage-v2-instant-survey-type': 'leakage_survey_questions',
  'quick-pulse-instant-survey-type': 'quick_pulse_questions',
  'quick-pulse-v2-instant-survey-type': 'quick_pulse_questions',
  'test-survey-instant-survey-type': 'test_survey_questions',
};

export const customFolderId = 2;
export const recommendedFolderId = 1;

export const defaultGroupName = 'Panelist Group';
export const defaultResponseCount = 200;
export const maxQuestions = 15;
export const maxQuestionError = 'Maximum number of questions has been reached.';
export const minAskWhyCredits = 0.5;
export const minQuestions = 1;
export const minSampleSize = 50;
export const responseCountIncrement = 50;
export const responseCountMinimum = 50;

export const placeholderImage =
  'https://www.numerator.com/sites/default/files/2023-03/DW%20WEB%20HEADER-100_0_0.jpg';

export const questionLogicOperators = {
  ALL: {
    value: 'ALL',
    text: 'All of the Following',
  },
  ANY: {
    value: 'ANY',
    text: 'Any of the Following',
  },
  NOT: {
    value: 'NOT',
    text: 'Not Any of the Following',
  },
};
export const questionTypes = {
  checkbox: { value: 'CHECKBOX', label: 'Multiple Select (Check Box)', name: 'Multiple Select' },
  essay: { value: 'ESSAY', label: 'Open End (Essay)', name: 'Open End' },
  radio: { value: 'RADIO', label: 'Single Select (Radio Button)', name: 'Single Select' },
};

export const surveyPageSize = 20;
