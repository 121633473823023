import generationAlpha from './assets/generation-alpha.png';
import healthHabits from './assets/health-habits.png';
import homeImprovementTrends from './assets/home-improvement-trends.png';
import snap from './assets/snap.png';

import styles from './_index.module.scss';

const ThoughtLeadership = () => {
  return (
    <div className={styles['thought-leadership']}>
      <h2>
        <strong>Thought Leadership</strong>
      </h2>
      <p>Know more about the changing omnichannel marketplace.</p>
      <hr></hr>
      <ul>
        <li>
          <a
            href="https://www.numerator.com/resources/report/generation-alpha?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={generationAlpha} alt="generation-alpha" />
            <small>REPORT</small>
            <p>Anticipating Gen Alpha</p>
          </a>
        </li>
        <li>
          <a
            href="https://www.numerator.com/resources/report/health-habits?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={healthHabits} alt="health-habits" />
            <small>REPORT</small>
            <p>Health Habits</p>
          </a>
        </li>
        <li>
          <a
            href="https://www.numerator.com/resources/blog/home-improvement-trends?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={homeImprovementTrends} alt="home-improvement-trends" />
            <small>ARTICLE</small>
            <p>Home Improvement Trends</p>
          </a>
        </li>
        <li>
          <a
            href="https://www.numerator.com/snap?utm_source=insights.numerator.com&utm_medium=referral&utm_campaign=thought-leadership-dashboard"
            rel="noreferrer"
            target="_blank"
          >
            <img src={snap} alt="snap" />
            <small>TRENDING INSIGHTS</small>
            <p>SNAP Hub</p>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default ThoughtLeadership;
