import PropTypes from 'prop-types';

import { IconCTA, OverlayTriggerTooltip, RepromptIcon } from '@utilities';

const Reprompt = ({ canReprompt, hasException, hasExpiration, isHoverAction, selectedRows }) => {
  const isVisible = selectedRows.length === 1 && canReprompt && !hasException && !hasExpiration;

  if (!isVisible) return null;

  return (
    <OverlayTriggerTooltip content={isHoverAction ? 'Reprompt' : ''}>
      <IconCTA
        data-testid="reprompt-cta"
        icon={<RepromptIcon />}
        path={`/dashboard/create-report/${selectedRows[0]?.documentId}?job_id=${selectedRows[0]?.id}`}
        text={isHoverAction ? '' : 'Reprompt'}
      />
    </OverlayTriggerTooltip>
  );
};

Reprompt.propTypes = {
  canReprompt: PropTypes.bool,
  hasException: PropTypes.bool,
  hasExpiration: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  selectedRows: PropTypes.array,
};

export default Reprompt;
