import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { setEndpoint } from '@helpers';

import { DownloadIcon, OverlayTriggerTooltip } from '@utilities';

import styles from './_index.module.scss';

const Download = ({ isAvailable, reportId }) => {
  let { job_id: jobId } = useParams();

  const id = reportId ? reportId : jobId;
  const downloadFileUrl = setEndpoint(`v2/api/jobs/${id}/export`);

  return (
    <OverlayTriggerTooltip
      aria-label="Download"
      content={
        isAvailable
          ? 'Download as .XLSX file'
          : 'Download will be available after report has finished processing'
      }
    >
      <a
        className={classNames(styles['download'], { [styles['is-disabled']]: !isAvailable })}
        data-testid="download"
        download
        href={downloadFileUrl}
      >
        <DownloadIcon />
      </a>
    </OverlayTriggerTooltip>
  );
};

Download.propTypes = {
  isAvailable: PropTypes.bool,
  reportId: PropTypes.number,
};

export default Download;
