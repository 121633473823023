/**
 * List of domains that are considered part of the Verified Voices platform.
 * These domains are used to determine if the current site belongs to Verified Voices.
 *
 * @type {string[]}
 */
const VERIFIED_VOICES_DOMAINS = [
  'verifiedvoices.insights.dev.panel-insights.numerator.cloud',
  'verifiedvoices.insights-ca.dev.panel-insights.numerator.cloud',
  'verifiedvoices.insights.stg.panel-insights.numerator.cloud',
  'verifiedvoices.insights-ca.stg.panel-insights.numerator.cloud',
  'verifiedvoices.insights.numerator.com',
  'ca.verifiedvoices.insights.numerator.com',
];

/**
 * Checks if the current URL belongs to a Verified Voices domain.
 *
 * This function normalizes the hostname by:
 * - Removing the 'www.' prefix if present
 * - Stripping query parameters and fragments
 *
 * @returns {boolean} `true` if the current site is a Verified Voices domain, otherwise `false`.
 */
export const isVerifiedVoices = () => {
  let hostname = window.location.hostname;

  // Remove 'www.' if present
  hostname = hostname.replace(/^www\./, '');

  return VERIFIED_VOICES_DOMAINS.includes(hostname);
};
