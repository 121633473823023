import axios from 'axios';
import Cookies from 'js-cookie';

/**
 * base instance and global settings for axios
 * @type {Object}
 */
const api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : '',
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    (data, headers) => {
      headers['X-CSRFToken'] = Cookies.get('csrftoken');
      return JSON.stringify(data);
    },
  ],
});

export default api;
