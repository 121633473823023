import keyBy from 'lodash/keyBy';
import moment from 'moment';
import convert from 'xml-js';

import { reportStatus, tabStatus } from '@helpers';

import { transformPrompt } from '@src/app/pages/Dashboard/components/Builder/utilities/helpers.js';

const transformComment = (data) => {
  return {
    client: data?.client,
    commentId: data?.comment_id,
    commentOwnerUserId: data?.user_id,
    date: data?.date,
    tabId: data?.job_item_id,
    text: data?.text,
    time: data?.time,
    user: data?.user,
    views: {
      viewedBy: data?.views?.viewed_by?.map((user) => {
        return transformViewer(user);
      }),
      viewCount: data?.views?.view_count,
      viewed: data?.views?.viewed,
    },
  };
};

/**
 * transforms and formats comment data
 * @param  {Object} data object keys using snake_case
 * @return {Object} commentData object keys using camelCase
 */
export const transformCommentData = (data) => {
  let commentData;
  if (Array.isArray(data)) {
    commentData = data.map((data) => {
      return transformComment(data);
    });
  } else {
    commentData = transformComment(data);
  }
  return commentData;
};

/**
 * transforms and formats report data V1
 * @param  {Object} data
 * @return {Object}
 */
export const transformReportData = (data) => {
  const askWhyPeopleGroups = data?.ask_why_people_group_types?.map((peopleGroup) => ({
    id: peopleGroup?.id,
    isDefaultFrozen: peopleGroup?.frozen,
    name: peopleGroup?.name,
  }));
  const peopleGroupTypes = data?.people_group_types?.map((peopleGroupType) => ({
    id: peopleGroupType?.id,
    isDefaultFrozen: peopleGroupType?.frozen,
    name: peopleGroupType?.name,
  }));

  const tabs = data?.items.map((item) => {
    return {
      id: item?.id,
      label: item?.document_item?.name,
      status: item?.status,
    };
  });
  if (data?.ask_why_instant_surveys?.length > 0) {
    tabs.push({
      id: 'ask-why',
      label: 'AskWhy',
    });
  }

  return {
    advancedPrompts: data?.document?.prompts
      .filter((prompt) => prompt?.advanced)
      .map((prompt) => {
        return transformPrompt(prompt);
      }),
    answers: data?.answers,
    askWhyInstantSurveys: data?.ask_why_instant_surveys?.map((askWhyInstantSurvey) => ({
      documentId: askWhyInstantSurvey.ask_why_document_id,
      id: askWhyInstantSurvey.id,
      peopleGroupTypeId: askWhyInstantSurvey.ask_why_people_group_type_id,
      publicUrl: askWhyInstantSurvey.provider_report_public_url,
      title: askWhyInstantSurvey.title,
    })),
    askWhyPeopleGroups,
    createdDateTime: new Date(data?.queue_datetime).toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    defaultAnswers: data?.document?.default_answers,
    documentId: data?.document?.id,
    helpUrl: data?.document?.help_url,
    id: data?.id,
    instantSurveyId: data?.instant_survey_id,
    isFavorite: data?.is_favorite,
    isOwner: data?.is_owner,
    isReadOnly: data?.read_only,
    madlib: data?.document?.madlib || data?.document?.prompts_madlib,
    madlibAdditional: data?.document?.prompts_madlib_additional,
    madlibAdditionalLimit: data?.document?.prompts_madlib_additional_limit,
    name: data?.name || data?.document?.title,
    numberCompleteTabs: data?.items?.filter((item) => item?.status >= tabStatus.COMPLETED)?.length,
    numberIncompleteTabs: data?.items?.filter((item) => item?.status < tabStatus.COMPLETED)?.length,
    peopleGroupTypes,
    permissions: {
      canCreatePeopleGroups: data?.document?.spg_in_doc_header,
      canExport: data?.document?.can_export,
      canFavorite: data?.document?.can_favorite,
      canReprompt: data?.document?.can_reprompt,
      canShare: data?.document?.can_share,
    },
    prompts: data?.document?.prompts
      .filter((prompt) => !prompt?.advanced)
      .map((prompt) => {
        return transformPrompt(prompt);
      }),
    status: data?.status,
    surveyGPTJobId: data?.surveygpt_job_id,
    tabs,
    tags: data?.tags,
  };
};

/**
 * transforms and formats report data V2
 * @param  {Object} data
 * @return {Object}
 */
export const transformReportItemData = (data) => {
  return {
    createdDateTime: new Date(data?.queue_datetime).toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    creator: data?.creator,
    documentId: data?.document?.id,
    folderEntryId: data?.folder_entry_id,
    hasAskWhy: data?.ask_why_instant_surveys > 0,
    hasException: data?.status > reportStatus.COMPLETED,
    hasExpiration: data?.status === reportStatus.OUT_OF_DATE,
    id: data?.id,
    isCompleted: data?.status === reportStatus.COMPLETED,
    isDataWaveStatic: data?.is_data_wave_static,
    isFavorite: data?.is_favorite,
    isItem: true,
    isLocked: data?.is_locked ?? false,
    isOwner: !data?.is_locked ?? true,
    isReadOnly: data?.read_only,
    isShared: data?.is_shared,
    madlib: data?.madlib,
    name: data?.name,
    numberCompleteTabs: data?.complete_job_items,
    numberIncompleteTabs: data?.incomplete_job_items,
    permissions: {
      canFavorite: data?.document?.can_favorite,
      canReprompt: data?.document?.can_reprompt,
      canShare: data?.document?.can_share,
    },
    type: data?.document?.name,
    status: data?.status,
  };
};

/**
 * transforms and formats report list items
 * @param  {Object} data
 * @return {Object}
 */
export const transformReportItemsData = (data) => {
  return {
    items: data?.items?.map((item) => {
      return transformReportItemData(item);
    }),
    page: data?.page,
    size: data?.size,
    total: data?.total,
  };
};

/**
 * transforms and formats single report folder
 * @param  {Object} folder
 * @return {Object}
 */
export const transformReportFolder = (folder) => {
  return {
    createdDatetime: folder?.created_datetime,
    creator: folder?.creator,
    folderId: folder?.id,
    id: folder?.folderentry_id,
    isFolder: true,
    isLocked: folder?.is_locked ?? false,
    isOwner: !folder?.is_locked,
    isShared: folder?.is_shared ?? false,
    name: folder?.name,
    numberOfItems: folder?.number_of_items,
  };
};

/**
 * transforms and formats report folders
 * @param  {Object} data
 * @return {Object}
 */
export const transformReportFolders = (data) => {
  return {
    folders: data?.items?.map((folder) => {
      return transformReportFolder(folder);
    }),
    page: data?.page,
    size: data?.size,
    total: data?.total,
  };
};

/**
 * transforms and forms report prompts
 * @param  {Object} data
 * @return {Object}
 */
export const transformReportPrompts = (data) => {
  return {
    ...keyBy(
      data?.prompts?.map((prompt) => transformPrompt(prompt)),
      'id'
    ),
    shopper_group: {
      name: 'Groups',
    },
  };
};

/**
 * transforms and formats Automate data to send to BE
 * @param  {Object} options.automateGroupName
 * @param  {Object} options.entries
 * @param  {Number} options.reportDestination
 * @param  {Number} options.reportId
 * @return {Object}
 */
export const transformReportScheduleData = ({
  automateGroupName,
  entries,
  reportDestination,
  reportId,
}) => {
  const entriesData = entries.map((entry) => ({
    data_end_date: entry.endDate,
    data_start_date: entry.startDate,
    name: entry.name,
    run_datetime: moment(entry.runOnDate).format(),
  }));
  return {
    entries: entriesData,
    name: automateGroupName,
    original_job_id: reportId,
    report_destination_id: reportDestination,
  };
};

/**
 * transforms and formats report tab data
 * @param  {Object} data
 * @return {Object}
 */
export const transformReportTabData = (data) => {
  const tab = convert.xml2js(data?.layout, {
    alwaysArray: true,
    attributesKey: '$',
    compact: true,
  })?.Tab?.[0];

  return {
    filters: {
      columns: tab?.ColumnFilter
        ? tab?.ColumnFilter?.map((filter) => {
            return {
              label: filter?.$?.label,
              options: filter.Option?.map((option) => {
                return {
                  label: option?.$?.label,
                  value: {
                    chartKey: option?.$?.chartKey?.split(','),
                    target: option?.$?.target?.split(','),
                  },
                };
              }),
            };
          })
        : [],
      id: tab?.DropdownSelector,
      groups: tab?.GroupFilter
        ? tab?.GroupFilter?.map((filter) => {
            return filter?.$?.group;
          })
        : [],
      rows: tab?.RowFilter
        ? tab?.RowFilter?.map((filter) => {
            return filter?.$?.field;
          })
        : [],
    },
    groupType: tab?.GroupFilter?.[0]?.$?.type
      ? `${tab?.GroupFilter?.[0]?.$?.type}_grouping`
      : 'table_grouping',
    jsx: data?.layout,
    sets: data?.data?.filter((set) => set?.type < 6),
    status: data?.status,
    summary: data?.summary,
  };
};

/**
 * transforms and formats individual user info
 * @param  {[Object]} user object keys using snake_case
 * @return {[Object]} user object keys using camelCase
 */
const transformViewer = (user) => {
  return {
    firstName: user?.first_name,
    lastName: user?.last_name,
  };
};
