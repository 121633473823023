import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FlowArrowIcon, MinusPlusButtons, TargetIcon } from '@utilities';

import QuestionLogicOptions from './components/QuestionLogicOptions';
import SkipLogicRow from './components/SkipLogicRow';
import { answerOperators, skipOperators } from './utilities/constants';

import styles from './_index.module.scss';

const QuestionLogic = ({
  firstErrorRef,
  isDetailedQuestionView,
  question,
  questionIndex,
  questionLogic,
  selectedQuestions,
  setQuestionLogic,
  type,
}) => {
  const addLogic = () => {
    // applicable to skip only currently
    const updatedLogic = { ...questionLogic };
    updatedLogic[question.id].skip.logic = [
      ...updatedLogic[question.id].skip.logic,
      {
        answers: [],
        operator: answerOperators[0],
        question: {},
      },
    ];
    setQuestionLogic(updatedLogic);
  };

  const removeLogic = (index) => {
    const updatedLogic = { ...questionLogic };
    if (type === 'disqualify') {
      delete updatedLogic[question.id].disqualify;
    } else {
      if (questionLogic[question.id].skip.logic.length === 1) {
        delete updatedLogic[question.id].skip;
      } else {
        updatedLogic[question.id].skip.logic.splice(index, 1);
      }
    }
    setQuestionLogic(updatedLogic);
  };

  const renderLogicIcon = () => {
    switch (type) {
      case 'disqualify':
        return <TargetIcon />;
      case 'skip':
        return <FlowArrowIcon />;
      default:
        return;
    }
  };

  const updateDisqualifyAnswers = (answers) => {
    const updatedLogic = { ...questionLogic };
    updatedLogic[question.id].disqualify.answers = answers;
    updatedLogic[question.id].disqualify.hasAnswerError = false;
    updatedLogic[question.id].disqualify.isFirstAnswerError = false;
    setQuestionLogic(updatedLogic);
  };

  const updateOperator = (operator) => {
    const updatedLogic = { ...questionLogic };
    updatedLogic[question.id][type].operator = operator;
    setQuestionLogic(updatedLogic);
  };

  return (
    <>
      {isDetailedQuestionView && type === 'disqualify' && (
        <div className={styles['question-logic-line']} />
      )}
      <div
        className={classNames(styles['question-logic'], {
          [styles['is-compact-view']]: !isDetailedQuestionView,
        })}
      >
        <span className={styles['question-logic-text']}>
          {renderLogicIcon()}
          {!isDetailedQuestionView && (
            <span>
              This question has&nbsp;
              <strong>{type === 'disqualify' ? 'disqualification logic' : 'question logic'}</strong>
            </span>
          )}
          {isDetailedQuestionView && (
            <>
              <div>
                {type === 'disqualify' && (
                  <>
                    <span>Disqualify if the answer is&nbsp;</span>
                    <QuestionLogicOptions
                      options={answerOperators}
                      optionsLabel="Operator"
                      optionsType="radio"
                      setValue={updateOperator}
                      value={questionLogic[question.id].disqualify.operator}
                    />
                    <span>:&nbsp;</span>
                    <QuestionLogicOptions
                      firstErrorRef={firstErrorRef}
                      isError={questionLogic[question.id].disqualify.hasAnswerError}
                      isFocused={questionLogic[question.id].disqualify.isFirstAnswerError}
                      isRequired
                      options={question.answers}
                      optionsLabel="Answer Options"
                      optionsType="checkbox"
                      setValue={updateDisqualifyAnswers}
                      value={questionLogic[question.id].disqualify.answers}
                    />
                  </>
                )}
                {type === 'skip' && (
                  <>
                    <span>Ask this question if&nbsp;</span>
                    <QuestionLogicOptions
                      options={skipOperators}
                      optionsLabel="Operator"
                      optionsType="radio"
                      setValue={updateOperator}
                      value={questionLogic[question.id].skip.operator}
                    />
                    <span>&nbsp;apply:</span>
                  </>
                )}
              </div>
            </>
          )}
        </span>
        {isDetailedQuestionView && (
          <span className={styles['question-logic-minus-plus-buttons']}>
            <MinusPlusButtons
              disablePlusButton={questionLogic[question.id].skip?.logic.length >= 2}
              hasPlusButton={type === 'skip'}
              isNeutral
              onClickMinus={removeLogic}
              onClickPlus={addLogic}
            />
          </span>
        )}
      </div>
      {isDetailedQuestionView && type === 'skip' && (
        <>
          {questionLogic[question.id].skip.logic.map((logic, index) => {
            return (
              <React.Fragment key={`${question.id}-skip-${index}`}>
                <div className={styles['question-logic-line']} />
                <div className={styles['question-logic']}>
                  <span className={styles['question-logic-text']}>
                    <SkipLogicRow
                      firstErrorRef={firstErrorRef}
                      index={index}
                      questionId={question.id}
                      questionList={[...selectedQuestions].splice(0, questionIndex)}
                      questionLogic={questionLogic}
                      setQuestionLogic={setQuestionLogic}
                    />
                  </span>
                  <span className={styles['question-logic-minus-plus-buttons']}>
                    <MinusPlusButtons
                      hasPlusButton={false}
                      isNeutral
                      onClickMinus={() => removeLogic(index)}
                    />
                  </span>
                </div>
              </React.Fragment>
            );
          })}
          <div className={styles['question-logic-line']} />
        </>
      )}
    </>
  );
};

QuestionLogic.propTypes = {
  firstErrorRef: PropTypes.object.isRequired,
  isDetailedQuestionView: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  questionIndex: PropTypes.number,
  questionLogic: PropTypes.object.isRequired,
  selectedQuestions: PropTypes.array,
  setQuestionLogic: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['disqualify', 'skip']).isRequired,
};

export default QuestionLogic;
