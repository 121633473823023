import axios from './api';

import { TEMPLATES_PER_PAGE } from './utilities/constants';
import { transformSurveyTemplates } from './utilities/helpers';

/**
 * read survey template
 * @param {Object} options
 * @param {String} [options.instantSurveyType]
 * @param {Number} [options.limit]
 * @param {Number} [options.offset]
 * @param {String} [options.ordering]
 * @param {String} [options.search]
 * @param {AbortSignal} options.signal
 * @return {Promise}
 */

export const readSurveyTemplates = async ({
  instantSurveyType: instant_survey_type_id,
  limit = TEMPLATES_PER_PAGE,
  offset,
  ordering,
  search,
  signal,
}) => {
  return axios({
    method: 'get',
    params: { instant_survey_type_id, limit, offset, ordering, search },
    signal,
    url: `/surveytemplate`,
  }).then(({ data }) => transformSurveyTemplates(data));
};
