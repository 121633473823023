import { cloneElement, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { ClearSelectedIcon, IconCTA } from '@utilities';

import { getConditions } from './utilities/helpers';

import styles from './_index.module.scss';

const ActionsToolbar = ({
  actions,
  expandedFolder,
  isHoverAction = false,
  itemsPage,
  itemsRow,
  onClear,
  onSubmit,
  selectedRows,
  row,
  setVisibleActionsCount,
  type,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isHoverAction) {
      setVisibleActionsCount(ref?.current?.childElementCount);
    }
  }, []);

  return isHoverAction ? (
    <div className={styles['hover-actions']} ref={ref}>
      {actions?.map((action, index) =>
        cloneElement(action, {
          expandedFolder,
          key: `action-${index}`,
          isHoverAction,
          itemsPage,
          itemsRow,
          onSubmit,
          selectedRows,
          row,
          type,
          ...getConditions(selectedRows),
        })
      )}
    </div>
  ) : (
    <div className={styles['actions-toolbar-wrapper']}>
      <div className={styles['actions-toolbar']}>
        <div>
          <IconCTA
            icon={<ClearSelectedIcon />}
            onClick={onClear}
            text={`Clear selected (${selectedRows.length})`}
          />
        </div>
        <div className={styles['actions-toolbar-divider']} />
        <div>
          {actions.map((action, index) =>
            cloneElement(action, {
              expandedFolder,
              key: `action-${index}`,
              itemsPage,
              onSubmit,
              selectedRows,
              row,
              type,
              ...getConditions(selectedRows),
            })
          )}
        </div>
      </div>
    </div>
  );
};

ActionsToolbar.propTypes = {
  actions: PropTypes.array.isRequired,
  expandedFolder: PropTypes.object,
  isHoverAction: PropTypes.bool,
  itemsPage: PropTypes.number,
  itemsRow: PropTypes.string,
  onClear: PropTypes.func,
  onSubmit: PropTypes.func,
  row: PropTypes.object,
  selectedRows: PropTypes.array.isRequired,
  setVisibleActionsCount: PropTypes.func,
  type: PropTypes.string,
};

export default ActionsToolbar;
