import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DownloadIcon, IconCTA, OverlayTriggerTooltip } from '@utilities';
import { createBulkDownloadReports } from '@api/reports';

import styles from './_index.module.scss';

const BulkDownload = ({ hasException, isHoverAction, isSelectedFolders, selectedRows }) => {
  const selectedRowsIds = selectedRows?.map((selectedRow) => selectedRow?.id);
  const isEmptyFolder =
    isSelectedFolders && selectedRows?.length === 1 && !selectedRows[0]?.numberOfItems;

  const hasNoItems =
    isSelectedFolders && !selectedRows?.some((selectedRow) => selectedRow?.numberOfItems > 0);

  const overlayContent =
    (isHoverAction && isEmptyFolder) || hasNoItems
      ? 'Bulk Download is not available for empty folders.'
      : isHoverAction
      ? 'Bulk Download'
      : '';

  const isVisible = isSelectedFolders || (selectedRows.length >= 2 && !hasException);

  if (!isVisible) return null;

  const handleSubmit = async () => {
    try {
      await createBulkDownloadReports({
        folderIds: isSelectedFolders ? selectedRowsIds : undefined,
        jobIds: !isSelectedFolders ? selectedRowsIds : undefined,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <OverlayTriggerTooltip
      content={isSelectedFolders ? overlayContent : ''}
      placement={!isHoverAction ? 'top' : undefined}
    >
      <div
        className={classNames(styles['bulk-download'], {
          [styles['is-disabled']]: isEmptyFolder || (isSelectedFolders && hasNoItems),
        })}
      >
        <IconCTA
          data-testid="bulk-download-cta"
          icon={<DownloadIcon />}
          onClick={handleSubmit}
          text={isHoverAction ? '' : 'Bulk Download'}
        />
      </div>
    </OverlayTriggerTooltip>
  );
};

BulkDownload.propTypes = {
  hasException: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  isSelectedFolders: PropTypes.bool,
  selectedRows: PropTypes.array,
};

export default BulkDownload;
