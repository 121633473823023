import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CloseIcon } from '../../icons/ui';
import styles from './_index.module.scss';

const Announcement = ({ className, footer, hasClose, header, icon, isCentered, text, variant }) => {
  const [isActive, setIsActive] = useState(true);

  if (!isActive) return null;

  return (
    <div
      className={classNames(styles[`announcement-${variant}`], className, {
        [styles['is-centered']]: isCentered,
      })}
      role="status"
    >
      <div>
        {icon && (
          <div aria-hidden className={styles['announcement-icon']}>
            {icon}
          </div>
        )}
        <div className={styles['announcement-content']}>
          {header && <h3>{header}</h3>}
          <div>{typeof text === 'object' ? text : <p>{text}</p>}</div>
        </div>
        {hasClose && (
          <div className={styles['announcement-close']}>
            <button aria-label="Close" onClick={() => setIsActive(false)}>
              <CloseIcon />
            </button>
          </div>
        )}
      </div>
      {footer && <div className={styles['announcement-footer']}>{footer}</div>}
    </div>
  );
};

Announcement.defaultProps = {
  text: 'Announcement',
  variant: 'success',
};

Announcement.propTypes = {
  className: PropTypes.string,
  footer: PropTypes.node,
  hasClose: PropTypes.bool,
  header: PropTypes.node,
  icon: PropTypes.node,
  isCentered: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  variant: PropTypes.oneOf(['ai', 'error', 'info', 'success', 'warn']),
};

export default Announcement;
