import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './_index.module.scss';

const NuLoader = ({ isCentered }) => {
  return (
    <div className={classNames(styles[`nuloader`], { [styles['is-centered']]: isCentered })}>
      <svg
        className={styles[`nu-animation`]}
        viewBox="0 0 68 67"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          className={styles[`right-bar`]}
          rx="9.765"
          transform="rotate(-180 61.2201 60.3943)"
          x="61.2201"
          y="60.3943"
        />
        <rect
          className={styles[`left-bar`]}
          rx="9.765"
          transform="rotate(-180 26.3019 60.3987)"
          x="26.3019"
          y="60.3987"
        />
        <rect
          className={styles[`bottom-circle`]}
          rx="9.765"
          transform="rotate(-180 26.3019 60.3943)"
          x="26.3019"
          y="60.3943"
        />
        <rect
          className={styles[`top-circle`]}
          rx="9.765"
          transform="rotate(-180 61.2201 25.4753)"
          x="61.2201"
          y="25.4753"
        />
        <rect
          className={styles[`diagonal-bar`]}
          rx="9.765"
          transform="rotate(135 65.2649 50.6293)"
          x="65.2649"
          y="50.6293"
        />
      </svg>
    </div>
  );
};

NuLoader.propTypes = {
  isCentered: PropTypes.bool,
};

export default NuLoader;
