import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AttributeList from './components/AttributeList';

import styles from './_index.module.scss';

const Attributes = ({ isLoading, isLoadingCustom, options, setActiveAttribute }) => {
  const userData = useSelector((state) => state?.user?.data);

  const attributes = options.filter((option) => !option?.isCustom);
  const customAttributes = options.filter((option) => option?.isCustom && !option?.isFixed);
  const fixedAttributes = options.filter((option) => option?.isFixed);

  return (
    <div className={styles['attributes']}>
      <AttributeList
        attributes={attributes}
        isLoading={isLoading}
        setActiveAttribute={setActiveAttribute}
      />
      {fixedAttributes?.length > 0 && (
        <>
          <h4>{`${userData?.clientInfo?.name} Hierarchy`}</h4>
          <AttributeList
            attributes={fixedAttributes}
            isLoading={isLoadingCustom}
            setActiveAttribute={setActiveAttribute}
          />
        </>
      )}
      {customAttributes?.length > 0 && (
        <>
          <h4>User Custom Groups</h4>
          <AttributeList
            attributes={customAttributes}
            isLoading={isLoadingCustom}
            setActiveAttribute={setActiveAttribute}
          />
        </>
      )}
    </div>
  );
};

Attributes.propTypes = {
  isLoading: PropTypes.bool,
  isLoadingCustom: PropTypes.bool,
  options: PropTypes.array,
  setActiveAttribute: PropTypes.func,
};

export default Attributes;
