import { useContext, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { useGate } from 'statsig-react';

import { ERROR_SAVE_DRAFT, FOLDER_ID_CUSTOM } from '@api/instant_survey';
import { maxQuestionError, maxQuestions, minQuestions } from '@api/surveys';

import { GATES } from '@constants';
import {
  ActionArea,
  Button,
  EditableTitle,
  IconButton,
  OverlayTriggerTooltip,
  Pill,
  toast,
} from '@utilities';
import { ContractIcon, ExpandIcon, OverviewIcon, PlusIcon } from '@utilities/icons';

import AddQuestionDropdown from './components/AddQuestionDropdown';
import InstantSurveyToolbar from '../InstantSurveyToolbar';
import ModalInstantSurveyQuestion from './components/ModalInstantSurveyQuestion';
import ModalQuestionsLibrary from './components/ModalQuestionLibrary';
import ModalSurveyTemplate from './components/ModalSurveyTemplate';
import QuestionnaireList from './components/QuestionnaireList';

import { checkForLogicChanges } from './utilities/helpers';
import { getCurrentQuestionnairePrice, getCurrentSamplePrice } from '../../utilities/helpers';
import { SurveyContext } from '../../utilities/context';

import styles from './_index.module.scss';

const Questionnaire = ({ onNext, onPrevious }) => {
  const { insightsDocumentType, instantSurveyType, saveInstantSurvey, survey, updateSurvey } =
    useContext(SurveyContext);

  const [isDetailedQuestionView, setIsDetailedQuestionView] = useState(true);
  const [isDuplicatingQuestion, setIsDuplicatingQuestion] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [showCustomQuestionModal, setShowCustomQuestionModal] = useState(false);
  const [showQuestionsLibraryModal, setShowQuestionsLibraryModal] = useState(false);
  const [showSurveyTemplateModal, setShowSurveyTemplateModal] = useState(false);

  const [isAddQuestionDropdownActive, setIsAddQuestionDropdownActive] = useState(false);
  const addQuestionButtonRef = useRef(null);

  const { value: canSurveyTemplate } = useGate(GATES.SURVEY_TEMPLATES);
  const { value: isSurveyLengthExtended } = useGate(GATES.SURVEY_LENGTH_EXTENDED);
  const { value: isSurveyPricingSplit } = useGate(GATES.SURVEY_PRICING_SPLIT);

  const currentQuestionnairePrice = useMemo(
    () =>
      getCurrentQuestionnairePrice({
        questionnairePricing: insightsDocumentType.questionnairePricing,
        survey,
      }),
    [survey]
  );
  const currentSamplePrice = useMemo(
    () => getCurrentSamplePrice({ samplePricing: insightsDocumentType.samplePricing, survey }),
    []
  );

  const activeQuestions = survey.questions.filter(({ _delete }) => !_delete);
  const creditsTotal = currentQuestionnairePrice?.amount + currentSamplePrice?.amount;
  const deletedQuestions = survey.questions.filter(({ _delete }) => _delete);
  const firstErrorRef = useRef(null);
  const pricingMaxQuestions = isSurveyLengthExtended
    ? insightsDocumentType.maxQuestions
    : maxQuestions;

  const handleAddLibraryQuestions = (questions) => {
    updateSurvey({
      questions: [
        ...activeQuestions,
        ...questions,
        ...deletedQuestions.filter(
          // extra .filter checks for questions being added that were previously removed
          ({ id: existingQuestionId }) =>
            !questions.some(
              ({ id: selectedQuestionId }) => existingQuestionId === selectedQuestionId
            )
        ),
      ],
    });
    handleQuestionLibraryModalClose();
  };

  const handleAddSurveyTemplate = (template) => {
    updateSurvey({
      questions: template.questions,
    });
    handleQuestionSurveyTemplateClose();
  };

  const handleQuestionCreate = (question) => {
    updateSurvey({ questions: [...activeQuestions, question, ...deletedQuestions] });
    handleQuestionModalClose();
  };

  const handleQuestionDuplicate = (question) => {
    setIsDuplicatingQuestion(true);
    setSelectedQuestion(question);
    setShowCustomQuestionModal(true);
  };

  const handleQuestionEdit = (question) => {
    setSelectedQuestion(question);
    setShowCustomQuestionModal(true);
  };

  const handleQuestionLibraryModalClose = () => {
    setShowQuestionsLibraryModal(false);
    setSelectedQuestion(null);
  };

  const handleQuestionSurveyTemplateClose = () => {
    setShowSurveyTemplateModal(false);
  };

  const handleQuestionModalClose = () => {
    setShowCustomQuestionModal(false);

    setIsDuplicatingQuestion(false);
    setSelectedQuestion(null);
  };

  const handleQuestionRemove = (question) => {
    const updatedQuestions = [...survey.questions];
    const questionIndex = updatedQuestions.findIndex(({ id }) => id === question.id);

    const [removedQuestion] = updatedQuestions.splice(questionIndex, 1);
    removedQuestion._delete = true;
    updateSurvey({ questions: [...updatedQuestions, removedQuestion] });
    checkForLogicChanges({
      hasQuestionBeenRemoved: true,
      question,
      questionLogic: survey.questionLogic,
      setQuestionLogic: (questionLogic) => updateSurvey({ questionLogic }),
    });
  };

  const handleQuestionUpdate = (question) => {
    const questionIndex = survey.questions.findIndex(({ id }) => id === question.id);
    if (questionIndex >= 0) {
      const updatedQuestion = survey.questions.splice(questionIndex, 1, question)[0];
      updateSurvey({ questions: [...survey.questions] });

      checkForLogicChanges({
        question,
        questionIndex,
        questionLogic: survey.questionLogic,
        setQuestionLogic: (questionLogic) => updateSurvey({ questionLogic }),
        updatedQuestion,
      });
    }
    handleQuestionModalClose();
  };

  const onContinue = async () => {
    const isLogicValid = validateQuestionLogic();
    if (!isLogicValid) {
      if (firstErrorRef.current) {
        firstErrorRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
      return;
    }

    setIsSaving(true);
    try {
      const response = await saveInstantSurvey();
      updateSurvey(response);
    } catch (error) {
      toast(ERROR_SAVE_DRAFT, { status: 'error' });
      setIsSaving(false);
      return;
    }

    onNext();
  };

  const validateQuestionLogic = () => {
    const modifiedQuestionLogic = { ...survey.questionLogic };
    let isValid = true;
    for (const questionId in modifiedQuestionLogic) {
      const question = modifiedQuestionLogic[questionId];

      if (question.skip) {
        for (const row of question.skip.logic) {
          if (!row.question?.id) {
            row.isFirstQuestionError = isValid;
            row.hasQuestionError = true;
            isValid = false;
          } else {
            row.hasQuestionError = false;
          }

          if (row.answers.length === 0) {
            row.isFirstAnswerError = isValid;
            row.hasAnswerError = true;
            isValid = false;
          } else {
            row.hasAnswerError = false;
          }
        }
      }

      if (question.disqualify) {
        if (question.disqualify.answers.length === 0) {
          question.disqualify.isFirstAnswerError = isValid;
          question.disqualify.hasAnswerError = true;
          isValid = false;
        } else {
          question.disqualify.hasAnswerError = false;
        }
      }
    }

    updateSurvey({ questionLogic: modifiedQuestionLogic });
    return isValid;
  };

  return (
    <>
      <ActionArea
        buttons={[
          <Button
            data-testid="previous-button"
            key="previous-button"
            onClick={onPrevious}
            text="Previous"
            variant="secondary"
          />,
          <Button
            data-testid="review-button"
            isDisabled={activeQuestions.length < minQuestions || !survey.title.trim()}
            isLoading={isSaving}
            key="review-button"
            onClick={onContinue}
            text="Go To Review"
          />,
        ]}
        className={styles['questionnaire']}
      >
        <InstantSurveyToolbar
          isSaveAsDraftDisabled={!survey.title.trim()}
          tools={[
            <div ref={addQuestionButtonRef}>
              <OverlayTriggerTooltip
                content={activeQuestions.length >= maxQuestions ? maxQuestionError : 'Add Question'}
              >
                <button
                  data-testid="add-question-toolbar-button"
                  disabled={activeQuestions.length >= maxQuestions}
                  onClick={() => setIsAddQuestionDropdownActive(true)}
                >
                  <PlusIcon />
                </button>
              </OverlayTriggerTooltip>
              <AddQuestionDropdown
                buttonRef={addQuestionButtonRef}
                isDropdownActive={isAddQuestionDropdownActive}
                onAddQuestion={() => setShowQuestionsLibraryModal(true)}
                onCreateQuestion={() => setShowCustomQuestionModal(true)}
                setIsDropdownActive={setIsAddQuestionDropdownActive}
              />
            </div>,
            ...(canSurveyTemplate
              ? [
                  <IconButton
                    onClick={() => setShowSurveyTemplateModal(true)}
                    tooltip="Survey Templates"
                  >
                    <OverviewIcon />
                    Templates
                  </IconButton>,
                ]
              : []),
            <OverlayTriggerTooltip
              content={'Change to ' + (isDetailedQuestionView ? 'Overview' : 'Detailed')}
            >
              <button
                data-testid="change-view-button"
                onClick={() => setIsDetailedQuestionView(!isDetailedQuestionView)}
              >
                {isDetailedQuestionView ? <ContractIcon /> : <ExpandIcon />}
                {!canSurveyTemplate && <span>View</span>}
              </button>
            </OverlayTriggerTooltip>,
          ]}
        >
          <div>
            <EditableTitle onChange={(title) => updateSurvey({ title })} value={survey.title} />
            <span className={styles['questionnaire-count']}>
              <Pill
                isCompleted
                value={activeQuestions.length + '/' + pricingMaxQuestions + ' Questions'}
              />
            </span>
          </div>
        </InstantSurveyToolbar>

        {isSurveyPricingSplit && (
          <div className={styles['questionnaire-pricing']}>
            <p>{currentQuestionnairePrice?.description}</p>
            <p>
              Cost:&nbsp;
              {currentQuestionnairePrice && currentSamplePrice ? (
                <strong>{`${creditsTotal} Credit${creditsTotal === 1 ? '' : 's'}`}</strong>
              ) : (
                <span>N/A</span>
              )}
            </p>
          </div>
        )}

        <QuestionnaireList
          firstErrorRef={firstErrorRef}
          isDetailedQuestionView={isDetailedQuestionView}
          maxQuestions={pricingMaxQuestions}
          onAddQuestion={() => setShowQuestionsLibraryModal(true)}
          onCreateQuestion={() => setShowCustomQuestionModal(true)}
          onDuplicateQuestion={handleQuestionDuplicate}
          onEditQuestion={handleQuestionEdit}
          onRemoveQuestion={handleQuestionRemove}
        />
      </ActionArea>

      {showCustomQuestionModal && (
        <ModalInstantSurveyQuestion
          id={selectedQuestion?.id}
          isDuplicate={isDuplicatingQuestion}
          isReadOnly={
            !isDuplicatingQuestion &&
            selectedQuestion?.folder &&
            selectedQuestion?.folder !== FOLDER_ID_CUSTOM
          }
          onClose={handleQuestionModalClose}
          onCreate={handleQuestionCreate}
          onUpdate={handleQuestionUpdate}
          surveyType={instantSurveyType}
        />
      )}
      {showQuestionsLibraryModal && (
        <ModalQuestionsLibrary
          maxQuestions={pricingMaxQuestions}
          onClose={handleQuestionLibraryModalClose}
          onSelectQuestions={handleAddLibraryQuestions}
          selectedQuestions={activeQuestions}
          surveyType={instantSurveyType}
        />
      )}
      {showSurveyTemplateModal && (
        <ModalSurveyTemplate
          hasWarning={activeQuestions.length > 0}
          onClose={handleQuestionSurveyTemplateClose}
          onSelectSurveyTemplate={handleAddSurveyTemplate}
          surveyType={instantSurveyType}
        />
      )}
    </>
  );
};

Questionnaire.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
};

export default Questionnaire;
