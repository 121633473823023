import { MentionsInput as Mentions, Mention } from 'react-mentions';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import SuggestionsWrapper from './components/SuggestionsWrapper';

import styles from './_index.module.scss';

const MentionsInput = ({
  className,
  error,
  id = nanoid(),
  isDisabled,
  isSingleLine,
  label,
  mentions,
  onChange,
  suggestions,
  ...props
}) => {
  return (
    <div className={className}>
      {label && (
        <label className={styles['mentions-input-label']} htmlFor={id}>
          {label}
        </label>
      )}
      <Mentions
        className="mentions"
        classNames={styles}
        customSuggestionsContainer={
          !suggestions?.title
            ? null
            : (children) => <SuggestionsWrapper children={children} {...suggestions} />
        }
        disabled={isDisabled}
        id={id}
        onChange={(event, newValue, newPlainTextValue) => onChange(newPlainTextValue)}
        singleLine={isSingleLine}
        {...props}
      >
        {mentions.map((mentionConfig) => (
          <Mention appendSpaceOnAdd {...mentionConfig} />
        ))}
      </Mentions>
      {error && <p className={styles['mentions-input-error']}>{error}</p>}
    </div>
  );
};

MentionsInput.defaultProps = {
  isSingleLine: true,
};

MentionsInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSingleLine: PropTypes.bool,
  label: PropTypes.string,
  mentions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  suggestions: PropTypes.object,
  value: PropTypes.string,
};

export default MentionsInput;
