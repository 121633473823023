import { useRef } from 'react';
import PropTypes from 'prop-types';

import { items } from './utilities/helpers';

import Item from './components/Item';
import styles from './_index.module.scss';

const AreaOfInterest = ({ selectedAreaOfInterest, setSelectedAreaOfInterest }) => {
  const modalBodyRef = useRef(null);

  return (
    <div className={styles['area-of-interest']} ref={modalBodyRef}>
      <h1>Let’s shape your experience</h1>
      <p>
        Now, tell us about your primary areas of interest so we can better understand your needs.
      </p>
      {items.map(({ attributeId, isRequired, pillLabel, promptId, type }) => (
        <Item
          attributeId={attributeId}
          isRequired={isRequired}
          key={attributeId}
          modalBodyRef={modalBodyRef}
          pillLabel={pillLabel}
          promptId={promptId}
          selectedAreaOfInterest={selectedAreaOfInterest}
          setSelectedAreaOfInterest={setSelectedAreaOfInterest}
          type={type}
        />
      ))}
    </div>
  );
};

AreaOfInterest.propTypes = {
  selectedAreaOfInterest: PropTypes.object.isRequired,
  setSelectedAreaOfInterest: PropTypes.func.isRequired,
};

export default AreaOfInterest;
