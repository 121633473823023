import { useLocation, useNavigate } from 'react-router-dom';

import { createPeopleGroup, readPeopleGroupPrompts } from '@api/people_groups';

import Builder from '../../components/Builder';

const CreatePeopleGroup = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <Builder
      create={createPeopleGroup}
      documentId="people-group"
      onSubmit={() => navigate('/dashboard/workspace/people-groups')}
      read={() => readPeopleGroupPrompts({ search, transform: false })}
      type="People Group"
    />
  );
};

export default CreatePeopleGroup;
