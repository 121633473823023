/**
 * gets updated selected filters based on options, current selections, and updated filter
 * @param  {Array}  options.filters
 * @param  {Object} options.selectedFilters
 * @param  {Object} options.updatedFilter
 * @return {Object}
 */
export const getUpdatedSelectedFilters = ({
  filters = [],
  selectedFilters = {},
  updatedFilter,
}) => {
  return filters?.reduce((acc, filter) => {
    let { id, options } = filter;

    // DEFAULT IS FIRST OPTION

    let value = options[0];

    // UPDATED FILTER VALUE

    if (updatedFilter?.id === id) {
      value = updatedFilter?.value;
    }

    // SUSTAIN EXISTING VALUE

    if (selectedFilters[id]) {
      value = selectedFilters[id];
    }

    // RESET DEFAULT VALUES IF EXIST

    if (filter?.defaultValues) {
      value = filter?.defaultValues?.map((defaultValue) =>
        options?.find((option) => defaultValue === option?.value)
      );
    }

    return {
      ...acc,
      [id]: value,
    };
  }, {});
};
