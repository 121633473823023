import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Announcement, Button, Input, isMaxNameLength, ModalV2 as Modal } from '@utilities';

import styles from './_index.module.scss';

const CreateItem = ({
  createItem,
  isCreateModalActive,
  onSubmit,
  setIsCreateModalActive,
  type,
}) => {
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await createItem({ name });
      onSubmit();
      setIsCreateModalActive(false);
    } catch (error) {
      console.error(error);
      setError(error?.response?.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(false);
    setName('');
    setError('');
  }, [isCreateModalActive]);

  return (
    <Modal
      buttons={[
        <Button
          key="cancel-btn"
          onClick={() => setIsCreateModalActive(false)}
          text="Cancel"
          variant="secondary"
        />,
        <Button
          key="submit-btn"
          isDisabled={name === '' || name.trim().length === 0 || isMaxNameLength(name)}
          isLoading={isLoading}
          onClick={handleSubmit}
          text="Save"
        />,
      ]}
      className={styles['create-item']}
      isActive={isCreateModalActive}
      onClose={() => setIsCreateModalActive(false)}
      title={`Create ${type}`}
    >
      {error && <Announcement text={error} variant="error" />}
      <Input
        error={
          isMaxNameLength(name)
            ? 'Maximum number of characters reached. Try something shorter.'
            : ''
        }
        label="Name"
        onChange={setName}
        placeholder="Enter name"
        value={name}
      />
    </Modal>
  );
};

CreateItem.propTypes = {
  createItem: PropTypes.func,
  isCreateModalActive: PropTypes.bool,
  onSubmit: PropTypes.func,
  setIsCreateModalActive: PropTypes.func,
  type: PropTypes.string,
};

export default CreateItem;
