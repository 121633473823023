/**
 * gets the madlib row count based on the current answers including the initial madlib row
 * @param {Object} answers
 * @returns {Number} a comparison cannot be less than 1 for the initial state
 */
export const getMadlibRowCount = (answers) => {
  if (!answers) {
    return 0;
  }

  const maxMadlibRow = Math.max(
    ...Object.keys(answers).map((answer) => {
      const number = parseInt(answer.charAt(answer.length - 1));

      return isNaN(number) ? 0 : number;
    })
  );

  return maxMadlibRow > 0 ? maxMadlibRow : 1;
};

/**
 * checks optional prompt against original prompt to see if it's required or not
 * @param {Number} madlibRowIndex
 * @param {Object} prompts
 * @param {Number} promptId
 * @returns {Boolean}
 */
export const isRequiredAdditionalPrompt = ({ madlibRowIndex, prompts, promptId }) => {
  if (madlibRowIndex >= 1) {
    return prompts.find((prompt) => promptId.includes(prompt?.id))?.isRequired;
  }
  return false;
};
