import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { default as ReactDatePicker } from 'react-datepicker';
import classNames from 'classnames';

import { CloseBoldIcon } from '../../icons';
import { getFormattedDate } from '../../helpers/dates';

import { getLabel } from './utilities/helpers';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './_index.module.scss';

const DatePicker = ({
  'data-testid': dataTestId,
  date,
  isDisabled,
  label,
  maxDate,
  minDate,
  onDateChange,
}) => {
  const [selectedDate, setSelectedDate] = useState(date ? new Date(date + 'T00:00:00') : null);
  const [selectedRawDate, setSelectedRawDate] = useState('');

  const format = 'MM/DD/YYYY';

  useEffect(() => {
    if (date && date !== selectedDate) {
      setSelectedDate(date ? new Date(date + 'T00:00:00') : null);
    }

    if (!date) {
      setSelectedDate(null);
    }
  }, [date]);

  useEffect(() => {
    onDateChange(
      selectedDate && (!selectedRawDate || selectedRawDate?.length <= format?.length)
        ? getFormattedDate(selectedDate)
        : null
    );
  }, [selectedDate]);

  return (
    <div
      className={classNames(styles['date-picker'], { [styles['is-disabled']]: isDisabled })}
      data-testid={dataTestId}
    >
      <h4>{getLabel({ label, selectedDate })}</h4>
      <div>
        <ReactDatePicker
          disabled={isDisabled}
          disabledKeyboardNavigation
          maxDate={new Date(maxDate + 'T00:00:00')}
          minDate={new Date(minDate + 'T00:00:00')}
          monthsShown={2}
          onChange={setSelectedDate}
          onChangeRaw={(event) => setSelectedRawDate(event.target.value)}
          placeholderText={format}
          popperModifiers={[
            {
              name: 'flip',
              options: {
                fallbackPlacements: [],
              },
            },
          ]}
          popperPlacement="bottom-start"
          selected={selectedDate}
          startDate={selectedDate}
        />
        <CloseBoldIcon
          className={classNames(styles['date-picker-close'], {
            [styles['is-visible']]: selectedDate,
          })}
          onClick={() => {
            setSelectedDate(null);
          }}
        />
      </div>
    </div>
  );
};

DatePicker.defaultProps = {
  'data-testid': 'date-picker',
  date: null,
  maxDate: '9999-12-31',
  onDateChange: () => {},
};

DatePicker.propTypes = {
  'data-testid': PropTypes.string,
  /**
   * dd-mm-yyyy format
   */
  date: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  /**
   * dd-mm-yyyy format
   */
  maxDate: PropTypes.string,
  /**
   * dd-mm-yyyy format
   */
  minDate: PropTypes.string,
  onDateChange: PropTypes.func,
};

export default DatePicker;
