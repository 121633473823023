/**
 * flattens report answers to an object of strings for inserting into a Mustache template
 * @param {Object} answers
 * @return {Object}
 */
export const getMustachePrompts = (answers) => {
  return Object.keys(answers)?.reduce((acc, promptId) => {
    const prompt = answers[promptId];
    const attributes = prompt?.map((attribute) =>
      attribute?.attributeId ? attribute.name : attribute
    );
    if (!attributes) {
      return acc;
    } else if (attributes.length <= 1) {
      acc[promptId] = attributes?.[0];
      return acc;
    }

    const lastAttribute = attributes.pop();
    let joinedAttributes = attributes.join(', ');
    if (attributes.length > 1) {
      joinedAttributes += ','; // oxford comma
    }
    if (attributes.length > 0) {
      joinedAttributes += ' or ';
    }
    acc[promptId] = joinedAttributes + lastAttribute;

    return acc;
  }, {});
};
