import { COLOR_FLYWEIGHT } from '../../../helpers/colors';

/**
 * column spline chart specific configurations
 * @type {Object}
 */
export const columnSplineChartConfiguration = {
  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
      },
    },
    series: {
      pointWidth: 30,
    },
  },
  tooltip: {
    shared: true,
    useHTML: true,
  },
};

/**
 * modifies options based on data
 * @param  {Object} options
 * @return {Object}
 */
export const setModifiedData = (options) => {
  options.series[0].type = 'column';
  options.series[1].type = 'spline';
  options.series[1].yAxis = 1;

  options.yAxis.forEach((axis, index) => {
    axis.endOnTick = false;
    axis.gridLineColor = COLOR_FLYWEIGHT;
    axis.gridLineWidth = 0;
    axis.min = 0;
    axis.reversedStacks = false;
    axis.startOnTick = false;

    if (index > 0) {
      axis.opposite = true;
      axis.softMax = 1;
    }
  });
};
