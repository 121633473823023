import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import uniqBy from 'lodash/uniqBy';

import { readSearchResults } from '@api/prompts';

import { Search as Searchbar, useDebounce } from '@utilities';

import styles from './_index.module.scss';

const Search = ({
  activePrompt,
  attributeId,
  documentId,
  isLoading,
  isLoadingCustom,
  searchAnswers,
  searchPrompts,
  setIsLoading,
  setIsLoadingCustom,
  setResults,
  setResultsCustom,
}) => {
  const [previousAttributeId, setPreviousAttributeId] = useState(attributeId);
  const [searchQuery, setSearchQuery, { signal, isDebouncing }] = useDebounce('');
  const controllerRef = useRef(null);

  const getResultsData = async ({ attributeChunks, controllerSignal, onComplete }) => {
    let searches = [];

    attributeChunks.forEach((attributeChunk) => {
      searches.push(() =>
        readSearchResults({
          attributeIds: attributeChunk.map((attribute) => attribute?.id),
          documentId,
          promptId: activePrompt?.id,
          searchAnswers,
          searchPrompts,
          searchQuery,
          signal: controllerSignal,
        })
      );
    });

    try {
      const response = await Promise.all(searches.map((search) => search()));
      onComplete(
        uniqBy(
          [].concat.apply(
            [],
            response?.map((results) => results?.data)
          ),
          'id'
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    controllerRef?.current?.abort();
    if (searchQuery !== '') {
      setSearchQuery('');
    }
  }, [activePrompt]);

  useEffect(() => {
    controllerRef?.current?.abort();
    const controller = new AbortController();
    controllerRef.current = controller;
    const controllerSignal = controllerRef?.current?.signal;

    const getResults = (attributes) => {
      setIsLoading(true);
      setIsLoadingCustom(true);

      const customAttributes = attributes.filter((option) => option?.isCustom);
      const customAttributeChunks = chunk(customAttributes, 10);

      const notCustomAttributes = attributes.filter((option) => !option?.isCustom);
      const attributeChunks = chunk(notCustomAttributes, 10);

      getResultsData({
        attributeChunks,
        controllerSignal,
        onComplete: (results) => {
          setResults(results);
          setIsLoading(false);
        },
      });

      getResultsData({
        attributeChunks: customAttributeChunks,
        controllerSignal,
        onComplete: (results) => {
          setResultsCustom(results);
          setIsLoadingCustom(false);
        },
      });
    };

    if (attributeId) {
      getResults([{ id: attributeId }]);
    }

    if (!previousAttributeId && !attributeId && searchQuery) {
      getResults(activePrompt?.attributes || activePrompt?.objects);
    }
  }, [attributeId, signal]);

  useEffect(() => {
    if (previousAttributeId && !attributeId) {
      setSearchQuery('');
    }
    setPreviousAttributeId(attributeId);
  }, [attributeId]);

  useEffect(() => {
    if (!attributeId && searchQuery === '') {
      setIsLoading(false);
      setIsLoadingCustom(false);
      setResults(null);
      setResultsCustom(null);
    }
    if (searchQuery !== '') {
      setIsLoading(true);
      setIsLoadingCustom(true);
    }
  }, [attributeId, searchQuery]);

  if (!activePrompt?.hasSearch) return null;

  return (
    <div className={styles['search']}>
      <Searchbar
        isSearching={(isDebouncing || isLoading || isLoadingCustom) && searchQuery !== ''}
        onChange={setSearchQuery}
        value={searchQuery}
      />
    </div>
  );
};

Search.propTypes = {
  activePrompt: PropTypes.object,
  attributeId: PropTypes.string,
  documentId: PropTypes.string,
  isLoading: PropTypes.bool,
  isLoadingCustom: PropTypes.bool,
  searchAnswers: PropTypes.object,
  searchPrompts: PropTypes.array,
  setIsLoading: PropTypes.func,
  setIsLoadingCustom: PropTypes.func,
  setResults: PropTypes.func,
  setResultsCustom: PropTypes.func,
};

export default Search;
