import React from 'react';
import PropTypes from 'prop-types';
import { ExternalLinkIcon, OverlayPopover } from '@utilities';

const ProductsDropdown = ({ isProductsDropdownActive, setActiveDropdown, target }) => {
  if (!isProductsDropdownActive) return null;

  return (
    <OverlayPopover
      data-testid="products-dropdown"
      header="Numerator"
      isActive={isProductsDropdownActive}
      onHide={() => setActiveDropdown('')}
      target={target}
    >
      <ul>
        <li>
          <a
            href="https://truview.numerator.com/"
            onClick={() => setActiveDropdown('')}
            rel="noreferrer"
            target="_blank"
          >
            <ExternalLinkIcon />
            <span>TruView</span>
          </a>
        </li>
        <li>
          <a
            href="https://promotionsintel.numerator.com/login.aspx"
            onClick={() => setActiveDropdown('')}
            rel="noreferrer"
            target="_blank"
          >
            <ExternalLinkIcon />
            <span>Promo Intel</span>
          </a>
        </li>
      </ul>
    </OverlayPopover>
  );
};

ProductsDropdown.propTypes = {
  isProductsDropdownActive: PropTypes.bool,
  setActiveDropdown: PropTypes.func,
  target: PropTypes.object,
};

export default ProductsDropdown;
