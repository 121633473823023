import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import LogRocket from 'logrocket';
import some from 'lodash/some';

import {
  Announcement,
  Breadcrumbs,
  Button,
  EditableTitle,
  Error,
  LoaderSkeleton,
  InfoFilledIcon,
} from '@utilities';

import { readPinnedAnswers } from '@api/prompts';

import ModalPromptErrors from './components/ModalPromptErrors';
import ModalValidation from './components/ModalValidation';
import PinnedAnswers from './components/PinnedAnswers';
import Prompts from '../../components/Prompts';
import Results from './components/Results';
import Search from './components/Search';
import Selections from './components/Selections';
import Toolbar from './components/Toolbar';

import { transformBuilderData, transformSearchAnswers } from './utilities/helpers';

import styles from './_index.module.scss';

const Builder = ({
  create,
  documentId,
  hasEditableTitle,
  initialAnswers,
  onSubmit,
  read,
  type,
}) => {
  const [activeAttribute, setActiveAttribute] = useState(null);
  const [activePrompt, setActivePrompt] = useState(null);
  const [answers, setAnswers] = useState({});
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCustom, setIsLoadingCustom] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [pinnedAnswers, setPinnedAnswers] = useState(null);
  const [promptErrors, setPromptErrors] = useState([]);
  const [results, setResults] = useState([]);
  const [resultsCustom, setResultsCustom] = useState([]);
  const [searchPrompts, setSearchPrompts] = useState([]);

  const activeAnswers = answers ? answers[activePrompt?.id] : [];
  const [searchParams] = useSearchParams();
  const isFromOnboarding = searchParams.get('fromOnboarding') === 'true';
  const searchAnswers = answers ? transformSearchAnswers(activePrompt, answers, searchPrompts) : {};

  const defaultName = `Create ${type}`;
  const hasActivePromptLimit =
    activeAnswers && activeAnswers?.length === activePrompt?.max && activePrompt?.max > 1;

  const invalidAnswers = data?.prompts
    .concat(data?.advancedPrompts)
    ?.filter((prompt) => prompt?.isRequired)
    ?.filter((requiredAnswer) => {
      return (
        answers === undefined ||
        answers[requiredAnswer?.id] === undefined ||
        answers[requiredAnswer?.id].length === 0 ||
        answers[requiredAnswer?.id].includes(undefined) ||
        answers[requiredAnswer?.id].includes(null)
      );
    });

  const getValue = ({ isSelectedAllClicked, isSelectedAllChecked, value }) => {
    if (isSelectedAllClicked) {
      if (!activeAnswers) return value;
      if (isSelectedAllChecked) {
        return [...activeAnswers, ...value].filter(
          (value, index, self) => index === self.findIndex((t) => t.id === value.id)
        );
      } else {
        return activeAnswers.filter((activeAnswer) => {
          return !value.some((v) => isEqual(v, activeAnswer));
        });
      }
    }

    if (
      (activePrompt?.dateOptions?.length > 0 || activePrompt?.hasCustomDateOption) &&
      Array.isArray(value)
    ) {
      return value;
    }

    if (activeAnswers && (!activePrompt?.max || activeAnswers?.length < activePrompt?.max)) {
      return some(activeAnswers, value)
        ? activeAnswers.filter((activeAnswer) => activeAnswer?.id !== value?.id)
        : [...activeAnswers, value];
    }

    if (hasActivePromptLimit) {
      return some(activeAnswers, value)
        ? activeAnswers.filter((activeAnswer) => activeAnswer?.id !== value?.id)
        : activeAnswers;
    }

    if (!activeAnswers || activePrompt?.max === 1) {
      return [value];
    }
  };

  const handleSubmit = async () => {
    if (invalidAnswers?.length > 0) return setIsInvalid(true);

    try {
      setIsSubmitting(true);
      const response = await create({ answers: answers || {}, documentId, name });
      if (isFromOnboarding) {
        LogRocket.track('Onboarding_Report_Ran', {
          type: documentId,
        });
      }

      if (onSubmit) {
        onSubmit(transformBuilderData(response?.data));
      }
    } catch (error) {
      setPromptErrors(error?.response?.data?.promptErrors || error?.response?.data || []);
      setIsSubmitting(false);
    }
  };

  const navigate = useNavigate();

  const onClearAnswer = (promptId) => {
    let updatedAnswers = { ...answers };

    delete updatedAnswers[promptId];

    setAnswers(updatedAnswers);
  };

  const onClearAnswers = ({ promptId, promptIds }) => {
    if (promptId) {
      onClearAnswer(promptId);
    }

    if (promptIds) {
      let updatedAnswers = { ...answers };

      for (const id of promptIds) {
        delete updatedAnswers[id];
      }

      setAnswers(updatedAnswers);
    }
  };

  const onSelectAnswer = (value, isSelectedAllClicked = false, isSelectedAllChecked = false) => {
    let updatedAnswers = { ...answers };

    const updatedValue = getValue({ isSelectedAllClicked, isSelectedAllChecked, value });
    if (!updatedValue.length) {
      delete updatedAnswers[activePrompt?.id];
      setAnswers(updatedAnswers);
    } else {
      setAnswers({
        ...answers,
        [activePrompt?.id]: updatedValue,
      });
    }
  };

  const onSetAnswerMetric = ({ id, operator, operatorInfo, value }) => {
    let updatedAnswers = [...answers[activePrompt?.id]];

    let updatedAnswer = updatedAnswers.find((answer) => answer?.id === id);

    updatedAnswer.operator = operator;
    updatedAnswer.operatorInfo = operatorInfo;
    updatedAnswer.value = value;

    setAnswers({
      ...answers,
      [activePrompt?.id]: updatedAnswers,
    });
  };

  const onSetAnswerInput = ({ id, inputId, value }) => {
    let updatedAnswers = [...answers[activePrompt?.id]];

    updatedAnswers.find((answer) => answer?.id === id).inputs[inputId].value = value;

    setAnswers({
      ...answers,
      [activePrompt?.id]: updatedAnswers,
    });
  };

  const onSortAnswers = (values) => {
    setAnswers({
      ...answers,
      [activePrompt?.id]: values,
    });
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const getData = async () => {
      try {
        const [response, allPinnedAnswers] = await Promise.all([
          read(),
          readPinnedAnswers({ documentId, signal }),
        ]);

        const data = transformBuilderData(response?.data);

        setActivePrompt(data?.prompts[0]);

        setPinnedAnswers(allPinnedAnswers?.data);

        const urlAnswers = searchParams.get('answers')
          ? JSON.parse(searchParams.get('answers'))
          : null;

        const defaultAnswers = data?.defaultAnswers || {};
        const {
          subscribed_categories: subscribedCategories,
          subscribed_channel: subscribedChannel,
        } = defaultAnswers;

        const mergedAnswers = urlAnswers
          ? {
              ...defaultAnswers,
              ...urlAnswers,
              ...(subscribedCategories && {
                subscribed_categories: subscribedCategories,
              }),
              ...(subscribedChannel && {
                subscribed_channel: subscribedChannel,
              }),
            }
          : null;

        setAnswers(initialAnswers || mergedAnswers || data?.answers || defaultAnswers);
        setSearchPrompts(data?.searchPrompts);
        setName(data?.name || defaultName);
        setData(data);
      } catch (error) {
        setError(error);
      }
    };

    getData();

    return () => {
      controller.abort();
    };
  }, [documentId]);

  useEffect(() => {
    if (activePrompt?.attributes?.length === 1) {
      setActiveAttribute(activePrompt?.attributes[0]);
    } else {
      setActiveAttribute(null);
      setResults(null);
      setResultsCustom(null);
    }
  }, [activePrompt]);

  useEffect(() => {
    if (!activeAttribute) {
      setResults(null);
      setResultsCustom(null);
    }
  }, [activeAttribute]);

  if (error) return <Error status={error?.response?.status} />;

  if (!data)
    return (
      <LoaderSkeleton height={450}>
        <rect x="0" y="0" rx="4" ry="4" width="1333" height="130" />
        <rect x="0" y="150" rx="4" ry="4" width="1333" height="40" />
        <rect x="0" y="210" rx="4" ry="4" width="1333" height="20" />
        <rect x="0" y="250" rx="2" ry="2" width="175" height="20" />
        <rect x="0" y="290" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="315" rx="2" ry="2" width="175" height="15" />
        <rect x="0" y="340" rx="2" ry="2" width="225" height="15" />
        <rect x="0" y="365" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="390" rx="2" ry="2" width="225" height="15" />
      </LoaderSkeleton>
    );

  let breadcrumbs = [{ label: activePrompt?.name, onClick: () => setActiveAttribute(null) }];

  if (activeAttribute && activePrompt?.attributes?.length > 1) {
    breadcrumbs.push({ label: activeAttribute?.name });
  }

  return (
    <div className={styles['builder']}>
      <Toolbar
        answers={answers}
        helpUrl={data?.helpUrl}
        isReadOnly={!hasEditableTitle}
        name={name}
        {...data?.permissions}
      >
        <EditableTitle autoFocus isDisabled={!hasEditableTitle} onChange={setName} value={name} />
      </Toolbar>
      <Prompts
        activePrompt={activePrompt}
        advancedPrompts={data?.advancedPrompts}
        answers={answers}
        madlib={data?.madlib}
        madlibAdditional={data?.madlibAdditional}
        madlibAdditionalLimit={data?.madlibAdditionalLimit}
        onClearAnswer={onClearAnswer}
        onClearAnswers={onClearAnswers}
        prompts={data?.prompts}
        setActivePrompt={setActivePrompt}
      />
      {isFromOnboarding && (
        <Announcement
          icon={<InfoFilledIcon />}
          text="Your report is pre-filled with your areas of interest and is just a click away! Hit ‘Create Report’ now to dive into your tailored insights."
          variant="info"
        />
      )}
      <Search
        activePrompt={activePrompt}
        attributeId={activeAttribute?.id}
        documentId={documentId}
        isLoading={isLoading}
        isLoadingCustom={isLoadingCustom}
        searchAnswers={searchAnswers}
        searchPrompts={searchPrompts}
        setActiveAttribute={setActiveAttribute}
        setIsLoading={setIsLoading}
        setIsLoadingCustom={setIsLoadingCustom}
        setResults={setResults}
        setResultsCustom={setResultsCustom}
      />
      <PinnedAnswers
        activeAnswers={activeAnswers}
        activePrompt={activePrompt}
        onSelectAnswer={onSelectAnswer}
        pinnedAnswers={pinnedAnswers}
        setPinnedAnswers={setPinnedAnswers}
      />
      {hasActivePromptLimit && (
        <Announcement
          text={`Maximum number of answers (${activePrompt?.max}) has been reached.`}
          variant="warn"
        />
      )}
      <Breadcrumbs options={breadcrumbs} />
      <div className={styles['builder-results']}>
        <div>
          <Results
            activeAnswers={activeAnswers}
            activeAttribute={activeAttribute}
            activePrompt={activePrompt}
            documentId={documentId}
            hasActivePromptLimit={hasActivePromptLimit}
            isLoading={isLoading}
            isLoadingCustom={isLoadingCustom}
            onSelectAnswer={onSelectAnswer}
            onSetAnswerInput={onSetAnswerInput}
            onSetAnswerMetric={onSetAnswerMetric}
            onSortAnswers={onSortAnswers}
            pinnedAnswers={pinnedAnswers}
            results={results}
            resultsCustom={resultsCustom}
            setActiveAttribute={setActiveAttribute}
            setPinnedAnswers={setPinnedAnswers}
          />
        </div>
        {activeAnswers?.length > 0 && activePrompt?.max !== 1 && (
          <div>
            <Selections
              activeAnswers={activeAnswers}
              activePrompt={activePrompt}
              onSelectAnswer={onSelectAnswer}
              onSortAnswers={onSortAnswers}
            />
          </div>
        )}
      </div>
      <div className={styles['builder-actions']}>
        <div className={styles['builder-actions-toolbar']}>
          <Button
            data-testid="builder-cancel"
            onClick={() => navigate(-1)}
            text="Cancel"
            variant="secondary"
          />
          <Button
            data-testid="builder-submit"
            isDisabled={!name?.trim()}
            isLoading={isSubmitting}
            onClick={handleSubmit}
            text={defaultName}
          />
        </div>
      </div>
      <ModalPromptErrors promptErrors={promptErrors} setPromptErrors={setPromptErrors} />
      <ModalValidation
        invalidAnswers={invalidAnswers}
        isInvalid={isInvalid}
        setActivePrompt={setActivePrompt}
        setIsInvalid={setIsInvalid}
      />
    </div>
  );
};

Builder.defaultProps = {
  hasEditableTitle: true,
};

Builder.propTypes = {
  create: PropTypes.func.isRequired,
  documentId: PropTypes.string,
  hasEditableTitle: PropTypes.bool,
  initialAnswers: PropTypes.object,
  onSubmit: PropTypes.func,
  read: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default Builder;
