import { customGroupStatus } from '@helpers';

/**
 * gets custom group status message to display
 * @param  {Object}  options.data
 * @return {String}
 */
export const getCustomGroupStatusMessage = ({ data }) => {
  switch (true) {
    case data?.status >= customGroupStatus.QUEUED && data?.status < customGroupStatus.COMPLETED:
      return 'Processing ';
    case data?.status === customGroupStatus.COMPLETED:
      return 'Complete';
    case data?.status === customGroupStatus.EXCEPTION:
      return 'Failed';
    default:
      return '';
  }
};
