import PropTypes from 'prop-types';

import { OverlayPopover, PlusIcon } from '@utilities';

import styles from './_index.module.scss';

const AddQuestionDropdown = ({
  buttonRef,
  className,
  isDropdownActive,
  onAddQuestion,
  onCreateQuestion,
  placement,
  setIsDropdownActive,
}) => {
  const onAdd = () => {
    setIsDropdownActive(false);
    onAddQuestion();
  };

  const onCreate = () => {
    setIsDropdownActive(false);
    onCreateQuestion();
  };

  return (
    <OverlayPopover
      className={styles['add-question-dropdown']}
      isActive={isDropdownActive}
      onHide={() => setIsDropdownActive(false)}
      placement={placement}
      target={buttonRef?.current}
    >
      <ul>
        <li>
          <button onClick={onCreate}>
            <span>Create Question</span>
            <PlusIcon />
          </button>
        </li>
      </ul>
      <hr />
      <ul>
        <li>
          <button onClick={onAdd}>
            <span>Add From Library</span>
            <PlusIcon />
          </button>
        </li>
      </ul>
    </OverlayPopover>
  );
};

AddQuestionDropdown.propTypes = {
  buttonRef: PropTypes.object.isRequired,
  className: PropTypes.string,
  isDropdownActive: PropTypes.bool.isRequired,
  onAddQuestion: PropTypes.func.isRequired,
  onCreateQuestion: PropTypes.func.isRequired,
  placement: PropTypes.string,
  setIsDropdownActive: PropTypes.func.isRequired,
};

export default AddQuestionDropdown;
