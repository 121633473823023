import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './_index.module.scss';

const IconCTA = ({ className, href, icon, onClick, path, state, text, ...props }) => {
  let iconCta = null;
  const iconCtaClasses = classNames(styles['icon-cta'], className);

  if (href) {
    iconCta = (
      <a className={iconCtaClasses} href={href} {...props}>
        <span>{icon}</span>
        {text && <span>{text}</span>}
      </a>
    );
  } else if (path) {
    iconCta = (
      <Link className={iconCtaClasses} state={state} to={path} {...props}>
        <span>{icon}</span>
        {text && <span>{text}</span>}
      </Link>
    );
  } else if (onClick) {
    iconCta = (
      <button className={iconCtaClasses} onClick={onClick} {...props}>
        <span>{icon}</span>
        {text && <span>{text}</span>}
      </button>
    );
  }

  return iconCta;
};

IconCTA.defaultProps = {
  'data-testid': 'icon-cta',
  onClick: () => {},
};

IconCTA.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  path: PropTypes.string,
  state: PropTypes.object,
  text: PropTypes.string,
};

export default IconCTA;
