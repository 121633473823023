import PropTypes from 'prop-types';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';

import { columnSplineChartConfiguration, setModifiedData } from './utilities/helpers';

const ColumnSplineChart = ({ series, xAxis, yAxis, ...props }) => {
  return (
    <Chart
      options={merge({}, columnSplineChartConfiguration, {
        series,
        type: 'column_spline',
        xAxis,
        yAxis,
        ...props,
      })}
      setModifiedData={setModifiedData}
      {...props}
    />
  );
};

ColumnSplineChart.propTypes = {
  series: PropTypes.array,
  xAxis: PropTypes.object,
  yAxis: PropTypes.array,
};

export default ColumnSplineChart;
