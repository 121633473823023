import {
  readStoresBarChart,
  readStoresChartsFiltersData,
  readStoresGlobalFiltersData,
  readStoresLineChart,
  readStoresTable,
  readStoresTableFiltersData,
} from '@api/dashboards';

import Insights from '../../../../components/Insights';

const Stores = () => {
  return (
    <Insights
      readBarChartData={readStoresBarChart}
      readChartsFiltersData={readStoresChartsFiltersData}
      readGlobalFiltersData={readStoresGlobalFiltersData}
      readLineChartData={readStoresLineChart}
      readTableData={readStoresTable}
      readTableFiltersData={readStoresTableFiltersData}
      tab="dashboard-stores"
    />
  );
};

export default Stores;
