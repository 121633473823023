import html2canvas from 'html2canvas';
import convert from 'xml-js';
import isEmpty from 'lodash/isEmpty';

import {
  CloseBoldIcon,
  DoubleArrowIcon,
  FavoriteFilledIcon,
  formatData,
  SortUpIcon,
  SquareIcon,
} from '@utilities';

import {
  renderDoubleTwoNodesConnector,
  renderFourNodesConnector,
  renderRightTwoNodesConnector,
  renderThreeNodesConnector,
  renderTwoNodesConnector,
} from '../components/Tree/components/Connector';

import { getSanitizedFilename } from './helpers';

/**
 * get node's askWhy option
 * @param  {String} nodeId
 * @param  {Object} askWhyPeopleGroups
 * @return {Object}
 */
const getAskWhyOption = (nodeId, askWhyPeopleGroups) => {
  return askWhyPeopleGroups?.find((askWhyGroup) => askWhyGroup?.id === nodeId);
};

/**
 * get node's people group option
 * @param  {String} nodeId
 * @param  {Object} peopleGroupTypes
 * @return {Object}
 */
const getPeopleGroupOption = (nodeId, peopleGroupTypes) => {
  return peopleGroupTypes?.find((peopleGroupType) => peopleGroupType?.id === nodeId);
};

/**
 * transforms and formats Card report data
 * @param  {Array} options.askWhyPeopleGroups
 * @param  {XML} options.jsx
 * @param  {Array} options.peopleGroupTypes
 * @param  {Array} options.reportData
 * @return {Object}
 */
export const transformCardReportData = ({
  askWhyPeopleGroups,
  jsx,
  peopleGroupTypes,
  reportData,
}) => {
  const tab = convert.xml2js(jsx, {
    alwaysArray: true,
    attributesKey: '$',
    compact: true,
  }).Tab?.[0];

  const {
    colLabels: labels = {},
    data = [],
    id = '',
    metadata: {
      context_variables: contextVariables = {},
      people_group_options: peopleGroupOptions,
    },
    name = '',
    tooManyRows = '',
  } = reportData[0];

  const cardsLayout = tab?.CardsLayout?.length > 0 ? tab?.CardsLayout?.[0] : {};
  const cardsGroup = cardsLayout?.CardsGroup?.[0].Card?.[0];

  return {
    cardBlocks: cardsLayout?.CardsBlock
      ? cardsLayout?.CardsBlock?.map((cardBlock) => {
          return cardBlock.Card?.map((block) => {
            const {
              $: { color = '', description = '', hasStatusBar = '', id = '', title = '' },
              CardPanels: cardPanels,
            } = block;

            const cardData = data?.find((d) => id === d['card']);
            const cardId = cardData?.['card'] || id;
            const peopleGroupId = peopleGroupOptions?.[cardId];
            const nodePeopleGroupOption = peopleGroupId
              ? getPeopleGroupOption(peopleGroupId, peopleGroupTypes)
              : null;
            const nodeAskWhyOption = peopleGroupId
              ? getAskWhyOption(peopleGroupId, askWhyPeopleGroups)
              : null;

            return {
              color,
              description: contextVariables?.[description] ? contextVariables?.[description] : '',
              hasStatusBar,
              id,
              level: cardData?.hml_shift ? cardData?.hml_shift : '',
              nodeAskWhyOption,
              nodePeopleGroupOption: nodePeopleGroupOption ? nodePeopleGroupOption : null,
              priority: cardData?.heavy_med_light ? cardData?.heavy_med_light : '',
              title: contextVariables?.[title] ? contextVariables?.[title] : '',
              treeRows: cardPanels?.map((cardPanel) => {
                const {
                  $: { content = '', orientation = '' },
                  CardPanel: panels,
                } = cardPanel;
                return {
                  content,
                  orientation,
                  treeCol: panels.map((card) => {
                    const {
                      $: {
                        color = '',
                        description = '',
                        inline = '',
                        label = '',
                        type = '',
                        valueNo = '',
                        valueYes = '',
                      },
                      CardRow: cardRows,
                    } = card;
                    let values = [];
                    return {
                      color: contextVariables?.[color] ? contextVariables?.[color] : '',
                      description: contextVariables?.[description]
                        ? contextVariables?.[description]
                        : '',
                      inline,
                      type,
                      value: contextVariables?.[label] ? contextVariables?.[label] : '',
                      valueNo: valueNo
                        ? formatData({
                            format: '0.0%',
                            value: cardData?.[valueNo],
                          })
                        : '',
                      valueNoLabel: labels?.[valueNo] ? labels?.[valueNo] : '',
                      valueYes: valueYes
                        ? formatData({
                            format: '0.0%',
                            value: cardData?.[valueYes],
                          })
                        : '',
                      valueYesLabel: labels?.[valueYes] ? labels?.[valueYes] : '',
                      cardRows: cardRows
                        ? cardRows?.map((row) => {
                            return {
                              cardCol: row?.CardCol?.filter(
                                (col) => col !== '' && !isEmpty(col)
                              ).map((col) => {
                                const {
                                  $: { format = '', id = '' },
                                } = col;

                                const title =
                                  cardData?.[id] === 0
                                    ? '-'
                                    : cardData?.[id]
                                    ? formatData({
                                        format: format,
                                        value: cardData?.[id],
                                      })
                                    : '';
                                values.push(title);
                                return {
                                  title,
                                  description: labels?.[id] ? labels?.[id] : '',
                                };
                              }),
                            };
                          })
                        : [],
                      values:
                        type === 'list' && orientation === 'horizontal' ? values.join(', ') : '',
                    };
                  }),
                };
              }),
            };
          });
        })
      : cardsLayout?.CardsGroup
      ? data?.map((cardData) => {
          const {
            $: { color = '#65748D', description = '', hasStatusBar = '', id = '', title = '' },
            CardPanels: cardPanels,
          } = cardsGroup;

          const cardId = cardData?.['card'] || id;
          const peopleGroupId = cardId ? peopleGroupOptions?.[cardId] : '';
          const nodePeopleGroupOption = peopleGroupId
            ? getPeopleGroupOption(peopleGroupId, peopleGroupTypes)
            : null;
          const nodeAskWhyOption = peopleGroupId
            ? getAskWhyOption(peopleGroupId, askWhyPeopleGroups)
            : null;

          return [
            {
              color,
              description: contextVariables?.[description] ? contextVariables?.[description] : '',
              hasStatusBar,
              id,
              nodeAskWhyOption,
              nodePeopleGroupOption,
              title: contextVariables?.[title] ? contextVariables?.[title] : '',
              treeRows: cardPanels?.map((cardPanel) => {
                const {
                  $: { orientation = '' },
                  CardPanel: panels,
                } = cardPanel;
                return {
                  orientation,
                  treeCol: panels.map((card) => {
                    const {
                      $: { label = '', type = '' },
                      CardRow: cardRows,
                    } = card;

                    return {
                      description: contextVariables?.[description]
                        ? contextVariables?.[description]
                        : '',
                      label,
                      type,
                      value:
                        orientation === 'vertical'
                          ? cardData?.['card_top_title']
                          : contextVariables?.[label],
                      cardRows: cardRows
                        ? cardRows?.map((row) => {
                            return {
                              cardCol: row?.CardCol?.map((col) => {
                                if (col) {
                                  const {
                                    $: { format = '', id = '' },
                                  } = col;
                                  return {
                                    title: cardData?.[id]
                                      ? formatData({
                                          format: format,
                                          value: cardData?.[id],
                                        })
                                      : '',
                                    description: labels?.[id] ? labels?.[id] : '',
                                  };
                                } else return null;
                              }),
                            };
                          })
                        : [],
                    };
                  }),
                };
              }),
            },
          ];
        })
      : null,
    id,
    name,
    tooManyRows,
  };
};

/**
 * transforms and formats Tree report data
 * @param  {Array} options.askWhyPeopleGroups
 * @param  {XML} options.jsx
 * @param  {Array} options.peopleGroupTypes
 * @param  {Array} options.reportData
 * @return {Object}
 */
export const transformTreeReportData = ({
  askWhyPeopleGroups,
  jsx,
  peopleGroupTypes,
  reportData,
}) => {
  const tab = convert.xml2js(jsx, {
    alwaysArray: true,
    attributesKey: '$',
    compact: true,
  }).Tab?.[0];

  const { colLabels: labels = {}, data = [], id = '' } = reportData?.[0];

  const treesLayout = tab?.Tree?.length > 0 ? tab?.Tree[0] : {};

  let rows = [];
  const nodes = treesLayout?.Node
    ? treesLayout?.Node?.filter((blocks) => (blocks?.$?.id ? blocks?.$?.id === id : blocks))
    : [];

  const type = treesLayout?.$?.type;
  const { peopleGroup } = getTreeConfig(type);

  const isLeakageTree = type === 'leakage-tree' || type === 'retailer-leakage-tree';
  const numOfRowsTreeType = {
    'brand-tree-v2': 4,
    'bricks-clicks-tree': 5,
    'ebsova-tree': 2,
    'leakage-tree': 4,
    'new-buyers-analysis-tree': 3,
    'new-buyers-tree': 3,
    'new-item-summary-tree': 1,
    'promo-effectiveness-conversion': 3,
    'trip-feedback': 4,
  };

  const rowsNumber = numOfRowsTreeType?.[type] ? numOfRowsTreeType?.[type] : 4;

  return !isLeakageTree
    ? {
        cardBlocks: {
          type: treesLayout?.$?.type ? treesLayout?.$?.type : '',
          treeNodes: nodes?.map((treeBlock, index) => {
            const node = treeBlock.$;
            const treeData = data?.[index] ? data[index] : {};
            const maxNumOfRows = treesLayout?.Node?.length > 0 && node?.['num_of_rows'];

            const numOfRows = maxNumOfRows ? parseInt(maxNumOfRows) : rowsNumber;
            rows[index] = [];
            for (let rowNumber = 1; rowNumber < numOfRows + 1; rowNumber += 1) {
              const rowPrefix = `r${rowNumber}`;
              const c1Value = treeData?.[`${rowPrefix}c1_value`];
              const c1Description = treeData?.[`${rowPrefix}c1_description`];
              const c1Format = node?.[`${rowPrefix}c1_format`];
              const c2Value = treeData?.[`${rowPrefix}c2_value`];
              const c2Description = treeData?.[`${rowPrefix}c2_description`];
              const c2Format = node?.[`${rowPrefix}c2_format`];

              let treeCol = [];
              if (
                ((c1Value && c1Value !== 'na') || (c1Description && c1Description !== 'na')) &&
                c1Value !== 'NAN_SENTINEL' &&
                c1Description !== 'NAN_SENTINEL'
              ) {
                if (type === 'trip-feedback' && treeData?.id === 'node_1') {
                  treeCol.push({
                    value:
                      c1Description && c1Description !== 'na'
                        ? formatData({ format: c1Format, value: c1Description })
                        : '',
                    description:
                      c1Value && c1Value !== 'na'
                        ? formatData({ format: c1Format, value: c1Value })
                        : '',
                  });
                } else {
                  treeCol.push({
                    value:
                      (c1Value || c1Value === 0) && c1Value !== 'na'
                        ? formatData({ format: c1Format, value: c1Value })
                        : '',
                    description:
                      c1Description && c1Description !== 'na'
                        ? formatData({ format: c1Format, value: c1Description })
                        : '',
                  });
                }
              }
              if (
                ((c2Value && c2Value !== 'na') || (c2Description && c2Description !== 'na')) &&
                c2Value !== 'NAN_SENTINEL' &&
                c2Description !== 'NAN_SENTINEL'
              ) {
                if (type === 'trip-feedback' && treeData?.id === 'node_1') {
                  treeCol.push({
                    value:
                      c2Description && c2Description !== 'na'
                        ? formatData({ format: c2Format, value: c2Description })
                        : '',
                    description:
                      c2Value && c2Value !== 'na'
                        ? formatData({ format: c2Format, value: c2Value })
                        : '',
                  });
                } else {
                  treeCol.push({
                    value:
                      c2Value && c2Value !== 'na'
                        ? formatData({ format: c2Format, value: c2Value })
                        : '',
                    description:
                      c2Description && c2Description !== 'na'
                        ? formatData({ format: c2Format, value: c2Description })
                        : '',
                  });
                }
              }
              rows[index].push({ treeCol });
            }

            const nodeId = node?.node_id ? node?.node_id : treeData?.id ? treeData?.id : '';
            const peopleGroupId = peopleGroup?.[nodeId];
            const nodePeopleGroupOption = peopleGroupId
              ? getPeopleGroupOption(peopleGroupId, peopleGroupTypes)
              : null;
            const nodeAskWhyOption = peopleGroupId
              ? getAskWhyOption(peopleGroupId, askWhyPeopleGroups)
              : null;

            return {
              color: node?.color ? node?.color : '#b3c9cb',
              description: treeData?.description ? treeData?.description : '',
              id: treeData?.id ? treeData?.id : '',
              inline: node?.inline ? node?.inline : '',
              nodeAskWhyOption,
              nodeId: node?.node_id ? node?.node_id : treeData?.id ? treeData?.id : '',
              nodePeopleGroupOption,
              nodeRow: node?.node_row ? node?.node_row : '',
              subdescription: treeData?.subdescription ? treeData?.subdescription : '',
              title: treeData?.header ? treeData?.header : '',
              treeRows: rows?.[index] ? rows[index] : [],
            };
          }),
        },
      }
    : {
        cardBlocks: {
          type,
          treeNodes: data.map((treeData, index) => {
            const node = nodes[index]?.$;
            rows[index] = [];
            for (let rowNumber = 1; rowNumber < rowsNumber + 1; rowNumber += 1) {
              const rowKey = node?.[`row_${rowNumber}`];
              const value = rowKey ? treeData?.[rowKey] : null;

              const description = rowKey ? labels?.[rowKey] : null;

              let format = '$0,0';
              if (rowKey === 'change') format = '0.0';
              if (rowKey === 'percent_post' || rowKey === 'percent_spend') format = '0.0%';

              let treeCol = [];
              if (value && description) {
                treeCol.push({
                  value: value ? formatData({ format: format, value: value }) : '',
                  description: description ? description : '',
                });
              }

              rows[index].push({ treeCol });
            }

            const nodeId = node?.node_id;
            const peopleGroupId = peopleGroup?.[nodeId];
            const nodePeopleGroupOption = peopleGroupId
              ? getPeopleGroupOption(peopleGroupId, peopleGroupTypes)
              : null;
            const nodeAskWhyOption = peopleGroupId
              ? getAskWhyOption(peopleGroupId, askWhyPeopleGroups)
              : null;

            return {
              color: node?.color ? node?.color : '#b3c9cb',
              description: treeData?.description ? treeData?.description : '',
              id: treeData?.id ? treeData?.id : '',
              nodeAskWhyOption,
              nodeId: node?.node_id ? node?.node_id : treeData?.id ? treeData?.id : '',
              nodePeopleGroupOption,
              nodeRow: node?.node_row ? node?.node_row : '',
              subdescription: treeData?.subdescription ? treeData?.subdescription : '',
              title: treeData?.title ? treeData?.title : '',
              treeRows: rows?.[index] ? rows?.[index] : [],
            };
          }),
        },
      };
};

/**
 * Return tree structure based on the type
 * @param  {String} type
 * @return {Object}
 */
export const getTreeConfig = (type) => {
  switch (type) {
    case 'brand-tree-v2':
      return {
        connectors: [
          renderThreeNodesConnector(2, 2, 2, 4, 2),
          renderThreeNodesConnector(2, 2, 4, 4, 4),
          renderThreeNodesConnector(2, 2, 6, 4, 6),
        ],
        xs: { node_1: 4, node_2: 4, node_3: 4, node_4: 4, node_5: 4, node_6: 4, node_7: 4 },
        xsOffset: { node_1: 2, node_4: 2, node_6: 4 },
      };

    case 'bricks-clicks-tree':
      return {
        connectors: [
          renderThreeNodesConnector(3, 3, 3, 6, 3),
          renderDoubleTwoNodesConnector(1, 1, 2, 5),
          renderDoubleTwoNodesConnector(1, 1, 2, 5),
        ],
        peopleGroup: {
          node_2: 'bricks-clicks-non-online-buyers',
          node_3: 'bricks-clicks-online-buyers',
          node_4: 'bricks-clicks-nothing-else-online-buyers',
          node_5: 'bricks-clicks-online-one-time-buyers',
          node_6: 'bricks-clicks-something-else-online-buyers',
          node_7: 'bricks-clicks-online-more-than-one-time-buyers',
        },
        xs: { node_1: 6, node_2: 6, node_3: 6, node_4: 6, node_5: 6, node_6: 6, node_7: 6 },
        xsOffset: { node_1: 3 },
      };

    case 'ebsova-tree':
      return {
        connectors: [renderFourNodesConnector(2)],
        xs: { node_1: 4, node_2: 4, node_3: 4, node_4: 4 },
        xsOffset: { node_1: 4 },
      };

    case 'leakage-tree':
      return {
        connectors: [
          renderThreeNodesConnector(2, 2, 6, 4, 6),
          renderThreeNodesConnector(2, 2, 4, 4, 4),
          renderThreeNodesConnector(2, 2, 2, 4, 2),
          renderTwoNodesConnector(),
          renderTwoNodesConnector(),
        ],
        peopleGroup: {
          node_5: 'leakage-tree-non-buyers',
          node_6: 'leakage-tree-closers',
          node_7: 'leakage-tree-non-closers',
        },
        xs: {
          node_1: 4,
          node_2: 4,
          node_3: 4,
          node_4: 4,
          node_5: 4,
          node_6: 4,
          node_7: 4,
          node_8: 4,
          node_9: 4,
        },
        xsOffset: { node_1: 6, node_2: 4, node_4: 2 },
      };

    case 'new-buyers-tree':
      return {
        connectors: [renderThreeNodesConnector(3, 3, 3, 6, 3)],
        peopleGroup: {
          node_1: 'portfolio-engagement-all',
          node_2: 'portfolio-engagement-exist',
          node_3: 'portfolio-engagement-new',
        },
        xs: { node_1: 6, node_2: 6, node_3: 6 },
        xsOffset: { node_1: 3 },
      };

    case 'new-buyers-analysis-tree':
      return {
        connectors: [
          renderThreeNodesConnector(2, 3, 2, 5, 2),
          renderThreeNodesConnector(2, 2, 6, 4, 6),
        ],
        peopleGroup: {
          node_1: 'new-buyers-all',
          node_2: 'new-buyers-repeat',
          node_3: 'new-buyers-new',
          node_4: 'new-buyers-repeat-cat',
          node_5: 'new-buyers-new-cat',
        },
        xs: { node_1: 6, node_2: 5, node_3: 5, node_4: 5, node_5: 5 },
        xsOffset: { node_1: 1, node_4: 2 },
      };

    case 'new-item-summary-tree':
      return {
        connectors: [renderThreeNodesConnector(2, 2, 4, 4, 4), renderTwoNodesConnector(2, 2)],
        peopleGroup: {
          node_1: 'new-item-triers',
          node_2: 'new-item-repeaters',
          node_3: 'new-item-one-time-buyers',
          node_4: 'new-item-multi-time-repeaters',
        },
        xs: { node_1: 4, node_2: 4, node_3: 4, node_4: 4 },
        xsOffset: { node_1: 4, node_2: 2, node_4: 2 },
      };

    case 'promo-effectiveness-conversion':
      return {
        connectors: [
          renderThreeNodesConnector(3, 3, 3, 6, 3),
          renderDoubleTwoNodesConnector(1, 1, 2, 5),
          renderDoubleTwoNodesConnector(1, 1, 2, 5),
          renderRightTwoNodesConnector(1, 8),
        ],
        xs: {
          node_1: 6,
          node_2: 6,
          node_3: 6,
          node_4: 6,
          node_5: 6,
          node_6: 6,
          node_7: 6,
          node_8: 6,
        },
        xsOffset: { node_1: 3, node_8: 6 },
      };

    case 'retailer-leakage-tree':
      return {
        connectors: [
          renderThreeNodesConnector(2, 2, 4, 4, 4),
          renderTwoNodesConnector(2, 2),
          renderTwoNodesConnector(2, 2),
        ],
        peopleGroup: {
          node_2: 'leakage-tree-closers',
          node_3: 'leakage-tree-non-closers',
        },
        xs: { node_1: 4, node_2: 4, node_3: 4, node_4: 4, node_5: 4 },
        xsOffset: { node_1: 4, node_2: 2, node_4: 2, node_5: 2 },
      };

    case 'retailer-trip-circuits-tree':
      return {
        connectors: [
          renderThreeNodesConnector(2, 2, 4, 4, 4),
          renderTwoNodesConnector(2, 2),
          renderTwoNodesConnector(2, 2),
          renderTwoNodesConnector(2, 2),
        ],
        xs: { node_1: 4, node_2: 4, node_3: 4, node_4: 4, node_5: 4, node_6: 4 },
        xsOffset: { node_1: 4, node_2: 2, node_4: 2, node_5: 2, node_6: 2 },
      };

    case 'trip-circuits-tree':
      return {
        connectors: [
          renderThreeNodesConnector(2, 2, 6, 4, 6),
          renderThreeNodesConnector(2, 2, 4, 4, 4),
          renderThreeNodesConnector(2, 2, 2, 4, 2),
          renderTwoNodesConnector(),
          renderTwoNodesConnector(),
        ],
        xs: {
          node_1: 4,
          node_2: 4,
          node_3: 4,
          node_4: 4,
          node_5: 4,
          node_6: 4,
          node_7: 4,
          node_8: 4,
          node_9: 4,
        },
        xsOffset: { node_1: 6, node_2: 4, node_4: 2 },
      };

    case 'customer-lifetime-value-tree':
      return {
        connectors: [
          renderThreeNodesConnector(2, 2, 6, 4, 6),
          renderThreeNodesConnector(2, 2, 4, 4, 4),
          renderTwoNodesConnector(2, 2),
          renderTwoNodesConnector(2, 2),
        ],
        xs: {
          node_1: 4,
          node_2: 4,
          node_3: 4,
          node_4: 4,
          node_5: 4,
          node_6: 4,
          node_7: 4,
        },
        xsOffset: { node_1: 6, node_2: 4, node_4: 2, node_6: 2, node_7: 2 },
      };

    case 'trip-feedback':
      return {
        connectors: [renderThreeNodesConnector(3, 3, 3, 6, 3)],
        xs: { node_1: 6, node_2: 6, node_3: 6 },
        xsOffset: { node_1: 3 },
      };

    default:
      return {};
  }
};

/**
 * download PNG for cards and trees
 * @param  {XML} jsx
 * @param  {Array} reportData
 * @return {Object}
 */
export const downloadDiagram = async (elementReference, exportData) => {
  const { reportType, reportName, tabName } = exportData;

  const element = elementReference.current;

  const reportFileName = `${getSanitizedFilename({
    reportType,
    reportName,
    tabName,
  })}_diagram`;

  try {
    const canvas = await html2canvas(element, {
      logging: false,
      onclone: (document) => {
        document.body.classList.remove('dark');
        const input = document.querySelector('.tree-wrapper');
        const nodes = document.querySelectorAll('.node-wrapper');
        nodes.forEach((node) => {
          node.style.boxShadow = 'none';
        });
        input.style.padding = '15px';
      },
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
    });
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.download = reportFileName;
    link.href = canvas.toDataURL();
    link.target = '_blank';
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  }
};

/**
 * get Status Bar Style for Heavy-Medium-Light cards
 * @param  {String} color
 * @param  {Number} level
 * @param  {Number} priority
 * @return {Object}
 */
export const getStatusBarStyle = ({ color, level, priority }) => {
  let heavyStyle;
  let icon;
  let lightStyle;
  let mediumStyle;

  switch (level) {
    case 5:
      //steady
      heavyStyle = { background: priority === 3 ? color : undefined };
      icon = <SquareIcon />;
      lightStyle = { background: priority === 1 ? color : undefined };
      mediumStyle = { background: priority === 2 ? color : undefined };
      break;
    case 6:
      //fast growth
      heavyStyle = { background: color };
      icon = <DoubleArrowIcon />;
      lightStyle = { background: color, opacity: 0.5 };
      mediumStyle = { background: color, opacity: 0.5 };
      break;
    case 7:
      //fast decline
      heavyStyle = { background: color, opacity: 0.5 };
      icon = <DoubleArrowIcon />;
      lightStyle = { background: color };
      mediumStyle = { background: color, opacity: 0.5 };
      break;
    case 8:
      //moderate growth
      heavyStyle = { background: priority === 3 ? color : null };
      icon = <SortUpIcon />;
      lightStyle = {
        background: priority === 2 ? color : undefined,
        opacity: priority === 2 ? 0.5 : 1,
      };
      mediumStyle = { background: color, opacity: priority === 2 ? 1 : 0.5 };
      break;
    case 9:
      //moderate decline
      heavyStyle = { background: priority === 2 ? color : null, opacity: priority === 2 ? 0.5 : 1 };
      icon = <SortUpIcon />;
      lightStyle = { background: priority === 1 ? color : null };
      mediumStyle = { background: color, opacity: priority === 2 ? 1 : 0.5 };
      break;
    case 10:
      //new
      heavyStyle = { background: priority === 3 ? color : undefined };
      icon = <FavoriteFilledIcon />;
      lightStyle = { background: priority === 1 ? color : undefined };
      mediumStyle = { background: priority === 2 ? color : undefined };
      break;
    case 11:
      //lapsed
      heavyStyle = { background: priority === 3 ? color : undefined };
      icon = <CloseBoldIcon />;
      lightStyle = { background: priority === 1 ? color : undefined };
      mediumStyle = { background: priority === 2 ? color : undefined };
      break;
    default:
      break;
  }

  return { heavyStyle, icon, lightStyle, mediumStyle };
};
