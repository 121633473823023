import PropTypes from 'prop-types';

import styles from './_index.module.scss';

const Media = ({ option }) => {
  return (
    <>
      <div className={styles['media-image']}>
        <img alt={option.caption} src={option.parameters} />
      </div>
      <div className={styles['media-description']}>
        <p>{option.name}</p>
        <span>{option.caption}</span>
      </div>
    </>
  );
};

Media.propTypes = {
  option: PropTypes.object.isRequired,
};

export default Media;
