import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { LockIcon } from '../../icons';

import styles from './_index.module.scss';

const Tabs = ({ onChange, options, routes, value }) => {
  const { pathname } = useLocation();
  const className = styles['tabs'];

  if (routes) {
    return (
      <ul className={className}>
        {routes.map((route, index) => {
          const url = route?.to?.pathname || route?.to;

          const listClassNames = classNames({
            [styles['is-active']]: pathname?.includes(url) || route?.is_active,
            [styles['is-locked']]: route?.is_locked,
          });

          return (
            <li key={`route-${index}`} className={listClassNames} data-testid={`tab-${index}`}>
              <Link replace to={route?.to}>
                {route?.label}
              </Link>
              {route?.is_locked && <LockIcon />}
            </li>
          );
        })}
      </ul>
    );
  }

  if (options) {
    return (
      <ul className={className}>
        {options?.map((option, index) => {
          const listClassNames = classNames({
            [styles['is-active']]: option?.value === value,
          });

          return (
            <li
              className={listClassNames}
              data-testid={`option-${index}`}
              key={`option-${index}`}
              tabIndex="0"
            >
              <span onClick={() => onChange(option?.value)}>{option?.label}</span>
            </li>
          );
        })}
      </ul>
    );
  }

  return null;
};

Tabs.defaultProps = {
  onChange: () => {},
};

Tabs.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Tabs;
