import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { AutomateIcon, IconCTA } from '@utilities';

import ModalAutomate from '@src/app/pages/Dashboard/components/ModalAutomate';

const Automate = ({
  canReprompt,
  hasException,
  hasExpiration,
  isHoverAction,
  isSelectedOwned,
  selectedRows,
}) => {
  const canAutomateReports = useSelector(
    (state) => state?.user?.data?.permissions?.canAutomateReports
  );

  const [showModalAutomate, setShowModalAutomate] = useState(false);

  const isVisible =
    !isHoverAction &&
    !hasException &&
    !hasExpiration &&
    canReprompt &&
    canAutomateReports &&
    selectedRows.length === 1 &&
    isSelectedOwned;

  if (!isVisible) return null;

  return (
    <>
      <IconCTA
        data-testid="automate-cta"
        icon={<AutomateIcon />}
        onClick={() => setShowModalAutomate(true)}
        text="Automate"
      />
      {showModalAutomate && (
        <ModalAutomate
          name={selectedRows[0]?.name}
          reportId={selectedRows[0]?.id}
          setShowModalAutomate={setShowModalAutomate}
        />
      )}
    </>
  );
};

Automate.propTypes = {
  canReprompt: PropTypes.bool,
  hasException: PropTypes.bool,
  hasExpiration: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  isSelectedOwned: PropTypes.bool,
  selectedRows: PropTypes.array,
};

export default Automate;
