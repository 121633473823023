import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './_index.module.scss';

function Pagination({
  className,
  'data-testid': dataTestId,
  nextLabel,
  onPageChange,
  pageCount,
  pageRangeDisplayed,
  previousLabel,
  show,
  ...other
}) {
  if (!show) {
    return null;
  }
  return (
    <div data-testid={dataTestId}>
      <ReactPaginate
        activeClassName={styles['pagination-active']}
        breakLabel="..."
        className={classNames(styles['pagination'], className)}
        disabledClassName={styles['pagination-disabled']}
        nextLabel={nextLabel}
        onPageChange={onPageChange}
        pageCount={pageCount}
        pageRangeDisplayed={pageRangeDisplayed}
        previousLabel={previousLabel}
        renderOnZeroPageCount={null}
        {...other}
      />
    </div>
  );
}

Pagination.defaultProps = {
  nextLabel: 'Next >',
  pageRangeDisplayed: 5,
  previousLabel: '< Prev',
};

Pagination.propTypes = {
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  nextLabel: PropTypes.string,
  onPageChange: PropTypes.func,
  pageCount: PropTypes.number.isRequired,
  pageRangeDisplayed: PropTypes.number,
  previousLabel: PropTypes.string,
  show: PropTypes.bool,
};

export default Pagination;
