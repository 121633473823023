import axios from '@api';
import { AxiosError } from 'axios';

import { surveyTypeQuestionId } from './utilities/constants';
import {
  transformConfirmSurvey,
  transformQuestionLogicValidation,
  transformSampleValidation,
  transformSurveyType,
} from './utilities/helpers';

/**
 * read survey type
 * @param  {String} options.documentId
 * @return {Promise}
 */
export const readSurveyType = ({ documentId }) => {
  return axios({
    method: 'get',
    url: `/api/survey-types/${documentId}`,
    withCredentials: true,
  }).then(({ data }) => transformSurveyType(data));
};

/**
 * create survey type prompt validation
 * @param {Object} options.answers
 * @param {String} options.documentId
 * @return {Promise}
 */
export const createSurveyTypePromptValidation = ({ answers, documentId }) => {
  return axios({
    data: {
      answers: { ...answers, [surveyTypeQuestionId[documentId]]: [] },
      instant_survey_type_id: documentId,
    },
    method: 'post',
    url: `/api/survey-types/${documentId}/validate_prompts`,
    withCredentials: true,
  });
};

/**
 * confirm survey
 * @param {Object} options.answers
 * @param {Number} [options.askWhyJobId]
 * @param {String} [options.askWhyPeopleGroupTypeId]
 * @param {String} options.documentId
 * @param {Number} [options.numberOfResponsesRequested]
 * @param {Array} options.questionLogic
 * @param {Array} options.questions
 * @param {AbortSignal} [options.signal]
 * @return {Promise}
 */
export const confirmSurvey = async ({
  answers,
  askWhyJobId: ask_why_job_id,
  askWhyPeopleGroupTypeId: ask_why_people_group_type_id,
  documentId,
  numberOfResponsesRequested: number_of_responses_requested,
  questionLogic: question_logic,
  questions,
  signal,
}) => {
  return axios({
    data: {
      answers: {
        ...answers,
        // TODO: endpoint requires questions to have a STRING id, we'll need to keep both entryId and id for questions
        [surveyTypeQuestionId[documentId]]: questions.map(({ id, text: name, ...rest }) => ({
          id: `promo_questions:${id}`,
          name,
          ...rest,
        })),
      },
      ask_why_job_id,
      ask_why_people_group_type_id,
      ...(ask_why_job_id
        ? { ask_why_document_id: documentId }
        : { instant_survey_type_id: documentId }),
      number_of_responses_requested,
      question_logic: transformQuestionLogicValidation(question_logic),
    },
    method: 'post',
    signal,
    url: `/api/survey-types/${documentId}/confirm`,
    withCredentials: true,
  })
    .then((response) => {
      // TODO: confirmation call still returns 200 status when promptErrors exist (INSRV-673)
      if (response?.data?.promptErrors) {
        throw new AxiosError(
          'Request failed with status code 400',
          'ERR_BAD_REQUEST',
          null,
          null,
          response
        );
      } else {
        return response;
      }
    })
    .then(({ data }) => transformConfirmSurvey(data));
};

/**
 * read sample validation
 * @param {AbortSignal} options.signal
 * @param {Number} options.validationKey
 * @return {Promise}
 */
export const readSampleValidation = async ({ signal, validationKey }) => {
  return axios({
    method: 'get',
    signal,
    url: `/api/survey-types/checkvalidation/${validationKey}`,
    withCredentials: true,
  }).then(({ data }) => transformSampleValidation(data));
};
