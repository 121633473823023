/**
 * spline chart specific configurations
 * @type {Object}
 */
export const splineChartConfiguration = {
  legend: {
    itemStyle: { cursor: 'default' },
  },
  plotOptions: {
    series: {
      lineWidth: 3,
      marker: {
        enabled: true,
        hover: {
          lineWidth: 2,
          radius: 4,
          size: 6,
        },
        lineWidth: 2,
        radius: 4,
        size: 6,
        symbol: 'circle',
      },
      connectNulls: true,
    },
  },
  tooltip: {
    shared: true,
  },
};
