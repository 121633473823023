/**
 * line chart specific configurations
 * @type {Object}
 */
export const lineChartConfiguration = {
  legend: {
    itemStyle: { cursor: 'default' },
  },
  plotOptions: {
    series: {
      lineWidth: 3,
      marker: {
        enabled: true,
        hover: {
          lineWidth: 2,
          radius: 4,
          size: 6,
        },
        lineWidth: 2,
        radius: 4,
        size: 6,
        symbol: 'circle',
      },
      connectNulls: true,
    },
  },
  tooltip: {
    shared: true,
  },
  yAxis: {
    min: 0,
  },
};

/**
 * modifies options based on data
 * @param  {Object} options
 * @return {Object}
 */
export const setModifiedData = (options) => {
  options?.series?.reduce((colorIndex, currentSeries, index) => {
    if (index > 0 && currentSeries?.type === 'arearange') {
      const previousSeries = options?.series?.[index - 1];
      const color = options?.colors?.[colorIndex];
      currentSeries.color = color;
      previousSeries.color = color;
      return colorIndex + 1;
    }
    return colorIndex;
  }, 0);
};
