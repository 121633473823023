import PropTypes from 'prop-types';

import Module from './components/Module';
import Sidebar from './components/Sidebar';
import Tabs from './components/Tabs';

const Layout = ({ children, route }) => {
  const type = route?.layout;

  if (type === 'modules') return <Module children={children} route={route} />;
  if (type === 'navigation') return <Sidebar children={children} route={route} />;
  if (type === 'tabs') return <Tabs children={children} route={route} />;

  return <>{children}</>;
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  route: PropTypes.object,
};

export default Layout;
