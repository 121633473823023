/**
 * transforms app context request
 * @param  {Object} data
 * @return {Object}
 */
export const transformAppContext = ({ data, ...response }) => {
  const instantSurveyRouteIndex = data?.routes.findIndex(({ id }) => id === 'instant-surveys');
  if (instantSurveyRouteIndex >= 0) {
    // move the existing survey "modules" routes to a different key
    data.routes[instantSurveyRouteIndex].surveyRoutes = data.routes[instantSurveyRouteIndex].routes;
    data.routes[instantSurveyRouteIndex].routes = [];
  }

  return {
    data: data,
    ...response,
  };
};
