import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './_index.module.scss';

const ButtonText = ({ as: Component, children, className, ...props }) => (
  <Component className={classNames(styles['button-text'], className)} {...props}>
    {children}
  </Component>
);

ButtonText.defaultProps = {
  as: 'button',
};

ButtonText.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ButtonText;
