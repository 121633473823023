/* eslint-disable react/prop-types */

import { formatData } from '@utilities';

/**
 * transforms and formats filters
 * @param  {Object} data
 * @return {Object} filters formatted to camelcase and section title
 */
export const transformFilters = (data) => {
  const filters = data?.filters?.map((filter) => {
    return {
      defaultValues: filter?.default_values,
      id: filter?.id,
      isMulti: filter?.is_multi ?? false,
      label: filter?.label,
      options: filter?.options,
      resetDefaults: filter?.reset_defaults ?? false,
      rowNumber: filter?.row || 1,
    };
  });

  return { filters, lastUpdated: data?.last_updated, title: data?.title };
};

/**
 * transforms and formats table data
 * @param  {Object} data
 * @return {Object}
 */
export const transformTableData = (data) => {
  const columns = data?.columns?.map((column) => {
    return {
      ...column,
      Cell: ({ value }) => {
        return (
          <span>
            {formatData({
              format: column?.format,
              value: value || 'NAN_SENTINEL',
            })}
          </span>
        );
      },
    };
  });
  return { columns, data: data?.data };
};
