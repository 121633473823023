import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import AttributeOptionsResults from './components/AttributeOptionResults';

import styles from './_index.module.scss';

const AttributeOptions = ({
  activeAnswers,
  activePrompt,
  documentId,
  hasActivePromptLimit,
  isLoading,
  isLoadingCustom,
  isSurvey,
  onSelectAnswer,
  pinnedAnswers,
  results,
  resultsCustom,
  setPinnedAnswers,
}) => {
  const userData = useSelector((state) => state?.user?.data);

  const customAttributes = activePrompt?.attributes.filter(
    (attribute) => attribute?.isCustom && !attribute?.isFixed
  );
  const fixedAttributes = activePrompt?.attributes.filter((attribute) => attribute?.isFixed);

  const customResults = resultsCustom?.filter((result) => !result?.is_fixed);
  const fixedCustomResults = resultsCustom?.filter((result) => result?.is_fixed);

  return (
    <div className={styles['attribute-options']}>
      <AttributeOptionsResults
        activeAnswers={activeAnswers}
        activePrompt={activePrompt}
        documentId={documentId}
        hasActivePromptLimit={hasActivePromptLimit}
        isLoading={isLoading}
        isSurvey={isSurvey}
        onSelectAnswer={onSelectAnswer}
        pinnedAnswers={pinnedAnswers}
        results={results}
        setPinnedAnswers={setPinnedAnswers}
      />
      {fixedAttributes?.length > 0 && (
        <>
          <h4>{`${userData?.clientInfo?.name} Hierarchy`}</h4>
          <AttributeOptionsResults
            activeAnswers={activeAnswers}
            activePrompt={activePrompt}
            documentId={documentId}
            hasActivePromptLimit={hasActivePromptLimit}
            isLoading={isLoadingCustom}
            isSurvey={isSurvey}
            onSelectAnswer={onSelectAnswer}
            pinnedAnswers={pinnedAnswers}
            results={fixedCustomResults}
            setPinnedAnswers={setPinnedAnswers}
          />
        </>
      )}
      {customAttributes?.length > 0 && (
        <>
          <h4>User Custom Groups</h4>
          <AttributeOptionsResults
            activeAnswers={activeAnswers}
            activePrompt={activePrompt}
            documentId={documentId}
            hasActivePromptLimit={hasActivePromptLimit}
            isLoading={isLoadingCustom}
            isSurvey={isSurvey}
            onSelectAnswer={onSelectAnswer}
            pinnedAnswers={pinnedAnswers}
            results={customResults}
            setPinnedAnswers={setPinnedAnswers}
          />
        </>
      )}
    </div>
  );
};

AttributeOptions.propTypes = {
  activeAnswers: PropTypes.array,
  activePrompt: PropTypes.object,
  documentId: PropTypes.string,
  hasActivePromptLimit: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingCustom: PropTypes.bool,
  isSurvey: PropTypes.bool,
  onSelectAnswer: PropTypes.func,
  pinnedAnswers: PropTypes.object,
  results: PropTypes.array,
  resultsCustom: PropTypes.array,
  setPinnedAnswers: PropTypes.func,
};

export default AttributeOptions;
