import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import { IconCTA, OverlayTriggerTooltip, PlusIcon } from '@utilities';

import styles from './_index.module.scss';

const AddBreakout = ({
  checkAvailability,
  groupType,
  isHoverAction,
  isSelectedOwned,
  itemsPage,
  itemsRow,
  redirect,
  selectedRows,
  type,
}) => {
  const [isAvailable, setIsAvailable] = useState(true);
  const navigate = useNavigate();

  const isVisible = selectedRows.length === 1 && isSelectedOwned;

  useEffect(() => {
    const selectedRow = selectedRows[0];
    const isActionAvailable = checkAvailability(selectedRow);

    if (isActionAvailable !== undefined) {
      setIsAvailable(isActionAvailable);
    }
  }, [selectedRows]);

  const overlayContent = isAvailable
    ? 'Add a Breakout'
    : `Add a Breakout will be available after ${type} has finished processing`;

  if (!isVisible) return null;

  return (
    <OverlayTriggerTooltip content={isHoverAction ? overlayContent : ''}>
      <div
        className={classNames(styles['add-breakout'], { [styles['is-disabled']]: !isAvailable })}
      >
        <IconCTA
          data-testid="add-breakout-cta"
          icon={<PlusIcon />}
          onClick={() =>
            navigate(`${redirect}/${selectedRows[0]?.id}`, {
              state: { itemsPage, itemsRow, type: groupType },
            })
          }
          text={isHoverAction ? '' : 'Add a Breakout'}
        />
      </div>
    </OverlayTriggerTooltip>
  );
};

AddBreakout.defaultProps = {
  checkAvailability: () => {},
  itemsPage: 1,
};

AddBreakout.propTypes = {
  checkAvailability: PropTypes.func,
  groupType: PropTypes.string,
  isHoverAction: PropTypes.bool,
  isSelectedOwned: PropTypes.bool,
  itemsPage: PropTypes.number,
  itemsRow: PropTypes.string,
  redirect: PropTypes.string,
  selectedRows: PropTypes.array,
  type: PropTypes.string,
};

export default AddBreakout;
