import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { getPromptPillValue } from '@helpers';
import { CloseBoldIcon, DragDropIcon, truncateTextWithEllipsis } from '@utilities';

import styles from './_index.module.scss';

const Selections = ({ activeAnswers, activePrompt, onSelectAnswer, onSortAnswers }) => {
  const onDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }

    const answers = activeAnswers;
    const [removed] = answers.splice(result?.source?.index, 1);
    answers.splice(result?.destination?.index, 0, removed);

    onSortAnswers(answers);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            className={styles['selections']}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <h3>
              <strong>{activePrompt?.name}</strong>
            </h3>
            {activePrompt?.hasSortEnabled && (
              <p>Make up to {activePrompt?.max} selections and order them by priority below.</p>
            )}
            {!activePrompt?.hasSortEnabled && activePrompt?.max && (
              <p>Make up to {activePrompt?.max} selections.</p>
            )}
            <ul>
              {activeAnswers.map((activeAnswer, index) => {
                const pillValue = truncateTextWithEllipsis({
                  text: getPromptPillValue({
                    answers: { [activePrompt?.id]: [activeAnswer] },
                    prompt: activePrompt,
                  }),
                });

                return (
                  <Draggable
                    draggableId={`activeAnswer-${index}`}
                    key={`activeAnswer-${index}`}
                    index={index}
                    isDragDisabled={!activePrompt?.hasSortEnabled}
                  >
                    {(provided, snapshot) => {
                      return (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div>
                            <div>
                              {activePrompt?.hasSortEnabled && <DragDropIcon />}
                              <p>{pillValue}</p>
                            </div>
                            <button
                              className={styles['selections-remove']}
                              onClick={() => onSelectAnswer(activeAnswer)}
                            >
                              <CloseBoldIcon />
                            </button>
                          </div>
                        </li>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

Selections.propTypes = {
  activeAnswers: PropTypes.array,
  activePrompt: PropTypes.object,
  onSelectAnswer: PropTypes.func,
  onSortAnswers: PropTypes.func,
};

export default Selections;
