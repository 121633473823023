import PropTypes from 'prop-types';
import { MentionsInput, Mention } from 'react-mentions';

import styles from './_index.module.scss';

const Mentions = ({ onChange, placeholder, usersList, value }) => {
  const handleChange = (event) => {
    onChange(event?.target?.value);
  };

  const displayTransformer = (id) => {
    const allSharedUsersMentions = [
      { display: 'Shared with all current colleagues', id: 'all-current-users' },
      {
        display: 'Shared with all current and future colleagues',
        id: 'all-current-and-future-users',
      },
    ];
    const expandedUserList = [...usersList, ...allSharedUsersMentions];
    const userName = expandedUserList.find((user) => user?.id.toString() === id.toString()).display;
    return '@' + userName;
  };

  return (
    <MentionsInput
      a11ySuggestionsListLabel="Suggested mentions"
      allowSpaceInQuery
      classNames={styles}
      onChange={handleChange}
      placeholder={placeholder}
      value={value}
    >
      <Mention
        className={styles['mentions-mention']}
        data={usersList}
        displayTransform={(id) => displayTransformer(id)}
        markup="@{{__id__}}"
        renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
          <div className={`user ${focused ? 'focused' : ''}`}>{highlightedDisplay}</div>
        )}
        trigger="@"
      />
    </MentionsInput>
  );
};

Mentions.defaultProps = {
  onChange: () => {},
};

Mentions.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  usersList: PropTypes.array,
  value: PropTypes.string,
};

export default Mentions;
