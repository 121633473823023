import moment from 'moment';

/**
 * accepts javascript new Date and converts to YYYY/MM/DD
 * @param  {Date}    date
 * @param  {Boolean} isUTC
 * @return {String}  YYYY/MM/DD
 */
export const getFormattedDate = (date, isUTC = false) => {
  if (isUTC) {
    return moment.unix(date).utc().format('YYYY-MM-DD');
  }

  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return year + '-' + month + '-' + day;
};

/**
 * accepts javascript new Date and converts to MMM DD, YYYY
 * @param  {Date}    date
 * @return {String}  MMM DD, YYYY
 */
export const getFormattedLongDate = (date) => {
  return date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

/**
 * gets previous day
 * @param  {Date}    date
 * @return {Date}
 */
export const getPreviousDay = (date) => {
  const previousDay = new Date(date.getTime());

  previousDay.setDate(date.getDate() - 1);

  return previousDay;
};
