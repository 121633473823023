import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './_index.module.scss';

const Toolbar = ({ children, className, tools }) => {
  return (
    <div className={classNames(styles['toolbar'], className)}>
      <div>{children}</div>
      {tools.length > 0 && (
        <ul className={styles['toolbar-tools']}>
          {tools.map((tool, index) => (
            <li key={index}>{tool}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

Toolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tools: PropTypes.array.isRequired,
};

export default Toolbar;
