/* eslint-disable react/prop-types */

import { nanoid } from 'nanoid';
import pick from 'lodash/pick';

import { formatData } from '@utilities';

import IndexBar from '../components/IndexBar';

/**
 * gets table data rows and columns
 * @param  {Object} options.activeColumnFilter
 * @param  {String} options.addChart
 * @param  {Node}   options.children
 * @param  {String} options.defaultDataId
 * @param  {Object} options.filteredData
 * @return {Array|Array} columns and rows
 */
export const getTableData = ({
  activeColumnFilter,
  addChart,
  children,
  defaultDataId,
  filteredData,
}) => {
  const tableSet = defaultDataId
    ? filteredData.find((set) => set?.id === defaultDataId)
    : filteredData[0];

  if (!tableSet) return {};

  const getColumns = (columns) =>
    columns
      .filter((child) => child?.props?.data)
      .map((column) => {
        const hasIndexBar = column?.props?.addChart === 'True';
        const width = parseInt(column?.props?.width);

        return {
          accessor: column?.props?.data?.replace('.', '_'),
          Cell: (props) => {
            const accessors = column?.props?.data.replace('.', '_').split(';');

            let index, value;

            for (const [accessorIndex] of accessors.entries()) {
              if (props?.row?.original[accessors[accessorIndex]] !== 'NAN_SENTINEL') {
                index = accessorIndex;
                value = props?.row?.original[accessors[accessorIndex]];
              } else {
                break;
              }
            }

            const style = hasIndexBar
              ? {
                  alignItems: 'center',
                  display: 'flex',
                }
              : {};

            return (
              <span
                style={{
                  ...style,
                  ...{
                    marginLeft: `${index * 15}px`,
                  },
                }}
              >
                {formatData({
                  format: column?.props?.format,
                  value: value || 'NAN_SENTINEL',
                })}
                {hasIndexBar && <IndexBar value={value} />}
              </span>
            );
          },
          disableSortBy: column?.props?.disableSorting === 'true',
          format: column?.props?.format,
          gradient: column?.props?.gradient === 'True',
          Header: tableSet?.colLabels[column?.props?.data] || '',
          headerTooltip: column?.props?.tooltip,
          getCellHighlight: ({ rowIndex, value }) => {
            if (
              tableSet?.metadata?.summaryRows &&
              tableSet?.metadata?.summaryRows.includes(rowIndex) &&
              !tableSet?.metadata?.indentedRows[rowIndex]
            ) {
              return 'header';
            }

            if (
              !value ||
              column?.props?.heatMap !== 'True' ||
              !column?.props?.data.includes(column?.props?.heatMapColumn)
            )
              return;

            if (value <= 80) {
              return 'negative';
            } else if (value <= 90 && value > 80) {
              return 'soft-negative';
            } else if (value >= 110 && value < 120) {
              return 'soft-positive';
            } else if (value >= 120) {
              return 'positive';
            }
          },
          width: width >= 150 ? width : 150,
        };
      })
      .filter((column) => {
        return !activeColumnFilter
          ? column
          : activeColumnFilter?.value?.target.includes(column?.accessor) ||
              activeColumnFilter?.value?.target.includes(column?.accessor.replace('_', '.'));
      });

  const columnGroups = children.filter((child) => Array.isArray(child?.props?.children));

  const columns =
    columnGroups?.length > 0
      ? children
          .filter((child) => Array.isArray(child?.props?.children) || child?.props?.data)
          .map((child) => {
            const isColumnGroup = Array.isArray(child?.props?.children);

            return isColumnGroup
              ? {
                  columns: getColumns(child?.props?.children),
                  Header: child?.props?.title,
                  id: child?.props?.title,
                }
              : {
                  columns: getColumns([child]),
                  Header: '',
                  id: nanoid(),
                };
          })
      : getColumns(children);

  const rows = tableSet?.data.map((set) => {
    Object.keys(set).forEach((setKey) => {
      if (setKey.includes('.')) {
        const newKey = setKey.replace('.', '_');
        set[newKey] = set[setKey];
        delete set[setKey];
      }
    });

    return !activeColumnFilter
      ? set
      : {
          ...set,
          data: set?.data?.map((row) => pick(row, activeColumnFilter?.value?.target)),
        };
  });

  return { columns, rows };
};
