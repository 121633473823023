import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const PageTitle = ({ title }) => {
  const defaultTitle = 'Numerator Insights';
  return (
    <Helmet defer={false}>
      <title>{title || defaultTitle}</title>
    </Helmet>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
};

export default PageTitle;
