import axios from '@api';
import { transformFilters, transformTableData } from './utilities/helpers';

/**
 * read data for demographics bar chart
 * @param  {Object} options.chartsFilters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readDemographicsBarChart = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      demographic_attribute: globalFilters?.demographic_attribute?.value,
      metric: chartsFilters?.metric?.value,
      product_level_one: globalFilters?.product_level_one?.value,
      product_level_three: chartsFilters?.product_level_three?.value,
      store_level_two: chartsFilters?.store_level_two?.value,
      time_int: globalFilters?.time_int?.value,
    },
    signal,
    url: `/v2/api/dashboards/demographics-tab/bar-chart`,
    withCredentials: true,
  });
};

/**
 * read data for demographics charts filter data
 * @param  {Object} options.chartsFilters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readDemographicsChartsFiltersData = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      demographic_attribute: globalFilters?.demographic_attribute?.value,
      product_level_one: globalFilters?.product_level_one?.value,
      time_int: globalFilters?.time_int?.value,
    },
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: `/v2/api/dashboards/demographics-tab/charts/filters`,
    withCredentials: true,
  });
};

/**
 * read data for demographics global filters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readDemographicsGlobalFiltersData = ({ signal }) => {
  return axios({
    method: 'get',
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: `/v2/api/dashboards/demographics-tab/global-filters`,
    withCredentials: true,
  });
};

/**
 * read data for stores bar chart
 * @param  {Object} options.chartsFilters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readStoresBarChart = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      metric: chartsFilters?.metric?.value,
      product_level_one: globalFilters?.product_level_one?.value,
      product_level_three: chartsFilters?.product_level_three
        ?.map((filter) => filter.value)
        .join(';'),
      store_level_one: chartsFilters?.store_level_one?.value,
      store_level_three: chartsFilters?.store_level_three?.value,
      store_level_two: chartsFilters?.store_level_two?.value,
      time_int: globalFilters?.time_int?.value,
    },
    signal,
    url: `/v2/api/dashboards/stores-tab/bar-chart`,
    withCredentials: true,
  });
};

/**
 * read data for stores charts filter data
 * @param  {Object} options.chartsFilters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readStoresChartsFiltersData = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      product_level_one: globalFilters?.product_level_one?.value,
      store_level_one: chartsFilters?.store_level_one?.value,
      store_level_three: chartsFilters?.store_level_three?.value,
      store_level_two: chartsFilters?.store_level_two?.value,
      time_int: globalFilters?.time_int?.value,
    },
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: `/v2/api/dashboards/stores-tab/charts/filters`,
    withCredentials: true,
  });
};

/**
 * read data for stores global filters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readStoresGlobalFiltersData = ({ signal }) => {
  return axios({
    method: 'get',
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: `/v2/api/dashboards/stores-tab/global-filters`,
    withCredentials: true,
  });
};

/**
 * read data for stores line chart
 * @param  {Object} options.chartsFilters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readStoresLineChart = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      metric: chartsFilters?.metric?.value,
      product_level_one: globalFilters?.product_level_one?.value,
      product_level_three: chartsFilters?.product_level_three
        ?.map((filter) => filter.value)
        .join(';'),
      store_level_one: chartsFilters?.store_level_one?.value,
      store_level_three: chartsFilters?.store_level_three?.value,
      store_level_two: chartsFilters?.store_level_two?.value,
      time_int: globalFilters?.time_int?.value,
    },
    signal,
    url: `/v2/api/dashboards/stores-tab/line-graph`,
    withCredentials: true,
  });
};

/**
 * read data for stores table
 * @param  {Object} options.globalFilters
 * @param  {Object} options.tableFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readStoresTable = ({ globalFilters, tableFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      metric_type: tableFilters?.metric_type?.value,
      product_level_one: globalFilters?.product_level_one?.value,
      product_level_three: tableFilters?.product_level_three?.value,
      store_level_one: tableFilters?.store_level_one?.value,
      time_int: globalFilters?.time_int?.value,
    },
    signal,
    transformResponse: [(data) => transformTableData(JSON.parse(data))],
    url: `/v2/api/dashboards/stores-tab/table`,
    withCredentials: true,
  });
};

/**
 * read data for stores table filters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readStoresTableFiltersData = ({ globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      product_level_one: globalFilters?.product_level_one?.value,
      time_int: globalFilters?.time_int?.value,
    },
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: `/v2/api/dashboards/stores-tab/table/filters`,
    withCredentials: true,
  });
};

/**
 * read data for summary bar chart
 * @param  {Object} options.chartsFilters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readSummaryBarChart = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      breakout: globalFilters?.breakout?.value,
      metric: chartsFilters?.metric?.value,
      product: chartsFilters?.product?.map((filter) => filter.value).join(';'),
      product_level_one: globalFilters?.product_level_one?.value,
      time_int: globalFilters?.time_int?.value,
    },
    signal,
    url: `/v2/api/dashboards/summary-tab/bar-chart`,
    withCredentials: true,
  });
};

/**
 * read data for summary line chart
 * @param  {Object} options.chartsFilters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readSummaryLineChart = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      breakout: globalFilters?.breakout?.value,
      metric: chartsFilters?.metric?.value,
      product: chartsFilters?.product?.map((filter) => filter.value).join(';'),
      product_level_one: globalFilters?.product_level_one?.value,
      time_int: globalFilters?.time_int?.value,
    },
    signal,
    url: `/v2/api/dashboards/summary-tab/line-graph`,
    withCredentials: true,
  });
};

/**
 * read data for summary charts filter data
 * @param  {Object} options.chartsFilters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readSummaryChartsFiltersData = ({ chartsFilters, globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      breakout: globalFilters?.breakout?.value,
      product_level_one: globalFilters?.product_level_one?.value,
      time_int: globalFilters?.time_int?.value,
    },
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: `/v2/api/dashboards/summary-tab/charts/filters`,
    withCredentials: true,
  });
};

/**
 * read data for summary global filters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readSummaryGlobalFiltersData = ({ signal }) => {
  return axios({
    method: 'get',
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: `/v2/api/dashboards/summary-tab/global-filters`,
    withCredentials: true,
  });
};

/**
 * read data for summary table
 * @param  {Object} options.globalFilters
 * @param  {Object} options.tableFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readSummaryTable = ({ globalFilters, signal, tableFilters }) => {
  return axios({
    method: 'get',
    params: {
      product_level_one: globalFilters?.product_level_one?.value,
      time_int: globalFilters?.time_int?.value,
      store_level_one: tableFilters?.store_level_one?.value,
      metric_type: tableFilters?.metric_type?.value,
      breakout: globalFilters?.breakout?.value,
    },
    signal,
    transformResponse: [(data) => transformTableData(JSON.parse(data))],
    url: `/v2/api/dashboards/summary-tab/table`,
    withCredentials: true,
  });
};

/**
 * read data for summary table filters
 * @param  {Object} options.globalFilters
 * @param  {Object} options.signal
 * @return {Promise}
 */
export const readSummaryTableFiltersData = ({ globalFilters, signal }) => {
  return axios({
    method: 'get',
    params: {
      product_level_one: globalFilters?.product_level_one?.value,
      time_int: globalFilters?.time_int?.value,
      breakout: globalFilters?.breakout?.value,
    },
    signal,
    transformResponse: [(data) => transformFilters(JSON.parse(data))],
    url: `/v2/api/dashboards/summary-tab/table/filters`,
    withCredentials: true,
  });
};
