import { reportStatus, tabStatus } from '@helpers';

/**
 * Calculate the tab status based on specified conditions.
 * @param {Object} options.report
 * @param {number} options.tabId
 * @returns {string}
 */
export const getTabStatus = ({ report, tabId }) => {
  const { completeJobItemIds, status } = report || {};

  if (completeJobItemIds && status >= reportStatus.RUNNING) {
    return completeJobItemIds.includes(tabId) ? tabStatus.COMPLETED : tabStatus.STARTED;
  }

  return tabStatus.QUEUED;
};

/**
 * gets tab status message to display
 * @param  {Number} options.status
 * @return {String}
 */
export const getTabStatusMessage = ({ status }) => {
  let message;

  switch (true) {
    case status <= tabStatus.QUEUED:
      message = 'In Queue';
      break;
    case status === tabStatus.STARTED:
      message = 'Processing';
      break;
    default:
      message = '';
  }

  return message;
};
