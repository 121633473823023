import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import LogRocket from 'logrocket';
import PropTypes from 'prop-types';

import { Announcement, Button, IconButton, Layout, Select, toast } from '@utilities';
import { RepromptIcon, ThumbsDownIcon, ThumbsUpIcon } from '@utilities/icons';

import { createSurveyGPTFeedback, readSurveyGPT } from '@api/surveygpt';

import gptIcon from './assets/gpt-icon.svg';

import buttonStyles from '@utilities/components/Button/_index.module.scss';
import styles from './_index.module.scss';

const NEGATIVE = 'NEGATIVE';
const POSITIVE = 'POSITIVE';

const noteOptions = {
  [NEGATIVE]: [
    { label: 'Incorrect information returned', value: 'Incorrect information returned' },
    { label: 'Confusing statements', value: 'Confusing statements' },
    { label: 'Too vague', value: 'Too vague' },
    { label: 'Inappropriate comments', value: 'Inappropriate comments' },
    { label: 'Other, not listed', value: 'Other, not listed' },
  ],
  [POSITIVE]: [
    { label: 'Correct information returned', value: 'Correct information returned' },
    { label: 'Relevant information', value: 'Relevant information' },
    { label: 'Helped guide next steps', value: 'Helped guide next steps' },
    { label: 'Added additional context', value: 'Added additional context' },
    { label: 'Other, not listed', value: 'Other, not listed' },
  ],
};

const SurveyGPTAnnouncement = ({ documentId, surveyGPTJobId }) => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectingFeedback, setIsSelectingFeedback] = useState(false);
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [gptResponse, setGPTResponse] = useState();
  const [note, setNote] = useState(null);

  const { id: userId, permissions } = useSelector((state) => state?.user?.data);

  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    getSurveyGPTResponse();
    return () => {
      controller.abort();
    };
  }, []);

  const getSurveyGPTResponse = async () => {
    try {
      const response = await readSurveyGPT({
        signal,
        surveyGPTJobId,
        userId,
      });
      if (response.status === 'COMPLETE') {
        setGPTResponse(response);
        setIsLoading(false);
        LogRocket.track('NumeratorAI_Viewed', { type: documentId });
      } else {
        setError(response);
      }
    } catch (error) {
      if (!signal.aborted) {
        console.error(error);
        setError(error);
      }
    }
  };

  const hasProvidedFeedback =
    gptResponse?.userLeftFeedback || gptResponse?.userLeftNegativeFeedback;

  const sendSurveyGPTFeedback = async () => {
    setIsSendingFeedback(true);
    const feedbackController = new AbortController();

    try {
      await createSurveyGPTFeedback({
        feedback,
        note: note?.value,
        signal: feedbackController.signal,
        surveyGPTJobId,
      });

      setGPTResponse({
        ...gptResponse,
        userLeftFeedback: true,
      });
      setIsSelectingFeedback(false);
      LogRocket.track('NumeratorAI_Feedback', { feedback, note: note?.value });
    } catch (error) {
      if (!feedbackController.signal.aborted) {
        toast(
          "There was an error while providing feedback that we'll be investigating. Thank you for your patience.",
          { status: 'error' }
        );
      }
      setIsSendingFeedback(false);
    }
  };

  const setDefaultFeedback = (value) => {
    setFeedback(value);
    setNote(noteOptions[value][0]);
    setIsSelectingFeedback(true);
  };

  return (
    <Announcement
      className={classNames(styles['survey-gpt'], {
        [styles['is-hidden']]: !!error || gptResponse?.isActive === false,
      })}
      hasClose
      header={
        <>
          Numerator AI<sup>BETA</sup> Says
        </>
      }
      text={
        isLoading ? (
          <Layout.Flex className={styles['survey-gpt-footer']} gap="small">
            <RepromptIcon className={styles['survey-gpt-loader']} />
            Reviewing what shoppers are saying...
          </Layout.Flex>
        ) : (
          <>
            <p>{gptResponse?.output}</p>
            <Layout.Flex className={styles['survey-gpt-footer']} gap="small">
              <img alt="" className={styles['survey-gpt-icon']} src={gptIcon} />
              <div>
                This insight is powered by the Numerator AI<sup>BETA</sup> Engine reviewing millions
                of historical Numerator Survey results and data points to develop an Insight.
                <br />
                Like with all AI, the Numerator team is experimenting with how it learns and the
                insights it delivers.
              </div>
              {!hasProvidedFeedback && (
                <>
                  <IconButton
                    id="survey-gpt-feedback-thumbs-up"
                    onClick={() => setDefaultFeedback(POSITIVE)}
                    tooltip="This is helpful"
                  >
                    <ThumbsUpIcon />
                  </IconButton>
                  <IconButton.Danger
                    id="survey-gpt-feedback-thumbs-down"
                    onClick={() => setDefaultFeedback(NEGATIVE)}
                    tooltip="This is not helpful"
                  >
                    <ThumbsDownIcon />
                  </IconButton.Danger>
                </>
              )}
              {permissions?.canCreateSurveys && (
                <Link
                  data-log="NumeratorAI_ClickedCTA"
                  data-log-properties={JSON.stringify({ type: documentId })}
                  className={classNames(buttonStyles['button-primary'], {
                    [buttonStyles['button-secondary']]: isSelectingFeedback,
                    [styles['survey-gpt-disabled-link']]: isSelectingFeedback,
                  })}
                  target="_blank"
                  to="/dashboard/surveys/instant-surveys"
                >
                  Launch an Instant Survey
                </Link>
              )}
            </Layout.Flex>
            {isSelectingFeedback && (
              <Layout.Flex className={styles['survey-gpt-feedback']} gap="small">
                <Select
                  id="survey-gpt-feedback-note-select"
                  options={noteOptions[feedback]}
                  onChange={setNote}
                  value={note}
                />
                <Button
                  id="survey-gpt-feedback-cancel"
                  onClick={() => setIsSelectingFeedback(false)}
                  text="Cancel"
                  variant="secondary"
                />
                <Button
                  id="survey-gpt-feedback-send"
                  isLoading={isSendingFeedback}
                  onClick={sendSurveyGPTFeedback}
                  text="Send Feedback"
                />
              </Layout.Flex>
            )}
          </>
        )
      }
    />
  );
};

SurveyGPTAnnouncement.propTypes = {
  documentId: PropTypes.string,
  surveyGPTJobId: PropTypes.number,
};

export default SurveyGPTAnnouncement;
