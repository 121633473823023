import PropTypes from 'prop-types';
import queryString from 'query-string';

import { Button } from '@utilities';

import { setEndpoint } from '@helpers';

import { containers } from '../../utilities/helpers';

import styles from './_index.module.scss';

const SSO = ({ isVerifiedVoicesSubdomain, next, setContainer }) => {
  const url = queryString.stringifyUrl({ url: setEndpoint('sso/login'), query: { next } });

  return (
    <div className={styles['sso']}>
      {isVerifiedVoicesSubdomain ? (
        <h1>Real research from real buyers. Real fast.</h1>
      ) : (
        <h1>
          Single data source.
          <br />
          Infinite consumer insights.
        </h1>
      )}
      <Button
        data-testid="sso-login"
        onClick={() => {
          window.location.replace(url);
        }}
        text="Sign In"
        variant="tertiary"
      />
      {process.env.REACT_APP_CONSULTANT_LOGIN === 'true' && (
        <div
          className={styles['sso-action']}
          data-testid="sso-action-consultant"
          onClick={() => setContainer(containers.CONSULTANT)}
        >
          Consultant Sign In
        </div>
      )}
    </div>
  );
};

SSO.propTypes = {
  isVerifiedVoicesSubdomain: PropTypes.bool,
  next: PropTypes.string,
  setContainer: PropTypes.func.isRequired,
};

export default SSO;
