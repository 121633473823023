import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  handlePageClick,
  LoaderSkeleton,
  Pagination,
  Radio,
  Search,
  usePaginatedItems,
} from '@utilities';

import { readSearchResults } from '@api/prompts';

import styles from './_index.module.scss';

const SearchOptions = ({
  attributeIds,
  isDebouncing,
  item,
  promptId,
  searchQuery,
  selectedAreaOfInterest,
  setItem,
  setSearchQuery,
  signal,
}) => {
  const [allproducts, setAllproducts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const searchRef = useRef(null);

  const prompts = ['product', 'categories'];

  const { currentItems, itemsPerPage, pageCount, setOffset } = usePaginatedItems({
    items: allproducts,
    itemsPerPage: 5,
  });

  const searchAnswers = Object.entries(selectedAreaOfInterest)
    .filter(
      ([key, value]) =>
        key !== promptId && prompts.includes(key) && value?.[0]?.attributeId && value?.[0]?.id
    )
    .reduce((acc, [key, [{ attributeId, id }]]) => {
      acc[key] = [{ attributeId, id }];
      return acc;
    }, {});

  const onPageChange = (event) => {
    handlePageClick({ event, items: allproducts, itemsPerPage: 5, setOffset });
  };

  const handleRadioChange = (option) => {
    setItem(option);
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      if (searchRef?.current) {
        searchRef.current.abort();
      }
      searchRef.current = new AbortController();

      try {
        const response = await readSearchResults({
          attributeIds,
          documentId: 'area-of-interest',
          promptId,
          searchAnswers,
          searchPrompts: prompts,
          searchQuery,
          signal: searchRef.current.signal,
        });
        setAllproducts(response?.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    getData();
  }, [signal]);

  return (
    <>
      <div className={styles['search-options-searchbar']}>
        <Search
          hasAutoFocus
          isSearching={(isDebouncing || isLoading) && searchQuery !== ''}
          onChange={setSearchQuery}
          value={searchQuery}
        />
      </div>
      <div className={styles['search-options-results']}>
        {isDebouncing || isLoading ? (
          <div className={styles['search-options-loader']}>
            <LoaderSkeleton>
              <rect x="0" y="0" rx="2" ry="2" width="200" height="10" />
              <rect x="0" y="25" rx="2" ry="2" width="175" height="10" />
              <rect x="0" y="50" rx="2" ry="2" width="225" height="10" />
              <rect x="0" y="75" rx="2" ry="2" width="200" height="10" />
              <rect x="0" y="100" rx="2" ry="2" width="225" height="10" />
              <rect x="0" y="125" rx="2" ry="2" width="200" height="10" />
            </LoaderSkeleton>
          </div>
        ) : (
          <div>
            {currentItems?.length === 0 && (
              <div className={styles['search-options-empty-message']}>
                <h3>No results found.</h3>
                <p>Try refining your search.</p>
              </div>
            )}
            <ul className={styles['search-options-list']}>
              {currentItems?.map((currentItem, index) => (
                <li key={index}>
                  <Radio
                    isChecked={currentItem?.id === item?.id}
                    label={currentItem?.name}
                    name={currentItem?.name}
                    onChange={handleRadioChange}
                    value={currentItem}
                  />
                </li>
              ))}
            </ul>
            <Pagination
              onPageChange={onPageChange}
              pageCount={pageCount}
              show={allproducts?.length > itemsPerPage}
            />
          </div>
        )}
      </div>
    </>
  );
};

SearchOptions.propTypes = {
  attributeIds: PropTypes.array,
  isDebouncing: PropTypes.bool,
  item: PropTypes.object,
  promptId: PropTypes.string,
  searchQuery: PropTypes.string,
  selectedAreaOfInterest: PropTypes.object,
  setItem: PropTypes.func,
  setSearchQuery: PropTypes.func,
  signal: PropTypes.number,
};

export default SearchOptions;
