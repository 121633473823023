import axios from '@api';

import {
  getCustomGroupType,
  transformCustomGroupData,
  transformCustomGroupsData,
} from './utilities/helpers';

/**
 * create custom group
 * @param  {Object} options.data
 * @param  {String} options.name
 * @param  {String} options.type
 * @return {Promise}
 */
export const createCustomGroup = ({ definition = {}, name, type }) => {
  return axios({
    data: {
      definition,
      name,
    },
    method: 'post',
    url: `/v2/api/customgroups?group_type=${type}`,
    withCredentials: true,
  });
};

/**
 * delete custom groups
 * @param  {Array}  options.ids
 * @return {Promise}
 */
export const deleteCustomGroups = ({ ids }) => {
  return axios({
    data: ids,
    method: 'delete',
    url: `/v2/api/customgroups`,
    withCredentials: true,
  });
};

/**
 * read custom group item V2
 * @param  {Number} options.id
 * @param  {Number} options.signal  AbortController signal
 * @return {Promise}
 */
export const readCustomGroup = ({ id, signal }) => {
  return axios({
    method: 'get',
    signal,
    transformResponse: [(data) => transformCustomGroupData(JSON.parse(data))],
    url: `/v2/api/customgroups/${id}`,
    withCredentials: true,
  });
};

/**
 * read custom group list V2
 * @param  {String} options.order   "asc" || "desc" || undefined
 * @param  {Number} options.page
 * @param  {String} options.query
 * @param  {Number} options.signal  AbortController signal
 * @param  {Number} options.size
 * @param  {String} options.sortBy
 * @param  {Array}  options.tags    [is_locked, is_shared]
 * @param  {String} options.type product || store
 */
export const readCustomGroups = ({ order, page, query, signal, size, sortBy, tags, type }) => {
  return axios({
    method: 'get',
    params: {
      order_by: sortBy ? order : undefined,
      page,
      search_bar_query: query?.length > 0 ? query : undefined,
      size,
      sort_by: sortBy,
      tags: tags?.length > 0 ? tags?.join(',') : undefined,
    },
    signal,
    transformResponse: [(data) => transformCustomGroupsData(JSON.parse(data))],
    url: `/v2/api/customgroups/list/${type}`,
    withCredentials: true,
  });
};

/**
 * read custom group attribute elements
 * @param  {String} options.attributeId
 * @param  {String} options.search
 * @param  {Number} options.signal  AbortController signal
 * @param  {String} options.type product || store
 * @return {Promise}
 */
export const readCustomGroupAttributeElements = ({ attributeId, search, signal, type }) => {
  const groupType = getCustomGroupType(type);
  return axios({
    method: 'get',
    params: {
      attributeId,
      search,
    },
    signal,
    url: `/api/prompts/${groupType}/search`,
    withCredentials: true,
  });
};

/**
 * read custom group hierarchy
 * @param  {Number} options.signal  AbortController signal
 * @param  {String} options.type    product || store
 * @return {Promise}
 */
export const readCustomGroupHierarchy = ({ signal, type }) => {
  return axios({
    method: 'get',
    signal,
    url: `/api/hierarchies/${type}`,
    withCredentials: true,
  });
};

/**
 * search custom group hierarchy
 * @param  {String} options.search
 * @param  {Number} options.signal  AbortController signal
 * @param  {String} options.type    product || store
 * @return {Promise}
 */
export const searchCustomGroupHierarchy = ({ search, signal, type }) => {
  return axios({
    method: 'get',
    signal,
    url: `/api/hierarchies/${type}/search?expression=${encodeURIComponent(search)}`,
    withCredentials: true,
  });
};

/**
 * shares group with all users under a client
 * @param  {Number} clientId
 * @param  {Array}  ids
 * @return {Promise}
 */
export const shareCustomGroupWithClient = ({ clientId, ids }) => {
  return axios({
    data: {
      customgroup_ids: ids,
      recipient_client_id: clientId,
    },
    method: 'post',
    url: `/v2/api/customgroups/client_share`,
    withCredentials: true,
  });
};

/**
 * shares group with array of users
 * @param  {Array}  options.ids
 * @param  {Boolean} options.skipEmail
 * @param  {Array}  options.userIds
 * @return {Promise}
 */
export const shareCustomGroupWithUsers = ({ ids, skipEmail = false, userIds }) => {
  return axios({
    data: {
      customgroup_ids: ids,
      recipient_ids: userIds,
      skip_email: skipEmail,
    },
    method: 'post',
    url: `/v2/api/customgroups/user_share`,
    withCredentials: true,
  });
};

/**
 * update custom group breakouts
 * @param  {Object} options.definition
 * @param  {Number} options.id
 * @return {Promise}
 */
export const updateCustomGroupBreakouts = ({ definition, id }) => {
  return axios({
    data: {
      definition,
    },
    method: 'put',
    url: `/v2/api/customgroups/${id}`,
    withCredentials: true,
  });
};

/**
 * update custom group name
 * @param  {Number} options.id
 * @param  {String} options.name
 * @return {Promise}
 */
export const updateCustomGroupName = ({ id, name }) => {
  return axios({
    data: {
      name,
    },
    method: 'put',
    url: `/v2/api/customgroups/${id}`,
    withCredentials: true,
  });
};
