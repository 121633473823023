import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { createReport, readReportPrompts } from '@api/reports';

import Builder from '../../components/Builder';

const CreateReport = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { report: documentId } = useParams();

  return (
    <Builder
      create={createReport}
      documentId={documentId}
      onSubmit={({ reportId }) => navigate(`/dashboard/report/${reportId}`)}
      read={() => readReportPrompts({ documentId, search, transform: false })}
      type="Report"
    />
  );
};

export default CreateReport;
