import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './_index.module.scss';

const Flex = ({ as: Component, children, className, direction, gap, ...props }) => (
  <Component
    className={classNames(styles[`flex-${direction}`], styles[`is-${gap}`], className)}
    {...props}
  >
    {children}
  </Component>
);

Flex.defaultProps = {
  as: 'div',
  direction: 'row',
  gap: 'medium',
};

Flex.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
  direction: PropTypes.oneOf(['column', 'column-reverse', 'row', 'row-reverse']),
  gap: PropTypes.oneOf(['small', 'medium', 'large', 'x-large']),
};

Flex.Column = (args) => <Flex direction="column" {...args} />;
Flex.Row = (args) => <Flex direction="row" {...args} />;

export default Flex;
