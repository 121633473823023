import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { DownloadIcon, IconCTA, OverlayTriggerTooltip } from '@utilities';
import { reportStatus, setEndpoint } from '@helpers';

import styles from './_index.module.scss';

const Download = ({ hasException, hasExpiration, isHoverAction, selectedRows }) => {
  const [isAvailable, setIsAvailable] = useState(
    selectedRows[0]?.isCompleted || selectedRows[0]?.hasExpiration
  );

  const reports = useSelector((state) => state?.websocket?.reports);

  useEffect(() => {
    if (!isAvailable) {
      const report = reports?.find((report) => report?.id === selectedRows[0]?.id);

      if (
        report?.status === reportStatus.COMPLETED ||
        report?.status === reportStatus.OUT_OF_DATE
      ) {
        setIsAvailable(true);
      }
    }
  }, [reports]);

  const isVisible = selectedRows.length === 1 && (!hasException || hasExpiration);
  const downloadFileUrl = setEndpoint(`v2/api/jobs/${selectedRows[0]?.id}/export`);

  if (!isVisible) return null;

  const overlayContent = isAvailable
    ? 'Download as .XLSX file'
    : 'Download will be available after report has finished processing';

  return (
    <OverlayTriggerTooltip
      aria-label="Download"
      content={overlayContent}
      placement={!isHoverAction ? 'top' : undefined}
    >
      <div className={classNames(styles['download'], { [styles['is-disabled']]: !isAvailable })}>
        <IconCTA
          data-testid="download-cta"
          href={downloadFileUrl}
          icon={<DownloadIcon />}
          text={isHoverAction ? '' : 'Download'}
        />
      </div>
    </OverlayTriggerTooltip>
  );
};

Download.propTypes = {
  hasException: PropTypes.bool,
  hasExpiration: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  selectedRows: PropTypes.array,
};

export default Download;
