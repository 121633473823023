import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Announcement, InfoFilledIcon } from '@utilities';
import { readReport } from '@api/reports';

import styles from './_index.module.scss';

const OmniCharacteristicsBanner = ({ reportId }) => {
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    readReport({ reportId, signal });

    return () => controller.abort();
  }, []);

  return (
    <Announcement
      hasClose
      header="OmniCharacteristics Detected"
      icon={<InfoFilledIcon />}
      text={
        <>
          Before diving into the data, make sure to check out the{' '}
          <a
            className={styles['omni-characteristics-banner-link']}
            href="https://help.insights.numerator.com/en/articles/7932537-about-omnicharacteristics"
            rel="noopener noreferrer"
            target="_blank"
          >
            Help Center
          </a>{' '}
          to learn more about the nuances of OmniCharacteristics data.
          <br />
          Still have questions? Reach out to a friendly Numerator Insights expert via our
          in-platform chat.
        </>
      }
      variant="info"
    />
  );
};

OmniCharacteristicsBanner.propTypes = {
  reportId: PropTypes.number.isRequired,
};

export default memo(OmniCharacteristicsBanner);
