import { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import sample from 'lodash/sample';

import Loader from '../../components/Loader';
import NuLoader from '../../components/NuLoader';

import styles from './_index.module.scss';

const LoaderView = ({ children, heading, isNuLoader, messages, progress }) => {
  const [text] = useState(sample(messages));

  return (
    <div className={styles['loader-view']}>
      {heading && <h2>{heading}</h2>}
      {isNuLoader ? (
        <>
          <NuLoader isCentered />
          <ProgressBar className={styles['loader-view-progress-bar']} now={progress} />
        </>
      ) : (
        <Loader isCentered />
      )}
      {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
      {children && <div className={styles['loader-view-children']}>{children}</div>}
    </div>
  );
};

LoaderView.defaultProps = {
  isNuLoader: false,
  messages: [],
  progress: 0,
};

LoaderView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  heading: PropTypes.string,
  isNuLoader: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.string),
  progress: PropTypes.number,
};

export default LoaderView;
