import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const CheckoutCancel = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');

  useEffect(() => {
    if (jobId) {
      return navigate(`/dashboard/report/${jobId}`);
    }
    navigate('/dashboard');
  }, []);

  return null;
};

export default CheckoutCancel;
