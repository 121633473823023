import PropTypes from 'prop-types';

import { Button, ModalV2 as Modal, Pill } from '@utilities';

import styles from './_index.module.scss';

const ModalMissingRequiredPrompts = ({ groups, invalidGroups, onClose }) => {
  return (
    <Modal
      buttons={[<Button key="button" onClick={onClose} text="Okay, Got it" />]}
      className={styles['modal-missing-required-prompts']}
      id="modal-missing-required-prompts"
      isActive
      onClose={onClose}
      title="Missing Required Prompts"
    >
      <p>
        Your panelist {invalidGroups.length === 1 ? 'group is' : 'groups are'} incomplete. Select
        values for the following prompts before continuing:
      </p>
      <ul>
        {invalidGroups.map((group) => {
          return (
            <li key={`group-missing-prompts-${group.id}`}>
              {groups.length > 1 && <h4>{group.name}</h4>}
              {group.prompts.map((prompt) => (
                <Pill
                  isRequired
                  key={`missing-prompt-${prompt.id}`}
                  onClick={() => {
                    onClose(prompt);
                  }}
                  value={prompt?.emptyValue}
                />
              ))}
            </li>
          );
        })}
      </ul>
    </Modal>
  );
};

ModalMissingRequiredPrompts.propTypes = {
  groups: PropTypes.array.isRequired,
  invalidGroups: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalMissingRequiredPrompts;
