import {
  COLOR_MIDDLEWEIGHT,
  COLOR_NUMERATOR_GREEN,
  COLOR_PINK,
  COLOR_WELTERWEIGHT,
} from '../../../helpers/colors';

/**
 * waterfall chart specific configurations
 * @type {Object}
 */
export const waterfallChartConfiguration = {
  colors: [COLOR_WELTERWEIGHT, COLOR_NUMERATOR_GREEN, COLOR_PINK, COLOR_MIDDLEWEIGHT],
  legend: {
    enabled: false,
  },
  xAxis: {
    type: 'category',
  },
};

/**
 * modifies options based on data
 * @param  {Object} options
 * @return {Object}
 */
export const setModifiedData = (options) => {
  options.series[0].borderWidth = 0;
  options.series[0].color = options?.colors[2];
  options.series[0].dataLabels = {
    allowOverlap: false,
    enabled: true,
    formatter: function () {
      const position = this.x;
      const label = this?.series?.data[position].options?.label;

      return label;
    },
    inside: false,
  };
  options.series[0].dashStyle = 'Dash';
  options.series[0].data[0].color = options?.colors[0];
  options.series[0].data[options.series[0].data.length - 1].color = options?.series[0]?.data[
    options?.series[0]?.data?.length - 1
  ]?.isSum
    ? options?.colors[3]
    : null;
  options.series[0].pointPadding = 0;
  options.series[0].upColor = options?.colors[1];
};
