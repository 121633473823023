import PropTypes from 'prop-types';
import { Toaster, ToastBar } from 'react-hot-toast';

import styles from './_index.module.scss';

const Notification = () => {
  return (
    <Toaster position="bottom-left" toastOptions={{ className: styles['notification'] }}>
      {(t) => {
        const status = t?.status || 'info';

        return (
          <ToastBar toast={t}>
            {() => (
              <div className={styles['notification-content']}>
                <div className={styles[`is-${status}`]}>
                  {t?.title && <h3>{t?.title}</h3>}
                  {t?.message}
                </div>
              </div>
            )}
          </ToastBar>
        );
      }}
    </Toaster>
  );
};

Notification.defaultProps = {
  status: 'info',
};

Notification.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
  status: PropTypes.oneOf(['success', 'error', 'info', 'warn']),
  title: PropTypes.string,
};

export default Notification;
