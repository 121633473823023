import {
  readSummaryBarChart,
  readSummaryChartsFiltersData,
  readSummaryGlobalFiltersData,
  readSummaryLineChart,
  readSummaryTable,
  readSummaryTableFiltersData,
} from '@api/dashboards';

import Insights from '../../../../components/Insights';

const Summary = () => {
  return (
    <Insights
      readBarChartData={readSummaryBarChart}
      readChartsFiltersData={readSummaryChartsFiltersData}
      readGlobalFiltersData={readSummaryGlobalFiltersData}
      readLineChartData={readSummaryLineChart}
      readTableData={readSummaryTable}
      readTableFiltersData={readSummaryTableFiltersData}
      tab="dashboard-summary"
    />
  );
};

export default Summary;
