export const payWithCard = {
  currency: 'USD',
  description: 'Secure payment processed through Stripe',
  name: 'Pay by Credit Card',
  key: 'credit_card',
};

export const payWithCredits = {
  currency: 'credits',
  currencySingular: 'credit',
  description: 'Available Credits:',
  name: 'Spend Numerator Credits',
  key: 'credits',
};
