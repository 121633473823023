import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { updateReportFavorite } from '@api/reports';

import { FavoriteToggle, OverlayTriggerTooltip } from '@utilities';

const Favorite = ({ isFavorite }) => {
  const [isFavoriteReport, setIsFavoriteReport] = useState(isFavorite);

  let { job_id: id } = useParams();

  const handleSubmitFavorite = async () => {
    try {
      const response = await updateReportFavorite({ id, isFavorite: !isFavoriteReport });
      setIsFavoriteReport(response?.data?.is_favorite);
    } catch (error) {
      console.error(error, 'Could not update favorite status.');
    }
  };

  return (
    <OverlayTriggerTooltip aria-label="Favorite" content="Favorite">
      <button data-testid="favorite-button" onClick={handleSubmitFavorite}>
        <FavoriteToggle isFavorite={isFavoriteReport} />
      </button>
    </OverlayTriggerTooltip>
  );
};

Favorite.propTypes = {
  isFavorite: PropTypes.bool,
};

export default Favorite;
