/**
 * column stack chart specific configurations
 * @type {Object}
 */
export const columnStackChartConfiguration = {
  plotOptions: {
    column: { stacking: 'normal' },
  },
  yAxis: {
    reversedStacks: false,
  },
};
