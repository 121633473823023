import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  CheckSquareIcon,
  DragDropIcon,
  ExclusiveIcon,
  PinIcon,
  RadioButtonIcon,
  TrashIcon,
} from '@utilities/icons';
import { ActionOverlayPopover } from '@utilities';

import styles from './_index.module.scss';

const Answer = ({
  answer,
  children,
  isDisabled,
  isDraggable,
  onAnchor,
  onDelete,
  onMakeExclusive,
  type,
}) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const optionsDropdown = useRef(null);

  return (
    <div className={styles['answer']}>
      {isDraggable && (
        <div className={styles['answer-drag-icon']}>
          <DragDropIcon />
        </div>
      )}
      <div className={styles['answer-type-icon']}>
        {type === 'CHECKBOX' ? <CheckSquareIcon /> : <RadioButtonIcon />}
      </div>
      <div className={styles['answer-input-wrapper']}>{children}</div>
      {(answer.isAnchored || answer.isExclusive) && (
        <div className={styles['answer-types']}>
          {answer.isExclusive && <ExclusiveIcon />}
          {answer.isAnchored && <PinIcon />}
        </div>
      )}
      {!isDisabled && (
        <div className={styles['answer-controls']}>
          <ActionOverlayPopover
            hasArrow={false}
            isActive={isDropdownActive}
            onClick={() => setIsDropdownActive(!isDropdownActive)}
            onHide={() => setIsDropdownActive(false)}
            target={optionsDropdown.current}
          >
            <ul>
              {!answer.isNoneOfTheAbove && type === 'CHECKBOX' && (
                <li>
                  <button
                    aria-label={
                      answer.isExclusive ? 'Make answer exclusive' : 'Make answer unexclusive'
                    }
                    className="answer-option-button"
                    onClick={() => {
                      onMakeExclusive();
                      setIsDropdownActive(false);
                    }}
                  >
                    <ExclusiveIcon />
                    <span>Make {answer.isExclusive ? 'Unexclusive' : 'Exclusive'}</span>
                  </button>
                </li>
              )}
              {!answer.isNoneOfTheAbove && !answer.isOther && (
                <>
                  <li>
                    <button
                      aria-label={answer.isAnchored ? 'Unpin answer' : 'Pin answer'}
                      className="answer-option-button"
                      onClick={() => {
                        onAnchor();
                        setIsDropdownActive(false);
                      }}
                    >
                      <PinIcon />
                      <span>{answer.isAnchored ? 'Unpin' : 'Pin'} Position</span>
                    </button>
                  </li>
                  <hr />
                </>
              )}
              <li>
                <button
                  aria-label="Delete answer"
                  className="answer-option-button"
                  onClick={() => {
                    onDelete();
                    setIsDropdownActive(false);
                  }}
                >
                  <TrashIcon />
                  <span>Delete</span>
                </button>
              </li>
            </ul>
          </ActionOverlayPopover>
        </div>
      )}
    </div>
  );
};

Answer.defaultProps = {
  onDelete: () => {},
};

Answer.propTypes = {
  answer: PropTypes.object,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  isDraggable: PropTypes.bool,
  onAnchor: PropTypes.func,
  onDelete: PropTypes.func,
  onMakeExclusive: PropTypes.func,
  type: PropTypes.oneOf(['CHECKBOX', 'RADIO']).isRequired,
};

export default Answer;
