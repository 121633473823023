import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AxiosError } from 'axios';

import { Breadcrumbs, Error, LoaderSkeleton } from '@utilities';

import {
  readExternalQuestions,
  readInstantSurvey,
  readInstantSurveyType,
} from '@api/instant_survey';
import { readSurvey, readSurveyType, surveyStatus } from '@api/surveys';

import Dashboard from './components/Dashboard';

const Survey = () => {
  const [insightsDocument, setInsightsDocument] = useState();
  const [insightsType, setInsightsType] = useState();
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [survey, setSurvey] = useState();
  const [surveyQuestions, setSurveyQuestions] = useState();
  const [surveyType, setSurveyType] = useState();

  const dataRequestController = useRef();

  const navigate = useNavigate();
  const { routes } = useSelector((state) => state?.user?.data);
  const instantSurveyRoutes = routes?.find(({ id }) => id === 'instant-survey-reports')?.routes;

  let { survey_id: surveyId, tab_id: tabId } = useParams();

  useEffect(() => {
    if (!tabId) {
      navigate(`/dashboard/survey/${surveyId}/${instantSurveyRoutes[0].id}`, { replace: true });
    }

    getData();
    return () => dataRequestController?.current?.abort();
  }, [surveyId]);

  const getData = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const insightsSurvey = await readSurvey({ id: surveyId, signal });
      const validStatuses = [surveyStatus.COMPLETED, surveyStatus.MET_SAMPLE];
      if (!validStatuses.includes(insightsSurvey.status)) {
        throw new AxiosError('Request failed with status code 404', 'ERR_BAD_REQUEST', null, null, {
          status: 404,
        });
      }

      setInsightsDocument(insightsSurvey);
      if (insightsSurvey.atlasInstantSurveyRef) {
        const atlasRequests = [
          readInstantSurvey({
            id: insightsSurvey.atlasInstantSurveyRef,
            params: { include_response_count: true },
            signal,
          }),
          readInstantSurveyType({
            id: insightsSurvey.instantSurveyTypeId,
            signal,
          }),
          readExternalQuestions({
            signal: signal,
            surveySourceId: insightsSurvey.surveySourceId,
          }),
        ];
        const [instantSurveyResponse, instantSurveyTypeResponse, externalQuestionsResponse] =
          await Promise.all(atlasRequests);
        const { groups, ...instantSurvey } = instantSurveyResponse;
        setSurvey({
          ...instantSurvey,
          // sorting groups here so it never impacts a draft survey
          groups: groups.sort((a, b) => a.quotaGroupRef - b.quotaGroupRef),
        });
        setSurveyQuestions(externalQuestionsResponse);
        setSurveyType(instantSurveyTypeResponse);
      } else {
        const [surveyTypeResponse, externalQuestionsResponse] = await Promise.all([
          readSurveyType({
            documentId: insightsSurvey.instantSurveyTypeId,
            signal,
          }),
          readExternalQuestions({
            signal: signal,
            surveySourceId: insightsSurvey.surveySourceId,
          }),
        ]);
        setInsightsType(surveyTypeResponse);
        setSurveyQuestions(externalQuestionsResponse);
      }
      setIsDataLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        console.error(error);
        setLoadingError(error);
      }
    }
  };

  return (
    <>
      <Breadcrumbs />
      {loadingError && <Error status={loadingError?.response?.status} />}
      {!loadingError && (
        <>
          {isDataLoading ? (
            <LoaderSkeleton height={725}>
              <rect x="0" y="0" rx="4" ry="4" width="850" height="95" />
              <rect x="0" y="105" rx="4" ry="4" width="1333" height="40" />
              <rect x="0" y="155" rx="4" ry="4" width="300" height="30" />
              <rect x="0" y="195" rx="4" ry="4" width="1333" height="165" />
              <rect x="0" y="380" rx="4" ry="4" width="300" height="30" />
              <rect x="0" y="420" rx="4" ry="4" width="1333" height="58" />
              <rect x="0" y="488" rx="4" ry="4" width="1333" height="58" />
              <rect x="0" y="556" rx="4" ry="4" width="1333" height="58" />
            </LoaderSkeleton>
          ) : (
            <Dashboard
              insightsDocument={insightsDocument}
              insightsType={insightsType}
              survey={survey}
              surveyQuestions={surveyQuestions}
              surveyType={surveyType}
            />
          )}
        </>
      )}
    </>
  );
};

export default Survey;
