import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import {
  Announcement,
  DatePickerV2,
  DateRangePickerV2,
  Input,
  isMaxNameLength,
  MinusPlusButtons,
} from '@utilities';
import { getMinRunOnDate } from './utilities/helpers';

import styles from './_index.module.scss';

const DateSelectors = ({ entries, isLastEntryValid, name, setEntries }) => {
  const hasReachedMaxEntries = entries.length === 12;

  const createEntry = () => {
    setEntries([...entries, { id: nanoid(), name: `${name} (${entries?.length + 1})` }]);
  };

  const deleteEntry = (entryId) => {
    setEntries(entries.filter((entry) => entry.id !== entryId));
  };

  const updateEntry = (updatedEntry) => {
    const index = entries.findIndex((entry) => {
      return updatedEntry?.id === entry?.id;
    });
    let updatedEntries = [...entries];
    const existingEntry = updatedEntries[index];
    if (existingEntry?.endDate !== updatedEntry?.endDate) {
      updatedEntry.runOnDate = getMinRunOnDate(updatedEntry?.endDate);
    }
    updatedEntries[index] = updatedEntry;
    setEntries(updatedEntries);
  };

  return (
    <>
      <ul className={styles['date-selectors']}>
        {entries.map((entry, index) => {
          const isLastEntry = entries?.length - 1 === index;

          return (
            <li key={`date-selectors-row-${index}`}>
              <Input
                data-testid="date-selectors-input"
                error={
                  isMaxNameLength(entry.name)
                    ? 'Maximum number of characters reached. Try something shorter.'
                    : ''
                }
                isDisabled={!isLastEntry}
                label="Run Name"
                onChange={(name) => updateEntry({ ...entry, name: name })}
                value={entry.name ? entry.name : ''}
              />
              <DateRangePickerV2
                endDate={entry?.endDate}
                isDisabled={!isLastEntry}
                label="Included Date Range"
                onDatesChange={(date) => {
                  const [startDate, endDate] = date;
                  updateEntry({ ...entry, endDate, startDate });
                }}
                startDate={entry?.startDate}
              />
              <DatePickerV2
                date={entry?.runOnDate}
                isDisabled={!isLastEntry || !entry?.endDate}
                label="Run Date"
                minDate={getMinRunOnDate(entry?.endDate)}
                onDateChange={(date) => updateEntry({ ...entry, runOnDate: date })}
              />

              <MinusPlusButtons
                hasMinusButton={index > 0}
                hasPlusButton={isLastEntry && isLastEntryValid && !hasReachedMaxEntries}
                onClickMinus={() => deleteEntry(entry?.id)}
                onClickPlus={createEntry}
              />
            </li>
          );
        })}
      </ul>
      {hasReachedMaxEntries && (
        <Announcement
          className={styles['date-selectors-limit']}
          text="You have reached the limit for this report."
          variant="warn"
        />
      )}
    </>
  );
};

DateSelectors.propTypes = {
  entries: PropTypes.array,
  isLastEntryValid: PropTypes.bool,
  name: PropTypes.string,
  setEntries: PropTypes.func,
};

export default DateSelectors;
