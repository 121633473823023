import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Announcement, LoaderView } from '@utilities';

import { completeSurveyStripeCheckout } from '@api/surveys';

import { payWithCredits } from '../../helpers/constants';

import buttonStyles from '@utilities/components/Button/_index.module.scss';
import styles from './_index.module.scss';

const CheckoutSuccess = () => {
  const [confirmError, setConfirmError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const paymentMethod = searchParams.get('paymentMethod');
  const token = searchParams.get('token');

  const confirmToken = async () => {
    try {
      await completeSurveyStripeCheckout({ token });
    } catch (error) {
      setConfirmError(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!jobId) {
      return navigate('/dashboard');
    } else if (paymentMethod === payWithCredits.key) {
      setIsLoading(false);
    } else if (token) {
      confirmToken();
    }
  }, []);

  // TODO: get panelist count from BE survey response
  return (
    <div className={styles['checkout-success']}>
      <h1>Pay as You Go</h1>
      {isLoading && <LoaderView />}
      {!isLoading && !confirmError && (
        <>
          <p>
            {paymentMethod === payWithCredits.key
              ? '0.5 credits have been deducted from your account.'
              : 'Thank you, your payment was received.'}
          </p>
          <p>
            <strong>Your AskWhy is being sent to 100 panelists.</strong>
          </p>
          <Announcement
            className={styles['checkout-success-announcement']}
            header="Want to write your own questions or send to more panelists?"
            text="Check out Numerator's Instant Survey in the Surveys tab above."
            variant="info"
          />
          <Link to={`/dashboard/report/${jobId}/ask-why`}>
            <div className={buttonStyles['button-primary']}>Return to Report</div>
          </Link>
        </>
      )}
      {!isLoading && confirmError && (
        <>
          <Announcement
            className={styles['checkout-success-announcement']}
            text="Sorry, we were unable to process your payment through Stripe"
            variant="error"
          />
          <p>Your AskWhy survey could not be sent.</p>
          <p>Please reach out to your Numerator contact if this continues.</p>
          <Link to={`/dashboard/report/${jobId}`}>
            <div className={buttonStyles['button-primary']}>Return to Report</div>
          </Link>
        </>
      )}
    </div>
  );
};

export default CheckoutSuccess;
