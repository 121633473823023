import PropTypes from 'prop-types';

import styles from './_index.module.scss';

const BinaryDataBar = ({ valueNo, valueNoLabel, valueYes, valueYesLabel }) => {
  return (
    <div className={styles['binary-data-bar']}>
      <div>
        <div style={{ width: valueYes }}>&nbsp;</div>
        <div style={{ width: valueNo }}>&nbsp;</div>
      </div>
      <div>
        <div>
          <strong>{valueYesLabel}</strong>: {valueYes}
        </div>
        <div>
          <strong>{valueNoLabel}</strong>: {valueNo}
        </div>
      </div>
    </div>
  );
};

BinaryDataBar.propTypes = {
  valueNo: PropTypes.string.isRequired,
  valueNoLabel: PropTypes.string.isRequired,
  valueYes: PropTypes.string.isRequired,
  valueYesLabel: PropTypes.string.isRequired,
};

export default BinaryDataBar;
