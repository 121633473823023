import PropTypes from 'prop-types';
import classNames from 'classnames';

import QuestionLogicOptions from '../QuestionLogicOptions';
import { answerOperators } from '../../utilities/constants';

import styles from './_index.module.scss';

const SkipLogicRow = ({
  firstErrorRef,
  isCompleted,
  index,
  questionId,
  questionList,
  questionLogic,
  setQuestionLogic,
  showOnOneLine,
}) => {
  const updateAnswers = (answers) => {
    const updatedLogic = { ...questionLogic };
    updatedLogic[questionId].skip.logic[index].answers = answers;
    updatedLogic[questionId].skip.logic[index].hasAnswerError = false;
    updatedLogic[questionId].skip.logic[index].isFirstAnswerError = false;
    setQuestionLogic(updatedLogic);
  };

  const updateOperator = (operator) => {
    const updatedLogic = { ...questionLogic };
    updatedLogic[questionId].skip.logic[index].operator = operator;
    setQuestionLogic(updatedLogic);
  };

  const updateQuestion = (question) => {
    const updatedLogic = { ...questionLogic };
    updatedLogic[questionId].skip.logic[index].question = question;
    updatedLogic[questionId].skip.logic[index].hasQuestionError = false;
    updatedLogic[questionId].skip.logic[index].isFirstQuestionError = false;
    setQuestionLogic(updatedLogic);
  };

  return (
    <div
      className={classNames(styles['skip-logic-row'], {
        [styles['is-one-line']]: showOnOneLine,
      })}
    >
      <p>
        <span>
          The <strong>Answer</strong> to&nbsp;
        </span>
        <QuestionLogicOptions
          firstErrorRef={firstErrorRef}
          isCompleted={isCompleted}
          isError={questionLogic[questionId].skip.logic[index].hasQuestionError}
          isFocused={questionLogic[questionId].skip.logic[index].isFirstQuestionError}
          isRequired
          options={questionList}
          optionsLabel="Question"
          optionsType="radio"
          setValue={updateQuestion}
          value={questionLogic[questionId].skip.logic[index].question}
        />
        {questionLogic[questionId].skip.logic[index].question?.id ? (
          <>
            <span>&nbsp;is&nbsp;</span>
            <QuestionLogicOptions
              isCompleted={isCompleted}
              options={answerOperators}
              optionsLabel="Operator"
              optionsType="radio"
              setValue={updateOperator}
              value={questionLogic[questionId].skip.logic[index].operator}
            />
            :
          </>
        ) : (
          <span>...</span>
        )}
      </p>
      {questionLogic[questionId].skip?.logic[index].question?.id && (
        <p>
          <QuestionLogicOptions
            firstErrorRef={firstErrorRef}
            isCompleted={isCompleted}
            isError={questionLogic[questionId].skip.logic[index].hasAnswerError}
            isFocused={questionLogic[questionId].skip.logic[index].isFirstAnswerError}
            isRequired
            isSkip
            options={questionLogic[questionId].skip.logic[index].question.answers}
            optionsLabel="Answer Options"
            optionsType="checkbox"
            setValue={updateAnswers}
            value={questionLogic[questionId].skip.logic[index].answers}
          />
        </p>
      )}
    </div>
  );
};

SkipLogicRow.propTypes = {
  firstErrorRef: PropTypes.object,
  isCompleted: PropTypes.bool,
  index: PropTypes.number.isRequired,
  questionId: PropTypes.number.isRequired,
  questionList: PropTypes.array.isRequired,
  questionLogic: PropTypes.object.isRequired,
  setQuestionLogic: PropTypes.func,
  showOnOneLine: PropTypes.bool,
};

export default SkipLogicRow;
