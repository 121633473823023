import moment from 'moment';
import numbro from 'numbro';

/**
 * used to increase readability for REPORT status
 * @returns {Status}
 */
export const reportStatus = {
  NOT_STARTED: -1,
  SUBMITTED: 0,
  QUEUED: 1,
  SETUP: 2,
  RUNNING: 3,
  EXPORT: 4,
  CLEANUP: 5,
  COMPLETED: 6,
  EXCEPTION: 7,
  CANCELLED: 8,
  INVALIDATED: 9,
  OUT_OF_DATE: 10,
};

/**
 * used to increase readability for TAB status
 * @returns {Status}
 */
export const tabStatus = {
  NOT_STARTED: -1,
  SUBMITTED: 0,
  QUEUED: 1,
  STARTED: 2,
  COMPLETED: 3,
  EXCEPTION: 4,
  CANCELLED: 5,
  INVALIDATED: 6,
};

/**
 * gets input value string for pill
 * @param  {String} options.format
 * @param  {String} options.value
 * @return {String}
 */
const getInputValue = ({ format, value }) => {
  let formattedValue = value;

  if (value?.charAt(0) === '.') {
    formattedValue = '0' + value;
  }

  switch (format) {
    case '0%':
      return formattedValue
        ? numbro(formattedValue / 100).format({
            output: 'percent',
            mantissa: 0,
          })
        : '--';
    default:
      return formattedValue ? numbro(formattedValue).format(format) : '--';
  }
};

/**
 * gets inputs label for pill and tooltip
 * @param  {Object} selection
 * @return {String}
 */
const getInputsLabel = (selection) => {
  const inputs = selection?.inputs;

  let inputValues = '';

  const keys = Object.keys(inputs);

  keys.forEach((key, index) => {
    const input = inputs[key];

    inputValues += `${input?.label?.charAt(0).toUpperCase()}: ${getInputValue(input)}`;

    if (index < keys?.length - 1) {
      inputValues += ', ';
    }
  });

  return `${selection?.name} (${inputValues})`;
};

/**
 * gets inputs labels for pill
 * @param {Array}  answer
 * @param {Object} selection
 * @returns String
 */
const getInputsLabels = ({ answer, selection }) => {
  const inputsLabel = getInputsLabel(selection);

  return answer?.length === 1 ? inputsLabel : `${inputsLabel} or ${answer?.length - 1} more`;
};

/**
 * gets metrics label for pill and tooltip
 * @param   {Object} selection
 * @returns {String}
 */
const getMetricsLabel = (selection) => {
  const label = selection?.operatorInfo?.label;
  const value = selection?.value;
  const metrics =
    Array.isArray(value) && !value.includes(null) ? `${value[0]} and ${value[1]}` : value;

  return selection?.metric?.name + `${value && ': ' + label + ' ' + metrics}`;
};

/**
 * gets metrics labels for pill
 * @param {Array}  answer
 * @param {Object} selection
 * @returns String
 */
const getMetricsLabels = ({ answer, selection }) => {
  const metricsLabel = getMetricsLabel(selection);

  return answer?.length === 1 ? metricsLabel : `${metricsLabel} or ${answer?.length - 1} more`;
};

/**
 * gets value for pill based on answers and prompt
 * @param  {Object} options.answers
 * @param  {Object} options.prompt
 * @return {String}
 */
export const getPromptPillValue = ({ answers, prompt }) => {
  const answer = answers ? answers[prompt?.id] : [];
  const dateFormat = 'MM/DD/YYYY';

  if (!answer || answer?.length === 0 || !Array.isArray(answer)) return prompt?.emptyValue;

  const firstAnswerAttribute = prompt?.attributes?.find(
    (attribute) => attribute?.id === answer?.[0].attributeId
  );

  const firstAnswerName =
    firstAnswerAttribute?.name && prompt?.isPrefixIncluded
      ? `${firstAnswerAttribute?.name}: ${answer?.[0].name}`
      : answer?.[0].name;

  // INPUT LABELS

  if (answer[0]?.inputs) return getInputsLabels({ answer, selection: answer[0] });

  // DATE RANGE

  if (answer[0]?.date_range) {
    const [startDate, endDate] = answer[0]?.date_range;
    return `${moment(startDate).format(dateFormat)} to ${moment(endDate).format(dateFormat)}`;
  }

  // OPERATORS

  if (answer[0]?.operatorInfo) {
    return getMetricsLabels({ answer, selection: answer[0] });
  }

  // CUSTOM DATE OPTION

  if (prompt?.hasCustomDateOption || prompt?.dateOptions?.length > 0) {
    const [startDate, endDate] = answer;

    return `${moment(startDate ? startDate : null).format(dateFormat)} to ${moment(
      endDate ? endDate : null
    ).format(dateFormat)}`;
  }

  // SINGLE ANSWER

  if (answer?.length === 1) {
    return firstAnswerName;
  }

  // MULTIPLE ANSWERS

  if (answer?.length > 0) {
    if (prompt?.hasSortEnabled) {
      return `${firstAnswerName} then ${answer?.length - 1} more`;
    }

    return `${firstAnswerName} or ${answer?.length - 1} more`;
  }
};

/**
 * gets tooltip content for pill based on answers and prompt
 * @param  {Object} options.answers
 * @param  {Object} options.prompt
 * @return {String}
 */
export const getPromptPillTooltip = ({ answers, prompt }) => {
  const answer = answers ? answers[prompt?.id] : [];
  if (!answer || !Array.isArray(answer) || answer?.length === 0 || prompt?.hasCustomDateOption)
    return null;

  if (answer.length > 1) {
    return answer.map((selection, index) => {
      return (
        <div key={`selection-${index}`}>
          <small>{getPromptPillValue({ answers: { [prompt?.id]: [selection] }, prompt })}</small>
        </div>
      );
    });
  }
};
