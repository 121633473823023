/**
 * bar chart specific configurations
 * @type {Object}
 */
export const barChartConfiguration = {
  plotOptions: {
    series: {
      dataLabels: {
        enabled: true,
      },
      groupPadding: 0.1,
      pointPadding: 0.05,
    },
  },
};
