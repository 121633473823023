import PropTypes from 'prop-types';

import { ReactComponent as OverMaxIndicator } from './assets/over-max-indicator.svg';

import styles from './_index.module.scss';

const IndexBar = ({ max = 200, threshold = 100, value }) => {
  const baseline = (threshold / max) * 100;
  const percentage = ((value / threshold) * 100) / 2;

  return (
    <span className={styles['index-bar']}>
      <span style={{ width: `${percentage >= baseline ? baseline : percentage}%` }} />
      <span
        style={{
          left: `${percentage < baseline ? baseline - (baseline - percentage) : baseline}%`,
        }}
      />
      <span
        style={{
          right: `${percentage > baseline ? baseline - (percentage - baseline) : baseline}%`,
        }}
      />
      {percentage > 100 && <OverMaxIndicator />}
    </span>
  );
};

IndexBar.propTypes = {
  max: PropTypes.number,
  threshold: PropTypes.number,
  value: PropTypes.number,
};

export default IndexBar;
