import PropTypes from 'prop-types';

import Tab from '../../../Report/components/Tab';

import styles from './_index.module.scss';

const Report = ({ onComplete, reportId, tabId }) => {
  return (
    <div className={styles['report']}>
      <Tab hasComments={false} onComplete={onComplete} reportId={reportId} tabId={tabId} />
    </div>
  );
};

Report.propTypes = {
  onComplete: PropTypes.func,
  reportId: PropTypes.number,
  tabId: PropTypes.number,
};

export default Report;
