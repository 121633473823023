import axios from '@api';

/**
 * save feedback
 * @param  {Boolean} like
 * @param  {Number} tabId
 * @return {Promise}
 */
export const saveFeedback = ({ like, tabId }) => {
  return axios({
    data: {
      job_item_id: tabId,
      like,
    },
    method: 'post',
    url: '/v2/api/summary/feedback',
    withCredentials: true,
  });
};
