import moment from 'moment';

/**
 * transforms and formats notification api response
 * @param  {Object} data
 * @return {Object}
 */
export const transformNotification = (data) => {
  return {
    meta: {
      next: data?.meta?.next,
      numPages: data?.meta?.num_pages,
      prev: data?.meta?.prev,
      page: data?.meta?.page,
      pageSize: data?.meta?.page_size,
    },
    results: data?.results?.map((notification) => {
      return transformNotificationData(notification);
    }),
  };
};

/**
 * transforms and formats individual notification data
 * @param  {Object} notification
 * @return {Object}
 */
export const transformNotificationData = (notification) => {
  return {
    createdDatetime: notification?.created_datetime
      ? moment.utc(notification.created_datetime).fromNow()
      : moment.utc().fromNow(),
    data: {
      isToast: notification?.data?.is_toast || notification?.isToast,
      message: notification?.data?.message || notification?.message,
      status: notification?.data?.status || notification?.status,
      type: notification?.data?.type || notification?.type,
    },
    isRead: notification?.is_read ?? false,
  };
};
