import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './_index.module.scss';

const Button = ({
  buttonRef,
  className,
  icon,
  isDisabled,
  isLoading,
  onClick,
  text,
  variant,
  ...props
}) => {
  return (
    <button
      className={classNames(styles[`button-${variant}`], className, {
        [styles['has-text']]: text,
      })}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      ref={buttonRef}
      {...props}
    >
      {isLoading ? (
        'Loading...'
      ) : (
        <>
          {icon && icon}
          {text}
        </>
      )}
    </button>
  );
};

Button.defaultProps = {
  'data-testid': 'button',
  onClick: () => {},
  text: 'Button',
  variant: 'primary',
};

Button.propTypes = {
  buttonRef: PropTypes.object,
  className: PropTypes.string,
  icon: PropTypes.node,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(['error', 'primary', 'secondary', 'tertiary']).isRequired,
};

export default Button;
