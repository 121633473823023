import PropTypes from 'prop-types';

import { questionTypes } from '@api/surveys';

import { Checkbox, PillOverlayPopover, Radio, truncateTextWithEllipsis } from '@utilities';

import styles from './_index.module.scss';

const QuestionLogicOptions = ({
  isCompleted,
  firstErrorRef,
  isError,
  isFocused,
  isRequired,
  isSkip,
  options,
  optionsLabel,
  optionsType,
  setValue,
  value,
}) => {
  const getPillLabel = () => {
    let updatedLabel;
    if (optionsType === 'radio') {
      updatedLabel = value?.text || optionsLabel;
      if (value?.id) {
        // truncation for selected question
        const truncatedText = truncateTextWithEllipsis({ length: 30, text: updatedLabel });
        updatedLabel = `Q${
          options.findIndex((question) => question.id === value.id) + 1
        }: "${truncatedText}"`;
      }
    } else {
      updatedLabel = value[0]?.text || optionsLabel;
      if (value.length > 1) {
        // truncation for multiple selected answers
        const textLimit = isSkip ? 50 : 10;
        if (updatedLabel.length > textLimit) {
          updatedLabel = updatedLabel.substring(0, textLimit) + '...';
        }
        updatedLabel = `"${updatedLabel}" and ${value.length - 1} more`;
      } else {
        // truncation for single selected answer
        const textLimit = isSkip ? 60 : 20;
        if (updatedLabel.length > textLimit) {
          updatedLabel = updatedLabel.substring(0, textLimit) + '...';
        }
      }
    }
    return updatedLabel;
  };

  const toggleCheckbox = (option) => {
    const index = value.findIndex((val) => {
      return val.id === option.id;
    });

    if (index === -1) {
      setValue([...value, option]);
    } else {
      const updatedValue = [...value];
      updatedValue.splice(index, 1);
      setValue(updatedValue);
    }
  };

  return (
    <PillOverlayPopover
      buttonRef={isFocused ? firstErrorRef : null}
      isCompleted={isCompleted}
      isError={isError}
      isRequired={isRequired}
      overlayClassName={styles['question-logic-options-overlay']}
      overlayTestId="question-logic-pill-overlay"
      pillClassName={isFocused ? styles['question-logic-options-pill-focus'] : null}
      pillTestId="question-logic-pill"
      tooltipContent={
        optionsType === 'checkbox' && value.length > 1 ? (
          <>
            {value.map((option) => {
              return <div key={`tooltip-${option.id}`}>{option.text}</div>;
            })}
          </>
        ) : null
      }
      tooltipPlacement="bottom"
      value={getPillLabel()}
    >
      {optionsLabel && <div className={styles['question-logic-options-label']}>{optionsLabel}</div>}
      <ul className={styles['question-logic-options-list']}>
        {isError && (
          <li className={styles['question-logic-options-overlay-error']}>
            {optionsType === 'checkbox' && <span>Please select at least one option.</span>}
            {optionsType === 'radio' && <span>Please select an option.</span>}
          </li>
        )}
        {options
          ?.filter((question) => question.type !== questionTypes.essay.value)
          .map((option, index) => {
            return (
              <li key={`${option}-${index}`}>
                {optionsType === 'radio' && (
                  <Radio
                    isChecked={value?.id ? value.id === option.id : value?.text === option.text}
                    label={
                      option.id
                        ? `Q${options.findIndex((question) => question.id === option.id) + 1}: "${
                            option.text
                          }"`
                        : option.text
                    }
                    onChange={setValue}
                    value={option}
                  />
                )}
                {optionsType === 'checkbox' && (
                  <Checkbox
                    isChecked={value?.filter((val) => val.id === option.id).length > 0}
                    label={option.text || `Image Answer Option ${index + 1}`}
                    onChange={toggleCheckbox}
                    value={option}
                  />
                )}
              </li>
            );
          })}
      </ul>
    </PillOverlayPopover>
  );
};

QuestionLogicOptions.propTypes = {
  firstErrorRef: PropTypes.object,
  isCompleted: PropTypes.bool,
  isError: PropTypes.bool,
  isFocused: PropTypes.bool,
  isRequired: PropTypes.bool,
  isSkip: PropTypes.bool,
  options: PropTypes.array.isRequired,
  optionsLabel: PropTypes.string,
  optionsType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  setValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default QuestionLogicOptions;
