import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

import { truncateTextWithEllipsis } from '../../helpers';

import { ChevronLeftIcon, ChevronRightIcon } from '../../icons';

import styles from './_index.module.scss';

const Breadcrumbs = ({ options, routes }) => {
  const navigate = useNavigate();
  if (routes?.length > 1) {
    return (
      <ul className={styles['breadcrumbs']}>
        {routes.map((route, index) => {
          return (
            <li key={`route-${index}`}>
              {index < routes.length - 1 ? (
                <Link to={route?.href}>
                  <small>{route?.label}</small>
                </Link>
              ) : (
                <small>{route?.label}</small>
              )}
              {index !== routes.length - 1 && <ChevronRightIcon />}
            </li>
          );
        })}
      </ul>
    );
  }

  if (options) {
    return (
      <ul className={styles['breadcrumbs']}>
        {options.map((option, index) => {
          const optionLabel = truncateTextWithEllipsis({ text: option?.label });

          return (
            <li key={`route-${index}`}>
              {index < options.length - 1 ? (
                <button data-testid={`breadcrumbs-${option.label}`} onClick={option?.onClick}>
                  <small>{optionLabel}</small>
                </button>
              ) : (
                <small>{optionLabel}</small>
              )}
              {index !== options.length - 1 && <ChevronRightIcon />}
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <ul className={styles['breadcrumbs']}>
      <li>
        <ChevronLeftIcon />
        <button data-testid="breadcrumbs-return" onClick={() => navigate(-1)}>
          Back
        </button>
      </li>
    </ul>
  );
};

Breadcrumbs.propTypes = {
  options: PropTypes.array,
  routes: PropTypes.array,
};

export default Breadcrumbs;
