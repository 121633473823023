import moment from 'moment';

const PILL_DATE_FORMAT = 'MM/DD/YYYY';

/**
 * @typedef {Object} PromptAnswerValue value returned from Insights prompt searching
 * @property {String} attributeId eg. "sector"
 * @property {String} id eg. "sector:isc_apparel"
 * @property {Boolean} is_fixed
 * @property {String} name
 * @property {String} prompt_desc
 */

/**
 * @typedef {Object} PromptAnswer
 * @property {String} field
 * @property {Number|String} [id]
 * @property {Number} promptId
 * @property {PromptAnswerValue[]} values
 */

/**
 * transformPromptSelections
 * @param {Object[]|String[]} options.answers
 * @param {Object} options.prompt
 * @return {PromptAnswer[]}
 */
export const transformPromptSelections = ({ answers, prompt }) => {
  if (prompt.promptKey === 'date_range') {
    return [
      {
        field: prompt.attributes[0]?.field,
        fieldType: 'DATE',
        operator: 'IN_RANGE',
        promptId: prompt.id,
        values: answers,
      },
    ];
  }

  const defaultField = prompt.attributes?.[0]?.field;
  const promptAnswers = [];
  answers.forEach((answer) => {
    const field =
      answer.field ||
      prompt.attributes?.find(({ id }) => answer.attributeId === id)?.field ||
      defaultField;
    const existingAnswer = promptAnswers.find(
      ({ field: existingField }) => existingField === field
    );
    if (existingAnswer) {
      existingAnswer.operator = 'IN_LIST';
      existingAnswer.values.push(answer);
    } else {
      promptAnswers.push({
        field,
        fieldType: 'TEXT',
        operator: 'EQUALS',
        promptId: prompt.id,
        values: [answer],
      });
    }
  });

  return promptAnswers;
};

/**
 * gets value for pill based on current prompt and answers
 * @param {Object} options.prompt
 * @param {PromptAnswer[]} options.promptAnswers group.promptAnswers
 * @return {String}
 */
export const getPromptPillValue = ({ prompt, promptAnswers = [] }) => {
  const answers = promptAnswers
    .filter(({ _delete, promptId }) => !_delete && promptId === prompt.id)
    .map(({ values }) => values)
    .flat(1);

  if (answers.length === 0) return prompt?.emptyValue;

  if (prompt.promptKey === 'date_range') {
    if (answers.length === 2 && typeof answers[0] === 'string') {
      return [answers[0], answers[1]]
        .map((value) => moment(value))
        .sort((a, b) => a.diff(b)) // values not guaranteed to be in order, sort is necessary
        .map((date) => date.format(PILL_DATE_FORMAT))
        .join(' to ');
    }

    return answers[0]?.date_range
      ?.map((value) => moment(value).format(PILL_DATE_FORMAT))
      .join(' to ');
  }

  const firstAnswerAttribute = prompt?.attributes?.find(
    (attribute) => attribute?.field === answers[0].attributeId
  );
  const firstAnswerName =
    firstAnswerAttribute?.name && prompt?.isPrefixIncluded
      ? `${firstAnswerAttribute?.name}: ${answers[0].name}`
      : answers[0].name;

  if (answers.length > 1) {
    return `${firstAnswerName} ${prompt.isSortEnabled ? 'then' : 'or'} ${answers.length - 1} more`;
  }
  return firstAnswerName;
};

/**
 * gets tooltip content for pill based on current prompt and answers
 * @param {Object} options.prompt
 * @param {PromptAnswer[]} options.promptAnswers group.promptAnswers
 * @return {String}
 */
export const getPromptPillTooltip = ({ prompt, promptAnswers = [] }) => {
  const answers = promptAnswers
    .filter(({ _delete, promptId }) => !_delete && promptId === prompt.id)
    .map(({ values }) => values)
    .flat(1);

  if (answers.length <= 1 || prompt?.promptKey === 'date_range') return null;

  return answers.map((value, index) => {
    return (
      <div key={`selection-${index}`}>
        <small>
          {getPromptPillValue({
            prompt,
            promptAnswers: [{ promptId: prompt.id, values: [value] }],
          })}
        </small>
      </div>
    );
  });
};
