import PropTypes from 'prop-types';

import LoaderSkeleton from '../../components/LoaderSkeleton';

import styles from './_index.module.scss';

const Legend = ({ isUpdating, items }) => {
  if (isUpdating) {
    return (
      <LoaderSkeleton height={300}>
        <rect x="0" y="25" rx="2" ry="2" width="15" height="15" />
        <rect x="20" y="25" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="50" rx="2" ry="2" width="15" height="15" />
        <rect x="20" y="50" rx="2" ry="2" width="175" height="15" />
        <rect x="0" y="75" rx="2" ry="2" width="15" height="15" />
        <rect x="20" y="75" rx="2" ry="2" width="225" height="15" />
        <rect x="0" y="100" rx="2" ry="2" width="15" height="15" />
        <rect x="20" y="100" rx="2" ry="2" width="185" height="15" />
      </LoaderSkeleton>
    );
  }

  return (
    <ul className={styles['legend']}>
      {items?.map((item, index) => {
        return (
          <li className={styles['legend-item']} key={`legend-item-${index}`}>
            <div style={{ backgroundColor: item?.color }} />
            <p>{item?.name}</p>
          </li>
        );
      })}
    </ul>
  );
};

Legend.defaultProps = {
  items: [],
};

Legend.propTypes = {
  isUpdating: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default Legend;
