import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';

import { isVerifiedVoices } from '@helpers';
import { NumeratorInsightsLogoCC, NumeratorVerifiedVoicesLogo } from '@utilities';

import Consultant from './components/Consultant';
import SSO from './components/SSO';

import { containers } from './utilities/helpers';

import styles from './_index.module.scss';

const Login = () => {
  const [container, setContainer] = useState(containers.SSO);

  const currentYear = new Date().getFullYear();
  const location = useLocation();
  const next = queryString.parse(location?.search)?.next || '/';

  const isVerifiedVoicesSubdomain = isVerifiedVoices();

  return (
    <div
      className={classNames(styles['login'], {
        [styles['is-verified-voices']]: isVerifiedVoicesSubdomain,
      })}
    >
      <div className={styles['login-container']}>
        <div className={styles['login-wrapper']}>
          {isVerifiedVoicesSubdomain ? (
            <NumeratorVerifiedVoicesLogo
              alt="Numerator Verified Voices Logo"
              data-testid="sso-logo"
            />
          ) : (
            <NumeratorInsightsLogoCC alt="Numerator Logo" data-testid="sso-logo" />
          )}

          {container === containers.SSO && (
            <SSO
              isVerifiedVoicesSubdomain={isVerifiedVoicesSubdomain}
              next={next}
              setContainer={setContainer}
            />
          )}
          {container === containers.CONSULTANT && (
            <Consultant next={next} setContainer={setContainer} />
          )}
        </div>
        <small className={styles['login-footer']}>© {currentYear} All Rights Reserved</small>
      </div>
    </div>
  );
};

export default Login;
