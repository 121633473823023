import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  CopyIcon,
  IconCTA,
  ModalV2 as Modal,
  OverlayTriggerTooltip,
  PencilIcon,
  Pill,
  Toolbar,
} from '@utilities';

import Statement from '../Statement';
import Delete from './components/Delete';

import styles from './_index.module.scss';

const Breakout = ({
  breakout,
  isOwner,
  itemsPage,
  itemsRow,
  onSubmit,
  type,
  updateItemBreakouts,
}) => {
  const [isActive, setIsActive] = useState(false);
  const { conditionalDefinitionAttributes, hasException } = breakout;
  const { conditions, logicalOperators } = conditionalDefinitionAttributes;
  return (
    <>
      <Toolbar
        tools={
          isOwner && !hasException
            ? [
                <OverlayTriggerTooltip content="Edit">
                  <IconCTA
                    data-testid="item-breakout-edit"
                    icon={<PencilIcon />}
                    path={`/dashboard/create-breakout/${breakout?.id}`}
                    state={{ action: 'edit', breakout, itemsPage, itemsRow, type }}
                  />
                </OverlayTriggerTooltip>,
                <OverlayTriggerTooltip content="Copy">
                  <IconCTA
                    data-testid="item-breakout-copy"
                    icon={<CopyIcon />}
                    path={`/dashboard/create-breakout/${breakout?.id}`}
                    state={{ action: 'copy', breakout, itemsPage, itemsRow, type }}
                  />
                </OverlayTriggerTooltip>,
                <Delete
                  breakout={breakout}
                  onSubmit={onSubmit}
                  updateItemBreakouts={updateItemBreakouts}
                />,
              ]
            : []
        }
      >
        <h4>{breakout?.title}</h4>
      </Toolbar>
      {hasException ? (
        <p>Numerator created this breakout. Please contact your consultant for more information.</p>
      ) : (
        <>
          {conditions?.length > 0 && (
            <div className={styles['breakout-statement']} key={`condition-${0}`}>
              <Statement condition={conditions[0]} conditionIndex={0} />
            </div>
          )}
          {conditions?.length > 1 && (
            <div className={styles['breakout-more-statement']}>
              <Pill
                onClick={() => setIsActive(true)}
                value={`+${conditions?.length - 1} more statements`}
              />
              <Modal
                buttons={[
                  <Button
                    key="close-btn"
                    onClick={() => setIsActive(false)}
                    text="Close"
                    variant="secondary"
                  />,
                ]}
                className={styles['modal-breakout-statements']}
                isActive={isActive}
                onClose={() => setIsActive(false)}
                title={breakout?.title}
              >
                {conditions?.map((condition, conditionIndex) => {
                  return (
                    <div
                      className={styles['breakout-statement-preview']}
                      key={`condition-${conditionIndex}`}
                    >
                      <Statement
                        isCompleted
                        condition={condition}
                        conditionIndex={conditionIndex}
                        logicalOperators={logicalOperators}
                      />
                    </div>
                  );
                })}
              </Modal>
            </div>
          )}
        </>
      )}
    </>
  );
};

Breakout.propTypes = {
  breakout: PropTypes.object.isRequired,
  isOwner: PropTypes.bool,
  itemsPage: PropTypes.number,
  itemsRow: PropTypes.string,
  onSubmit: PropTypes.func,
  type: PropTypes.string,
  updateItemBreakouts: PropTypes.func,
};

export default Breakout;
