import PropTypes from 'prop-types';

import { Error, Select, TableData } from '@utilities';

import styles from './_index.module.scss';

const Table = ({
  hasSharedLocalFilters,
  isUpdatingGlobal,
  isUpdatingTable,
  isUpdatingTableFilters,
  onSelectedTableFilterChange,
  selectedTableFilters,
  tableData,
  tableFilters,
  tableTitle,
}) => {
  if (!hasSharedLocalFilters && (!tableFilters || tableFilters.length === 0))
    return (
      <Error
        description="There's insufficient sample based on the selection provided."
        header="Insufficient Sample"
        status="failed-report"
      />
    );

  return (
    <div className={styles['table']}>
      {tableTitle && <h3>{tableTitle}</h3>}
      <div>
        {tableFilters?.map((filter, index) => {
          return (
            <Select
              data-testid={`table-filter-${filter?.id}`}
              isDisabled={isUpdatingGlobal || isUpdatingTableFilters}
              isLoading={isUpdatingGlobal || isUpdatingTable || isUpdatingTableFilters}
              key={`filter-${index}`}
              label={filter?.label}
              onChange={(value) => {
                onSelectedTableFilterChange({ ...filter, value });
              }}
              options={filter?.options}
              value={selectedTableFilters[filter?.id]}
            />
          );
        })}
      </div>
      <TableData
        columns={tableData?.columns}
        data={tableData?.data}
        isUpdating={isUpdatingGlobal || isUpdatingTable}
      />
    </div>
  );
};

Table.propTypes = {
  hasSharedLocalFilters: PropTypes.bool,
  isUpdatingGlobal: PropTypes.bool,
  isUpdatingTable: PropTypes.bool,
  isUpdatingTableFilters: PropTypes.bool,
  onSelectedTableFilterChange: PropTypes.func,
  selectedTableFilters: PropTypes.object,
  tableData: PropTypes.object,
  tableFilters: PropTypes.array,
  tableTitle: PropTypes.string,
};

export default Table;
