/**
 * transforms and formats attribute
 * @param  {Object} attribute
 * @return {Object}
 */
export const transformAttribute = (attribute) => {
  return {
    description: attribute?.description,
    id: attribute?.id,
    isCustom: attribute?.is_custom,
    isFixed: attribute?.is_fixed,
    name: attribute?.name,
    type: attribute?.type,
  };
};

/**
 * transforms and formats builder data
 * @param  {Object} data
 * @return {Object}
 */
export const transformBuilderData = (data) => {
  return {
    advancedPrompts: data?.prompts
      ? data?.prompts
          .filter((prompt) => prompt?.advanced)
          .map((prompt) => {
            return transformPrompt(prompt);
          })
      : [],
    answers: data?.answers,
    defaultAnswers: data?.default_answers,
    helpUrl: data?.help_url,
    madlib: data?.madlib || data?.prompts_madlib,
    madlibAdditional: data?.prompts_madlib_additional,
    madlibAdditionalLimit: data?.prompts_madlib_additional_limit,
    name: data?.title || data?.name,
    permissions: {
      canCreatePeopleGroups: data?.spg_in_doc_header,
      canExport: data?.can_export,
      canFavorite: data?.can_favorite,
      canReprompt: data?.can_reprompt,
      canShare: data?.can_share,
    },
    prompts: data?.prompts
      ? data?.prompts
          .filter((prompt) => !prompt?.advanced)
          .map((prompt) => {
            return transformPrompt(prompt);
          })
      : [],
    recommendedAnswers: data?.recommended_answers || {},
    reportId: data?.jobId,
    searchPrompts: data?.hierarchy_search_prompts || [],
  };
};

/**
 * transforms and formats date option
 * @param  {Object} dateOption
 * @return {Object}
 */
const transformDateOption = (dateOption) => {
  return {
    options: dateOption?.options?.map((option) => {
      return {
        label: option?.label,
        value: option?.date_range,
      };
    }),
    timeFrame: dateOption?.time_frame,
    type: dateOption?.tooltip,
  };
};

/**
 * transforms and formats metric
 * @param  {Object} metric
 * @return {Object}
 */
const transformMetric = (metric) => {
  const operator = metric?.operator || 1;
  const value = metric?.value || '';
  return {
    id: metric?.id,
    metric: {
      ...metric,
      type: {
        description: metric?.description,
        value: metric?.type,
      },
    },
    operator,
    operatorInfo: transformOperator(operator),
    value,
  };
};

/**
 * transforms and formats operator
 * @param  {Object} dateOption
 * @return {Object}
 */
const transformOperator = (operator) => {
  let label = '';
  let multiple = false;
  switch (operator) {
    case 1:
      label = 'Equal to';
      break;
    case 2:
      label = 'Not Equal to';
      break;
    case 3:
      label = 'Greater than';
      break;
    case 4:
      label = 'Greater than or equal to';
      break;
    case 5:
      label = 'Less than';
      break;
    case 6:
      label = 'Less than or equal to';
      break;
    case 12:
      label = 'Between';
      multiple = true;
      break;
    default:
      label = '';
      break;
  }
  return {
    label,
    multiple,
    value: operator,
  };
};

/**
 * transforms and formats individual prompts
 * @param  {Object} prompt
 * @return {Object}
 */
export const transformPrompt = (prompt) => {
  const transformedPrompt = {
    attributes: prompt?.module?.attributes?.map((attribute) => {
      return transformAttribute(attribute);
    }),
    dateOptions: prompt?.module?.date_options?.map((dateOption) => {
      return transformDateOption(dateOption);
    }),
    emptyValue: prompt?.empty_value,
    hasCustomDateOption: prompt?.allow_custom,
    hasSearch: prompt?.search_enabled,
    hasSortEnabled: prompt?.sort_enabled,
    id: prompt?.id,
    isPinnable: prompt?.isPinnable,
    isPrefixIncluded: prompt?.is_prefix_included ?? false,
    isReadOnly: prompt?.read_only,
    isRequired: prompt?.required,
    max: prompt?.max,
    maxDate: prompt?.general_lag_date,
    metrics: prompt?.module?.metrics?.map((metric) => {
      return transformMetric(metric);
    }),
    min: prompt?.min,
    name: prompt?.name,
    objects: prompt?.module?.objects,
    operators: prompt?.module?.operators?.map((operator) => {
      return transformOperator(operator);
    }),
  };
  return transformedPrompt;
};

/**
 * transforms and formats builder error
 * @param  {Array} error
 * @return {Object}
 */
export const transformBuilderError = (error) => {
  return error?.promptErrors || [];
};

/**
 * transforms and formats answers
 * @param {Object} answers - The answers object to filter and transform.
 * @param {Array} searchPrompts - The prompts to filter answers by.
 * @returns {Object} A new object containing filtered and transformed answers.
 **/
export const transformSearchAnswers = (activePrompt, answers, searchPrompts) => {
  const searchPrompt = searchPrompts
    ?.find((promptCombo) => promptCombo.includes(activePrompt?.id))
    ?.filter((prompt) => prompt !== activePrompt?.id);
  return Object.keys(answers)
    .filter((key) => searchPrompt?.some((value) => key === value))
    .reduce((searchAnswers, key) => {
      searchAnswers[key] = answers[key].map((answer) => {
        const { id, attributeId } = answer;
        return { id, attributeId };
      });
      return searchAnswers;
    }, {});
};
