import { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  BarChart,
  DEFAULT_CHART_COLORS,
  formatData,
  Layout,
  TableData,
  tooltipFormatter,
} from '@utilities';

import { DISPLAY_AS } from '../../utilities/helpers';
import NoDataPlaceholder from '../NoDataPlaceholder';
import OtherPleaseSpecifyTable from '../OtherPleaseSpecifyTable';

import styles from './_index.module.scss';

const ComparisonCharts = ({
  compareByGroups,
  data,
  displayAs = DISPLAY_AS.PERCENT.value,
  question,
}) => {
  const chartToDisplay = displayAs === DISPLAY_AS.PERCENT.value ? data?.chartPct : data?.chartCount;
  const tableToDisplay = displayAs === DISPLAY_AS.PERCENT.value ? data?.tablePct : data?.tableCount;

  const colors = useMemo(() => {
    if (!compareByGroups?.length || !chartToDisplay?.series) return DEFAULT_CHART_COLORS;
    let unusedColorIndex = 0;
    const unusedColors = DEFAULT_CHART_COLORS.slice(compareByGroups.length);

    return chartToDisplay.series.map(({ id: seriesId, name: seriesName }, index) => {
      const matchingGroup = compareByGroups.find(
        ({ id: groupId, name: groupName }) => groupId === seriesId || groupName === seriesName
      );

      return matchingGroup?.color || unusedColors[unusedColorIndex++ % unusedColors.length];
    });
  }, [compareByGroups, data]);

  if (!data) return <NoDataPlaceholder />;

  return (
    <Layout.Flex.Column className={styles['comparison-charts']} gap="x-large">
      <BarChart
        chartTitle={`Answer Options by ${
          displayAs === DISPLAY_AS.PERCENT.value ? DISPLAY_AS.PERCENT.label : DISPLAY_AS.COUNT.label
        }`}
        colors={colors}
        exportName={question.title}
        plotOptions={{ series: { dataLabels: { allowOverlap: true } } }}
        series={chartToDisplay?.series}
        tooltip={{
          formatter: function () {
            return tooltipFormatter({ format: chartToDisplay?.format, data: this });
          },
        }}
        xAxis={chartToDisplay?.xAxis}
        yAxis={{
          labels: {
            formatter: function () {
              return formatData({ format: chartToDisplay?.format, value: this.value });
            },
          },
        }}
      />
      <hr />
      <TableData columns={tableToDisplay?.columns} data={tableToDisplay?.data} />
      {data?.tableOther?.data.length > 0 && (
        <>
          <hr />
          <OtherPleaseSpecifyTable columns={data.tableOther.columns} data={data.tableOther.data} />
        </>
      )}
    </Layout.Flex.Column>
  );
};

ComparisonCharts.propTypes = {
  compareByGroups: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.object,
  displayAs: PropTypes.string,
  question: PropTypes.object.isRequired,
};

export default ComparisonCharts;
