import { useLocation, useNavigate } from 'react-router-dom';

import { createTripGroup, readTripGroupPrompts } from '@api/trip_groups';

import Builder from '../../components/Builder';

const CreateTripGroup = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (
    <Builder
      create={createTripGroup}
      documentId="trip-group"
      onSubmit={() => navigate('/dashboard/workspace/trip-groups')}
      read={() => readTripGroupPrompts({ search, transform: false })}
      type="Trip Group"
    />
  );
};

export default CreateTripGroup;
