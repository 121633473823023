import axios from '@api';

import { transformAppContext } from './utilities/helpers';

/**
 * Fetches the app context from the server.
 * @returns {Promise}
 */
export const readAppContext = () => {
  return axios({
    method: 'get',
    url: '/api/appcontext',
    withCredentials: true,
  }).then((response) => transformAppContext(response));
};
