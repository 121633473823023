import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { RepromptIcon } from '@utilities';
import { reportStatus } from '@helpers';

import { getReportStatusMessage } from './utilities/helpers';

import styles from './_index.module.scss';

const ReportStatus = ({ onComplete, report, showCompleteMessage, showTruncatedMessage }) => {
  const reports = useSelector((state) => state?.websocket?.reports);

  const [data, setData] = useState(report);

  const completedStatus = reportStatus.COMPLETED;

  useEffect(() => {
    const report = reports?.find((report) => report?.id === data?.id);

    if (report) {
      setData(report);
    }

    if (report?.status >= completedStatus) onComplete();
  }, [reports]);

  if (data?.status < completedStatus) {
    return (
      <div className={styles['report-status']}>
        <RepromptIcon className={styles['report-status-loading-icon']} />
        {getReportStatusMessage({ data, showTruncatedMessage })}
        ...
      </div>
    );
  }

  if (showCompleteMessage) {
    return getReportStatusMessage({ data, showTruncatedMessage });
  }

  return null;
};

ReportStatus.defaultProps = {
  onComplete: () => {},
};

ReportStatus.propTypes = {
  onComplete: PropTypes.func,
  report: PropTypes.object,
  showCompleteMessage: PropTypes.bool,
  showTruncatedMessage: PropTypes.bool,
};

export default ReportStatus;
