import PropTypes from 'prop-types';

import { OverlayTriggerTooltip, Pill } from '@utilities';

import { getPillTooltip, getPillValue } from '@helpers';

import styles from './_index.module.scss';

const Statement = ({ condition, conditionIndex, isCompleted, logicalOperators }) => {
  const values = condition?.values?.map((value) => Object.keys(value)[0]);
  const tooltip = getPillTooltip({ products: values });
  const value = getPillValue({ firstProductName: values[0], products: values });

  return (
    <>
      <div className={styles['statement-condition']}>
        (
        {condition?.operator === 'in' ? (
          <Pill isCompleted={isCompleted} value="Include" />
        ) : (
          <Pill isCompleted={isCompleted} value="Exclude" />
        )}
        the
        <Pill isCompleted={isCompleted} value={condition?.attribute} />
        <OverlayTriggerTooltip content={tooltip}>
          <Pill isCompleted={isCompleted} value={value} />
        </OverlayTriggerTooltip>
        )
      </div>
      <div className={styles['statement-operator']}>
        {logicalOperators?.length !== 0 && logicalOperators?.[conditionIndex]?.name && (
          <Pill isCompleted={isCompleted} value={logicalOperators?.[conditionIndex]?.name} />
        )}
      </div>
    </>
  );
};

Statement.defaultProps = {
  logicalOperators: [],
};

Statement.propTypes = {
  condition: PropTypes.object.isRequired,
  conditionIndex: PropTypes.number,
  isCompleted: PropTypes.bool,
  logicalOperators: PropTypes.array,
};

export default Statement;
