import PropTypes from 'prop-types';

import { answerOperators } from '../../../../../QuestionLogic/utilities/constants';

import QuestionLogicOptions from '../../../../../QuestionLogic/components/QuestionLogicOptions';
import SkipLogicRow from '../../../../../QuestionLogic/components/SkipLogicRow';

import styles from './_index.module.scss';

const QuestionLogicCondition = ({ question, questionLogic, rowIndex, selectedQuestions, type }) => {
  return (
    <>
      <div className={styles['question-logic-condition']}>
        <span className={styles['question-logic-text']}>
          <div>
            {type === 'disqualify' && (
              <>
                <span>Disqualify if the answer is&nbsp;</span>
                <QuestionLogicOptions
                  isCompleted={true}
                  options={answerOperators}
                  optionsType="radio"
                  value={questionLogic[question.id].disqualify.operator}
                />
                <span>:&nbsp;</span>
                <QuestionLogicOptions
                  isCompleted={true}
                  options={question.answers}
                  optionsLabel="Answer Options"
                  optionsType="checkbox"
                  value={questionLogic[question.id].disqualify.answers}
                />
              </>
            )}
            {type === 'skip' && (
              <SkipLogicRow
                isCompleted={true}
                index={rowIndex}
                questionId={question.id}
                questionList={selectedQuestions}
                questionLogic={questionLogic}
                showOnOneLine={true}
              />
            )}
          </div>
        </span>
      </div>
    </>
  );
};

QuestionLogicCondition.propTypes = {
  question: PropTypes.object.isRequired,
  questionLogic: PropTypes.object.isRequired,
  rowIndex: PropTypes.number,
  selectedQuestions: PropTypes.array,
  type: PropTypes.oneOf(['disqualify', 'skip']).isRequired,
};

export default QuestionLogicCondition;
