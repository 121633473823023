/**
 * Update an item in an array or add it if it doesn't exist.
 * @param {Object} options - The options object.
 * @param {Array} options.array - The array to update.
 * @param {Object} options.data - The new data to update or add to the item.
 */
export const updateOrAppendItem = ({ array, data }) => {
  const index = array.findIndex(({ id }) => id === data.id);

  if (index !== -1) {
    array[index] = { ...array[index], ...data };
  } else {
    array.push(data);
  }
};
