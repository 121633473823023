import PropTypes from 'prop-types';
import { Overlay, Popover } from 'react-bootstrap';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import styles from './_index.module.scss';

const OverlayPopover = ({
  children,
  className,
  container,
  'data-testid': dataTestId,
  header,
  hasArrow,
  isActive,
  onHide,
  placement,
  target,
}) => {
  return (
    <Overlay
      arrowProps
      container={container}
      containerPadding={20}
      onHide={onHide}
      placement={placement}
      popperConfig={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 10],
            },
          },
        ],
      }}
      rootClose
      shouldUpdatePosition
      show={isActive}
      target={target}
    >
      <Popover
        className={classNames(styles['overlay-popover'], className, {
          [styles['has-arrow']]: hasArrow,
        })}
        data-testid={dataTestId}
        id={nanoid()}
      >
        {header && <h4>{header}</h4>}
        {children}
      </Popover>
    </Overlay>
  );
};

OverlayPopover.defaultProps = {
  'data-testid': 'overlay-popover',
  hasArrow: true,
  placement: 'bottom',
};

OverlayPopover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  container: PropTypes.object,
  'data-testid': PropTypes.string,
  header: PropTypes.string,
  hasArrow: PropTypes.bool,
  isActive: PropTypes.bool,
  onHide: PropTypes.func,
  placement: PropTypes.string,
  target: PropTypes.object,
};

export default OverlayPopover;
