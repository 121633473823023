import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '@utilities';

import { getInputType } from './utilities/helpers';

import styles from './_index.module.scss';

const Inputs = ({ answerInputs, inputs, onSetAnswerInput, optionId }) => {
  return (
    <div className={styles['inputs']}>
      <ul>
        {Object.keys(inputs).map((key) => {
          const input = inputs[key];

          return (
            <li key={key}>
              <Input
                label={input?.label}
                onChange={(value) => onSetAnswerInput({ id: optionId, inputId: input?.id, value })}
                type={getInputType(input?.format)}
                value={answerInputs[key]?.value}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Inputs.propTypes = {
  answerInputs: PropTypes.object.isRequired,
  inputs: PropTypes.object.isRequired,
  onSetAnswerInput: PropTypes.func.isRequired,
  optionId: PropTypes.string.isRequired,
};

export default Inputs;
