import { useState, useEffect } from 'react';

const INITIAL_OFFSET = 0;
const ITEMS_PER_PAGE = 15;

/**
 * Invoke when user click to request another page.
 * @param {dictionary} event contains the user-selected page
 * @param {array} items contains all data to be grouped into pages
 * @param {number} itemsPerPage how much data should be assigned to each page
 * @param {function} setOffset controls going to selected page
 */
export const handlePageClick = ({
  event: { selected },
  items,
  itemsPerPage = ITEMS_PER_PAGE,
  setOffset,
}) => {
  const newOffset = (selected * itemsPerPage) % items.length;
  setOffset(newOffset);
};

/**
 *  page count tracks how many pages there should be
 *  whilst offset works with itemsPerPage to determine how much will be on each page
 * @param {array} items contains all data to be grouped into pages
 * @param {number} itemsPerPage how much data should be assigned to each page
 * @param {number} initialOffset where to begin getting data
 * @returns a dictionary containing the number of pages and the items for the page in focus
 */
export function usePaginatedItems({
  items,
  itemsPerPage = ITEMS_PER_PAGE,
  initialOffset = INITIAL_OFFSET,
}) {
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [offset, setOffset] = useState(initialOffset);

  const endOffset = offset + itemsPerPage;

  const setup = () => {
    setCurrentItems(items?.slice(offset, endOffset));
    setPageCount(Math.ceil(items?.length / itemsPerPage));
  };

  const reset = () => {
    setOffset(initialOffset);
    setCurrentItems(items?.slice(offset, endOffset));
    setPageCount(Math.ceil(items?.length / itemsPerPage));
  };

  useEffect(() => {
    setup();
  }, [offset, itemsPerPage]);

  useEffect(() => {
    reset();
  }, [items]);

  return { currentItems, itemsPerPage, pageCount, setOffset };
}
