import PropTypes from 'prop-types';

import { getTemplate } from './utilities/helpers';

import styles from './_index.module.scss';

const Error = ({ description, header, status }) => {
  const { descriptionText, headerText, imageSource } = getTemplate({ description, header, status });

  return (
    <div className={styles['error']}>
      <img alt={headerText} src={imageSource} />
      <h2>
        <strong>{headerText}</strong>
      </h2>
      <p>{descriptionText}</p>
    </div>
  );
};

Error.propTypes = {
  description: PropTypes.string,
  header: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Error;
