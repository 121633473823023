import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import {
  AskWhyIcon,
  getReportIcon,
  getSurveyIcon,
  LockIcon,
  OverlayTriggerTooltip,
  ShoppingCartIcon,
  StoreIcon,
} from '@utilities';

import Favorite from './components/Favorite';
import styles from './_index.module.scss';

const ModuleListItems = ({ routes, type }) => {
  const getLogName = () => `${type === 'report' ? 'Report' : 'InstantSurvey'}_Open`;

  return (
    <ul className={styles['module-list-items']}>
      {routes?.map((route) => {
        const hasAskWhyIcon = route?.tags?.includes('AskWhy');
        const hasShoppingCartIcon = route?.tags?.includes('Subscribed Categories');
        const hasStoreIcon = route?.tags?.includes('Subscribed Channels');

        return (
          <li
            key={route?.id}
            className={route?.is_locked ? styles['is-locked'] : ''}
            data-testid={route?.id}
          >
            {type === 'report' ? getReportIcon(route?.path) : getSurveyIcon(route?.path)}
            <div>
              <div className={styles['module-list-items-title']}>
                <Link
                  data-log={getLogName(type)}
                  data-log-properties={JSON.stringify({ type: route?.path })}
                  to={`/dashboard/create-${type}/${route?.path}`}
                >
                  <h3>{route?.label}</h3>
                  {route?.is_locked && <LockIcon />}
                </Link>
                {hasShoppingCartIcon && (
                  <OverlayTriggerTooltip
                    content="Report filtered by subscribed categories"
                    placement="top"
                  >
                    <i
                      aria-label="Report filtered by subscribed categories"
                      data-testid="shopping-cart-icon"
                      role="img"
                    >
                      <ShoppingCartIcon />
                    </i>
                  </OverlayTriggerTooltip>
                )}
                {hasStoreIcon && (
                  <OverlayTriggerTooltip
                    content="Report filtered by subscribed channels"
                    placement="top"
                  >
                    <i
                      aria-label="Report filtered by subscribed channels"
                      data-testid="store-icon"
                      role="img"
                    >
                      <StoreIcon />
                    </i>
                  </OverlayTriggerTooltip>
                )}
                {hasAskWhyIcon && (
                  <OverlayTriggerTooltip
                    content="Report allows for launching an AskWhy survey"
                    placement="top"
                  >
                    <i aria-label="AskWhy" data-testid="ask-why-icon" role="img">
                      <AskWhyIcon />
                    </i>
                  </OverlayTriggerTooltip>
                )}
                {type === 'report' && (
                  <Favorite isFavorite={route?.is_favorite} reportId={route?.id} />
                )}
              </div>
              <div className="wysiwyg">
                <ReactMarkdown children={route?.description} />
              </div>
              {route?.status && route?.status !== 'normal' && <small>{route?.status}!</small>}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

ModuleListItems.propTypes = {
  routes: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['report', 'survey']).isRequired,
};

export default ModuleListItems;
