import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Error, getReportIcon, LoaderSkeleton } from '@utilities';

import { readRecommendedReports } from '@api/reports';

import styles from './_index.module.scss';

const RecommendedReports = ({ onReportClick }) => {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);

  const encodedAnswers = (answers) => {
    const answersParams = Object.keys(answers).reduce((acc, key) => {
      const value = answers[key];
      acc[key] = value;

      return acc;
    }, {});

    return `${encodeURIComponent(JSON.stringify(answersParams))}&fromOnboarding=true`;
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const getData = async () => {
      try {
        const response = await readRecommendedReports({ signal });
        setData(response?.data);
        setReports(response?.data?.recommendations);
        setIsLoading(false);
      } catch (error) {
        setError(error);
      }
    };

    getData();

    return () => controller.abort();
  }, []);

  if (error) return <Error status={error?.response?.status} />;

  if (isLoading || !reports) {
    return (
      <LoaderSkeleton height={450}>
        <rect x="0" y="0" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="50" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="100" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="150" rx="4" ry="4" width="1333" height="30" />
        <rect x="0" y="210" rx="4" ry="4" width="1333" height="20" />
        <rect x="0" y="250" rx="2" ry="2" width="1333" height="20" />
        <rect x="0" y="290" rx="2" ry="2" width="1333" height="15" />
        <rect x="0" y="315" rx="2" ry="2" width="1333" height="15" />
        <rect x="0" y="340" rx="2" ry="2" width="1333" height="15" />
        <rect x="0" y="365" rx="2" ry="2" width="1333" height="15" />
        <rect x="0" y="390" rx="2" ry="2" width="1333" height="15" />
      </LoaderSkeleton>
    );
  }

  return (
    <div className={styles['recommended-reports']}>
      <h1>You made it!</h1>
      <p>{data?.title}</p>
      <ul className={styles['recommended-reports-items']}>
        {reports?.map((report) => {
          const reportURL = report?.url;
          const answers = reportURL ? null : encodedAnswers(report?.answers);

          return (
            <li data-testid={report?.id} key={report?.id}>
              {getReportIcon(report?.id)}
              <div>
                <div className={styles['recommended-reports-items-title']}>
                  {reportURL ? (
                    <a href={reportURL} onClick={onReportClick} rel="noreferrer" target="_blank">
                      <h3>{report?.label}</h3>
                    </a>
                  ) : (
                    <Link
                      onClick={onReportClick}
                      to={`/dashboard/create-report/${report?.id}?answers=${answers}`}
                      target="_blank"
                    >
                      <h3>{report?.label}</h3>
                    </Link>
                  )}
                </div>
                <div className="wysiwyg">
                  <ReactMarkdown children={report?.description} />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

RecommendedReports.propTypes = {
  onReportClick: PropTypes.func,
};

export default RecommendedReports;
