import axios from '@api';

import {
  transformCommentData,
  transformReportData,
  transformReportFolders,
  transformReportItemsData,
  transformReportPrompts,
  transformReportScheduleData,
  transformReportTabData,
} from './utilities/helpers';

/**
 * report folder type id
 * @type {Number}
 */
const folderTypeId = 1;

/** cancel report
 * @param  {Number}  options.reportId
 * @return {Promise}
 */
export const cancelReport = ({ reportId }) => {
  return axios({
    method: 'put',
    url: `/api/jobs/${reportId}/cancel`,
    withCredentials: true,
  });
};

/**
 * create bulk download reports
 * @param {Array|undefined} options.folderIds mutually exclusive with jobIds
 * @param {Array|undefined} options.jobIds mutually exclusive with folderIds
 * @return {Promise}
 */
export const createBulkDownloadReports = ({ folderIds, jobIds }) => {
  return axios({
    data: {
      folder_entry_ids: folderIds,
      job_ids: jobIds,
    },
    method: 'post',
    url: '/v2/api/jobs/bulk-export',
    withCredentials: true,
  });
};

/** create report
 * @param  {Object}  options.answers
 * @param  {String}  options.documentId
 * @param  {String}  options.name
 * @return {Promise}
 */
export const createReport = ({ answers, documentId, name }) => {
  return axios({
    data: {
      answers,
      documentId,
      name,
    },
    method: 'post',
    url: `/api/documents/${documentId}`,
    withCredentials: true,
  });
};

/**
 * create report folder
 * @param  {String}  options.name
 * @return {Promise}
 */
export const createReportFolder = ({ name }) => {
  return axios({
    data: {
      folder_type: folderTypeId,
      parent_entry_id: 'root',
      name,
    },
    method: 'post',
    url: '/v2/api/folders',
    withCredentials: true,
  });
};

/**
 * creates a new comment on tab
 * @param  {Number} options.tabId
 * @param  {String} options.text
 * @return {Promise}
 */
export const createReportTabComment = ({ tabId, text }) => {
  return axios({
    data: { text },
    method: 'post',
    transformResponse: [
      (data) => {
        const response = JSON.parse(data);
        return transformCommentData(response);
      },
    ],
    url: `/api/comments/jobitem/${tabId}`,
    withCredentials: true,
  });
};

/** create a scheduled report
 * @param  {Object} options.automateGroupName
 * @param  {Object} options.entries
 * @param  {Number} options.reportDestination
 * @param  {Number} options.reportId
 * @return {Promise}
 */
export const createReportSchedule = ({
  automateGroupName,
  entries,
  reportDestination,
  reportId,
}) => {
  return axios({
    data: transformReportScheduleData({ automateGroupName, entries, reportDestination, reportId }),
    method: 'post',
    url: `/v2/api/schedules`,
    withCredentials: true,
  });
};

/**
 * deletes reports
 * @param {Array} options.ids
 * @return {Promise}
 */
export const deleteReports = ({ ids }) => {
  return axios({
    data: ids,
    method: 'delete',
    url: '/v2/api/jobs',
    withCredentials: true,
  });
};

/**
 * delete report folders
 * @param  {Array} options.ids
 * @return {Promise}
 */
export const deleteReportFolders = ({ ids }) => {
  return axios({
    data: ids,
    method: 'delete',
    url: '/v2/api/folders',
    withCredentials: true,
  });
};

/**
 * deletes comment on tab
 * @param  {Number} options.commentId
 * @return {Promise}
 */
export const deleteReportTabComment = ({ commentId }) => {
  return axios({
    data: commentId,
    method: 'delete',
    url: `/api/comments/${commentId}`,
    withCredentials: true,
  });
};

/**
 * Toggle favorite status for a report.
 * @param {Object} options
 * @param {string} options.panel
 * @param {boolean} options.isFavorite
 * @param {string} options.reportId
 * @returns {Promise}
 */
export const updateFavoriteReport = ({ isFavorite, panel, reportId }) => {
  return axios({
    data: {
      document_id: reportId,
      is_favorite: isFavorite,
      panel,
    },
    method: 'post',
    url: '/v2/api/menu/favorite',
    withCredentials: true,
  });
};

/**
 * gets recent reports for homepage
 * @return {Promise}
 */
export const readHomepageRecentReports = () => {
  return axios({
    method: 'get',
    params: {
      size: 10,
      sort_by: 'created_datetime',
    },
    transformResponse: [(data) => transformReportItemsData(JSON.parse(data))],
    url: '/v2/api/jobs',
    withCredentials: true,
  });
};

/**
 * gets homepage report
 * @param  {Number}  options.signal
 * @return {Promise}
 */
export const readHomepageReport = ({ signal }) => {
  return axios({
    method: 'get',
    signal,
    transformResponse: [
      (data) => {
        const response = JSON.parse(data);

        if (response?.id && response?.items[0]?.id) {
          return { id: response.id, tabId: response.items[0].id };
        }
        const error = new Error('Report Failed to Load');
        error.response = { status: 'failed-report' };
        throw error;
      },
    ],
    url: '/api/jobs/recent/homepage',
    withCredentials: true,
  });
};

/**
 * gets recommended reports
 * @param  {Number}  options.signal
 * @return {Promise}
 * */
export const readRecommendedReports = ({ signal }) => {
  return axios({
    method: 'get',
    signal,
    url: '/v2/api/users/recommended-reports',
    withCredentials: true,
  });
};

/**
 * gets report
 * @param  {String}  options.publicToken
 * @param  {Number}  options.reportId
 * @param  {Number}  options.signal
 * @return {Promise}
 */
export const readReport = ({ publicToken, reportId, signal }) => {
  return axios({
    method: 'get',
    params: {
      publicToken,
    },
    signal,
    transformResponse: [
      (data) => {
        try {
          const response = JSON.parse(data);

          return transformReportData(response);
        } catch {
          return data;
        }
      },
    ],
    url: `/api/jobs/${reportId}`,
    withCredentials: true,
  });
};

/**
 * gets report details
 * @param  {Number}  options.id
 * @param  {Number}  options.signal
 * @return {Promise}
 */
export const readReportDetails = ({ id, signal }) => {
  return axios({
    method: 'get',
    signal,
    url: `/api/jobs/${id}`,
    withCredentials: true,
  });
};

/**
 * read report folders
 * @param  {String} options.order   "asc" || "desc" || undefined
 * @param  {Number} options.page
 * @param  {String} options.query
 * @param  {Number} options.signal  AbortController signal
 * @param  {Number} options.size
 * @param  {String} options.sortBy
 * @param  {Array}  options.tags    [is_locked, is_shared]
 * @return {Promise}
 */
export const readReportFolders = ({ order, page, query, signal, size, sortBy, tags }) => {
  return axios({
    method: 'get',
    params: {
      order_by: sortBy ? order : undefined,
      page,
      query: query?.length > 0 ? query : undefined,
      size: size ? size : 2000,
      sort_by: sortBy,
      tags: tags?.length > 0 ? tags?.join(',') : undefined,
    },
    signal,
    transformResponse: [(data) => transformReportFolders(JSON.parse(data))],
    url: '/v2/api/jobs/folders',
    withCredentials: true,
  });
};

/**
 * read report list V2
 * @param  {Number} options.folderId
 * @param  {String} options.order   "asc" || "desc" || undefined
 * @param  {Number} options.page
 * @param  {String} options.query
 * @param  {Number} options.signal  AbortController signal
 * @param  {Number} options.size
 * @param  {String} options.sortBy
 * @param  {Array}  options.tags    [is_favorite, is_shared]
 * @return {Promise}
 */
export const readReportItems = ({ folderId, order, page, query, signal, size, sortBy, tags }) => {
  return axios({
    method: 'get',
    params: {
      folder_id: folderId,
      order_by: sortBy ? order : undefined,
      page,
      query: query?.length > 0 ? query : undefined,
      size,
      sort_by: sortBy,
      tags: tags?.length > 0 ? tags?.join(',') : undefined,
    },
    signal,
    transformResponse: [(data) => transformReportItemsData(JSON.parse(data))],
    url: '/v2/api/jobs',
    withCredentials: true,
  });
};

/**
 * read report prompts
 * @param  {String}  options.documentId
 * @param  {String}  options.search
 * @param  {Boolean} options.transform
 * @return {Promise}
 */
export const readReportPrompts = ({ documentId, search = '', transform = true }) => {
  return axios({
    method: 'get',
    transformResponse: [
      (data) => {
        if (!transform) return JSON.parse(data);

        return transformReportPrompts(JSON.parse(data));
      },
    ],
    url: `/api/documents/${documentId}${search}`,
    withCredentials: true,
  });
};

/**
 * gets report tab
 * @param  {String}  options.publicToken
 * @param  {Number}  options.reportId
 * @param  {Number}  options.signal
 * @param  {Number}  options.tabId
 * @return {Promise}
 */
export const readReportTab = ({ publicToken, reportId, signal, tabId }) => {
  return axios({
    method: 'get',
    params: {
      publicToken,
    },
    signal,
    transformResponse: [
      (data) => {
        try {
          const response = JSON.parse(data);

          return transformReportTabData(response);
        } catch {
          return data;
        }
      },
    ],
    url: `/api/jobs/${reportId}/items/${tabId}`,
    withCredentials: true,
  });
};

/**
 * gets report tab comments
 * @param  {Number} options.signal
 * @param  {Number} options.tabId
 * @return {Promise}
 */
export const readReportTabComments = ({ signal, tabId }) => {
  return axios({
    method: 'GET',
    signal,
    transformResponse: [
      (data) => {
        const response = JSON.parse(data);
        return transformCommentData(response);
      },
    ],
    url: `/api/comments/jobitem/${tabId}`,
    withCredentials: true,
  });
};

/**
 * reads report share token for read only
 * @param  {Number}  options.reportId
 * @return {Promise}
 */
export const readReportShareToken = ({ reportId }) => {
  return axios({
    method: 'put',
    transformResponse: [
      (data) => {
        const response = JSON.parse(data);

        return response?.publicToken;
      },
    ],
    url: `/api/jobs/${reportId}/share`,
    withCredentials: true,
  });
};

/**
 * reads report public token and retrieves report id
 * @param  {String} options.token
 * @return {Number}
 */
export const readReportPublicToken = ({ publicToken }) => {
  return axios({
    method: 'get',
    transformResponse: [
      (data) => {
        const response = JSON.parse(data);

        return response?.jobId;
      },
    ],
    url: `/api/jobs/check-token/${publicToken}`,
  });
};

/**
 * shares report with all users under a client
 * @param  {Number} options.clientId
 * @param  {Number} options.ids
 * @return {Promise}
 */
export const shareReportWithClientGroup = ({ clientId, ids }) => {
  return axios({
    data: {
      job_ids: ids,
      recipient_client_id: clientId,
    },
    method: 'post',
    url: '/v2/api/jobs/client_share',
    withCredentials: true,
  });
};

/**
 * shares report with users
 * @param  {Array} options.ids
 * @param  {Boolean} options.skipEmail
 * @param  {Number} options.userIds
 * @return {Promise}
 */
export const shareReportWithUsers = ({ ids, skipEmail = false, userIds }) => {
  return axios({
    data: {
      job_ids: ids,
      recipient_ids: userIds,
      skip_email: skipEmail,
    },
    method: 'post',
    url: '/v2/api/jobs/user_share',
    withCredentials: true,
  });
};

/**
 * shares report folder with all users under a client
 * @param  {Number} options.clientId
 * @param  {Array} options.folderIds
 * @return {Promise}
 */
export const shareReportFolderWithClientGroup = ({ clientId, folderIds }) => {
  return axios({
    data: {
      client_id: clientId,
      shared_folder_ids: folderIds,
    },
    method: 'post',
    url: '/v2/api/folders/client_share',
    withCredentials: true,
  });
};

/**
 * shares report folder with users
 * @param  {Array} options.folderIds
 * @param  {Boolean} options.skipEmail
 * @param  {Number} options.userIds
 * @return {Promise}
 */
export const shareReportFolderWithUsers = ({ folderIds, skipEmail = false, userIds }) => {
  return axios({
    data: {
      shared_folder_ids: folderIds,
      shared_with_user_ids: userIds,
      skip_email: skipEmail,
    },
    method: 'post',
    url: '/v2/api/folders/user_share',
    withCredentials: true,
  });
};

/**
 * updates report Favorite
 * @param  {Number}  options.id
 * @param  {Number}  options.isFavorite
 * @return {Promise}
 */
export const updateReportFavorite = ({ id, isFavorite }) => {
  return axios({
    method: 'put',
    data: {
      is_favorite: isFavorite,
    },
    url: `/api/jobs/${id}`,
    withCredentials: true,
  });
};

/**
 * add reports into any folder
 * @param  {Number} options.folderId
 * @param  {Array}  options.ids
 * @return {Promise}
 */
export const updateReportFolder = ({ folderId, ids }) => {
  return axios({
    data: { folder_type: folderTypeId, ids, parent_entry_id: folderId },
    method: 'put',
    url: '/v2/api/folders/move',
    withCredentials: true,
  });
};

/**
 * rename report folder
 * @param  {Number} options.id
 * @param  {String} options.name
 * @return {Promise}
 */
export const updateReportFolderName = ({ id, name }) => {
  return axios({
    data: { name },
    method: 'put',
    url: `/v2/api/folders/${id}/rename`,
    withCredentials: true,
  });
};

/**
 * update report name
 * @param  {Number} options.id
 * @param  {String} options.name
 * @return {Promise}
 */
export const updateReportName = ({ name, id }) => {
  return axios({
    data: { name },
    method: 'put',
    url: `/v2/api/jobs/${id}/rename`,
    withCredentials: true,
  });
};

/**
 * updates report tab comment
 * @param  {Number} options.commentId
 * @param  {String} options.text
 * @return {Promise}
 */
export const updateReportTabComment = ({ commentId, text }) => {
  return axios({
    data: { text },
    method: 'put',
    transformResponse: [
      (data) => {
        const response = JSON.parse(data);
        return transformCommentData(response);
      },
    ],
    url: `/api/comments/${commentId}`,
    withCredentials: true,
  });
};
