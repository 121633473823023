import {
  COLOR_BANTAMWEIGHT,
  COLOR_MIDDLEWEIGHT,
  COLOR_MINT,
  COLOR_NUMERATOR_GREEN,
  COLOR_SAPPHIRE,
} from '../../../helpers/colors';

/**
 * bullet chart specific configurations
 * @type {Object}
 */
export const bulletChartConfiguration = {
  chart: { inverted: true },
  colors: [COLOR_NUMERATOR_GREEN, COLOR_MINT, COLOR_SAPPHIRE, COLOR_BANTAMWEIGHT],
  plotOptions: {
    bullet: {
      dataLabels: {
        enabled: true,
      },
      targetOptions: {
        color: COLOR_MIDDLEWEIGHT,
        width: '110%',
      },
    },
  },
};

/**
 * modifies options based on data
 * @param  {Object} options
 * @return {Object}
 */
export const setModifiedData = (options) => {
  options?.series?.forEach((bullet) => {
    bullet.color = COLOR_MIDDLEWEIGHT;

    bullet.data.forEach((value) => {
      const target = value?.target;

      if (!target) {
        value.color = options?.colors[3];
      }

      const y = value?.y;

      if (y > target) {
        value.color = options?.colors[0];
      } else if (y === target) {
        value.color = options?.colors[1];
      } else {
        value.color = options?.colors[2];
      }
    });
    bullet.events = {
      legendItemClick: function (e) {
        e.preventDefault();
      },
    };
  });

  return options;
};
