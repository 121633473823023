import axios from './api';

import { transformExternalQuestions } from './utilities/helpers';

/**
 * read external questions by survey source id
 * @param {Object} options
 * @param {AbortSignal} [options.signal]
 * @param {Number|String} options.surveySourceId
 * @return {Promise}
 */
export const readExternalQuestions = ({ signal, surveySourceId }) => {
  return axios({
    method: 'get',
    signal,
    url: `/external_questions/${surveySourceId}`,
  }).then(({ data }) => transformExternalQuestions(data));
};
