import PropTypes from 'prop-types';

import classNames from 'classnames';

import { FavoriteIcon, FavoriteFilledIcon } from '../../icons';

import styles from './_index.module.scss';

const FavoriteToggle = ({ isFavorite, onChange }) => (
  <span className={classNames({ [styles['is-favorite']]: isFavorite })} onClick={() => onChange()}>
    {isFavorite ? <FavoriteFilledIcon /> : <FavoriteIcon />}
  </span>
);

FavoriteToggle.defaultProps = {
  isFavorite: false,
  onChange: () => {},
};

FavoriteToggle.propTypes = {
  isFavorite: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FavoriteToggle;
