import { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { OverlayTriggerTooltip, Pill } from '@utilities';

import { getPromptPillTooltip, getPromptPillValue } from '../../../../utilities/helpers';

import styles from './_index.module.scss';

const GroupMadlib = ({
  activePrompt,
  group,
  isReadOnly,
  onClearAnswer,
  onSetActivePrompt,
  surveyType,
}) => {
  const { prompts, madlib } = surveyType;
  const { promptAnswers } = group;

  const regex = /{[^}]*}|[^{}]+/g;
  const parts = madlib.match(regex);
  let promptIndex = 0;

  return (
    <div
      className={classNames(styles['group-madlib'], {
        [styles['is-complete']]: isReadOnly,
      })}
    >
      {parts.map((part, partIndex) => {
        if (!part.startsWith('{')) {
          return <Fragment key={`madlib-part-${partIndex}`}>{part}</Fragment>;
        }

        const prompt = prompts[promptIndex++];
        const currentPromptAnswers = promptAnswers.filter(
          ({ _delete, promptId }) => !_delete && promptId === prompt.id
        );
        const tooltip = getPromptPillTooltip({ prompt, promptAnswers });
        const value = getPromptPillValue({ prompt, promptAnswers });

        return (
          <Fragment key={`madlib-pill-${partIndex}`}>
            <OverlayTriggerTooltip content={tooltip} isFullWidth>
              <Pill
                data-testid={`group-madlib-pill-${prompt.id}`}
                isActive={prompt === activePrompt}
                isCompleted={isReadOnly}
                isModified={currentPromptAnswers.length > 0}
                isRequired={prompt.isRequired}
                onClick={() => onSetActivePrompt(prompt)}
                onClose={() => onClearAnswer(prompt.id)}
                value={value}
              />
            </OverlayTriggerTooltip>
          </Fragment>
        );
      })}
    </div>
  );
};

GroupMadlib.propTypes = {
  activePrompt: PropTypes.object,
  group: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  onClearAnswer: PropTypes.func,
  onSetActivePrompt: PropTypes.func,
  surveyType: PropTypes.object.isRequired,
};

export default GroupMadlib;
