import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Button,
  Card,
  handlePageClick,
  LoaderSkeleton,
  Loader,
  Pagination,
  Search,
  usePaginatedItems,
} from '@utilities';
import { customGroupStatus } from '@helpers';

import Breakout from './components/Breakout';
import { getFilterBreakouts } from './utilities/helpers';
import styles from './_index.module.scss';

const ItemBreakouts = ({
  groupType,
  item,
  itemsPage,
  itemsRow,
  onSubmit,
  readItem,
  updateItemBreakouts,
}) => {
  const completedStatus = customGroupStatus.COMPLETED;
  const [isItemProcessing, setIsItemProcessing] = useState(item?.status < completedStatus);
  const [isLoading, setIsLoading] = useState(false);
  const [itemBreakouts, setItemBreakouts] = useState(item);

  const groups = useSelector((state) => state?.websocket?.customGroups);

  const { id, isOwner } = itemBreakouts;
  const [value, setValue] = useState('');
  const breakoutsPerPage = 5;

  const navigate = useNavigate();
  const itemControllerRef = useRef(null);

  const getItem = async () => {
    itemControllerRef?.current?.abort();
    const controller = new AbortController();
    itemControllerRef.current = controller;

    try {
      const { data } = await readItem({
        id: item?.id,
        signal: itemControllerRef?.current?.signal,
      });

      setItemBreakouts(data);
      itemControllerRef.current = null;
    } catch (error) {
      if (error?.response?.status > 200) {
        console.error(error);
        setIsLoading(false);
      }
    }
  };

  const filteredBreakouts = useMemo(() => {
    return value
      ? getFilterBreakouts({ breakouts: itemBreakouts?.breakouts, value })
      : itemBreakouts?.breakouts;
  }, [itemBreakouts?.breakouts, value]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getItem();
      setIsLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleGroupStatus = async () => {
      const group = groups?.find((group) => group?.id === item?.id);

      if (group) {
        if (group?.status < completedStatus) {
          setIsItemProcessing(true);
        }
        if (group?.status >= completedStatus) {
          await getItem();
          setIsItemProcessing(false);
        }
      }
    };

    handleGroupStatus();
  }, [groups]);

  const { currentItems, itemsPerPage, pageCount, setOffset } = usePaginatedItems({
    items: filteredBreakouts,
    itemsPerPage: breakoutsPerPage,
  });

  const onPageChange = (event) => {
    handlePageClick({ event, items: filteredBreakouts, itemsPerPage: breakoutsPerPage, setOffset });
  };

  const handleNavigate = ({ id }) => {
    navigate(`/dashboard/create-breakout/${id}`, {
      state: { itemsPage, itemsRow, type: groupType?.type },
    });
  };

  if ((isLoading && !isItemProcessing) || !filteredBreakouts) {
    return (
      <div>
        <LoaderSkeleton height={40} width={2000}>
          <rect x="0" y="0" rx="2" ry="2" width="100%" height="40" />
        </LoaderSkeleton>
        {[...Array(currentItems?.length)].map((item, index) => (
          <LoaderSkeleton height={110} key={index} width={2000}>
            <rect x="0" y="0" rx="2" ry="2" width="100%" height="110" />
          </LoaderSkeleton>
        ))}
      </div>
    );
  }

  return (
    <>
      {isItemProcessing ? (
        <div className={styles['item-breakouts-loader']}>
          <Loader isCentered />
        </div>
      ) : (
        <div className={styles['item-breakouts']}>
          {!itemBreakouts?.breakouts?.length && (
            <div className={styles['item-breakouts-add']}>
              <p>{`This ${groupType?.label} hasn't been defined yet.`}</p>
              {isOwner && (
                <Button
                  data-testid="add-item-breakout"
                  onClick={() => handleNavigate({ id })}
                  text="Add a Breakout"
                  variant="secondary"
                />
              )}
            </div>
          )}
          {itemBreakouts?.breakouts?.length > 0 && (
            <>
              <div className={styles['item-breakouts-search']}>
                <Search
                  onChange={setValue}
                  placeholder="Search by breakout name or definitions"
                  value={value}
                />
                {isOwner && (
                  <Button
                    data-testid="create-item-breakout"
                    onClick={() => handleNavigate({ id })}
                    text="Add a Breakout"
                    variant="secondary"
                  />
                )}
              </div>
              <div>
                {currentItems?.map((breakout, breakoutIndex) => (
                  <Card key={breakoutIndex}>
                    <Breakout
                      breakout={breakout}
                      isOwner={isOwner}
                      itemsPage={itemsPage}
                      itemsRow={itemsRow}
                      onSubmit={onSubmit}
                      type={groupType?.type}
                      updateItemBreakouts={updateItemBreakouts}
                    />
                  </Card>
                ))}
                <Pagination
                  onPageChange={onPageChange}
                  pageCount={pageCount}
                  show={filteredBreakouts?.length > itemsPerPage}
                />
              </div>
              {filteredBreakouts && filteredBreakouts.length === 0 && (
                <div className={styles['item-breakouts-search-empty-message']}>
                  <h3>No results found.</h3>
                  <p>Try refining your search.</p>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

ItemBreakouts.propTypes = {
  groupType: PropTypes.object,
  item: PropTypes.shape({
    breakouts: PropTypes.array,
    id: PropTypes.number.isRequired,
    isOwner: PropTypes.bool,
    status: PropTypes.number,
  }).isRequired,
  itemsPage: PropTypes.number,
  itemsRow: PropTypes.string,
  onSubmit: PropTypes.func,
  readItem: PropTypes.func,
  updateItemBreakouts: PropTypes.func,
};

export default ItemBreakouts;
