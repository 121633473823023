import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, DeleteIcon, IconCTA, ModalV2 as Modal, OverlayTriggerTooltip } from '@utilities';

const Delete = ({ breakout, onSubmit, updateItemBreakouts }) => {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { allDefinitions, title, id } = breakout;

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const updatedDefinitions = { ...allDefinitions };
      delete updatedDefinitions[title];
      await updateItemBreakouts({ definition: updatedDefinitions, id });
      setIsActive(false);
      onSubmit({ refreshItems: true });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(false);
  }, [isActive]);

  return (
    <>
      <OverlayTriggerTooltip content="Delete">
        <IconCTA
          data-testid="item-breakout-delete"
          icon={<DeleteIcon />}
          onClick={() => setIsActive(true)}
        />
      </OverlayTriggerTooltip>
      <Modal
        buttons={[
          <Button
            key="cancel-btn"
            onClick={() => setIsActive(false)}
            text="Cancel"
            variant="secondary"
          />,
          <Button
            key="submit-btn"
            isLoading={isLoading}
            onClick={handleSubmit}
            text="Delete"
            variant="error"
          />,
        ]}
        isActive={isActive}
        onClose={() => setIsActive(false)}
        title="Delete"
      >
        <p>Are you sure you would like to delete this breakout?</p>
      </Modal>
    </>
  );
};

Delete.propTypes = {
  breakout: PropTypes.object,
  onSubmit: PropTypes.func,
  updateItemBreakouts: PropTypes.func,
};

export default Delete;
