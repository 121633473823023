import PropTypes from 'prop-types';

import ModuleListItems from './components/ModuleListItems';
import styles from './_index.module.scss';

const ModuleList = ({ routes, type, show_favorites: showFavorites }) => {
  const favoriteRoutes = routes?.filter((route) => route?.is_favorite);
  const allRoutes = showFavorites ? routes?.filter((route) => !route?.is_favorite) : routes;

  return (
    <div>
      {showFavorites && favoriteRoutes?.length > 0 && (
        <div className={styles['module-list-favorite']}>
          <h4>Favorites</h4>
          <ModuleListItems routes={favoriteRoutes} type={type} />
        </div>
      )}
      <ModuleListItems routes={allRoutes} type={type} />
    </div>
  );
};

ModuleList.propTypes = {
  routes: PropTypes.array.isRequired,
  show_favorites: PropTypes.bool,
  type: PropTypes.oneOf(['report', 'survey']).isRequired,
};

export default ModuleList;
