/**
 * column chart specific configurations
 * @type {Object}
 */
export const columnChartConfiguration = {
  plotOptions: {
    series: {
      groupPadding: 0.1,
      pointPadding: 0.05,
    },
  },
};
