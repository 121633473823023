import PropTypes from 'prop-types';
import { Modal as ModalBootstrap } from 'react-bootstrap';
import classNames from 'classnames';

import { CloseIcon } from '../../icons';

import styles from './_index.module.scss';

const Modal = ({
  buttons,
  children,
  className,
  hasCloseIcon,
  isActive,
  isScrollable,
  onClose,
  size,
  title,
  ...props
}) => {
  return (
    <ModalBootstrap
      animation={false}
      backdropClassName={styles['modal-backdrop']}
      centered
      className={classNames(
        styles['modal'],
        {
          [styles['is-large']]: size === 'large',
        },
        className
      )}
      scrollable={isScrollable}
      show={isActive}
      {...props}
    >
      {title && (
        <div className={styles['modal-header']}>
          <h2>{title}</h2>
          {hasCloseIcon && onClose && (
            <button
              aria-label="Close"
              className={styles['modal-close']}
              data-testid="modal-close"
              onClick={onClose}
            >
              <CloseIcon />
            </button>
          )}
        </div>
      )}
      <ModalBootstrap.Body>{children}</ModalBootstrap.Body>
      {buttons?.length > 0 && <div className={styles['modal-footer']}>{buttons}</div>}
    </ModalBootstrap>
  );
};

Modal.defaultProps = {
  hasCloseIcon: true,
};

Modal.propTypes = {
  buttons: PropTypes.array,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasCloseIcon: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  isScrollable: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(['large']),
  title: PropTypes.string,
};

export default Modal;
