/**
 * universal conditions applied to all actions to determine whether visible
 * @param  {Array} selectedRows
 * @return {Object}
 */
export const getConditions = (selectedRows) => {
  return {
    canReprompt: selectedRows.some((row) => row?.permissions?.canReprompt),
    hasCustomAttribute: selectedRows.some((row) => row?.hasCustomAttribute),
    hasException: selectedRows.some((row) => row?.hasException),
    hasExpiration: selectedRows.some((row) => row?.hasExpiration),
    hasFrozen: selectedRows.some((row) => row?.isFrozen),
    hasJoined: selectedRows.some((row) => row?.isJoin),
    hasPremiumPG: selectedRows.some((row) => row?.isPremiumPG),
    hasSmart: selectedRows.some((row) => row?.isSmart),
    isSelectedCompleted: selectedRows.every((row) => row?.isCompleted),
    isSelectedItems: selectedRows.every((row) => !row.isFolder),
    isSelectedFolders: selectedRows.every((row) => row?.isFolder),
    isSelectedOwned: selectedRows.every((row) => row?.isOwner),
  };
};
