import PropTypes from 'prop-types';

import { Error, Layout, TableData, Loader } from '@utilities';

const RawData = ({ isRawDataLoading, rawData, rawDataLoadingError }) => {
  if (rawDataLoadingError) return <Error status={rawDataLoadingError?.response?.status} />;
  if (isRawDataLoading) return <Loader isCentered />;

  return (
    <Layout.Flex.Column>
      {rawData?.map((data, index) => (
        <TableData
          columns={data?.table?.columns}
          data={data?.table?.data}
          key={`raw-data-${index}`}
          size={20}
        />
      ))}
    </Layout.Flex.Column>
  );
};

RawData.propTypes = {
  isRawDataLoading: PropTypes.bool,
  rawData: PropTypes.arrayOf(PropTypes.object),
  rawDataLoadingError: PropTypes.object,
};

export default RawData;
