import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './_index.module.scss';

const Card = ({
  as: Component,
  children,
  className,
  elevation,
  padding,
  status,
  statusColor,
  ...props
}) => {
  return (
    <Component
      className={classNames(
        styles['card'],
        styles[`is-elevation-${elevation}`],
        styles[`is-padding-${padding}`],
        { [styles[`is-status-${status}`]]: status, [styles['is-status-color']]: statusColor },
        className
      )}
      style={statusColor ? { '--status-color': statusColor } : {}}
      {...props}
    >
      {children}
    </Component>
  );
};

Card.defaultProps = {
  as: 'div',
  elevation: 0,
  padding: 'medium',
};

Card.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  elevation: PropTypes.oneOf([0, 1, 2]),
  padding: PropTypes.oneOf(['small', 'medium', 'large', 'x-large']),
  status: PropTypes.oneOf(['error', 'info', 'success', 'warn']),
  statusColor: PropTypes.string,
};

export default Card;
