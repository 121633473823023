import { useContext, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { maxQuestionError } from '@api/surveys';

import { OverlayTriggerTooltip, PlusIcon } from '@utilities';

import AddQuestionDropdown from '../AddQuestionDropdown';
import ModalLogicChangeConfirmation from './components/ModalLogicChangeConfirmation';
import Question from './components/Question';
import QuestionPlaceholder from './components/QuestionPlaceholder';
import { SurveyContext } from '../../../../utilities/context';

import styles from './_index.module.scss';

const QuestionnaireList = ({
  firstErrorRef,
  isDetailedQuestionView,
  maxQuestions,
  onAddQuestion,
  onCreateQuestion,
  onDuplicateQuestion,
  onEditQuestion,
  onRemoveQuestion,
}) => {
  const { survey, updateSurvey } = useContext(SurveyContext);
  const [isAddQuestionDropdownActive, setIsAddQuestionDropdownActive] = useState(false);
  const [questionBeingMoved, setQuestionBeingMoved] = useState(null);
  const [questionsRequiringLogicConfirmation, setQuestionsRequiringLogicConfirmation] =
    useState(null);

  const activeQuestions = survey?.questions.filter(({ _delete }) => !_delete);
  const addQuestionButtonRef = useRef(null);

  const onDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }

    const destinationIndex = result?.destination?.index;
    const sourceIndex = result?.source?.index;
    if (destinationIndex === sourceIndex) {
      return;
    }
    const updatedQuestions = [...survey.questions];
    const [questionToMove] = updatedQuestions.splice(sourceIndex, 1);
    updatedQuestions.splice(destinationIndex, 0, questionToMove);

    const hasLogicChanges = updatedQuestions.some(({ id }, index) => {
      const currentLogic = survey?.questionLogic[id];

      if (
        (index > 1 && currentLogic?.disqualify) || // only first two questions can disqualify
        currentLogic?.skip?.logic.some(
          // skip logic cannot reference questions that are after the current question
          ({ question }) =>
            updatedQuestions.findIndex(({ id: questionId }) => question.id === questionId) > index
        )
      ) {
        return true;
      }
      return false;
    });

    if (hasLogicChanges) {
      setQuestionBeingMoved(questionToMove);
      setQuestionsRequiringLogicConfirmation(updatedQuestions);
    } else {
      updateSurvey({ questions: updatedQuestions });
    }
  };

  return (
    <>
      <div className={styles['questionnaire-list-container']}>
        {activeQuestions.length === 0 && (
          <QuestionPlaceholder onCreateQuestion={onCreateQuestion} />
        )}
        {activeQuestions.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className={styles['selections']}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {activeQuestions.map((question, index) => {
                    return (
                      <Draggable
                        draggableId={`activeAnswer-${index}`}
                        key={`activeAnswer-${index}`}
                        index={index}
                      >
                        {(provided) => {
                          return (
                            <Question
                              firstErrorRef={firstErrorRef}
                              index={index}
                              isDetailedQuestionView={isDetailedQuestionView}
                              key={`question-${question.id}`}
                              maxQuestions={maxQuestions}
                              number={index + 1}
                              onRemoveQuestion={onRemoveQuestion}
                              onDuplicateQuestion={onDuplicateQuestion}
                              onEditQuestion={onEditQuestion}
                              provided={provided}
                              question={question}
                              questionLogic={survey?.questionLogic}
                              selectedQuestions={activeQuestions}
                              setQuestionLogic={(questionLogic) => updateSurvey({ questionLogic })}
                            />
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <div
          className={styles['questionnaire-list-add-question-button']}
          ref={addQuestionButtonRef}
        >
          <OverlayTriggerTooltip
            content={activeQuestions.length >= maxQuestions ? maxQuestionError : 'Add Question'}
            position="bottom"
          >
            <button
              data-testid="add-question-button"
              disabled={activeQuestions.length >= maxQuestions}
              onClick={() => setIsAddQuestionDropdownActive(true)}
            >
              <PlusIcon />
            </button>
          </OverlayTriggerTooltip>
        </div>
        <AddQuestionDropdown
          buttonRef={addQuestionButtonRef}
          isDropdownActive={isAddQuestionDropdownActive}
          onAddQuestion={onAddQuestion}
          onCreateQuestion={onCreateQuestion}
          placement="right"
          setIsDropdownActive={setIsAddQuestionDropdownActive}
        />
      </div>
      {questionsRequiringLogicConfirmation && (
        <ModalLogicChangeConfirmation
          onClose={() => setQuestionsRequiringLogicConfirmation(null)}
          questionBeingMoved={questionBeingMoved}
          questions={questionsRequiringLogicConfirmation}
          questionLogic={survey?.questionLogic}
          updateSurvey={updateSurvey}
        />
      )}
    </>
  );
};

QuestionnaireList.propTypes = {
  firstErrorRef: PropTypes.object.isRequired,
  isDetailedQuestionView: PropTypes.bool.isRequired,
  maxQuestions: PropTypes.number,
  onAddQuestion: PropTypes.func.isRequired,
  onCreateQuestion: PropTypes.func.isRequired,
  onDuplicateQuestion: PropTypes.func.isRequired,
  onEditQuestion: PropTypes.func.isRequired,
  onRemoveQuestion: PropTypes.func.isRequired,
};

export default QuestionnaireList;
