import {
  readProductsGlobalFiltersData,
  readProductsLineChart,
  readProductsLocalFiltersData,
  readProductsTable,
} from '@api/forecasting';

import Insights from '../../../../components/Insights';

const Products = () => {
  return (
    <Insights
      hasSharedLocalFilters={true}
      readChartsFiltersData={readProductsLocalFiltersData}
      readGlobalFiltersData={readProductsGlobalFiltersData}
      readLineChartData={readProductsLineChart}
      readTableData={readProductsTable}
      tab="forecasting-products"
    />
  );
};

export default Products;
