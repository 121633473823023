import PropTypes from 'prop-types';

import { Accordion, OverlayTriggerTooltip, Pill } from '@utilities';

import { getPromptPillTooltip, getPromptPillValue } from '../../../../utilities/helpers';

import styles from '../../../../../../../../components/Prompts/components/AdvancedPrompts/_index.module.scss';

const GroupAdvancedPrompts = ({
  activePrompt,
  advancedPrompts = [],
  group,
  isCompleted,
  onClearAnswer,
  setActivePrompt,
}) => {
  const { promptAnswers } = group;

  return (
    <Accordion
      className={styles['advanced-prompts']}
      id="advanced-prompts-header"
      label="Advanced Options"
    >
      <ul className={styles['advanced-prompts-list']}>
        {advancedPrompts.map((prompt) => {
          const currentPromptAnswers = promptAnswers.filter(
            ({ _delete, promptId }) => !_delete && promptId === prompt.id
          );
          const tooltip = getPromptPillTooltip({ prompt, promptAnswers });
          const value = getPromptPillValue({ prompt, promptAnswers });

          return (
            <li key={`advanced-prompt-${prompt.id}`}>
              <span>{prompt?.name}:</span>
              <span>
                <OverlayTriggerTooltip content={tooltip} isFullWidth>
                  <Pill
                    isActive={prompt === activePrompt}
                    isCompleted={isCompleted}
                    isModified={currentPromptAnswers.length > 0}
                    isRequired={prompt?.isRequired}
                    onClick={() => setActivePrompt(prompt)}
                    onClose={() => onClearAnswer(prompt?.id)}
                    value={value}
                  />
                </OverlayTriggerTooltip>
              </span>
            </li>
          );
        })}
      </ul>
    </Accordion>
  );
};

GroupAdvancedPrompts.propTypes = {
  activePrompt: PropTypes.object,
  advancedPrompts: PropTypes.array,
  group: PropTypes.object.isRequired,
  isCompleted: PropTypes.bool,
  onClearAnswer: PropTypes.func,
  setActivePrompt: PropTypes.func,
};

export default GroupAdvancedPrompts;
