import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { nanoid } from 'nanoid';

import styles from './_index.module.scss';

export const renderTwoNodesConnector = (xs = 2, offset = 0) => {
  return (
    <>
      <Row key={nanoid()}>
        <Col className={styles['connector-right-border']} xs={{ span: xs, offset }}>
          &nbsp;
        </Col>
      </Row>
      <Row key={nanoid()}>
        <Col className={styles['connector-right-border']} xs={{ span: xs, offset }}>
          &nbsp;
        </Col>
      </Row>
    </>
  );
};

export const renderRightTwoNodesConnector = (xs = 2, offset = 0) => {
  return (
    <>
      <Row key={nanoid()}>
        <Col className={styles['connector-right-border']} xs={{ span: xs, offset }}>
          &nbsp;
        </Col>
      </Row>
      <Row key={nanoid()}>
        <Col className={styles['connector-right-border']} xs={{ span: xs, offset }}>
          &nbsp;
        </Col>
      </Row>
    </>
  );
};

export const renderDoubleTwoNodesConnector = (
  xsCol1 = 1,
  xsCol2 = 1,
  xsCol1Offset = 0,
  xsCol2Offset = 0
) => {
  return (
    <>
      <Row key={nanoid()}>
        <Col
          className={styles['connector-right-border']}
          xs={{ span: xsCol1, offset: xsCol1Offset }}
        >
          &nbsp;
        </Col>
        <Col
          className={styles['connector-right-border']}
          xs={{ span: xsCol2, offset: xsCol2Offset }}
        >
          &nbsp;
        </Col>
      </Row>
      <Row key={nanoid()}>
        <Col
          className={styles['connector-right-border']}
          xs={{ span: xsCol1, offset: xsCol1Offset }}
        >
          &nbsp;
        </Col>
        <Col
          className={styles['connector-right-border']}
          xs={{ span: xsCol2, offset: xsCol2Offset }}
        >
          &nbsp;
        </Col>
      </Row>
    </>
  );
};

export const renderDoubleThreeNodesConnector = (
  xsRow1Col1 = 2,
  xsRow1Col2 = 2,
  xsRow1Col3 = 2,
  xsRow1Col4 = 2,
  xsRow2Col1 = 4,
  xsRow2Col2 = 4,
  xsRow1Col1Offset = 1,
  xsRow1Col2Offset = 0,
  xsRow1Col3Offset = 2,
  xsRow1Col4Offset = 0,
  xsRow2Col1Offset = 1,
  xsRow2Col2Offset = 2
) => {
  return (
    <>
      <Row key={nanoid()}>
        <Col
          className={classNames(
            styles['connector-bottom-border'],
            styles['connector-right-border']
          )}
          xs={{ span: xsRow1Col1, offset: xsRow1Col1Offset }}
        >
          &nbsp;
        </Col>
        <Col
          className={styles['connector-bottom-border']}
          xs={{ span: xsRow1Col2, offset: xsRow1Col2Offset }}
        >
          &nbsp;
        </Col>
        <Col
          className={classNames(
            styles['connector-bottom-border'],
            styles['connector-right-border']
          )}
          xs={{ span: xsRow1Col3, offset: xsRow1Col3Offset }}
        >
          &nbsp;
        </Col>
        <Col
          className={styles['connector-bottom-border']}
          xs={{ span: xsRow1Col4, offset: xsRow1Col4Offset }}
        >
          &nbsp;
        </Col>
      </Row>
      <Row key={nanoid()}>
        <Col
          className={classNames(styles['connector-left-border'], styles['connector-right-border'])}
          xs={{ span: xsRow2Col1, offset: xsRow2Col1Offset }}
        >
          &nbsp;
        </Col>
        <Col
          className={classNames(styles['connector-left-border'], styles['connector-right-border'])}
          xs={{ span: xsRow2Col2, offset: xsRow2Col2Offset }}
        >
          &nbsp;
        </Col>
      </Row>
    </>
  );
};

export const renderThreeNodesConnector = (
  xsRow1Col1 = 2,
  xsRow1Col2 = 2,
  xsRow1Col1Offset = 0,
  xsRow2Col1 = 4,
  xsRow2Col1Offset = 0
) => {
  return (
    <>
      <Row key={nanoid()}>
        <Col
          className={classNames(
            styles['connector-right-border'],
            styles['connector-bottom-border']
          )}
          xs={{ span: xsRow1Col1, offset: xsRow1Col1Offset }}
        >
          &nbsp;
        </Col>
        <Col className={styles['connector-bottom-border']} xs={xsRow1Col2}>
          &nbsp;
        </Col>
      </Row>

      <Row key={nanoid()}>
        <Col
          className={classNames(styles['connector-left-border'], styles['connector-right-border'])}
          xs={{ span: xsRow2Col1, offset: xsRow2Col1Offset }}
        >
          &nbsp;
        </Col>
      </Row>
    </>
  );
};

export const renderFourNodesConnector = (offset) => {
  return (
    <>
      <Row key={nanoid()}>
        <Col
          className={classNames(
            styles['connector-right-border'],
            styles['connector-bottom-border']
          )}
          xs={{ span: 4, offset }}
        >
          &nbsp;
        </Col>
        <Col className={styles['connector-bottom-border']} xs={4}>
          &nbsp;
        </Col>
      </Row>
      <Row key={nanoid()}>
        <Col
          className={classNames(styles['connector-left-border'], styles['connector-right-border'])}
          xs={{ span: 4, offset }}
        >
          &nbsp;
        </Col>
        <Col className={styles['connector-right-border']} xs={4}>
          &nbsp;
        </Col>
      </Row>
    </>
  );
};

export const renderTripleTwoNodesConnector = (
  xsCol1 = 1,
  xsCol2 = 1,
  xsCol3 = 1,
  xsCol1Offset = 0,
  xsCol2Offset = 0,
  xsCol3Offset = 0
) => {
  return (
    <>
      <Row key={nanoid()}>
        <Col
          className={styles['connector-right-border']}
          xs={{ span: xsCol1, offset: xsCol1Offset }}
        >
          &nbsp;
        </Col>
        <Col
          className={styles['connector-right-border']}
          xs={{ span: xsCol2, offset: xsCol2Offset }}
        >
          &nbsp;
        </Col>
        <Col
          className={styles['connector-right-border']}
          xs={{ span: xsCol3, offset: xsCol3Offset }}
        >
          &nbsp;
        </Col>
      </Row>
      <Row key={nanoid()}>
        <Col
          className={styles['connector-right-border']}
          xs={{ span: xsCol1, offset: xsCol1Offset }}
        >
          &nbsp;
        </Col>
        <Col
          className={styles['connector-right-border']}
          xs={{ span: xsCol2, offset: xsCol2Offset }}
        >
          &nbsp;
        </Col>
        <Col
          className={styles['connector-right-border']}
          xs={{ span: xsCol3, offset: xsCol3Offset }}
        >
          &nbsp;
        </Col>
      </Row>
    </>
  );
};
