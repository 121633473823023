import { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useGate } from 'statsig-react';

import { isVerifiedVoices } from '@helpers';
import { Error, LoaderPage, MaintenanceView } from '@utilities';
import Module from '../../components/Layout/components/Module';
import ModuleList from '../../components/ModuleList';

import { GATES } from '@constants';
import { mapSurveyTypeToRoute, SURVEY_TABS } from './utilities/helpers';
import { MAINTENANCE_MESSAGE, readInstantSurveyTypes } from '@api/instant_survey';

import { ReactComponent as NumeratorLogoVerifiedVoices } from '../../components/Header/assets/verified-voices-logo.svg';
import { ReactComponent as NumeratorLogo } from '../../components/Header/assets/data-wave-logo.svg';

const Surveys = () => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [surveyRoutes, setSurveyRoutes] = useState([]);

  const { isLoading: isStatsigLoading, value: isInstantSurveyMaintenance } = useGate(
    GATES.INSTANT_SURVEY_MAINTENANCE,
  );
  const requestRef = useRef(null);

  useEffect(() => {
    if (!isStatsigLoading && !isInstantSurveyMaintenance) {
      getSurveyTypes();
    }

    return () => {
      requestRef.current?.abort();
    };
  }, [isStatsigLoading]);

  const getSurveyTypes = async () => {
    requestRef.current = new AbortController();

    try {
      const { results } = await readInstantSurveyTypes({ signal: requestRef.current.signal });
      const surveyTypes = results
        .filter(({ label }) => label === SURVEY_TABS[0].id)
        .map(mapSurveyTypeToRoute);
      const surveyLabs = results
        .filter(({ label }) => label === SURVEY_TABS[1].id)
        .map(mapSurveyTypeToRoute);

      setSurveyRoutes([
        {
          ...SURVEY_TABS[0],
          routes: surveyTypes,
        },
        ...(surveyLabs.length > 0
          ? [
              {
                ...SURVEY_TABS[1],
                routes: surveyLabs,
              },
            ]
          : []),
      ]);
    } catch (error) {
      if (!requestRef.current.signal.aborted) {
        console.log('error', error);
        setError(error);
      }
    }
    setIsLoading(false);
  };

  if (isInstantSurveyMaintenance) {
    return (
      <MaintenanceView
        heading={MAINTENANCE_MESSAGE}
        logo={isVerifiedVoices() ? <NumeratorLogoVerifiedVoices /> : <NumeratorLogo />}
      />
    );
  }
  if (isLoading || isStatsigLoading) return <LoaderPage />;
  if (error) {
    if (error?.code === 'ERR_NETWORK')
      return (
        <Error
          description="Apologies, but our Instant Survey option is currently unavailable. If the problem persists please contact your consultant."
          header="Service Unavailable"
          status={404}
        />
      );
    return <Error />;
  }

  return (
    <Module route={{ routes: surveyRoutes }}>
      <Routes>
        {surveyRoutes.map((route) => (
          <Route
            element={<ModuleList routes={route.routes} type="survey" />}
            key={route.id}
            path={route.path}
          />
        ))}
        <Route element={<Navigate replace to={surveyRoutes[0].path} />} path="*" />
      </Routes>
    </Module>
  );
};

export default Surveys;
