import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userUpdateEmailPreferencesRequested } from '@redux/slices/user';
import { Button, Checkbox } from '@utilities';

import styles from './_index.module.scss';

const Email = () => {
  const user = useSelector((state) => state?.user?.data);

  const [emailOptions, setEmailOptions] = useState({
    comments: user?.emailPreferences?.comments,
    schedules: user?.emailPreferences?.schedules,
    share: user?.emailPreferences?.share,
  });
  const [isUpdatingEmailPreferences, setIsUpdatingEmailPreferences] = useState(false);

  const dispatch = useDispatch();

  const applyChanges = async () => {
    setIsUpdatingEmailPreferences(true);

    try {
      await dispatch(
        userUpdateEmailPreferencesRequested({
          emailOptions,
          userId: user?.id,
        })
      );

      setIsUpdatingEmailPreferences(false);
    } catch (error) {
      console.error(error, `Could not update user's email preferences`);
    }
  };

  const isDisabled =
    user?.emailPreferences?.comments === emailOptions.comments &&
    user?.emailPreferences?.schedules === emailOptions.schedules &&
    user?.emailPreferences?.share === emailOptions.share;

  return (
    <ul className={styles['email']}>
      <ul>
        <li>
          <h4>Mentions</h4>
          <Checkbox
            data-testid="email-has-comments"
            isChecked={emailOptions?.comments}
            label="You were mentioned in a report comment"
            onChange={() =>
              setEmailOptions({
                ...emailOptions,
                comments: !emailOptions?.comments,
              })
            }
            value={emailOptions?.comments}
          />
        </li>
        <li>
          <h4>Sharing</h4>
          <ul className={styles['email-sharing-email-preferences']}>
            <li>
              <Checkbox
                data-testid="email-has-something-shared"
                isChecked={emailOptions?.share}
                label="A report, group, or folder was shared with you"
                onChange={() =>
                  setEmailOptions({
                    ...emailOptions,
                    share: !emailOptions?.share,
                  })
                }
                value={emailOptions?.share}
              />
            </li>
          </ul>
        </li>
        <li>
          <h4>Automated Reports</h4>
          <Checkbox
            data-testid="email-has-automated-reports"
            isChecked={emailOptions?.schedules}
            label="Your automated report is processing"
            onChange={() =>
              setEmailOptions({
                ...emailOptions,
                schedules: !emailOptions?.schedules,
              })
            }
            value={emailOptions?.schedules}
          />
        </li>
      </ul>
      <div className={styles['email-actions']}>
        <div className={styles['email-actions-toolbar']}>
          <Button
            data-testid="email-apply-changes"
            isDisabled={isDisabled}
            isLoading={isUpdatingEmailPreferences}
            onClick={applyChanges}
            text="Save"
          />
        </div>
      </div>
    </ul>
  );
};

export default Email;
