import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import {
  AISummaryIcon,
  Announcement,
  GPTIcon,
  IconButton,
  ThumbsDownIcon,
  ThumbsUpIcon,
} from '@utilities';
import { saveFeedback } from '@api/feedback';

import styles from './_index.module.scss';

const KeyTakeaways = ({ data, isPublic, tabId }) => {
  const [isSavingFeedback, setIsSavingFeedback] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(data?.like);

  const wasHelpful = selectedFeedback === true;
  const wasNotHelpful = selectedFeedback === false;

  const handleFeedback = async (feedback) => {
    if (isSavingFeedback) return;

    setIsSavingFeedback(true);
    try {
      await saveFeedback({
        like: feedback === selectedFeedback ? null : feedback,
        tabId,
      });

      setSelectedFeedback(feedback === selectedFeedback ? null : feedback);
    } catch (error) {
      console.error('Failed to save feedback:', error);
    } finally {
      setIsSavingFeedback(false);
    }
  };

  const footer = isPublic ? null : (
    <div className={styles['key-takeaways-footer']}>
      <GPTIcon />
      <p>
        Generated by Numerator AI<sup>BETA</sup>. Was this helpful?
      </p>
      <IconButton
        className={wasHelpful ? styles['active'] : ''}
        onClick={() => handleFeedback(true)}
        tooltip="This was helpful"
      >
        <ThumbsUpIcon />
      </IconButton>
      <IconButton.Danger
        className={wasNotHelpful ? styles['active'] : ''}
        onClick={() => handleFeedback(false)}
        tooltip="This was not helpful"
      >
        <ThumbsDownIcon />
      </IconButton.Danger>
    </div>
  );

  return (
    <Announcement
      footer={footer}
      hasClose
      header="Key Takeaways"
      icon={<AISummaryIcon />}
      text={<ReactMarkdown children={data?.text} />}
      variant="ai"
    />
  );
};

KeyTakeaways.propTypes = {
  data: PropTypes.object,
  isPublic: PropTypes.bool,
  tabId: PropTypes.number,
};

export default KeyTakeaways;
