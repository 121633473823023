import {
  readDemographicsBarChart,
  readDemographicsChartsFiltersData,
  readDemographicsGlobalFiltersData,
} from '@api/dashboards';

import Insights from '../../../../components/Insights';

const Demographics = () => {
  return (
    <Insights
      readBarChartData={readDemographicsBarChart}
      readChartsFiltersData={readDemographicsChartsFiltersData}
      readGlobalFiltersData={readDemographicsGlobalFiltersData}
      tab="dashboard-demographics"
    />
  );
};

export default Demographics;
