import PropTypes from 'prop-types';

import { formatData } from '../../../../helpers/data_format';

const MetadataItem = ({ format, name, value }) => {
  return (
    <li>
      <span>{name}</span>
      <span>{formatData({ format, value })}</span>
    </li>
  );
};

MetadataItem.propTypes = {
  format: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default MetadataItem;
