/**
 * gets boolean value to determine if an entry is valid
 * @param  {Object} entry
 * @return {Boolean}
 */
export const isEntryValid = (entry) => {
  return (
    entry.hasOwnProperty('endDate') &&
    entry?.endDate !== null &&
    entry.hasOwnProperty('runOnDate') &&
    entry?.runOnDate !== null &&
    entry.hasOwnProperty('startDate') &&
    entry?.startDate !== null &&
    entry.hasOwnProperty('name') &&
    entry?.name?.trim() !== ''
  );
};
