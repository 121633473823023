import { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';

export const useDebounce = (
  defaultValue,
  delay = 500,
  options = { leading: false, trailing: true }
) => {
  const [value, setValueImmediately] = useState(defaultValue);
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [signal, setSignal] = useState(Date.now());

  const setValue = useCallback((value) => {
    setValueImmediately(value);
    setIsDebouncing(true);
    triggerUpdate();
  }, []);

  const triggerUpdate = useCallback(
    debounce(
      () => {
        setIsDebouncing(false);
        setSignal(Date.now());
      },
      delay,
      options
    ),
    []
  );

  return [
    value,
    setValue,
    {
      signal,
      isDebouncing,
    },
  ];
};
