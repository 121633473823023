import {
  COLOR_COBALT,
  COLOR_PURPLE,
  COLOR_DEEPTEAL,
  COLOR_NUMERATOR_GREEN,
  COLOR_PINK,
  COLOR_SAPPHIRE,
  COLOR_YELLOW,
} from '../../../helpers/colors';

/**
 * venn diagram specific configurations
 * @type {Object}
 */
export const vennDiagramConfiguration = {
  colors: [
    COLOR_NUMERATOR_GREEN,
    COLOR_SAPPHIRE,
    COLOR_PURPLE,
    COLOR_YELLOW,
    COLOR_DEEPTEAL,
    COLOR_PINK,
    COLOR_COBALT,
  ],
  legend: {
    labelFormat: '{label}',
  },
  series: [{ legendType: 'point', name: 'venn', showInLegend: true }],
  tooltip: {
    formatter: function () {
      const label = this?.point?.label;
      const name = this?.point?.name || '';

      return label ? `${label}: ${name}` : name;
    },
  },
};
