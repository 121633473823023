import axios from './api';

import { transformInstantSurveyType, transformInstantSurveyTypes } from './utilities/helpers';

/**
 * read survey types
 * @return {Promise}
 */
export const readInstantSurveyTypes = async () => {
  return axios({
    method: 'get',
    url: '/instantsurveytype',
  }).then(({ data }) => transformInstantSurveyTypes(data));
};

/**
 * read survey type
 * @param {Object} options
 * @param {String} options.id
 * @return {Promise}
 */
export const readInstantSurveyType = async ({ id }) => {
  return axios({
    method: 'get',
    url: `/instantsurveytype/${id}`,
  }).then(({ data }) => transformInstantSurveyType(data));
};
