import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { CopyIcon, IconCTA, OverlayTriggerTooltip } from '@utilities';

const Copy = ({
  hasCustomAttribute,
  hasException,
  hasFrozen,
  hasJoined,
  hasPremiumPG,
  hasSmart,
  isHoverAction,
  redirect,
  selectedRows,
}) => {
  const navigate = useNavigate();

  const isVisible =
    selectedRows.length === 1 &&
    !hasException &&
    !hasCustomAttribute &&
    !hasFrozen &&
    !hasJoined &&
    !hasPremiumPG &&
    !hasSmart;

  if (!isVisible) return null;

  return (
    <OverlayTriggerTooltip content={isHoverAction ? 'Copy' : ''}>
      <IconCTA
        data-testid="copy-cta"
        icon={<CopyIcon />}
        onClick={() => navigate(`${redirect}?id=${selectedRows[0]?.id}`)}
        text={isHoverAction ? '' : 'Copy'}
      />
    </OverlayTriggerTooltip>
  );
};

Copy.propTypes = {
  hasCustomAttribute: PropTypes.bool,
  hasException: PropTypes.bool,
  hasFrozen: PropTypes.bool,
  hasJoined: PropTypes.bool,
  hasPremiumPG: PropTypes.bool,
  hasSmart: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  redirect: PropTypes.string,
  selectedRows: PropTypes.array,
};

export default Copy;
