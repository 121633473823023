import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Tooltip as BootstrapTooltip } from 'react-bootstrap';

import styles from './_index.module.scss';

const Tooltip = forwardRef(({ className, children, isFullWidth, ...props }, ref) => {
  return (
    <BootstrapTooltip
      className={classNames(
        styles['tooltip'],
        {
          [styles['is-full-width']]: isFullWidth,
        },
        className
      )}
      {...props}
      ref={ref}
    >
      {children}
    </BootstrapTooltip>
  );
});

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isFullWidth: PropTypes.bool,
};

export default Tooltip;
