import { useEffect, useRef, useState } from 'react';
import createPersistedState from 'use-persisted-state';

import { MoonIcon, SlidersIcon, SunIcon } from '../../icons';

export const THEMES = [
  {
    icon: <SunIcon />,
    label: 'Light',
    value: 'light',
  },
  {
    icon: <MoonIcon />,
    label: 'Dark',
    value: 'dark',
  },
  {
    icon: <SlidersIcon />,
    label: 'Match System',
    value: 'system',
  },
];

export const THEMES_DARK = THEMES[1];
export const THEMES_LIGHT = THEMES[0];
export const THEMES_SYSTEM = THEMES[2];

export const THEMES_DEFAULT = THEMES_LIGHT;

const SYSTEM_THEME_OPTIONS = [THEMES_DARK.value, THEMES_LIGHT.value, 'no-preference'];

const getCurrentSystemTheme = () => {
  const systemTheme = SYSTEM_THEME_OPTIONS.find(
    (themeOption) => window.matchMedia(`(prefers-color-scheme: ${themeOption})`).matches
  );
  return !systemTheme || systemTheme === SYSTEM_THEME_OPTIONS[2]
    ? THEMES_DEFAULT.value
    : systemTheme;
};

const useThemeState = createPersistedState('theme');

export const useThemePreference = () => {
  const [themePreference, setThemePreference] = useThemeState();

  return [themePreference, setThemePreference];
};

export const useTheme = () => {
  const [systemThemePreference, setSystemThemePreference] = useState(getCurrentSystemTheme());
  const [theme, setTheme] = useState();
  const [themePreference] = useThemePreference();

  const matchesRef = useRef();

  useEffect(() => {
    setTheme(
      themePreference === THEMES_SYSTEM.value
        ? systemThemePreference
        : themePreference || THEMES_DEFAULT.value
    );
  }, [systemThemePreference, themePreference]);

  useEffect(() => {
    matchesRef.current = window.matchMedia(`(prefers-color-scheme: ${systemThemePreference})`);
    matchesRef.current.addEventListener('change', onSchemeChange);

    function onSchemeChange(event) {
      if (!event.matches) {
        this.removeEventListener('change', onSchemeChange);
        const newSystemTheme = getCurrentSystemTheme();
        setSystemThemePreference(newSystemTheme);

        matchesRef.current = window.matchMedia(`(prefers-color-scheme: ${newSystemTheme})`);
        matchesRef.current.addEventListener('change', onSchemeChange);
      }
    }

    return () => {
      matchesRef.current.removeEventListener('change', onSchemeChange);
    };
  }, []);

  return theme;
};
