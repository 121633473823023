import PropTypes from 'prop-types';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';

import { barChartConfiguration } from './utilities/helpers';

const BarChart = ({ series, xAxis, yAxis, ...props }) => {
  return (
    <Chart
      options={merge({}, barChartConfiguration, { series, type: 'bar', xAxis, yAxis, ...props })}
      {...props}
    />
  );
};

BarChart.propTypes = {
  series: PropTypes.array,
  xAxis: PropTypes.object,
  yAxis: PropTypes.object,
};

export default BarChart;
