import PropTypes from 'prop-types';

import classNames from 'classnames';

import Card from '../Card';
import { CheckboxIcon, CheckboxFilledIcon, RadioIcon, RadioFilledIcon } from '../../icons/ui';

import styles from './_index.module.scss';

const SelectableCard = ({
  details,
  error,
  header,
  helperText,
  isActive,
  isDisabled,
  isMultiSelect,
  onClick,
  text,
  ...props
}) => {
  const handleKeyDown = ({ code }) => {
    if (isDisabled || !onClick) {
      return;
    }
    if (code === 'Enter' || code === 'Space') {
      onClick();
    }
  };

  const getCurrentIcon = () => {
    if (isActive) {
      return isMultiSelect ? <CheckboxFilledIcon /> : <RadioFilledIcon />;
    }
    return isMultiSelect ? <CheckboxIcon /> : <RadioIcon />;
  };

  return (
    <>
      <Card
        aria-disabled={isDisabled}
        aria-pressed={isActive}
        className={classNames(styles['selectable-card'], {
          [styles['is-active']]: isActive,
          [styles['is-clickable']]: !!onClick,
        })}
        onClick={isDisabled ? undefined : onClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={isDisabled ? -1 : 0}
        {...props}
      >
        {helperText && <p className={styles['selectable-card-helper-text']}>{helperText}</p>}
        <div className={styles['selectable-card-content']}>
          <div className={styles['selectable-card-icon']}>{getCurrentIcon()}</div>
          <div>
            {header && <h5 className={styles['selectable-card-header']}>{header}</h5>}
            {text && (
              <p
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            )}
            {details && (
              <p
                className={styles['selectable-card-details']}
                dangerouslySetInnerHTML={{
                  __html: details,
                }}
              />
            )}
          </div>
        </div>
      </Card>
      {error && <div className={styles['selectable-card-error']}>{error}</div>}
    </>
  );
};

SelectableCard.propTypes = {
  details: PropTypes.node,
  error: PropTypes.node,
  header: PropTypes.node,
  helperText: PropTypes.node,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.node,
};

export default SelectableCard;
