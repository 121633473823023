import PropTypes from 'prop-types';
import venn from 'highcharts/modules/venn';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';

import { vennDiagramConfiguration } from './utilities/helpers';

const VennDiagram = ({ data, ...props }) => {
  return (
    <Chart
      highchartsModule={venn}
      options={merge({}, vennDiagramConfiguration, {
        series: [{ data }],
        type: 'venn',
        ...props,
      })}
      {...props}
    />
  );
};

VennDiagram.defaultProps = {
  data: [],
};

VennDiagram.propTypes = {
  data: PropTypes.array.isRequired,
};

export default VennDiagram;
