import PropTypes from 'prop-types';

import { HelpIcon } from '../../../../icons';

import styles from './_index.module.scss';

const SuggestionsWrapper = ({ children, description, helpUrl, title }) => {
  return (
    <>
      <div className={styles['suggestions-wrapper-header']}>
        {helpUrl && (
          <a aria-label="Help" href={helpUrl} rel="noreferrer" target="_blank">
            <HelpIcon />
          </a>
        )}
        <h4>{title}</h4>
        {description && <p>{description}</p>}
      </div>
      <div className={styles['suggestions-wrapper-list']}>{children}</div>
    </>
  );
};

SuggestionsWrapper.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string,
  helpUrl: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SuggestionsWrapper;
