import PropTypes from 'prop-types';

import styles from './_index.module.scss';

const Orders = ({ isJoiningThreeGroups }) => {
  return (
    <ul className={styles['orders']}>
      <li>A.</li>
      <li>B.</li>
      {isJoiningThreeGroups && <li>C.</li>}
    </ul>
  );
};

Orders.propTypes = {
  isJoiningThreeGroups: PropTypes.bool,
};

export default Orders;
