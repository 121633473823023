import styles from './_index.module.scss';

import { isVerifiedVoices } from '@helpers';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const isSurvey = location?.pathname?.includes('survey');
  const isVerifiedVoicesSubdomain = isVerifiedVoices();

  const footerText =
    isVerifiedVoicesSubdomain || isSurvey
      ? 'Powered by Numerator Verified Voices'
      : 'Powered by Numerator OmniPanel';

  return <div className={styles['footer']}>{footerText}</div>;
};

export default Footer;
