/**
 * used to increase readability for custom group status
 * @returns {Status}
 */
export const customGroupStatus = {
  QUEUED: 1,
  STARTED: 2,
  COMPLETED: 3,
  EXCEPTION: 4,
};

/**
 * gets pill value
 * @param  {Array} options.products
 * @return {string|null}
 */
export const getPillValue = ({ firstProductName, products, type }) => {
  if (!products || products.length === 0) {
    return `Any ${type}`;
  }

  if (products.length === 1) {
    return firstProductName;
  }

  return `${firstProductName} or ${products.length - 1} more`;
};

/**
 * gets tooltip content for pill based on products
 * @param  {Array} options.products
 * @return {JSX.Element|null}
 */
export const getPillTooltip = ({ products }) => {
  if (!products || products.length < 2) {
    return null;
  }

  return products.map((product, index) => (
    <div key={`product-${index}`}>
      <small>{product?.name || product}</small>
    </div>
  ));
};
