import PropTypes from 'prop-types';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';

import { splineChartConfiguration } from './utilities/helpers';

const SplineChart = ({ series, xAxis, yAxis, ...props }) => {
  return (
    <Chart
      options={merge({}, splineChartConfiguration, {
        series,
        type: 'spline',
        xAxis,
        yAxis,
        ...props,
      })}
      {...props}
    />
  );
};

SplineChart.propTypes = {
  series: PropTypes.array,
  xAxis: PropTypes.object,
  yAxis: PropTypes.object,
};

export default SplineChart;
