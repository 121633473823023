import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  BarChart,
  Error,
  formatData,
  LineChart,
  OverlayTriggerTooltip,
  ResetFilterIcon,
  Select,
  Toolbar,
  tooltipFormatter,
  tooltipMultiPointFormatter,
} from '@utilities';

import styles from './_index.module.scss';

const Charts = ({
  barChartData,
  chartsFilters,
  chartsTitle,
  hasFiltersHistory,
  handleResetFiltersHistory,
  isUpdatingChartsFilters,
  isUpdatingCharts,
  isUpdatingGlobal,
  lineChartData,
  onChartCallback,
  onSelectedChartsFilterChange,
  selectedChartsFilters,
}) => {
  const CHART_MAX_HEIGHT = 400;

  if (!chartsFilters || chartsFilters.length === 0)
    return (
      <Error
        description="There's insufficient sample based on the selection provided."
        header="Insufficient Sample"
        status="failed-report"
      />
    );

  const groupedFilters = chartsFilters?.reduce((acc, filter) => {
    if (!acc[filter?.rowNumber]) {
      acc[filter?.rowNumber] = [];
    }
    acc[filter?.rowNumber].push(filter);
    return acc;
  }, {});

  const handleChartRenderEvent = ({ chart, textElements }) => {
    textElements.forEach((text) => {
      if (text?.textStr === 'N/A') {
        text.destroy();
      }
    });

    chart.series[0]?.points?.forEach((point) => {
      if (point.y === null) {
        if (!isNaN(point.barX)) {
          const text = chart?.renderer
            ?.text('N/A', -999, -999)
            .css({
              'font-weight': 'bold',
            })
            .add();

          text.attr({
            x: chart.plotLeft + text.getBBox().width / 4,
            y: chart.plotHeight - point.barX + text.getBBox().height / 4,
          });

          textElements.push(text);
        }
      }
    });
  };

  const filterRows = Object.values(groupedFilters);

  return (
    <div>
      <Toolbar
        tools={[
          <OverlayTriggerTooltip
            aria-label="Restore default selections"
            content={hasFiltersHistory ? 'Restore default selections' : 'Defaults selected'}
          >
            <button
              data-testid="reset-filter-button"
              disabled={!hasFiltersHistory}
              onClick={handleResetFiltersHistory}
            >
              <ResetFilterIcon />
            </button>
          </OverlayTriggerTooltip>,
        ]}
      >
        {chartsTitle && <h3 className={styles['charts-title']}>{chartsTitle}</h3>}
      </Toolbar>
      <div className={styles['charts-filters']}>
        {filterRows?.map((filterRow, rowIndex) => (
          <div key={`filter-row-${rowIndex}`}>
            {filterRow?.map((filter, index) => {
              const value = filter?.isMulti
                ? selectedChartsFilters[filter?.id]?.sort((a, b) => a.label.localeCompare(b.label))
                : selectedChartsFilters[filter?.id];

              return (
                <Select
                  className={classNames(styles['charts-filter'], {
                    [styles['is-multi']]: filter?.isMulti,
                  })}
                  data-testid={`charts-filter-${filter?.id}`}
                  isDisabled={isUpdatingGlobal || isUpdatingChartsFilters}
                  isLoading={isUpdatingGlobal || isUpdatingCharts || isUpdatingChartsFilters}
                  isMulti={filter?.isMulti}
                  key={`filter-${index}`}
                  label={filter?.label}
                  onChange={(value) => onSelectedChartsFilterChange({ ...filter, value })}
                  options={filter?.options}
                  value={value}
                />
              );
            })}
          </div>
        ))}
      </div>
      <div className={styles['charts']}>
        {lineChartData && (
          <LineChart
            chartTitle={lineChartData?.title}
            hasColorMap
            hasExportButtonText={false}
            hasLegend={false}
            isUpdating={isUpdatingGlobal || isUpdatingCharts || isUpdatingChartsFilters}
            maxHeight={CHART_MAX_HEIGHT}
            onChartCallback={onChartCallback}
            series={lineChartData?.series}
            tooltip={{
              formatter: function () {
                return tooltipMultiPointFormatter({
                  data: this,
                  format: lineChartData?.format,
                });
              },
            }}
            xAxis={lineChartData?.xAxis}
            yAxis={{
              labels: {
                formatter: function () {
                  return formatData({ format: lineChartData?.format, value: this.value });
                },
              },
            }}
          />
        )}
        {barChartData && (
          <BarChart
            chartTitle={barChartData?.title}
            handleChartRenderEvent={handleChartRenderEvent}
            hasColorMap
            hasExportButtonText={false}
            hasLegend={false}
            isUpdating={isUpdatingGlobal || isUpdatingCharts || isUpdatingChartsFilters}
            maxHeight={CHART_MAX_HEIGHT}
            onChartCallback={onChartCallback}
            plotOptions={{
              bar: {
                dataLabels: {
                  formatter: function () {
                    return formatData({ format: barChartData?.format, value: this.y });
                  },
                },
              },
            }}
            series={barChartData?.series}
            tooltip={{
              formatter: function () {
                return tooltipFormatter({ format: barChartData?.format, data: this });
              },
            }}
            xAxis={barChartData?.xAxis}
            yAxis={{
              labels: {
                formatter: function () {
                  return formatData({ format: barChartData?.format, value: this.value });
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

Charts.propTypes = {
  barChartData: PropTypes.object,
  chartsFilters: PropTypes.array,
  chartsTitle: PropTypes.string,
  hasFiltersHistory: PropTypes.bool,
  handleResetFiltersHistory: PropTypes.func,
  isUpdatingChartsFilters: PropTypes.bool,
  isUpdatingCharts: PropTypes.bool,
  isUpdatingGlobal: PropTypes.bool,
  lineChartData: PropTypes.object,
  onChartCallback: PropTypes.func,
  onSelectedChartsFilterChange: PropTypes.func,
  selectedChartsFilters: PropTypes.object,
};

export default Charts;
