import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { FavoriteToggle, OverlayTriggerTooltip } from '@utilities';

import { updateFavoriteReport } from '@api/reports';
import { userUpdateFavoriteReport } from '@redux/slices/user';

const Favorite = ({ isFavorite, reportId }) => {
  const userData = useSelector((state) => state?.user?.data);
  const panel = userData?.panel?.abbrev;

  const dispatch = useDispatch();

  const handleSubmitFavorite = async () => {
    try {
      await updateFavoriteReport({ isFavorite: !isFavorite, panel, reportId });
      dispatch(userUpdateFavoriteReport({ id: reportId, isFavorite: !isFavorite }));
    } catch (error) {
      console.error(error, 'Could not update favorite status.');
    }
  };

  return (
    <OverlayTriggerTooltip aria-label="Favorite" content="Favorite">
      <button data-testid="favorite-button-report-list" onClick={handleSubmitFavorite}>
        <FavoriteToggle isFavorite={isFavorite} />
      </button>
    </OverlayTriggerTooltip>
  );
};

Favorite.propTypes = {
  isFavorite: PropTypes.bool,
  reportId: PropTypes.string,
};

export default Favorite;
