import PropTypes from 'prop-types';

import {
  FolderIcon,
  HelpIcon,
  LoaderSkeleton,
  OverlayTriggerTooltip,
  truncateTextWithEllipsis,
} from '@utilities';

import styles from './_index.module.scss';

const AttributeList = ({ attributes, isLoading, setActiveAttribute }) => {
  if (isLoading)
    return (
      <LoaderSkeleton height={130}>
        <rect x="0" y="0" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="25" rx="2" ry="2" width="175" height="15" />
        <rect x="0" y="50" rx="2" ry="2" width="225" height="15" />
        <rect x="0" y="75" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="100" rx="2" ry="2" width="225" height="15" />
      </LoaderSkeleton>
    );

  return (
    <ul className={styles['attribute-list']}>
      {attributes.map((attribute, index) => {
        const attributeName = truncateTextWithEllipsis({ text: attribute?.name });

        return (
          <li key={`attribute-${index}`}>
            <button onClick={() => setActiveAttribute(attribute)}>
              <FolderIcon /> {attributeName}
            </button>
            {attribute?.description && (
              <OverlayTriggerTooltip content={attribute?.description}>
                <HelpIcon />
              </OverlayTriggerTooltip>
            )}
          </li>
        );
      })}
    </ul>
  );
};

AttributeList.propTypes = {
  attributes: PropTypes.array,
  isLoading: PropTypes.bool,
  setActiveAttribute: PropTypes.func,
};

export default AttributeList;
