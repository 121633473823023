import PropTypes from 'prop-types';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Breadcrumbs } from '@utilities';

import Layout from '../Layout';

import { getFirstAvailableRoute, getRouteComponent, hasBreadcrumbs } from '../../utilities/helpers';

const Page = ({ route, url }) => {
  const path = `${url}/${route?.path}`;

  return route?.routes.length === 0 ? (
    <>
      {hasBreadcrumbs(path) && <Breadcrumbs />}
      {getRouteComponent({ path, route })}
    </>
  ) : (
    <Layout route={route}>
      <Routes>
        {!route.layout && (
          <Route
            element={getRouteComponent({
              path,
              route,
            })}
            path=""
          />
        )}
        {route?.routes
          .filter((child) => !child.is_locked)
          .map((child) => (
            <Route
              element={getRouteComponent({
                path: `${path}/${child?.path}`,
                route: child,
              })}
              key={child?.id}
              path={child?.path}
            />
          ))}
        {route.layout && (
          <Route
            element={<Navigate replace to={getFirstAvailableRoute(route?.routes)} />}
            path="*"
          />
        )}
      </Routes>
    </Layout>
  );
};

Page.propTypes = {
  route: PropTypes.object,
  url: PropTypes.string,
};

export default Page;
