/**
 * export menu format options
 * check out the following modules if there is a need to implement more exporting functionality
 * https://code.highcharts.com/modules/export-data.js
 * https://code.highcharts.com/modules/exporting.js
 * @type {Array}
 */
export const exportMenuOptions = [
  {
    description: 'Download as PNG',
    format: 'png',
    onClick: (chart) => {
      if (chart?.current?.chart) {
        chart?.current?.chart?.exportChartLocal();
      }
    },
  },
  {
    description: 'Download as CSV',
    format: 'csv',
    onClick: (chart) => {
      if (chart?.current?.chart) {
        chart?.current?.chart?.downloadCSV();
      }
    },
  },
];
