import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import uniqBy from 'lodash/uniqBy';

import {
  Announcement,
  Breadcrumbs,
  Button,
  ModalV2 as Modal,
  Search,
  useDebounce,
} from '@utilities';
import { readSearchResults } from '@api/prompts';

import Results from '../Builder/components/Results';
import Selections from '../Builder/components/Selections';

import styles from './_index.module.scss';

const ModalPromptSearch = ({
  document,
  isSurvey,
  onClose,
  onSubmit,
  prompt,
  searchAnswers,
  searchPrompts,
  selectedAnswers,
}) => {
  const [activeAnswers, setActiveAnswers] = useState(selectedAnswers ? [...selectedAnswers] : null);
  const [activeAttribute, setActiveAttribute] = useState(
    prompt.attributes?.length === 1 ? prompt.attributes[0] : null
  );
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchingCustom, setIsSearchingCustom] = useState(false);
  const [results, setResults] = useState(null);
  const [resultsCustom, setResultsCustom] = useState(null);
  const [searchError, setSearchError] = useState(null);
  const [searchQuery, setSearchQuery, { signal, isDebouncing }] = useDebounce('');
  const searchRef = useRef(null);

  useEffect(() => {
    if (searchQuery) {
      setIsSearching(true);
      setIsSearchingCustom(true);
    } else if (!activeAttribute && !searchQuery) {
      searchRef.current?.abort();
      setResults(null);
      setResultsCustom(null);
      setIsSearching(false);
      setIsSearchingCustom(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!activeAttribute && !searchQuery) {
      setResults(null);
      setResultsCustom(null);
    } else {
      queryAttributes(
        activeAttribute ? [{ id: activeAttribute.id }] : prompt.attributes || prompt.objects
      );
    }

    return () => {
      searchRef.current?.abort();
    };
  }, [activeAttribute, signal]);

  const chunkAttributesQuery = async ({ attributeChunks, signal }) => {
    if (!attributeChunks.length) {
      return [];
    }
    const requests = attributeChunks.map((attributeChunk) =>
      readSearchResults({
        attributeIds: attributeChunk.map((attribute) => attribute?.id),
        documentId: document?.id,
        promptId: prompt.id,
        searchAnswers,
        searchPrompts,
        searchQuery,
        signal,
      })
    );
    const responses = await Promise.all(requests);

    return uniqBy(
      [].concat.apply(
        [],
        responses?.map((results) => results?.data)
      ),
      'id'
    );
  };
  const queryAttributes = async (attributes) => {
    searchRef.current?.abort();
    searchRef.current = new AbortController();
    setIsSearching(true);
    setIsSearchingCustom(true);

    // TODO: move attribute chunking to an api layer perhaps?
    const attributeChunks = chunk(
      attributes.filter((option) => !option?.isCustom),
      10
    );
    const customAttributeChunks = chunk(
      attributes.filter((option) => option?.isCustom),
      10
    );

    try {
      await Promise.all([
        chunkAttributesQuery({
          attributeChunks,
          signal: searchRef.current.signal,
        }).then((values) => {
          setResults(values);
          setIsSearching(false);
        }),
        chunkAttributesQuery({
          attributeChunks: customAttributeChunks,
          signal: searchRef.current.signal,
        }).then((values) => {
          setResultsCustom(values);
          setIsSearchingCustom(false);
        }),
      ]);
    } catch (error) {
      if (error.code !== 'ERR_CANCELED') {
        console.error(error);
        setIsSearching(false);
        setIsSearchingCustom(false);
        setSearchError(error);
      }
    }
  };

  let breadcrumbs = [{ label: prompt.name, onClick: () => setActiveAttribute(null) }];
  if (activeAttribute && prompt.attributes?.length > 1) {
    breadcrumbs.push({ label: activeAttribute?.name });
  }

  const isApplyDisabled = () => {
    return (
      (!selectedAnswers && !activeAnswers) ||
      (selectedAnswers?.length === activeAnswers?.length &&
        selectedAnswers.every(
          (answer, index) =>
            typeof answer === 'string'
              ? answer === activeAnswers[index] // string match for dates
              : answer?.id === activeAnswers[index]?.id // id matches for objects
        ))
    );
  };
  const isAtPromptLimit = activeAnswers && activeAnswers.length === prompt?.max && prompt?.max > 1;

  const onSelectAnswer = (value) => {
    if (Array.isArray(value)) {
      return setActiveAnswers(value);
    } else if (!activeAnswers || prompt?.max === 1) {
      return setActiveAnswers([value]);
    }
    const currentIndex = activeAnswers?.findIndex(({ id }) => id === value.id);
    if (currentIndex >= 0) {
      activeAnswers.splice(currentIndex, 1);
      setActiveAnswers([...activeAnswers]);
    } else if (!isAtPromptLimit) {
      setActiveAnswers([...activeAnswers, value]);
    }
  };

  const onSetAnswerInput = ({ id, inputId, value }) => {
    let updatedAnswers = [...activeAnswers];
    updatedAnswers.find((answer) => answer?.id === id).inputs[inputId].value = value;
    setActiveAnswers(updatedAnswers);
  };

  const onSetAnswerMetric = ({ id, operator, operatorInfo, value }) => {
    let updatedAnswers = [...activeAnswers];
    let updatedAnswer = updatedAnswers.find((answer) => answer?.id === id);

    updatedAnswer.operator = operator;
    updatedAnswer.operatorInfo = operatorInfo;
    updatedAnswer.value = value;
    setActiveAnswers(updatedAnswers);
  };

  return (
    <Modal
      buttons={[
        <Button
          data-testis="cancel-btn"
          id="modal-prompt-search-cancel"
          key="cancel-btn"
          onClick={onClose}
          text="Cancel"
          variant="secondary"
        />,
        <Button
          data-testid="apply-btn"
          id="modal-prompt-search-apply"
          isDisabled={isApplyDisabled()}
          key="apply-btn"
          onClick={() => onSubmit(activeAnswers)}
          text="Apply"
        />,
      ]}
      id="modal-prompt-search"
      isActive
      isScrollable
      onClose={onClose}
      size="large"
      title={prompt.name}
    >
      {searchError && (
        <Announcement
          text={
            searchError?.response?.data?.error ||
            `There was an error searching for ${activeAttribute?.name}.`
          }
          variant="error"
        />
      )}
      {prompt?.hasSearch && (
        <Search
          className={styles['modal-prompt-search-bar']}
          isSearching={(isDebouncing || isSearching || isSearchingCustom) && searchQuery !== ''}
          onChange={setSearchQuery}
          value={searchQuery}
        />
      )}
      {isAtPromptLimit && (
        <Announcement
          text={`Maximum number of answers (${prompt.max}) has been reached.`}
          variant="warn"
        />
      )}
      <Breadcrumbs options={breadcrumbs} />
      <div className={styles['modal-prompt-search-results']} data-testid="results">
        <Results
          activeAnswers={activeAnswers}
          activeAttribute={activeAttribute}
          activePrompt={prompt}
          hasActivePromptLimit={isAtPromptLimit}
          isLoading={isSearching}
          isLoadingCustom={isSearchingCustom}
          isSurvey={isSurvey}
          onSelectAnswer={onSelectAnswer}
          onSetAnswerInput={onSetAnswerInput}
          onSetAnswerMetric={onSetAnswerMetric}
          onSortAnswers={setActiveAnswers}
          results={results}
          resultsCustom={resultsCustom}
          setActiveAttribute={setActiveAttribute}
        />
        {activeAnswers?.length > 0 && prompt?.max !== 1 && (
          <Selections
            activeAnswers={activeAnswers}
            activePrompt={prompt}
            onSelectAnswer={onSelectAnswer}
            onSortAnswers={setActiveAnswers}
          />
        )}
      </div>
    </Modal>
  );
};

ModalPromptSearch.defaultProps = {};

ModalPromptSearch.propTypes = {
  document: PropTypes.object,
  isSurvey: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  prompt: PropTypes.object.isRequired,
  searchAnswers: PropTypes.object,
  searchPrompts: PropTypes.array,
  selectedAnswers: PropTypes.array,
};

export default ModalPromptSearch;
