import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Error } from '@utilities';

import { readHomepageReport } from '@api/reports';

import RecentReports from './components/RecentReports';
import Report from './components/Report';
import ThoughtLeadership from './components/ThoughtLeadership';
import Welcome from './components/Welcome';

const Home = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [isReportCompleted, setIsReportCompleted] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const getData = async () => {
      try {
        const response = await readHomepageReport({ signal });
        setData(response?.data);
      } catch (error) {
        setError(error);
      }
    };

    getData();

    return () => controller.abort();
  }, []);

  return (
    <>
      <Welcome isReportCompleted={isReportCompleted} reportId={data?.id} />
      {error ? (
        <Error status={error?.response?.status} />
      ) : (
        <Report
          reportId={data?.id}
          onComplete={() => setIsReportCompleted(true)}
          tabId={data?.tabId}
        />
      )}
      <Container>
        <Row>
          <Col xs={6}>
            <RecentReports />
          </Col>
          <Col xs={6}>
            <ThoughtLeadership />
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Home;
