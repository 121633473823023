/**
 * get tooltip content based on returned type
 * @param  {String} type
 * @return {Node}
 */

import styles from './_index.module.scss';

export const getTooltipContent = (type) => {
  switch (type) {
    case 'truview':
      return (
        <>
          <p>
            <strong>TruView Explorer data refreshes weekly on Thursdays,</strong> with the following
            date range options:
          </p>
          <br />
          <ul className={styles['truview-date-options-list']}>
            <li>
              <strong>TruView Standard</strong> Standard 30-day lag (optimized for eCommerce
              sample).
            </li>
            <br />
            <li>
              <strong>Insights Standard</strong> Standard 2-week lag used across Insights reports.
            </li>
            <br />
            <li>
              <strong>TruView Early Read</strong> 4-day lag, with data up to the most recent Sunday.
              For the time frame between TruView Standard and Early Read, data are forecasted based
              on historical purchase behavior and should be used directionally.
            </li>
            <br />
          </ul>
        </>
      );
    case 'weekly':
      return (
        <>
          <p>
            <strong>Most Recent Weeks</strong> will give you data ending two weeks prior to most
            recent Sunday's date.
          </p>
          <br />
          <p>
            <strong>Maximize eCommerce Sample</strong> will give you data ending four weeks prior to
            most recent Sunday's date.
          </p>
        </>
      );
    case 'monthly':
      return (
        <>
          <p>
            <strong>Most Recent Month</strong> will give you data ending the latest complete month,
            regardless of sample.
          </p>
          <br />
          <p>
            <strong>Maximize Sample</strong> will pick the earliest complete month while attempting
            to maximize sample.
          </p>
          <br />
          <p>
            <strong>Maximize eCommerce Sample</strong> finds the earliest complete month while
            attempting to maximize eCommerce sample.
          </p>
        </>
      );
    case 'ytd':
      return (
        <>
          <p>
            <strong>Most Recent Weeks</strong> will give you data ending two weeks prior to most
            recent Sunday's date.
          </p>
          <br />
          <p>
            <strong>Most Recent Month</strong> will give you data ending the latest complete month,
            regardless of sample.
          </p>
          <br />
          <p>
            <strong>Maximize Sample</strong> will give you data ending four weeks prior to most
            recent Sunday's date.
          </p>
          <br />
          <p>
            <strong>Maximize eCommerce Sample</strong> finds the earliest complete month while
            attempting to maximize eCommerce sample.
          </p>
        </>
      );
    default:
      return null;
  }
};
