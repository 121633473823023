import axios from '@api';

import { transformCreateSurvey, transformSurvey, transformSurveys } from './utilities/helpers';

/**
 * complete survey stripe checkout
 * @param {Object} options
 * @param {String} options.token
 * @return {Promise}
 */
export const completeSurveyStripeCheckout = ({ token }) => {
  return axios({
    data: {
      token,
    },
    method: 'post',
    url: '/api/surveys/complete_stripe_checkout',
    withCredentials: true,
  });
};

/**
 * create atlas survey
 * @param {Object} options
 * @param {Number} options.atlasInstantSurveyRef
 * @param {Number} [options.id] if an id is provided, it will act like an update call
 * @param {String} [options.status]
 * @param {String} options.title
 * @return {Promise}
 */
export const createAtlasSurvey = async ({
  atlasInstantSurveyRef: atlas_instant_survey_ref,
  id,
  status = 'draft',
  title,
}) => {
  return axios({
    data: {
      atlas_instant_survey_ref,
      id,
      status,
      title,
    },
    method: 'post',
    url: '/api/surveys',
    withCredentials: true,
  }).then(({ data }) => transformSurvey(data));
};

/**
 * create survey
 * @param {Object} options
 * @param {String} [options.cancelUrl]
 * @param {Number} [options.id] if an id is provided, it will act like an update call
 * @param {String} [options.paymentMethod]
 * @param {String} [options.status]
 * @param {String} [options.successUrl]
 * @param {String} options.title
 * @param {Number} options.validationKey
 * @return {Promise}
 */
export const createSurvey = async ({
  cancelUrl: cancel_url,
  id,
  paymentMethod: payment_method,
  status,
  successUrl: success_url,
  title,
  validationKey: validation_pk,
}) => {
  return axios({
    data: {
      cancel_url,
      id,
      payment_method,
      status,
      success_url,
      title,
      validation_pk,
    },
    method: 'post',
    url: '/api/surveys',
    withCredentials: true,
  }).then(({ data }) => transformCreateSurvey(data));
};

/**
 * publish atlas survey
 * @param {Object} options
 * @param {Number} options.atlasInstantSurveyRef
 * @param {String} options.id
 * @param {String} [option.status]
 * @param {String} options.title
 */
export const publishAtlasSurvey = async ({
  atlasInstantSurveyRef: atlas_instant_survey_ref,
  id,
  status = null,
  title,
}) => {
  return axios({
    data: {
      atlas_instant_survey_ref,
      id,
      status,
      title,
    },
    method: 'post',
    url: '/api/surveys',
    withCredentials: true,
  }).then(({ data }) => transformCreateSurvey(data));
};

/**
 * read survey
 * @param {Object} options
 * @param {String} options.id
 * @return {Promise}
 */
export const readSurvey = ({ id }) => {
  return axios({
    method: 'get',
    url: `/api/surveys/${id}`,
    withCredentials: true,
  }).then(({ data }) => transformSurvey(data));
};

/**
 * read surveys
 * @param {Object} options
 * @param {String} [options.order]
 * @param {Number} [options.page]
 * @param {String} [options.query]
 * @param {Number} options.signal AbortController signal
 * @param {Number} [options.size]
 * @param {String} [options.sortBy]
 * @param {Array} [options.tags]
 * @return {Promise}
 */
export const readSurveys = ({
  order: order_by,
  page,
  query: search,
  signal,
  size: page_size,
  sortBy: sort_by,
  tags = [],
}) => {
  return axios({
    method: 'get',
    params: {
      ...(order_by ? { order_by } : {}),
      ...(page && page > 1 ? { page } : {}),
      ...(page_size ? { page_size } : {}),
      ...(search ? { search } : {}),
      ...(sort_by ? { sort_by } : {}),
      ...(tags.length > 0 ? { status: tags.map((tag) => tag.replace(/^is_/, '')).join() } : {}),
    },
    signal,
    transformResponse: [...axios.defaults.transformResponse, (data) => transformSurveys(data)],
    url: '/api/surveys/',
    withCredentials: true,
  });
};

/**
 * update survey
 * @param {Object} options
 * @param {String} options.id
 * @param {Object} options.survey
 * @return {Promise}
 */
export const updateSurvey = ({ id, ...survey }) => {
  return axios({
    data: survey,
    method: 'put',
    url: `/api/surveys/${id}`,
    withCredentials: true,
  });
};

/**
 * update survey title
 * @param {Object} options
 * @param {String} options.id
 * @param {String} options.name
 * @return {Promise}
 */
export const updateSurveyTitle = ({ id, name: title }) => {
  return axios({
    data: { title },
    method: 'put',
    url: `/api/surveys/${id}/rename`,
    withCredentials: true,
  });
};

/**
 * delete surveys
 * @param {Object} options
 * @param {Array} options.ids
 * @return {Promise}
 */
export const deleteSurveys = ({ ids }) => {
  return Promise.all(
    ids.map((id) =>
      axios({
        method: 'delete',
        url: `/api/surveys/${id}`,
        withCredentials: true,
      })
    )
  );
};

/**
 * request survey responses
 * @param {Object} options
 * @param {Number} options.id
 * @return {Promise}
 */
export const requestSurveyResponses = ({ id, forceNewJobRun: force_new_job_run }) => {
  return axios({
    data: { force_new_job_run },
    method: 'post',
    url: `/api/surveys/${id}/responses`,
    withCredentials: true,
  });
};
