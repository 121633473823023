import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { MinusPlusButtons, PillOverlayPopover, Radio } from '@utilities';
import { getLogicalOperators, getOperators, getOperatorValue } from '../../../../utilities/helpers';

import ProductSearch from '../ProductSearch';

import styles from './_index.module.scss';

const Madlibs = ({
  conditionLine,
  conditions,
  levels,
  lineIndex,
  linesLength,
  onAddLine,
  onRemoveLine,
  setConditions,
  type,
}) => {
  const operators = getOperators();
  const logicalOperators = getLogicalOperators();
  const [currentCondition, setCurrentCondition] = useState({
    level: conditionLine?.breakoutCondition?.level || null,
    logicalOperator: !isEmpty(conditionLine?.logicalOperator)
      ? { ...conditionLine?.logicalOperator }
      : { ...logicalOperators[0], lineId: conditionLine?.id },
    operator: conditionLine?.breakoutCondition?.operator || operators[0],
    products: conditionLine?.breakoutCondition ? conditionLine?.breakoutCondition?.elements : [],
  });

  const typeLabel = type.charAt(0).toUpperCase() + type.slice(1);

  const operatorValue = getOperatorValue(currentCondition?.operator?.id);

  useEffect(() => {
    const breakoutIndex = conditions?.breakoutConditions?.findIndex(
      (item) => item?.lineId === conditionLine?.id
    );
    const logicalOperatorIndex = conditions?.logicalOperators?.findIndex(
      (item) => item?.lineId === conditionLine?.id
    );
    const updatedBreakoutConditions = [...conditions.breakoutConditions];
    const updatedLogicalOperators = [...conditions.logicalOperators];

    if (breakoutIndex !== -1) {
      updatedBreakoutConditions[breakoutIndex] = {
        ...updatedBreakoutConditions[breakoutIndex],
        level: currentCondition?.level,
        operator: currentCondition?.operator,
        elements: currentCondition?.products,
      };
    } else {
      if (currentCondition?.products?.length > 0) {
        updatedBreakoutConditions.push({
          lineId: conditionLine?.id,
          level: currentCondition?.level,
          operator: currentCondition?.operator,
          elements: currentCondition?.products,
        });
      }
    }

    if (logicalOperatorIndex !== -1) {
      updatedLogicalOperators[logicalOperatorIndex] = currentCondition.logicalOperator;
    } else {
      updatedLogicalOperators.push({
        ...currentCondition.logicalOperator,
        lineId: conditionLine?.id,
      });
    }

    setConditions({
      breakoutConditions: updatedBreakoutConditions,
      logicalOperators: updatedLogicalOperators,
    });
  }, [currentCondition]);

  return (
    <div className={styles['madlibs']}>
      <span>(</span>
      <PillOverlayPopover header="operator" value={operatorValue}>
        <ul className={styles['madlibs-operators']}>
          {operators?.map((operator, index) => (
            <li key={index}>
              <Radio
                isChecked={operatorValue === getOperatorValue(operator?.id)}
                label={getOperatorValue(operator?.id)}
                onChange={() =>
                  setCurrentCondition((prevState) => ({
                    ...prevState,
                    operator,
                  }))
                }
                value={operatorValue}
              />
            </li>
          ))}
        </ul>
      </PillOverlayPopover>
      <span>the</span>
      <PillOverlayPopover
        header={`${typeLabel} level`}
        value={currentCondition?.level?.name || `Any ${typeLabel} Level`}
      >
        <ul className={styles['madlibs-product-level']}>
          {levels?.map((level, index) => (
            <li key={index}>
              <Radio
                isChecked={currentCondition?.level?.name === level?.name}
                label={level?.name}
                onChange={() =>
                  setCurrentCondition((prevState) => ({
                    ...prevState,
                    level,
                    products: [],
                  }))
                }
                value={level?.name}
              />
            </li>
          ))}
        </ul>
      </PillOverlayPopover>
      <ProductSearch
        currentCondition={currentCondition}
        setCurrentCondition={setCurrentCondition}
        type={type}
      />
      <span>)</span>
      {lineIndex + 1 !== linesLength && (
        <PillOverlayPopover
          header="logical operator"
          value={currentCondition?.logicalOperator?.name}
        >
          <ul className={styles['madlibs-logical-operators']}>
            {logicalOperators?.map((logicalOperator, index) => (
              <li key={index}>
                <Radio
                  isChecked={currentCondition?.logicalOperator?.name === logicalOperator?.name}
                  label={logicalOperator?.name}
                  onChange={() =>
                    setCurrentCondition((prevState) => ({
                      ...prevState,
                      logicalOperator: { ...logicalOperator, lineId: conditionLine?.id },
                    }))
                  }
                  value={logicalOperator?.name}
                />
              </li>
            ))}
          </ul>
        </PillOverlayPopover>
      )}
      <MinusPlusButtons
        hasMinusButton={linesLength !== 1}
        hasPlusButton={lineIndex + 1 === linesLength && linesLength <= 100}
        onClickMinus={() => onRemoveLine(conditionLine?.id)}
        onClickPlus={onAddLine}
      />
    </div>
  );
};

Madlibs.propTypes = {
  conditionLine: PropTypes.object,
  conditions: PropTypes.object,
  levels: PropTypes.array,
  lineIndex: PropTypes.number,
  linesLength: PropTypes.number,
  onAddLine: PropTypes.func,
  onRemoveLine: PropTypes.func,
  setConditions: PropTypes.func,
  type: PropTypes.string,
};

export default Madlibs;
