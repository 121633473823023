import axios from '@api';

import { transformEmailPreferences, transformUser, transformUsersData } from './utilities/helpers';

/**
 * reads all users in a client including current user
 * @return {Promise}
 */
export const readClientUsers = () => {
  return axios({
    method: 'get',
    transformResponse: [(data) => transformUsersData(JSON.parse(data))],
    url: '/api/client/users',
    withCredentials: true,
  });
};

/**
 * reads profile options for disciplines and industries
 * @returns {Promise}
 */
export const readProfileOptions = () => {
  return axios({
    method: 'get',
    transformResponse: [
      (data) => {
        const response = JSON.parse(data);
        return {
          disciplines: response?.discipline?.map((discipline) => ({
            label: discipline,
            value: discipline,
          })),
          industries: response?.industry?.map((industry) => ({ label: industry, value: industry })),
          proficiencies: response?.proficiency?.map((proficiency) => ({
            label: proficiency,
            value: proficiency,
          })),
        };
      },
    ],
    url: '/v2/api/users/persona-options',
    withCredentials: true,
  });
};

/**
 * Reads proxy account options to switch between accounts.
 * @param {Object} options
 * @param {string} options.searchQuery
 * @param {AbortSignal} options.signal
 * @returns {Promise}
 */
export const readProxyAccounts = ({ searchQuery, signal }) => {
  if (searchQuery.includes('_')) {
    searchQuery = searchQuery.replace(/_/g, '\\_');
  }

  return axios({
    method: 'get',
    params: { query: searchQuery },
    signal,
    transformResponse: [
      (data) => {
        const response = JSON.parse(data);
        return {
          accounts: response?.items?.map((account) => ({
            label: account.label,
            value: account.value,
          })),
        };
      },
    ],
    url: '/v2/api/proxyaccounts/search',
    withCredentials: true,
  });
};

/**
 * reads all information pertaining to currently authenticated user
 * @returns {Promise}
 */
export const readUser = () => {
  return axios({
    method: 'get',
    transformResponse: [(data) => transformUser(JSON.parse(data))],
    url: '/api/user/',
    withCredentials: true,
  });
};

/**
 * updates user to the base sso account
 * @param {Number} options.baseAccountId
 * @returns {Promise}
 */
export const switchToBaseUser = ({ baseAccountId }) => {
  return axios({
    data: {
      login: baseAccountId,
    },
    method: 'post',
    url: '/api/proxy_switchback/',
    withCredentials: true,
  });
};

/**
 * updates user to the proxy account
 * @param {Number} options.proxyAccountId
 * @returns {Promise}
 */
export const switchToProxyUser = ({ proxyAccountId }) => {
  return axios({
    data: {
      login: proxyAccountId,
    },
    method: 'post',
    url: '/api/proxy_login/',
    withCredentials: true,
  });
};

/**
 * update area of interest for a given user
 * @param {Object} options.areaOfInterest
 * @returns {Promise}
 */
export const updateAreaOfInterest = ({ areaOfInterest }) => {
  return axios({
    data: {
      areas_of_interest: areaOfInterest,
    },
    method: 'put',
    url: '/v2/api/users/areas-of-interest',
    withCredentials: true,
  });
};

/**
 * updates email preferences for a given user
 * @param {Object} options.emailOptions
 * @param {String} options.userId
 * @return {Promise}
 */
export const updateEmailPreferences = ({ emailOptions, userId }) => {
  return axios({
    data: {
      COMMENTS: emailOptions?.comments,
      SCHEDULES: emailOptions?.schedules,
      SHARE: emailOptions?.share,
    },
    method: 'put',
    transformResponse: [(data) => transformEmailPreferences(JSON.parse(data))],
    url: `/api/user/${userId}/email-preferences`,
    withCredentials: true,
  });
};

/**
 *
 * @param {String} options.id of user we intend to update
 * @param {Object} options.profile of user
 * @returns {Promise}
 */
export const updateProfile = ({ id, profile }) => {
  return axios({
    data: {
      discipline: profile?.discipline,
      industry: profile?.industry,
      job_title: profile?.jobTitle,
      proficiency: profile?.proficiency,
    },
    method: 'put',
    transformResponse: [
      (data) => {
        const response = JSON.parse(data);
        return {
          persona: {
            discipline: response?.discipline,
            industry: response?.industry,
            jobTitle: response?.job_title,
            lastUpdated: response?.persona_last_updated,
            proficiency: response?.proficiency,
          },
        };
      },
    ],
    url: `/v2/api/users/${id}/persona`,
    withCredentials: true,
  });
};
