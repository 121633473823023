import classNames from 'classnames';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import styles from './_index.module.scss';

const ProgressBar = ({ className, id = nanoid(), label, max, value = 0, ...props }) => {
  const isComplete = max > 0 && value / max >= 1;

  return (
    <>
      {label && (
        <label className={styles['progress-bar-label']} htmlFor={id}>
          {label}
        </label>
      )}
      <progress
        className={classNames(styles['progress-bar'], className, {
          [styles['is-complete']]: isComplete,
        })}
        id={id}
        max={max}
        value={value}
        {...props}
      >
        {max > 0 ? `${Math.round((value / max) * 100)}%` : 'N/A'}
      </progress>
    </>
  );
};

ProgressBar.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.node,
  max: PropTypes.number.isRequired,
  value: PropTypes.number,
};

export default ProgressBar;
