import { QUESTION_TYPES } from '@api/instant_survey';

import { BookOpenIcon, CheckSquareIcon, RadioButtonIcon, TextTIcon } from '@utilities/icons';

/**
 * gets question icon based on question's type
 * @param  {Object} question
 * @param  {String} question.type
 * @return {Node}
 */
export const getQuestionIcon = ({ type }) => {
  switch (type) {
    case QUESTION_TYPES.CHECKBOX.value:
      return <CheckSquareIcon />;
    case QUESTION_TYPES.ESSAY.value:
      return <BookOpenIcon />;
    case QUESTION_TYPES.RADIO.value:
      return <RadioButtonIcon />;
    default:
      return <TextTIcon />;
  }
};
