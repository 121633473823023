import { getFormattedDate } from '@utilities';

/**
 * adds 14 days to endDate if in future or tomorrow if it is old date
 * @param  {String} date end date of date range
 * @return {String} valid run on date
 */
export const getMinRunOnDate = (date) => {
  if (!date) return null;

  let runOn = new Date(date);
  runOn.setDate(runOn.getDate() + 14);

  let tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 2);

  if (runOn <= tomorrow) {
    return getFormattedDate(tomorrow);
  }

  return getFormattedDate(runOn);
};
