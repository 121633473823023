import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { readReportShareToken } from '@api/reports';
import { Announcement, CopyToClipboard, Input, LoaderSkeleton } from '@utilities';

import styles from './_index.module.scss';

const Public = ({ id }) => {
  const { tab_id: tabId } = useParams();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shareToken, setShareToken] = useState(null);

  useEffect(() => {
    const getShareToken = async () => {
      setIsLoading(true);
      try {
        const response = await readReportShareToken({
          reportId: id,
        });
        setShareToken(response.data);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        console.warn(error);
      }
    };
    getShareToken();
  }, [setShareToken]);

  const publicURL = `${window.location.origin}/share/${shareToken}/${tabId || ''}`;

  if (error) {
    return (
      <Announcement
        text="There has been a problem creating the public share link. Please refresh the page and try again."
        variant="warn"
      />
    );
  }

  if (isLoading) {
    return (
      <LoaderSkeleton height={150}>
        <rect x="0" y="0" rx="4" ry="4" width="1333" height="90" />
        <rect x="0" y="120" rx="4" ry="4" width="1333" height="40" />
      </LoaderSkeleton>
    );
  }

  return (
    <div className={styles['public']}>
      <Announcement
        text="Copy this report link to share with those who do not have a Numerator Insights account. They will be able to see the data shared here but will not be able to re-prompt and run reports. "
        variant="info"
      />
      <span>
        <Input data-testid="public-input" isDisabled value={publicURL} />
        <CopyToClipboard data-testid="public-copy-to-clipboard-button" value={publicURL} />
      </span>
    </div>
  );
};

Public.propTypes = {
  id: PropTypes.number,
};

export default Public;
