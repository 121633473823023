import { memo } from 'react';
import PropTypes from 'prop-types';

import { PillOverlayPopover, useDebounce } from '@utilities';

import { getPillTooltip, getPillValue } from '@helpers';

import Products from './components/Products';
import styles from './_index.module.scss';

const ProductSearch = ({ currentCondition, setCurrentCondition, type }) => {
  const [searchQuery, setSearchQuery, { isDebouncing, signal }] = useDebounce('');
  const isDisabled = !currentCondition || !currentCondition.level;
  const tooltip = getPillTooltip({ products: currentCondition?.products });
  let elementType;
  switch (type) {
    case 'product':
      elementType = 'Product';
      break;
    case 'store':
      elementType = 'Channel';
      break;
    default:
  }
  const value = getPillValue({
    firstProductName: currentCondition?.products[0]?.name,
    products: currentCondition?.products,
    type: elementType,
  });

  return (
    <PillOverlayPopover
      header={currentCondition?.level?.name}
      isDisabled={isDisabled}
      isModified={currentCondition?.products?.length > 0}
      onClose={() =>
        setCurrentCondition((prevState) => ({
          ...prevState,
          products: [],
        }))
      }
      onHidePopover={() => setSearchQuery('')}
      overlayClassName={styles['product-search-overlay-popover']}
      tooltipContent={!isDisabled ? tooltip : `Please select a ${type} level`}
      value={value}
    >
      <div className={styles['product-search']}>
        <Products
          currentCondition={currentCondition}
          isDebouncing={isDebouncing}
          searchQuery={searchQuery}
          setCurrentCondition={setCurrentCondition}
          setSearchQuery={setSearchQuery}
          signal={signal}
          type={type}
        />
      </div>
    </PillOverlayPopover>
  );
};

ProductSearch.propTypes = {
  currentCondition: PropTypes?.object,
  setCurrentCondition: PropTypes.func,
  type: PropTypes.string,
};

export default memo(ProductSearch);
