import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './_index.module.scss';

const Loader = ({ isCentered }) => {
  return (
    <div className={classNames(styles[`loader`], { [styles['is-centered']]: isCentered })}>
      {[...Array(4)].map((bar, index) => (
        <div key={`loader-${index}`}>
          <div />
        </div>
      ))}
    </div>
  );
};

Loader.propTypes = {
  isCentered: PropTypes.bool,
};

export default Loader;
