import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import JsxParser from 'react-jsx-parser';

import { LoaderSkeleton, OverlayPopover } from '@utilities';

import styles from './_index.module.scss';

const NotificationDropdown = ({
  hasAllNotifications,
  isLoading,
  isNotificationDropdownActive,
  notifications,
  page,
  setActiveDropdown,
  setPage,
  target,
}) => {
  const scrollContainer = useRef(null);

  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  };

  const trackScrolling = useCallback(() => {
    const scrollContainerWrapper = scrollContainer.current;
    if (isBottom(scrollContainerWrapper) && !isLoading && !hasAllNotifications) {
      setPage(page + 1);
    }
  }, [isLoading, page]);

  useEffect(() => {
    const scrollContainerWrapper = scrollContainer.current;
    if (!hasAllNotifications && scrollContainerWrapper)
      scrollContainerWrapper.addEventListener('scroll', trackScrolling, true);
    return () => {
      if (scrollContainerWrapper) {
        scrollContainerWrapper.removeEventListener('scroll', trackScrolling, true);
      }
    };
  }, [trackScrolling, hasAllNotifications, isNotificationDropdownActive]);

  if (!isNotificationDropdownActive) return null;

  return (
    <OverlayPopover
      className={styles['notification-dropdown']}
      data-testid="notification-dropdown"
      header="Notifications"
      isActive={isNotificationDropdownActive}
      onHide={() => setActiveDropdown('')}
      target={target}
    >
      {isLoading && notifications.length === 0 && (
        <div className={styles['notification-dropdown-loading']}>
          <LoaderSkeleton height={40}>
            <rect x="0" y="0" rx="4" ry="4" width="400" height="10" />
            <rect x="0" y="20" rx="4" ry="4" width="300" height="10" />
          </LoaderSkeleton>
        </div>
      )}
      {!isLoading && notifications.length === 0 && (
        <div className={styles['notification-dropdown-empty']}>No New Notifications</div>
      )}
      {notifications.length > 0 && (
        <ul ref={scrollContainer}>
          {notifications.map((notification, index) => {
            if (notification?.data?.type === 'notification_center') {
              return (
                <li key={index}>
                  <p>
                    <JsxParser
                      components={{
                        a: ({ children, download, href }) => {
                          return download ? (
                            <a download href={window.location.origin + href}>
                              {children}
                            </a>
                          ) : (
                            <Link onClick={() => setActiveDropdown('')} to={href}>
                              {children}
                            </Link>
                          );
                        },
                      }}
                      jsx={notification?.data?.message}
                      renderInWrapper={false}
                    />
                  </p>
                  <span className={styles['notification-dropdown-datetime']}>
                    <small> ({notification.createdDatetime})</small>
                  </span>
                </li>
              );
            } else {
              return null;
            }
          })}
          {isLoading && (
            <LoaderSkeleton height={40}>
              <rect x="0" y="0" rx="4" ry="4" width="400" height="10" />
              <rect x="0" y="20" rx="4" ry="4" width="300" height="10" />
            </LoaderSkeleton>
          )}
          {hasAllNotifications && (
            <li className={styles['notification-dropdown-footer']}>No More Notifications</li>
          )}
        </ul>
      )}
    </OverlayPopover>
  );
};

NotificationDropdown.propTypes = {
  hasAllNotifications: PropTypes.bool,
  isLoading: PropTypes.bool,
  isNotificationDropdownActive: PropTypes.bool.isRequired,
  notifications: PropTypes.array.isRequired,
  page: PropTypes.number,
  setActiveDropdown: PropTypes.func.isRequired,
  setPage: PropTypes.func,
  target: PropTypes.object,
};

export default NotificationDropdown;
