import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import Button from '../../../../../../components/Button';
import InputColor from '../../../../../../components/InputColor';
import Modal from '../../../../../../components/ModalV2';

import { getInputs } from './utilities/helpers';

import styles from './_index.module.scss';

const ModalCustomExport = ({ chartOptions, isActive, onClose, renderChart }) => {
  const [colors, setColors] = useState(chartOptions?.colors);

  const chartRef = useRef(null);

  const chart = renderChart({
    customOptions: {
      animation: false,
      chart: {
        height: (2 / 3) * 100 + '%',
      },
      colors,
      legend: { enabled: true },
      plotOptions: {
        series: {
          animation: false,
        },
      },
    },
    isExport: true,
    ref: chartRef,
  });

  const inputs = getInputs({
    colors: chartOptions?.colors,
    series: chartOptions?.series,
  });

  const title = chartOptions?.exporting?.chartOptions?.title?.text;

  const handleColorChange = ({ index, value }) => {
    let updatedColors = [...colors];

    updatedColors[index] = value;

    setColors(updatedColors);
  };

  return (
    <Modal
      buttons={[
        <Button key="cancel" onClick={onClose} text="Cancel" variant="secondary" />,
        <Button
          key="export-png"
          onClick={() => chart?.ref?.current?.chart?.exportChartLocal()}
          text="Export as PNG"
        />,
      ]}
      isActive={isActive}
      onClose={onClose}
      size="large"
      title="Customize Export"
    >
      <Row>
        <Col xs={9}>
          <div className={styles['modal-custom-export-preview-wrapper']}>
            <div className={styles['modal-custom-export-preview']}>
              {title && <p>{title}</p>}
              {chart}
            </div>
            <h4>Preview</h4>
          </div>
        </Col>
        <Col xs={3}>
          <ul className={styles['modal-custom-export-options']}>
            {inputs?.map((input, index) => (
              <li key={`input-${index}`}>
                <InputColor
                  label={input?.name}
                  onChange={(value) => handleColorChange({ index, value })}
                  value={input?.color}
                />
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Modal>
  );
};

ModalCustomExport.propTypes = {
  chartOptions: PropTypes.object,
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  renderChart: PropTypes.func,
};

export default ModalCustomExport;
