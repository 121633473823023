import { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import { Button, Card, ModalV2 as Modal, truncateTextWithEllipsis } from '@utilities';

import QuestionLogicCondition from './components/QuestionLogicCondition';

import styles from './_index.module.scss';

const ModalLogicChangeConfirmation = ({
  onClose,
  questionBeingMoved,
  questions,
  questionLogic,
  updateSurvey,
}) => {
  const applyChanges = () => {
    updateSurvey({ questions, questionLogic: proposedQuestionLogic });
    onClose();
  };

  const affectedDisqualifyQuestions = questions.filter(
    ({ id }, index) => index > 1 && questionLogic[id]?.disqualify
  );
  const affectedSkipQuestions = questions.filter(({ id }, index) => {
    const currentLogic = questionLogic[id];
    if (!currentLogic?.skip) return false;

    if (
      currentLogic?.skip?.logic?.some(
        ({ question }) =>
          questions.findIndex(({ id: questionId }) => question.id === questionId) > index
      )
    ) {
      return true;
    }

    return false;
  });

  const destinationIndex = questions.findIndex(({ id }) => id === questionBeingMoved.id);

  const proposedQuestionLogic = cloneDeep(questionLogic);
  affectedDisqualifyQuestions.forEach((question) => {
    if (!proposedQuestionLogic[question.id]?.skip) {
      delete proposedQuestionLogic[question.id];
    } else {
      delete proposedQuestionLogic[question.id].disqualify;
    }
  });
  affectedSkipQuestions.forEach((question) => {
    if (!proposedQuestionLogic[question.id]?.disqualify) {
      delete proposedQuestionLogic[question.id];
    } else {
      delete proposedQuestionLogic[question.id].skip;
    }
  });

  const actionButtons = [
    <Button key="modal-cancel-button" onClick={onClose} text="Cancel" variant="secondary" />,
    <Button key="modal-confirm-button" onClick={applyChanges} text="Save" />,
  ];

  return (
    <Modal
      buttons={actionButtons}
      id="modal-logic-change-confirmation"
      isActive={true}
      onClose={onClose}
      size="large"
      title="Confirm Changes"
    >
      <div className={styles['modal-logic-change-confirmation-body']}>
        <p>
          Are you sure you want to move the question to{' '}
          <strong>position {destinationIndex + 1}</strong>?
        </p>

        {affectedSkipQuestions.map((question, index) => (
          <Fragment key={`affected-logic-${question.id}`}>
            <p>
              The following skip logic for{' '}
              <strong>“{truncateTextWithEllipsis({ length: 30, text: question.text })}”</strong>{' '}
              will be removed as it will break the survey flow:
            </p>
            <Card>
              <h4>CONDITION</h4>
              <QuestionLogicCondition
                question={question}
                questionLogic={questionLogic}
                rowIndex={index}
                selectedQuestions={questions}
                type="skip"
              />
            </Card>
          </Fragment>
        ))}
        {affectedDisqualifyQuestions.map((question) => (
          <Fragment key={`affected-logic-${question.id}`}>
            <p>
              The following for{' '}
              <strong>“{truncateTextWithEllipsis({ length: 30, text: question.text })}”</strong>{' '}
              will be removed as disqualification can only be applied at position 1 or 2 in your
              questionnaire:
            </p>
            <Card>
              <h4>CONDITION</h4>
              <QuestionLogicCondition
                question={question}
                questionLogic={questionLogic}
                type="disqualify"
              />
            </Card>
          </Fragment>
        ))}
      </div>
    </Modal>
  );
};

ModalLogicChangeConfirmation.propTypes = {
  onClose: PropTypes.func.isRequired,
  questionBeingMoved: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  questionLogic: PropTypes.object.isRequired,
  updateSurvey: PropTypes.func.isRequired,
};

export default ModalLogicChangeConfirmation;
