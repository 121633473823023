import PropTypes from 'prop-types';
import HighchartsMore from 'highcharts/highcharts-more';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';

import { lineChartConfiguration, setModifiedData } from './utilities/helpers';

const LineChart = ({ series, xAxis, yAxis, ...props }) => {
  return (
    <Chart
      highchartsModule={HighchartsMore}
      options={merge({}, lineChartConfiguration, { series, type: 'line', xAxis, yAxis, ...props })}
      setModifiedData={setModifiedData}
      {...props}
    />
  );
};

LineChart.propTypes = {
  series: PropTypes.array,
  xAxis: PropTypes.object,
  yAxis: PropTypes.object,
};

export default LineChart;
