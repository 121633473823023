import classNames from 'classnames';
import PropTypes from 'prop-types';

import { getStatusBarStyle } from '../../../../utilities/tree_helpers';

import styles from './_index.module.scss';

const StatusBar = ({ color, level, priority }) => {
  const { heavyStyle, icon, lightStyle, mediumStyle } = getStatusBarStyle({
    color,
    level,
    priority,
  });

  const className = {
    [styles['rotate-180']]: level === 7,
    [styles['rotate-90']]: level === 8,
    [styles['rotate-270']]: level === 9,
  };

  return level > 0 ? (
    <div className={styles['status-bar']}>
      <div>
        <div className={styles['status-bar-shift']} style={lightStyle}>
          {priority === 1 && (
            <div className={classNames(className)} style={{ background: color }}>
              {icon}
            </div>
          )}
        </div>
        <h4 className={styles['status-bar-text']}>Light</h4>
      </div>
      <div>
        <div className={styles['status-bar-shift']} style={mediumStyle}>
          {priority === 2 && (
            <div className={classNames(className)} style={{ background: color }}>
              {icon}
            </div>
          )}
        </div>
        <h4 className={styles['status-bar-text']}>Medium</h4>
      </div>
      <div>
        <div className={styles['status-bar-shift']} style={heavyStyle}>
          {priority === 3 && (
            <div className={classNames(className)} style={{ background: color }}>
              {icon}
            </div>
          )}
        </div>
        <h4 className={styles['status-bar-text']}>Heavy</h4>
      </div>
    </div>
  ) : null;
};

StatusBar.propTypes = {
  color: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  priority: PropTypes.number.isRequired,
};

export default StatusBar;
