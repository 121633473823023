/**
 * bar stack chart specific configurations
 * @type {Object}
 */
export const barStackChartConfiguration = {
  plotOptions: {
    bar: { stacking: 'normal' },
    series: {
      groupPadding: 0.1,
      pointPadding: 0.01,
    },
  },
  yAxis: {
    reversedStacks: false,
  },
};
