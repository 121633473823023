import axios from '@api';

import { transformAskWhyQuestions } from './utilities/helpers';

/**
 * read AskWhy questions
 * @param {Object} options
 * @param {String} options.documentId
 * @param {Number} [options.page]
 * @param {String} options.peopleGroupTypeId
 * @param {Number} options.signal AbortController signal
 * @return {Promise}
 */
export const readAskWhyQuestions = async ({
  documentId: document_id,
  page,
  peopleGroupTypeId: people_group_type_id,
  signal,
}) => {
  return axios({
    method: 'get',
    params: {
      document_id,
      ...(page && page > 1 ? { page } : {}),
      people_group_type_id,
    },
    signal,
    url: '/api/surveys/ask-why-questions/',
    withCredentials: true,
  }).then(({ data }) => transformAskWhyQuestions(data.results));
};
