import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { readReportTabComments } from '@api/reports';
import { transformCommentData } from '@api/reports/utilities/helpers';

import { readClientUsers } from '@api/user';
import Comment from './components/Comment';
import CommentInput from './components/CommentInput';

import styles from './_index.module.scss';

const Comments = ({ newComment, tabId }) => {
  const [commentsList, setCommentsList] = useState([]);
  const [error, setError] = useState(false);
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    if (newComment) {
      const data = transformCommentData(newComment);
      const modifiedCommentList = [data, ...commentsList];
      setCommentsList(modifiedCommentList);
    }
  }, [newComment]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await readClientUsers();
        setUsersList(response?.data);
      } catch (err) {
        setError(err);
        console.warn(error);
      }
    };

    const getCommentsList = async () => {
      try {
        const commentResponse = await readReportTabComments({ tabId });
        setCommentsList(commentResponse?.data);
      } catch (err) {
        setError(err);
        console.warn(error);
      }
    };
    getUsers();
    getCommentsList();
  }, []);

  return (
    <div className={styles['comments']}>
      <h2>Report Comments</h2>
      <CommentInput
        commentsList={commentsList}
        setCommentsList={setCommentsList}
        tabId={tabId}
        usersList={usersList}
      />
      <ul aria-labelledby="comments-list" data-testid="comments-list">
        {commentsList.map((comment, commentIndex) => {
          return (
            <li key={commentIndex}>
              <Comment
                client={comment?.client}
                commentId={comment?.commentId}
                commentsList={commentsList}
                commentOwner={comment?.user}
                commentOwnerId={comment?.commentOwnerUserId}
                date={comment?.date}
                setCommentsList={setCommentsList}
                tabId={comment?.tabId}
                text={comment?.text}
                time={comment?.time}
                usersList={usersList}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Comments.propTypes = { newComment: PropTypes.object, tabId: PropTypes.number };

export default Comments;
