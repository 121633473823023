import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import some from 'lodash/some';

import { getPromptPillValue } from '@helpers';
import { Pill, PinIcon, truncateTextWithEllipsis } from '@utilities';

import { deletePinnedAnswer } from '@api/prompts';

import styles from './_index.module.scss';

const PinnedAnswers = ({
  activeAnswers,
  activePrompt,
  onSelectAnswer,
  pinnedAnswers,
  setPinnedAnswers,
}) => {
  const [isUnPinning, setIsUnPinning] = useState(false);
  const promptPinnedAnswers = pinnedAnswers?.[activePrompt.id];
  if (!promptPinnedAnswers) return null;

  const handleUnpin = async ({ pinnedAnswerId }) => {
    if (isUnPinning) return;

    setIsUnPinning(true);

    try {
      await deletePinnedAnswer({ pinnedAnswerId });
      setPinnedAnswers((prevPinnedAnswers) => {
        const updatedPinnedAnswers = { ...prevPinnedAnswers };
        updatedPinnedAnswers[activePrompt.id] = updatedPinnedAnswers[activePrompt.id].filter(
          (pinnedAnswer) => pinnedAnswer.pinnedAnswerId !== pinnedAnswerId
        );
        return updatedPinnedAnswers;
      });
    } catch (error) {
      console.error('Failed to remove pin:', error);
    } finally {
      setIsUnPinning(false);
    }
  };

  return (
    <ul className={styles['pinned-answers']}>
      {promptPinnedAnswers?.map((pinnedAnswer, index) => {
        const value = truncateTextWithEllipsis({
          text: getPromptPillValue({
            answers: { [activePrompt?.id]: [pinnedAnswer] },
            prompt: activePrompt,
          }),
        });

        const pinnedAnswerWithoutId = { ...pinnedAnswer };
        delete pinnedAnswerWithoutId.pinnedAnswerId;
        const isPillActive = activeAnswers ? some(activeAnswers, pinnedAnswerWithoutId) : false;

        return (
          <li key={`pinned-answer-${index}`}>
            <Pill
              className={styles['pinned-answers-value']}
              isActive={isPillActive}
              onClick={() => {
                onSelectAnswer(pinnedAnswerWithoutId);
              }}
              value={value}
            />
            <Pill
              className={classNames(styles['pinned-answers-pin'], {
                [styles['is-active']]: isPillActive,
              })}
              icon={<PinIcon />}
              isActive={isPillActive}
              isModified
              onClose={() => handleUnpin({ pinnedAnswerId: pinnedAnswer?.pinnedAnswerId })}
            />
          </li>
        );
      })}
    </ul>
  );
};

PinnedAnswers.propTypes = {
  activeAnswers: PropTypes.array,
  activePrompt: PropTypes.object,
  onSelectAnswer: PropTypes.func,
  pinnedAnswers: PropTypes.object,
  setPinnedAnswers: PropTypes.func,
};

export default PinnedAnswers;
