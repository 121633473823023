import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button, ModalV2 as Modal } from '@utilities';
import { deleteReportTabComment } from '@api/reports';

import CommentInput from '../CommentInput';
import { sanitizeCommentText } from './utilities/helpers';
import styles from './_index.module.scss';

const Comment = ({
  client,
  commentId,
  commentsList,
  commentOwner,
  commentOwnerId,
  date,
  setCommentsList,
  text,
  time,
  usersList,
}) => {
  const editInputRef = useRef(null);
  const [error, setError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const currentUser = useSelector((state) => state?.user?.data);

  const className = {
    [styles['is-current-user-comment']]: currentUser?.id === commentOwnerId,
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (isEditing && editInputRef.current && !editInputRef.current.contains(event.target)) {
        setIsEditing(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editInputRef, isEditing]);

  const handleSubmitDelete = async (commentId) => {
    try {
      await deleteReportTabComment({ commentId });
      setShowDeleteModal(false);
      const modifiedCommentList = commentsList.filter(
        (comment) => comment?.commentId !== commentId
      );
      setCommentsList(modifiedCommentList);
    } catch {
      setError(error);
    }
  };

  return (
    <div className={classNames(styles['comment'], className)} ref={editInputRef}>
      <div>
        <strong>{commentOwner}</strong>
        <span>
          , {client} - {date} at {time}
        </span>
      </div>
      {isEditing ? (
        <CommentInput
          commentId={commentId}
          commentsList={commentsList}
          commentToEdit={text}
          isEditing={isEditing}
          setCommentsList={setCommentsList}
          setIsEditing={setIsEditing}
          usersList={usersList}
        />
      ) : (
        <div className={styles['comments-comment-text']}>
          {sanitizeCommentText({ text, usersList })}
        </div>
      )}
      {currentUser?.id === commentOwnerId && !isEditing && (
        <div className={styles['comment-buttons']}>
          <button data-testid={'edit-comment-btn'} onClick={() => setIsEditing(true)}>
            Edit
          </button>
          <button data-testid={'delete-comment-btn'} onClick={() => setShowDeleteModal(true)}>
            Delete
          </button>
        </div>
      )}
      {
        <Modal
          buttons={[
            <Button
              data-testid="modal-cancel-delete-btn"
              key="cancel-btn"
              onClick={() => setShowDeleteModal(false)}
              text="Cancel"
              variant="secondary"
            />,
            <Button
              data-testid="modal-submit-delete-btn"
              key="submit-btn"
              onClick={() => handleSubmitDelete(commentId)}
              text="Delete Comment"
              variant="error"
            />,
          ]}
          children="Are you sure you'd like to delete this comment forever?"
          isActive={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          title="Delete Comment"
        />
      }
    </div>
  );
};

Comment.propTypes = {
  client: PropTypes.string,
  commentId: PropTypes.number,
  commentsList: PropTypes.array,
  commentOwner: PropTypes.string,
  commentOwnerId: PropTypes.number,
  date: PropTypes.string,
  setCommentsList: PropTypes.func,
  text: PropTypes.string,
  time: PropTypes.string,
  usersList: PropTypes.array,
};

export default Comment;
