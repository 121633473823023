import axios from '@api';

/**
 * login - Logs the user in.
 * @param {String} options.email
 * @param {String} options.password
 * @returns {Promise}
 */
export const login = ({ email, password }) => {
  return axios({
    data: { login: email, password },
    method: 'post',
    url: '/api/login',
    withCredentials: true,
  });
};

/**
 * logout - Logs the user out.
 * @returns {Promise}
 */
export const logout = () => {
  return axios({
    method: 'get',
    url: '/api/logout',
    withCredentials: true,
  });
};
