import PropTypes from 'prop-types';

import { QUESTION_TYPES } from '@api/instant_survey';
import { getQuestionIcon } from '@helpers';
import { Card, Layout, Pill } from '@utilities';

import styles from './_index.module.scss';

const Questionnaire = ({ surveyQuestions }) => {
  return (
    <div className={styles['questionnaire']}>
      <h3>Questionnaire</h3>
      <Layout.Flex.Column as="ol" gap="small">
        {surveyQuestions?.map((question, index) => (
          <Layout.Flex
            as={Card}
            className={styles['questionnaire-question']}
            elevation={1}
            key={question.id}
            padding="small"
          >
            <strong>{index + 1}</strong>
            <Layout.Fill>{question.title}</Layout.Fill>
            <Pill
              icon={getQuestionIcon(question)}
              isCompleted
              value={QUESTION_TYPES[question.type]?.name}
            />
          </Layout.Flex>
        ))}
        {surveyQuestions?.length === 0 && <p>No questions are assigned to this survey.</p>}
      </Layout.Flex.Column>
    </div>
  );
};

Questionnaire.propTypes = {
  surveyQuestions: PropTypes.arrayOf(PropTypes.object),
};

export default Questionnaire;
