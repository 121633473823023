import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';

import { CloseBoldIcon } from '../../icons';
import { getFormattedDate } from '../../helpers/dates';

import { getLabel } from './utilities/helpers';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './_index.module.scss';

const DateRangePicker = ({
  'data-testid': dataTestId,
  endDate,
  isDisabled,
  label,
  maxDate,
  onDatesChange,
  startDate,
}) => {
  const [selectedEndDate, setSelectedEndDate] = useState(
    endDate ? new Date(endDate + 'T00:00:00') : null
  );
  const [selectedRawEndDate, setSelectedRawEndDate] = useState('');
  const [selectedRawStartDate, setSelectedRawStartDate] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(
    startDate ? new Date(startDate + 'T00:00:00') : null
  );

  const endRef = useRef(null);
  const format = 'MM/DD/YYYY';
  const startRef = useRef(null);

  useEffect(() => {
    if (endDate && endDate !== selectedEndDate) {
      setSelectedEndDate(endDate ? new Date(endDate + 'T00:00:00') : null);
    }

    if (startDate && startDate !== selectedStartDate) {
      setSelectedStartDate(startDate ? new Date(startDate + 'T00:00:00') : null);
    }

    if (!endDate && !startDate) {
      setSelectedEndDate(null);
      setSelectedStartDate(null);
    }
  }, [endDate, startDate]);

  useEffect(() => {
    onDatesChange([
      selectedStartDate && (!selectedRawStartDate || selectedRawStartDate?.length <= format?.length)
        ? getFormattedDate(selectedStartDate)
        : null,
      selectedEndDate && (!selectedRawEndDate || selectedRawEndDate?.length <= format?.length)
        ? getFormattedDate(selectedEndDate)
        : null,
    ]);
  }, [selectedEndDate, selectedStartDate]);

  useEffect(() => {
    if (
      selectedStartDate &&
      (!selectedRawStartDate || selectedRawStartDate?.length >= format?.length) &&
      !selectedEndDate
    ) {
      endRef.current.setFocus();
    }
  }, [selectedStartDate]);

  useEffect(() => {
    if (selectedStartDate && selectedRawStartDate?.length >= format?.length) {
      startRef.current.setOpen(false);
    }
  }, [selectedRawStartDate]);

  return (
    <div
      className={classNames(styles['date-range-picker'], { [styles['is-disabled']]: isDisabled })}
      data-testid={dataTestId}
    >
      <h4>{getLabel({ label, selectedEndDate, selectedStartDate })}</h4>
      <div>
        <DatePicker
          disabled={isDisabled}
          disabledKeyboardNavigation
          endDate={selectedEndDate}
          maxDate={selectedEndDate || new Date(maxDate + 'T00:00:00')}
          monthsShown={2}
          onChange={setSelectedStartDate}
          onChangeRaw={(event) => setSelectedRawStartDate(event.target.value)}
          placeholderText={format}
          popperModifiers={[
            {
              name: 'flip',
              options: {
                fallbackPlacements: [],
              },
            },
          ]}
          popperPlacement="bottom-start"
          ref={startRef}
          selected={selectedStartDate}
          selectsStart
          startDate={selectedStartDate}
        />
        <DatePicker
          disabled={isDisabled}
          disabledKeyboardNavigation
          endDate={selectedEndDate}
          minDate={selectedStartDate}
          maxDate={new Date(maxDate + 'T00:00:00')}
          monthsShown={2}
          onChange={setSelectedEndDate}
          onChangeRaw={(event) => setSelectedRawEndDate(event.target.value)}
          placeholderText={format}
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: ({ placement, reference, popper }) => {
                  return [-135, 0];
                },
              },
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: [],
              },
            },
          ]}
          popperPlacement="bottom-start"
          ref={endRef}
          selected={selectedEndDate}
          selectsEnd
          startDate={selectedStartDate}
        />
        <CloseBoldIcon
          className={classNames(styles['date-range-picker-close'], {
            [styles['is-visible']]: selectedStartDate || selectedEndDate,
          })}
          onClick={() => {
            setSelectedEndDate(null);
            setSelectedStartDate(null);
          }}
        />
      </div>
    </div>
  );
};

DateRangePicker.defaultProps = {
  'data-testid': 'date-range-picker',
  endDate: null,
  maxDate: '9999-12-31',
  onDatesChange: () => {},
  startDate: null,
};

DateRangePicker.propTypes = {
  'data-testid': PropTypes.string,
  /**
   * dd-mm-yyyy format
   */
  endDate: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  /**
   * dd-mm-yyyy format
   */
  maxDate: PropTypes.string,
  /**
   * returns [startDate, endDate]
   */
  onDatesChange: PropTypes.func,
  /**
   * dd-mm-yyyy format
   */
  startDate: PropTypes.string,
};

export default DateRangePicker;
