import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { LockIcon } from '../../icons';

import styles from './_index.module.scss';

const Navigation = ({ routes }) => {
  const { pathname } = useLocation();

  return (
    <ul className={styles['navigation']}>
      {routes.map((route, routeIndex) => {
        const url = route?.to;
        const isActive = pathname.includes(url) || route?.is_active ? styles['is-active'] : '';
        const isDisabled = route?.is_locked ? styles['is-disabled'] : '';

        return route?.is_hidden ? null : (
          <li
            key={routeIndex}
            className={`${isActive} ${isDisabled}`}
            data-testid={`${route?.label.toLowerCase().replace(/\s+/g, '-')}-navigation`}
          >
            <Link to={url}>{route?.label}</Link>
            {route?.is_locked && <LockIcon />}
          </li>
        );
      })}
    </ul>
  );
};

Navigation.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Navigation;
