import PropTypes from 'prop-types';
import bullet from 'highcharts/modules/bullet';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';

import { bulletChartConfiguration, setModifiedData } from './utilities/helpers';

const BulletChart = ({ series, xAxis, ...props }) => {
  return (
    <Chart
      highchartsModule={bullet}
      options={merge({}, bulletChartConfiguration, {
        series,
        type: 'bullet',
        xAxis,
        ...props,
      })}
      setModifiedData={setModifiedData}
      {...props}
    />
  );
};

BulletChart.propTypes = {
  series: PropTypes.array,
  xAxis: PropTypes.object,
};

export default BulletChart;
