import PropTypes from 'prop-types';

import styles from './_index.module.scss';

const Cart = ({ children, footnote, items, paymentOption }) => {
  const currency = paymentOption?.currency;
  const currencySingular = paymentOption?.currencySingular;
  const total = currency
    ? items.reduce((acc, item) => {
        return acc + item.prices[currency];
      }, 0)
    : null;

  const getDisplayCurrency = (value) => {
    if (!currencySingular) {
      return currency;
    }
    if (value > 0 && value <= 1) {
      return currencySingular;
    }
    return currency;
  };
  const getDisplayPrice = (value) => {
    if (currency !== 'credits') {
      return value?.toFixed(2);
    }
    return value;
  };

  if (!items.length) {
    return (
      <div className={styles['cart']}>
        <p>No items in your cart</p>
      </div>
    );
  }

  return (
    <section className={styles['cart']}>
      <ul>
        {items.map((item, index) => (
          <li className={styles['cart-item']} key={`cart-item-${index}`} data-testid="cart-item">
            <div className={styles['cart-item-details']}>
              <div>
                <p className={styles['cart-item-name']}>{item.name}</p>
                {item.description && (
                  <p className={styles['cart-item-description']}>{item.description}</p>
                )}
              </div>
              <div className={styles['cart-item-price']}>
                {`${getDisplayPrice(item.prices[currency])} ${getDisplayCurrency(
                  item.prices[currency]
                )}`}
              </div>
            </div>
            {item.notes && (
              <ul className={styles['cart-item-notes']}>
                {item.notes.map((note, noteIndex) => (
                  <li
                    dangerouslySetInnerHTML={{
                      __html: note,
                    }}
                    key={`note-${index}-${noteIndex}`}
                  />
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      {paymentOption && items.length > 1 && (
        <h3 className={styles['cart-total']}>
          <strong>Total</strong>
          <span>{`${getDisplayPrice(total)} ${getDisplayCurrency(total)}`}</span>
        </h3>
      )}
      {footnote && <div className={styles['cart-footnote']}>{footnote}</div>}
      {children}
    </section>
  );
};

Cart.propTypes = {
  children: PropTypes.node,
  footnote: PropTypes.string,
  items: PropTypes.array.isRequired,
  paymentOption: PropTypes.object,
};

export default Cart;
