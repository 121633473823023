import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, useMatch } from 'react-router-dom';

import { Layout, Navigation } from '@utilities';

const Sidebar = ({ children, route }) => {
  const { pathname: locationPathname } = useLocation();
  const { pathnameBase } = useMatch('/dashboard/:type/*');

  const routes = route?.routes;

  const currentRoute = routes.find((route) => {
    let id = route?.path.split('/').pop();

    if (route?.path.includes('/:')) {
      id = route?.path.split('/').reverse()[1];
    }

    let pathname = locationPathname?.split('/').pop();

    if (route?.path.includes('/:')) {
      pathname = locationPathname?.split('/').reverse()[1];
    }

    return pathname === id;
  });

  return (
    <Layout.Flex>
      <Layout.Sidebar>
        <Navigation
          routes={routes?.map((route) => {
            return {
              ...route,
              to: `${pathnameBase}/${route?.path}`,
            };
          })}
        />
      </Layout.Sidebar>
      <Layout.Fill>
        {currentRoute?.label && <h1>{currentRoute?.label}</h1>}
        {children}
      </Layout.Fill>
    </Layout.Flex>
  );
};

Sidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  route: PropTypes.object,
};

export default Sidebar;
