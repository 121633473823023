import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button } from '@utilities';
import { createReportTabComment, updateReportTabComment } from '@api/reports';

import Mentions from '../Mentions';

import styles from './_index.module.scss';

const CommentInput = ({
  commentId,
  commentsList,
  commentToEdit,
  isEditing,
  setCommentsList,
  setIsEditing,
  tabId,
  usersList,
}) => {
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const maxCommentLength = 1000;
  const isMaxLength = comment?.length > maxCommentLength;

  const currentUser = useSelector((state) => state?.user?.data);

  useEffect(() => {
    if (commentToEdit) {
      setComment(commentToEdit);
    }
  }, [commentToEdit]);

  const handleSubmitComment = async ({ comment, commentId, isEditing = false }) => {
    setIsLoading(true);
    try {
      if (isEditing) {
        const response = await updateReportTabComment({ text: comment, commentId });
        const modifiedCommentList = commentsList?.map((comment) =>
          comment?.commentId === response?.data?.commentId ? response?.data : comment
        );
        setCommentsList(modifiedCommentList);
        setComment('');
        setIsLoading(false);
        setIsEditing(false);
      } else {
        const response = await createReportTabComment({ tabId, text: comment });
        const modifiedCommentList = [response?.data, ...commentsList];
        setCommentsList(modifiedCommentList);
        setComment('');
        setIsLoading(false);
      }
    } catch {
      setError(error);
      setIsLoading(false);
      if (isEditing) {
        setIsEditing(false);
      }
    }
  };

  const handleCancelSubmitComment = () => {
    setComment('');
    if (isEditing) {
      setIsEditing(false);
    }
  };

  return (
    <div className={styles['comment-input']} data-testid="comment-input">
      {!isEditing && (
        <div className={styles['comment-input-header']}>
          <strong>
            {currentUser?.firstName} {currentUser?.lastName},
          </strong>
          <span>{currentUser?.clientInfo?.name}</span>
        </div>
      )}
      <Mentions
        isDisabled={isLoading}
        onChange={setComment}
        placeholder={
          commentToEdit ? '' : "Click here to add a comment and mention people using '@'"
        }
        usersList={usersList.filter((user) => user?.id !== currentUser?.id)}
        value={comment}
      />
      <div className={styles['comment-input-footer']}>
        <p className={isMaxLength ? styles['is-max-length'] : ''}>
          {`character limit: ${comment.length}/${maxCommentLength} `}
        </p>
        <Button
          data-testid={isEditing ? 'cancel-edited-comment-btn' : 'cancel-comment-btn'}
          onClick={() => handleCancelSubmitComment()}
          text="Cancel"
          variant="secondary"
        />
        <Button
          data-testid={isEditing ? 'submit-edited-comment-btn' : 'submit-comment-btn'}
          isDisabled={isMaxLength || comment.length === 0 || isLoading}
          onClick={() => handleSubmitComment({ comment, commentId, isEditing })}
          text="Save"
        />
      </div>
    </div>
  );
};

CommentInput.propTypes = {
  commentId: PropTypes.number,
  commentsList: PropTypes.array.isRequired,
  commentToEdit: PropTypes.string,
  isEditing: PropTypes.bool,
  setCommentsList: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func,
  tabId: PropTypes.number,
  usersList: PropTypes.array,
};

export default CommentInput;
