import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Tag, TagShareIcon } from '@utilities';

import styles from './_index.module.scss';

const ReportName = ({ data }) => {
  return (
    <div className={styles['report-name']}>
      {data?.hasException ? (
        <p>{data?.name}</p>
      ) : (
        <Link to={`/dashboard/report/${data?.id}`}>
          <span>{data?.name}</span>
        </Link>
      )}
      <ul>
        {data?.isShared && (
          <li>
            <Tag icon={<TagShareIcon />} tooltip="Shared" />
          </li>
        )}
      </ul>
    </div>
  );
};

ReportName.propTypes = {
  data: PropTypes.object,
};

export default ReportName;
