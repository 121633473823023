import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, DownloadIcon } from '@utilities';
import { downloadDiagram } from '../../../../utilities/tree_helpers';

import styles from './_index.module.scss';

const Control = ({ treeRef, exportData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadDiagram = async () => {
    try {
      setIsLoading(true);
      await downloadDiagram(treeRef, exportData);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <div className={styles['control']}>
      <Button
        icon={<DownloadIcon />}
        isLoading={isLoading}
        onClick={handleDownloadDiagram}
        text="Download Chart"
        variant="secondary"
      />
    </div>
  );
};

Control.propTypes = {
  treeRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  exportData: PropTypes.object,
};

export default Control;
