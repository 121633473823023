import PropTypes from 'prop-types';

import classNames from 'classnames';

import { SortDownIcon, SortUpIcon } from '../../icons';

import styles from './_index.module.scss';

const SortIndicator = ({ direction, isActive, isDisabled }) => {
  if (!isActive) return null;

  return (
    <span
      aria-hidden
      className={classNames(styles['sort-indicator'], { [styles['is-disabled']]: isDisabled })}
      data-testid="sort-indicator"
    >
      {direction === 'asc' ? <SortUpIcon /> : <SortDownIcon />}
    </span>
  );
};

SortIndicator.defaultProps = {
  direction: 'asc',
};

SortIndicator.propTypes = {
  direction: PropTypes.oneOf(['asc', 'desc']),
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default SortIndicator;
