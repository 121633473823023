/**
 *
 * @param {String} reportName user-provided name e.g. Andrew's Shopper Analysis
 * @param {String} tabName name of tab in focus e.g. Histogram Chart
 * @returns {String} chart title to display
 */
export const getExportName = ({ reportName = '', tabName = '' }) => {
  if (reportName === '' && tabName === '') {
    return '';
  }
  return `${reportName} - ${tabName}`;
};
