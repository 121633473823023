import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { nanoid } from 'nanoid';

import { questionTypes } from '@api/surveys';
import {
  createImageObject,
  createQuestion,
  FOLDER_ID_CUSTOM,
  FOLDER_ID_RECOMMENDED,
  readQuestion,
  updateQuestion,
  uploadImageToS3,
} from '@api/instant_survey';

import {
  Announcement,
  Button,
  ButtonText,
  Checkbox,
  Error,
  Fieldset,
  LoaderSkeleton,
  ModalV2 as Modal,
  PlusIcon,
  RichTextInput,
  Select,
  TextArea,
} from '@utilities';

import Answer from './components/Answer';

import {
  availableQuestionVariables,
  availableTypes,
  noneOfTheAbove,
  otherAlternates,
  otherPleaseSpecify,
} from './utilities/constants';

import styles from './_index.module.scss';

const ANSWER_MAX_LENGTH = 150;
const QUESTION_MAX_LENGTH = 1000;

const ANSWER_LENGTH_ERROR = `Answer text exceeds the max-length of ${ANSWER_MAX_LENGTH}. Trim or remove it to continue.`;
const QUESTION_LENGTH_ERROR = `Question text length is too long. Trim it to ${QUESTION_MAX_LENGTH} characters or less to continue.`;

const ModalInstantSurveyQuestion = ({
  id,
  isDuplicate,
  onClose,
  onCreate,
  onUpdate,
  surveyType,
}) => {
  const [deletedAnswers, setDeletedAnswers] = useState([]);
  const [errorLoading, setErrorLoading] = useState(null);
  const [errorSubmitting, setErrorSubmitting] = useState(null);
  const [isLoading, setIsLoading] = useState(!!id);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [question, setQuestion] = useState({
    answers: [
      { id: nanoid(), text: '' },
      { id: nanoid(), text: '' },
      { id: nanoid(), text: '' },
    ],
    image: null,
    noneAnswer: null,
    otherAnswer: null,
    randomizeOptions: false,
    text: '',
    type: questionTypes.checkbox,
  });

  const [hasImageError, setHasImageError] = useState({ answers: [] });
  const [isImageLoading, setIsImageLoading] = useState({ answers: [] });

  useEffect(() => {
    if (id) {
      getCustomQuestion(id);
    }
  }, []);

  const addAnswer = () => {
    setQuestion({ ...question, answers: [...question.answers, { id: nanoid(), text: '' }] });
  };

  const addNoneAnswer = () => {
    setQuestion((question) => ({
      ...question,
      noneAnswer: {
        id: nanoid(),
        isAnchored: true,
        isExclusive: true,
        isNoneOfTheAbove: true,
        text: noneOfTheAbove,
      },
    }));
  };

  const addOtherAnswer = () => {
    setQuestion((question) => ({
      ...question,
      otherAnswer: {
        id: nanoid(),
        isAnchored: true,
        isOther: true,
        text: otherPleaseSpecify,
      },
    }));
  };

  const deleteAnswer = (index) => {
    const answer = question.answers[index];
    if (typeof answer.id === 'number') {
      deletedAnswers.push({ ...answer });
    }

    question.answers.splice(index, 1);
    setQuestion({ ...question, answers: [...question.answers] });
  };

  const disableSaveButton = () => {
    // pasting text into <MentionsInput /> can exceed its maxLength, these checks are still necessary
    // https://github.com/signavio/react-mentions/issues/616
    if (!question.text.trim() || question.text.length > QUESTION_MAX_LENGTH) {
      return true;
    }

    if ([questionTypes.radio.value, questionTypes.checkbox.value].includes(question.type.value)) {
      return (
        question.answers.length === 0 ||
        question.answers.some(({ text }) => !text.trim() || text.length > ANSWER_MAX_LENGTH)
      );
    }

    return false;
  };

  const getAnswerInputError = (answer) => {
    if (answer.text?.length > ANSWER_MAX_LENGTH) return ANSWER_LENGTH_ERROR;
    if (!answer.text?.trim() && answer.cdnUrl) return 'Question options require text';
    return null;
  };

  const getCustomQuestion = async (id) => {
    const controller = new AbortController();

    try {
      const data = await readQuestion({ id, signal: controller.signal });
      if (!isDuplicate && data.folder === FOLDER_ID_RECOMMENDED) {
        setIsReadOnly(true);
      }

      setQuestion({
        ...data,
        answers: data.answers.filter(
          ({ isNoneOfTheAbove, isOther }) => !isNoneOfTheAbove && !isOther,
        ),
        id: isDuplicate ? undefined : data.id,
        noneAnswer: data.answers.find(({ isNoneOfTheAbove }) => isNoneOfTheAbove) || null,
        otherAnswer: data.answers.find(({ isOther }) => isOther) || null,
        type:
          availableTypes.find((availableType) => availableType.value === data.type) ||
          questionTypes.essay,
      });
    } catch (error) {
      if (!controller.signal.aborted) {
        console.error(error);
        setErrorLoading(error);
      }
    }
    setIsLoading(false);
  };

  const onAnswerDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }
    const answers = [...question.answers];
    const [removed] = answers.splice(result?.source?.index, 1);
    answers.splice(result?.destination?.index, 0, removed);
    setQuestion({ ...question, answers });
  };

  const onAnswerPaste = (event, value, index) => {
    const values = value
      .split(/[\n\r]+/) // split on newlines and returns
      .filter((n) => n) // filter removes empty string in cases of repeated line breaks
      .map((text) => text.trim().replace(/^•[\t\s]*/, '')) // trim whitespace and remove bullet points
      .filter((text) => {
        if (otherAlternates.includes(text.toLowerCase())) {
          if (!question.otherAnswer) addOtherAnswer();
          return false;
        } else if (text.toLowerCase() === noneOfTheAbove.toLowerCase()) {
          if (!question.noneAnswer) addNoneAnswer();
          return false;
        }
        return true;
      })
      .map((text) => ({
        id: nanoid(),
        text,
      }));

    if (values.length <= 1) {
      return;
    }
    event.preventDefault();

    const deletedItems = question.answers.splice(index, values.length, ...values);
    setDeletedAnswers([
      ...deletedAnswers,
      ...deletedItems.filter(({ id }) => typeof id === 'number'),
    ]);
    setQuestion((question) => ({ ...question })); // updates the answers prop after the above splice()
  };

  const onImageUpload = async (file, index) => {
    setImageError(false, index);
    setImageLoading(true, index);

    try {
      const imageObject = await createImageObject({ objectType: file.type.substring(6) });
      if (!imageObject.url.startsWith('http://s3mock')) {
        await uploadImageToS3({ file, url: imageObject.url });
      }
      setUploadedImage(imageObject, index);
      setImageLoading(false, index);
    } catch (error) {
      setImageError(true, index);
      setImageLoading(false, index);
      throw error; // passes error back to RichTextInput handler
    }
  };

  const onSave = async () => {
    setErrorSubmitting(null);
    setIsSubmitting(true);

    const { image, noneAnswer, otherAnswer, randomizeOptions, text, type } = question;
    let answers = [...question.answers];

    if (otherAnswer) answers.push(otherAnswer);
    if (noneAnswer) answers.push(noneAnswer);

    if (type.value === questionTypes.essay.value) {
      // mark any existing answers as _delete: true
      answers = answers
        .filter(({ id }) => typeof id === 'number')
        .map((answer) => ({ _delete: true, ...answer }));
    }

    const controller = new AbortController();
    try {
      if (question.id) {
        const data = await updateQuestion({
          question: {
            answers: [
              ...answers,
              ...deletedAnswers.map((answer) => ({ _delete: true, ...answer })),
            ],
            id: question.id,
            image,
            randomizeOptions,
            text,
            type: type.value,
          },
          signal: controller.signal,
        });
        onUpdate(data);
      } else {
        const data = await createQuestion({
          question: {
            answers,
            folder: FOLDER_ID_CUSTOM,
            image,
            instantSurveyTypes: [surveyType.id],
            randomizeOptions,
            text,
            type: type.value,
          },
          signal: controller.signal,
        });
        onCreate(data);
      }
    } catch (error) {
      if (!controller.signal.aborted) {
        setErrorSubmitting(error);
        setIsSubmitting(false);
      }
    }
  };

  const setImageError = (value, index) => {
    const updatedImageError = { ...hasImageError };
    if (index !== undefined) {
      updatedImageError.answers[index] = value;
    } else {
      updatedImageError.question = value;
    }
    setHasImageError(updatedImageError);
  };

  const setImageLoading = (value, index) => {
    const updatedImageLoading = { ...isImageLoading };
    if (index !== undefined) {
      updatedImageLoading.answers[index] = value;
    } else {
      updatedImageLoading.question = value;
    }
    setIsImageLoading(updatedImageLoading);
  };

  const setUploadedImage = (value, index) => {
    if (index !== undefined) {
      const answerId = question.answers[index].id;
      updateAnswer(answerId, { image: value });
    } else {
      setQuestion({ ...question, image: value });
    }
  };

  const updateAnswer = (answerId, changes) => {
    const answer = question.answers.find(({ id }) => answerId === id);
    Object.assign(answer, changes);
    setQuestion({ ...question, answers: question.answers });
  };

  const actionButtons = [
    <Button
      data-testid="modal-cancel-button"
      key="modal-cancel-button"
      onClick={onClose}
      text={isReadOnly ? 'Close' : 'Cancel'}
      variant="secondary"
    />,
    ...(!isReadOnly
      ? [
          <Button
            data-testid="modal-confirm-button"
            isDisabled={disableSaveButton()}
            isLoading={isSubmitting}
            key="modal-confirm-button"
            onClick={onSave}
            text={question.id ? 'Update' : 'Create & Add'}
          />,
        ]
      : []),
  ];
  const mentions = [
    {
      data: availableQuestionVariables.map((variable) => ({
        id: variable,
        display: variable,
      })),
      displayTransform: (_, display) => `[${display.toLowerCase()}]`,
      key: 'variables',
      markup: '[__display__]',
      trigger: '[',
    },
  ];
  const suggestions = {
    description: 'Use our dynamic variables to help with recalls for your surveys.',
    helpUrl: surveyType.helpUrl,
    title: 'Search for Variables',
  };
  const titleModifier = isReadOnly ? 'View' : id && !isDuplicate ? 'Edit' : 'Create';

  return (
    <Modal
      buttons={actionButtons}
      isActive={true}
      onClose={onClose}
      size="large"
      title={`${titleModifier} Survey Question`}
    >
      {errorLoading && (
        <Error
          description="This is awkward. There was an error loading the existing question."
          header="Question Error"
        />
      )}

      {isLoading && !errorLoading && (
        <LoaderSkeleton height={350}>
          <rect x="0" y="50" rx="4" ry="4" width="1333" height="40" />
          <rect x="0" y="120" rx="4" ry="4" width="1333" height="40" />
          <rect x="0" y="210" rx="4" ry="4" width="1333" height="120" />
        </LoaderSkeleton>
      )}

      {!isLoading && !errorLoading && (
        <div className={styles['modal-instant-survey-question']}>
          {isReadOnly && (
            <Announcement
              text={
                <>
                  You are viewing one of our <strong>Recommended Questions</strong> which you cannot
                  edit.
                </>
              }
              variant="info"
            />
          )}
          {errorSubmitting && (
            <Announcement
              text={
                errorSubmitting?.response?.data?.error ||
                `There was an error ${question.id ? 'updating' : 'creating'} your question.`
              }
              variant="error"
            />
          )}
          <div className={styles['modal-instant-survey-question-text-and-type']}>
            <RichTextInput
              autoFocus={question.id ? false : true}
              error={question.text.length > QUESTION_MAX_LENGTH ? QUESTION_LENGTH_ERROR : null}
              hasImageError={hasImageError.question}
              id="question-text-rich-input"
              imageUrl={question.image?.cdnUrl}
              isDisabled={isReadOnly}
              isImageLoading={isImageLoading.question}
              label="Question"
              maxLength={QUESTION_MAX_LENGTH}
              mentions={mentions}
              onChange={(text) => setQuestion({ ...question, text })}
              onImageRemove={setUploadedImage}
              onImageUpload={onImageUpload}
              placeholder="Enter question"
              setHasImageError={setImageError}
              suggestions={suggestions}
              value={question.text}
            />
            <Select
              className={styles['modal-instant-survey-question-select']}
              isDisabled={isReadOnly}
              label="Type"
              onChange={(type) => setQuestion({ ...question, type })}
              options={availableTypes}
              value={question.type}
            />
          </div>

          {question.type.value === questionTypes.essay.value ? (
            <TextArea
              className={styles['modal-instant-survey-question-input']}
              label="Answer (Multiple Lines)"
              isDisabled
            />
          ) : (
            <>
              <Fieldset legend={`Answer Options (${ANSWER_MAX_LENGTH} characters)`}>
                {!isReadOnly && (
                  <div className={styles['modal-instant-survey-question-randomize']}>
                    <Checkbox
                      isChecked={question.randomizeOptions}
                      label="Randomize order"
                      onChange={() =>
                        setQuestion({ ...question, randomizeOptions: !question.randomizeOptions })
                      }
                      value={question.randomizeOptions}
                    />
                  </div>
                )}
                <DragDropContext onDragEnd={onAnswerDragEnd}>
                  <Droppable droppableId="modalSurveyQuestionAnswers">
                    {(provided) => (
                      <ul ref={provided.innerRef} {...provided.droppableProps}>
                        {question.answers.map((answer, index) => {
                          return (
                            <Draggable
                              draggableId={`answer-${answer.id}`} // requires a string
                              key={answer.id}
                              index={index}
                              isDragDisabled={
                                question.answers.length === 1 || question.randomizeOptions
                              }
                            >
                              {(provided) => {
                                return (
                                  <li
                                    className={styles['modal-instant-survey-question-answers-li']}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    tabIndex="-1"
                                  >
                                    <Answer
                                      answer={answer}
                                      isDisabled={isReadOnly}
                                      isDraggable
                                      onAnchor={() =>
                                        updateAnswer(answer.id, {
                                          isAnchored: !answer.isAnchored,
                                        })
                                      }
                                      onDelete={() => deleteAnswer(index)}
                                      onMakeExclusive={() =>
                                        updateAnswer(answer.id, {
                                          isExclusive: !answer.isExclusive,
                                        })
                                      }
                                      type={question.type.value}
                                    >
                                      <RichTextInput
                                        error={getAnswerInputError(answer)}
                                        hasImageError={hasImageError.answers[index]}
                                        imageUrl={answer.image?.cdnUrl}
                                        isDisabled={isReadOnly}
                                        isImageLoading={isImageLoading.answers[index]}
                                        maxLength={ANSWER_MAX_LENGTH}
                                        mentions={mentions}
                                        onChange={(value) =>
                                          updateAnswer(answer.id, { text: value })
                                        }
                                        onImageRemove={() => setUploadedImage(null, index)}
                                        onImageUpload={(file) => onImageUpload(file, index)}
                                        onPaste={(event, value) =>
                                          onAnswerPaste(event, value, index)
                                        }
                                        placeholder="Enter answer"
                                        setHasImageError={(value) => setImageError(value, index)}
                                        suggestions={suggestions}
                                        value={answer.text}
                                      />
                                    </Answer>
                                  </li>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                {(question.otherAnswer || question.noneAnswer) && (
                  <ul className={styles['modal-instant-survey-question-options']}>
                    {question.otherAnswer && (
                      <li className={styles['modal-instant-survey-question-answers-li']}>
                        <Answer
                          answer={question.otherAnswer}
                          isDisabled={isReadOnly}
                          onDelete={() => {
                            if (typeof question.otherAnswer.id === 'number') {
                              setDeletedAnswers([...deletedAnswers, { ...question.otherAnswer }]);
                            }
                            setQuestion({ ...question, otherAnswer: null });
                          }}
                          onMakeExclusive={() => {
                            setQuestion({
                              ...question,
                              otherAnswer: {
                                ...question.otherAnswer,
                                isExclusive: !question.otherAnswer.isExclusive,
                              },
                            });
                          }}
                          type={question.type.value}
                        >
                          <span className={styles['modal-instant-survey-question-other']}>
                            {question.otherAnswer.text}:
                          </span>
                          <span
                            className={styles['modal-instant-survey-question-other-placeholder']}
                          >
                            Panelist's write-in
                          </span>
                        </Answer>
                      </li>
                    )}
                    {question.noneAnswer && (
                      <li className={styles['modal-instant-survey-question-answers-li']}>
                        <Answer
                          answer={question.noneAnswer}
                          isDisabled={isReadOnly}
                          onDelete={() => {
                            if (typeof question.noneAnswer.id === 'number') {
                              setDeletedAnswers([...deletedAnswers, { ...question.noneAnswer }]);
                            }
                            setQuestion({ ...question, noneAnswer: null });
                          }}
                          type={question.type.value}
                        >
                          <span className={styles['modal-instant-survey-question-none']}>
                            {question.noneAnswer.text}
                          </span>
                        </Answer>
                      </li>
                    )}
                  </ul>
                )}
              </Fieldset>

              {!isReadOnly && (
                <div className={styles['modal-instant-survey-question-actions']}>
                  <PlusIcon />
                  <ButtonText data-testid="add-answer-button" onClick={() => addAnswer()}>
                    <strong>New</strong>&nbsp;Option
                    {!question.otherAnswer || !question.noneAnswer ? ',' : null}
                  </ButtonText>
                  {!question.otherAnswer && (
                    <ButtonText data-testid="add-other-button" onClick={addOtherAnswer}>
                      <strong>"Other, Please Specify"</strong>&nbsp;Option
                      {!question.noneAnswer ? ',' : null}
                    </ButtonText>
                  )}
                  {!question.noneAnswer && (
                    <ButtonText data-testid="add-none-button" onClick={addNoneAnswer}>
                      <strong>"None of the above"</strong>&nbsp;Option
                    </ButtonText>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

ModalInstantSurveyQuestion.defaultProps = {
  isDuplicate: false,
};

ModalInstantSurveyQuestion.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDuplicate: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  surveyType: PropTypes.object.isRequired,
};

export default ModalInstantSurveyQuestion;
