import axios from './api';

import { formatQuestion, transformQuestion } from './utilities/helpers';

/**
 * create question
 * @param {Object} options
 * @param {Object} options.question
 * @param {AbortSignal} [options.signal]
 * @return {Promise}
 */
export const createQuestion = async ({ question, signal }) => {
  return axios({
    data: formatQuestion(question),
    method: 'post',
    signal,
    url: `/question`,
  }).then(({ data }) => transformQuestion(data));
};

/**
 * read question
 * @param {Object} options
 * @param {String} options.id
 * @param {AbortSignal} options.signal
 * @return {Promise}
 */
export const readQuestion = async ({ id, signal }) => {
  return axios({
    method: 'get',
    signal,
    url: `/question/${id}`,
  }).then(({ data }) => transformQuestion(data));
};

/**
 * update question
 * @param {Object} options
 * @param {Object} options.question
 * @param {AbortSignal} [options.signal]
 * @return {Promise}
 */
export const updateQuestion = async ({ question, signal }) => {
  return axios({
    data: formatQuestion(question),
    method: 'put',
    signal,
    url: `/question/${question.id}`,
  }).then(({ data }) => transformQuestion(data));
};
