import JsxParser from 'react-jsx-parser';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { toast } from '@utilities';
import {
  websocketConnect,
  websocketConnected,
  websocketDisconnect,
  websocketDisconnected,
  updateReportBanner,
  updateReportStatus,
  updateReportSurveyGptJob,
  updateNotificationCenter,
  updateCustomGroupStatus,
  updateTabProgress,
} from '@redux/slices/websocket';

const websocketMiddleware = () => {
  let socket = null;

  const onOpen = (store) => () => {
    store.dispatch(websocketConnected());
  };

  const onClose = (store) => () => {
    store.dispatch(websocketDisconnected());
  };

  const onMessage = (store) => (event) => {
    const payload = JSON.parse(event?.data);
    const { is_toast: isToast, message, status = null } = payload?.data;

    const displayToast = () => {
      toast(
        <p>
          <JsxParser
            components={{
              a: ({ children, download, href }) => {
                return download ? (
                  <a download href={window.location.origin + href}>
                    {children}
                  </a>
                ) : (
                  <Link to={href}>{children}</Link>
                );
              },
            }}
            jsx={message}
            renderInWrapper={false}
          />
        </p>,
        {
          status,
        }
      );
    };

    switch (payload?.data?.type) {
      case 'job':
        store.dispatch(updateReportStatus(payload));
        break;
      case 'surveygptjob':
        store.dispatch(updateReportSurveyGptJob(payload));
        break;
      case 'banner':
        store.dispatch(updateReportBanner(payload));
        break;
      case 'default':
        if (isToast === true) {
          displayToast();
        }
        break;
      case 'notification_center':
        if (isToast === true) {
          displayToast();
        }
        store.dispatch(updateNotificationCenter(payload));
        break;
      case 'customgroup':
        store.dispatch(updateCustomGroupStatus(payload));
        break;
      case 'jobitem':
        store.dispatch(updateTabProgress(payload));
        break;
      default:
        break;
    }
  };

  return (store) => (next) => (action) => {
    switch (action.type) {
      case websocketConnect.type:
        if (socket !== null) {
          socket.close();
        }

        socket = new ReconnectingWebSocket(action.payload);

        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);

        break;
      case websocketDisconnect.type:
        if (socket !== null) {
          socket.close();
        }
        socket = null;

        break;
      default:
        return next(action);
    }
  };
};

export default websocketMiddleware();
