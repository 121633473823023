import PropTypes from 'prop-types';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';

import { scatterRegressionLineChartConfiguration, setModifiedData } from './utilities/helpers';

const ScatterRegressionLineChart = ({ series, xAxis, yAxis, ...props }) => {
  return (
    <Chart
      options={merge({}, scatterRegressionLineChartConfiguration, {
        series,
        type: 'scatter_regression_line',
        xAxis,
        yAxis,
        ...props,
      })}
      setModifiedData={setModifiedData}
      {...props}
    />
  );
};

ScatterRegressionLineChart.propTypes = {
  series: PropTypes.array,
  xAxis: PropTypes.object,
  yAxis: PropTypes.object,
};

export default ScatterRegressionLineChart;
