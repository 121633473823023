import PropTypes from 'prop-types';

import { Card, DEFAULT_CHART_COLORS, Layout, ProgressBar } from '@utilities';

import GroupAdvancedPrompts from '../../../../../CreateInstantSurvey/components/Panelists/components/QuotaGroupCard/components/GroupAdvancedPrompts';
import GroupMadlib from '../../../../../CreateInstantSurvey/components/Panelists/components/QuotaGroupCard/components/GroupMadlib';
import Questionnaire from '../Questionnaire';

import styles from './_index.module.scss';

const Details = ({ survey, surveyQuestions, surveyType }) => {
  return (
    <div className={styles['details']}>
      <h3>Quota {survey.groups.length > 1 ? 'Groups' : 'Group'}</h3>
      <Layout.Flex.Column className={styles['details-list']}>
        {survey.groups.map((group, index) => (
          <Card className={styles['details-card']} key={group.id} status="info">
            {/* <EditableTitle value={group.name} /> */}
            <h2>
              <span
                className={styles['details-group-color']}
                style={{ background: DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length] }}
              />
              {group.name}
            </h2>
            <Layout.Flex gap="x-large">
              <Layout.Fill>
                <GroupMadlib group={group} isReadOnly surveyType={surveyType} />
                {surveyType.advancedPrompts?.length > 0 && (
                  <GroupAdvancedPrompts
                    advancedPrompts={surveyType.advancedPrompts}
                    group={group}
                    isCompleted
                  />
                )}
              </Layout.Fill>
              <div className={styles['details-group-divider']} />
              <Layout.Sidebar>
                <div
                  className={styles['details-group-response-counts']}
                  id={`response-counts-${group.id}`}
                >
                  Requested Quota <strong>{group.quotaLimit}</strong>
                </div>
                <ProgressBar
                  aria-labelledby={`response-counts-${group.id}`}
                  max={group.quotaLimit}
                  value={group.responseCount}
                />
              </Layout.Sidebar>
            </Layout.Flex>
          </Card>
        ))}
      </Layout.Flex.Column>

      <Questionnaire surveyQuestions={surveyQuestions} />
    </div>
  );
};

Details.propTypes = {
  survey: PropTypes.object.isRequired,
  surveyQuestions: PropTypes.arrayOf(PropTypes.object),
  surveyType: PropTypes.object.isRequired,
};

export default Details;
