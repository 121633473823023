import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Announcement,
  Button,
  DateRangePickerV2,
  FreezeIcon,
  getFormattedDate,
  getPreviousDay,
  IconCTA,
  Input,
  isMaxNameLength,
  ModalV2 as Modal,
  OverlayTriggerTooltip,
  Select,
} from '@utilities';

import styles from './_index.module.scss';

const Freeze = ({
  createFreeze,
  hasException,
  hasFrozen,
  isHoverAction,
  onSubmit,
  readStaticGroups,
  selectedRows,
}) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [error, setError] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [selectedStaticGroup, setSelectedStaticGroup] = useState(null);
  const [staticGroups, setStaticGroups] = useState([]);

  const isVisible = selectedRows?.length === 1 && !hasException && !hasFrozen;

  const options = staticGroups.map((staticGroup) => ({
    label: staticGroup?.promptDesc,
    value: staticGroup?.id,
  }));
  const [startDate, endDate] = dateRange;

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      await createFreeze({
        dateRange,
        id: selectedRows[0]?.id,
        name,
        staticGroup: selectedStaticGroup?.value.split(':').pop(),
      });
      setIsActive(false);
      onSubmit({ refreshItems: true });
    } catch (error) {
      console.error(error);
      setError(error?.response?.data);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setDateRange([null, null]);
    setError([]);
    setIsSubmitting(false);
    setName(selectedRows[0]?.name);

    if (isActive) {
      const getStaticGroups = async () => {
        try {
          const response = await readStaticGroups();
          setStaticGroups(response?.data);
          setSelectedStaticGroup({
            label: response?.data[0]?.promptDesc,
            value: response?.data[0]?.id,
          });
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      };
      getStaticGroups();
    }
  }, [isActive]);

  if (!isVisible) return null;

  return (
    <>
      <OverlayTriggerTooltip content={isHoverAction ? 'Freeze Panelists' : ''}>
        <IconCTA
          data-testid="freeze-cta"
          icon={<FreezeIcon />}
          onClick={() => setIsActive(true)}
          text={isHoverAction ? '' : 'Freeze Panelists'}
        />
      </OverlayTriggerTooltip>
      <Modal
        buttons={[
          <Button
            key="cancel-btn"
            onClick={() => setIsActive(false)}
            text="Cancel"
            variant="secondary"
          />,
          <Button
            key="submit-btn"
            isDisabled={
              !(startDate && endDate && name && selectedStaticGroup) ||
              name.trim().length === 0 ||
              isMaxNameLength(name)
            }
            isLoading={isSubmitting}
            onClick={handleSubmit}
            text="Save"
          />,
        ]}
        isActive={isActive}
        onClose={() => setIsActive(false)}
        title="Freeze Panelists"
      >
        <div className={styles['freeze-content']}>
          {error && error?.length > 0 && <Announcement text={error} variant="error" />}
          <p>
            Freeze the panelists in a group to a date range of your choice. Use these frozen groups
            to analyze the exact same panelists and their behaviors across two different time
            periods.
          </p>
          <Input
            error={
              isMaxNameLength(name)
                ? 'Maximum number of characters reached. Try something shorter.'
                : ''
            }
            label="Group Name"
            onChange={setName}
            value={name}
          />
          <Select
            className={styles['freeze-select']}
            label="Static Group"
            isLoading={isLoading}
            onChange={setSelectedStaticGroup}
            options={options}
            value={selectedStaticGroup}
          />
          <DateRangePickerV2
            endDate={endDate}
            maxDate={getFormattedDate(getPreviousDay(new Date()))}
            onDatesChange={setDateRange}
            startDate={startDate}
          />
        </div>
      </Modal>
    </>
  );
};

Freeze.propTypes = {
  createFreeze: PropTypes.func,
  hasException: PropTypes.bool,
  hasFrozen: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  onSubmit: PropTypes.func,
  readStaticGroups: PropTypes.func,
  selectedRows: PropTypes.array,
};

export default Freeze;
