import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { OverlayTrigger } from 'react-bootstrap';

import Tooltip from '../Tooltip';

const OverlayTriggerTooltip = ({ children, content, isFullWidth, placement }) => {
  if (!content) return children;

  return (
    <OverlayTrigger
      delayShow={400}
      key={nanoid()}
      overlay={
        <Tooltip data-testid="active-tooltip" id={nanoid()} isFullWidth>
          {content}
        </Tooltip>
      }
      placement={placement}
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

OverlayTriggerTooltip.defaultProps = {
  placement: 'bottom',
};

OverlayTriggerTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isFullWidth: PropTypes.bool,
  placement: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
};

export default OverlayTriggerTooltip;
