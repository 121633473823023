export const GATES = {
  CROSSTABS_EXPORT: 'crosstabs_export',
  INSTANT_SURVEY_MAINTENANCE: 'instant_survey_maintenance',
  INSTANT_SURVEY_SERVICE: 'instant_survey_service',
  NUMERATOR_AI_BETA: 'numerator_ai_beta',
  PHOTO_UPLOAD: 'photo_upload',
  SURVEY_DASHBOARD_QUESTION_FILTERS: 'survey_dashboard_question_filters',
  SURVEY_LENGTH_EXTENDED: 'survey_length_extended',
  SURVEY_PRICING_SPLIT: 'survey_pricing_split',
  SURVEY_TEMPLATES: 'survey_templates',
};
