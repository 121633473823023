/**
 * gets input type based on format from data
 * @param  {String} format
 * @return {String}
 */
export const getInputType = (format) => {
	switch (format) {
		case '0,0':
			return 'decimal';
		case '$0.00':
			return 'dollar';
		case '0%':
			return 'percentage';
		default:
			return '';
	}
};
