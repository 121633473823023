import axios from '@api';
import {
  transformTripGroups,
  transformTripGroupFolders,
  transformTripGroupPrompts,
} from './utilities/helpers';

/**
 * trip group folder type id
 * @type {Number}
 */
const folderTypeId = 3;

/**
 * create trip group
 * @param  {Object}  options.answers
 * @param  {String}  options.name
 * @return {Promise}
 */
export const createTripGroup = ({ answers, name }) => {
  return axios({
    data: {
      answers,
      name,
    },
    method: 'post',
    url: '/v2/api/tripgroups',
    withCredentials: true,
  });
};

/**
 * create trip group folder
 * @param  {String}  options.name
 * @return {Promise}
 */
export const createTripGroupFolder = ({ name }) => {
  return axios({
    data: {
      folder_type: folderTypeId,
      parent_entry_id: 'root',
      name,
    },
    method: 'post',
    url: '/v2/api/folders',
    withCredentials: true,
  });
};

/**
 * create trip group join
 * @param  {Array}  options.answers
 * @param  {Number} options.id
 * @param  {String} options.name
 * @return {Promise}
 */
export const createTripGroupJoin = ({ answers, id, name }) => {
  // FIXME: join actions are still using v1 API because the 3+ group join is only implemented there
  return axios({
    data: {
      name,
      answers,
      type_id: id,
    },
    method: 'post',
    url: '/api/trip-groups',
    withCredentials: true,
  });
};

/**
 * delete trip group folders
 * @param  {Array} options.ids
 * @return {Promise}
 */
export const deleteTripGroupFolders = ({ ids }) => {
  return axios({
    data: ids,
    method: 'delete',
    url: '/v2/api/folders',
    withCredentials: true,
  });
};

/**
 * delete trip groups
 * @param  {Array} options.ids
 * @return {Promise}
 */
export const deleteTripGroups = ({ ids }) => {
  return axios({
    data: ids,
    method: 'delete',
    url: '/v2/api/tripgroups',
    withCredentials: true,
  });
};

/**
 * read trip group answers
 * @param  {Number} options.id
 * @param  {object} options.signal
 * @return {Promise}
 */
export const readTripGroupAnswers = ({ id, signal }) => {
  return axios({
    method: 'get',
    signal,
    transformResponse: [
      (data) => {
        const response = JSON.parse(data);

        return { answers: response?.answers, detailsString: response?.details_string };
      },
    ],
    url: `/v2/api/tripgroups/${id}`,
    withCredentials: true,
  });
};

/**
 * read trip group folders
 * @param  {String} options.order   "asc" || "desc" || undefined
 * @param  {Number} options.page
 * @param  {String} options.query
 * @param  {Number} options.signal  AbortController signal
 * @param  {Number} options.size
 * @param  {String} options.sortBy
 * @param  {Array}  options.tags    [is_locked, is_shared]
 * @return {Promise}
 */
export const readTripGroupFolders = ({ order, page, query, signal, size, sortBy, tags }) => {
  return axios({
    method: 'get',
    params: {
      order_by: sortBy ? order : undefined,
      page,
      query: query?.length > 0 ? query : undefined,
      size,
      sort_by: sortBy,
      tags: tags?.length > 0 ? tags?.join(',') : undefined,
    },
    signal,
    transformResponse: [(data) => transformTripGroupFolders(JSON.parse(data))],
    url: '/v2/api/tripgroups/folders/',
    withCredentials: true,
  });
};

/**
 * read trip group list V2
 * @param  {Number} options.folderId
 * @param  {String} options.order   "asc" || "desc" || undefined
 * @param  {Number} options.page
 * @param  {String} options.query
 * @param  {Number} options.signal  AbortController signal
 * @param  {Number} options.size
 * @param  {String} options.sortBy
 * @param  {Array}  options.tags    [is_locked, is_shared]
 * @return {Promise}
 */
export const readTripGroupItems = ({
  folderId,
  order,
  page,
  query,
  signal,
  size,
  sortBy,
  tags,
}) => {
  return axios({
    method: 'get',
    params: {
      folder_id: folderId,
      order_by: sortBy ? order : undefined,
      page,
      query: query?.length > 0 ? query : undefined,
      size,
      sort_by: sortBy,
      tags: tags?.length > 0 ? tags?.join(',') : undefined,
    },
    signal,
    transformResponse: [(data) => transformTripGroups(JSON.parse(data))],
    url: '/v2/api/tripgroups/',
    withCredentials: true,
  });
};

/**
 * read trip group prompts
 * @return {Promise}
 */
export const readTripGroupPrompts = ({ search = '', transform = true }) => {
  return axios({
    method: 'get',
    transformResponse: [
      (data) => {
        if (!transform) return JSON.parse(data);

        return transformTripGroupPrompts(JSON.parse(data));
      },
    ],
    url: `/api/trip-group-prompts${search}`,
    withCredentials: true,
  });
};

/**
 * shares group with all users under a client
 * @param  {Number} clientId
 * @param  {Array} ids
 * @return {Promise}
 */
export const shareTripGroupWithClient = ({ clientId, ids }) => {
  return axios({
    data: {
      tg_ids: ids,
      recipient_client_id: clientId,
    },
    method: 'post',
    url: `/v2/api/tripgroups/client_share`,
    withCredentials: true,
  });
};

/**
 * shares group with array of users
 * @param  {Array} options.ids
 * @param  {Boolean} options.skipEmail
 * @param  {Array} options.userIds
 * @return {Promise}
 */
export const shareTripGroupsWithUsers = ({ ids, skipEmail = false, userIds }) => {
  return axios({
    data: {
      recipient_ids: userIds,
      skip_email: skipEmail,
      tg_ids: ids,
    },
    method: 'post',
    url: '/v2/api/tripgroups/user_share',
    withCredentials: true,
  });
};

/**
 * shares group folders with all users under a client
 * @param  {Number} clientId
 * @param  {Array} folderIds
 * @return {Promise}
 */
export const shareTripGroupFoldersWithClient = ({ clientId, folderIds }) => {
  return axios({
    data: {
      client_id: clientId,
      shared_folder_ids: folderIds,
    },
    method: 'post',
    url: '/v2/api/folders/client_share',
    withCredentials: true,
  });
};

/**
 * shares group folders with array of users
 * @param  {Array} options.folderIds
 * @param  {Boolean} options.skipEmail
 * @param  {Array} options.userIds
 * @return {Promise}
 */
export const shareTripGroupFoldersWithUsers = ({ folderIds, skipEmail = false, userIds }) => {
  return axios({
    data: {
      shared_folder_ids: folderIds,
      shared_with_user_ids: userIds,
      skip_email: skipEmail,
    },
    method: 'post',
    url: '/v2/api/folders/user_share',
    withCredentials: true,
  });
};

/**
 * add groups into any folder
 * @param  {Number} folderId
 * @param  {Array}  ids
 * @return {Promise}
 */
export const updateTripGroupFolder = ({ folderId, ids }) => {
  return axios({
    data: { folder_type: folderTypeId, ids, parent_entry_id: folderId },
    method: 'put',
    url: '/v2/api/folders/move',
    withCredentials: true,
  });
};

/**
 * update trip group name
 * @param  {Number} options.id
 * @param  {String} options.name
 * @return {Promise}
 */
export const updateTripGroupName = ({ id, name }) => {
  return axios({
    data: { name },
    method: 'put',
    url: `/v2/api/tripgroups/${id}/rename`,
    withCredentials: true,
  });
};

/**
 * rename trip group folder
 * @param  {Number} id
 * @param  {String} name
 * @return {Promise}
 */
export const updateTripGroupFolderName = ({ id, name }) => {
  return axios({
    data: { name },
    method: 'put',
    url: `/v2/api/folders/${id}/rename`,
    withCredentials: true,
  });
};
