import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './_index.module.scss';

const TextArea = ({ className, error, isDisabled, label, onChange, value, ...props }) => {
  const textAreaClassNames = {
    [styles['is-error']]: error,
  };

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div>
      {label && <label className={styles['textarea-label']}>{label}</label>}
      <textarea
        className={classNames(styles['textarea'], className, textAreaClassNames)}
        disabled={isDisabled}
        onChange={handleChange}
        value={value}
        {...props}
      />
      {error && <p className={styles['textarea-error']}>{error}</p>}
    </div>
  );
};

TextArea.defaultProps = {
  'aria-label': 'textarea',
  onChange: () => {},
};

TextArea.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default TextArea;
