import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useMatch } from 'react-router-dom';

import { getReportIcon, getSurveyIcon, Search, Tabs } from '@utilities';

import styles from './_index.module.scss';

const Module = ({ children, route }) => {
  const [value, setValue] = useState('');

  const { pathnameBase } = useMatch('/dashboard/:type/*');

  const routes = route?.routes;
  const type = routes[0]?.type;

  const options = routes
    .reduce((a, b) => a.concat(b?.routes), [])
    .filter((route) => !route?.is_locked && !route?.is_hidden)
    .map((route) => {
      return {
        label: route?.label,
        node: (
          <Link tabIndex="-1" to={`/dashboard/create-${type}/${route?.id}`}>
            {type === 'report' ? getReportIcon(route?.id) : getSurveyIcon(route?.id)}
            <span>{route?.label}</span>
          </Link>
        ),
        tags: route?.tags,
        value: route?.id,
      };
    });

  const uniqueOptions = [...new Map(options?.map((option) => [option?.label, option])).values()];
  const results = uniqueOptions?.filter((option) => {
    const optionLabelMatches = option?.label?.toLowerCase().includes(value.toLowerCase());
    const tagMatches = option?.tags?.some((tag) => tag.toLowerCase().includes(value.toLowerCase()));
    return optionLabelMatches || tagMatches;
  });

  return (
    <div className={styles['module']}>
      <Search
        className={styles['module-search']}
        isScrollable={true}
        onChange={setValue}
        placeholder={`Search all ${type}s`}
        results={results}
        value={value}
      />
      <Tabs
        routes={routes?.map((route) => {
          return {
            ...route,
            to: `${pathnameBase}/${route?.id}`,
          };
        })}
      />
      {children}
    </div>
  );
};

Module.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  route: PropTypes.object,
};

export default Module;
