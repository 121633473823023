/**
 * used to format endpoints for local or dev
 * @param   {String}  endpoint to go to or gain access to some service
 * @returns {String}
 */
export const setEndpoint = (endpoint = '') => {
  return process.env.NODE_ENV === 'development'
    ? `http://localhost:8000/${endpoint}`
    : `${window.location.origin}/${endpoint}`;
};
