/**
 * gets label based on various situations
 * @param  {String} options.label
 * @param  {Date}   options.selectedEndDate
 * @param  {Date}   options.selectedStartDate
 * @return {String}
 */
export const getLabel = ({ label, selectedEndDate, selectedStartDate }) => {
  const defaultLabel = label || 'Select a Start and End Date';

  if (!selectedStartDate || !selectedEndDate) return defaultLabel;

  const numberOfDays =
    Math.round((selectedEndDate.getTime() - selectedStartDate.getTime()) / (1000 * 3600 * 24)) + 1;

  if (numberOfDays < 0) return defaultLabel;

  return `${numberOfDays} Day${numberOfDays > 1 ? 's' : ''} Selected`;
};
