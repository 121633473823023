import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { createReportTabComment } from '@api/reports';
import { readClientUsers } from '@api/user';
import { Button, ModalV2 as Modal, Select, Tabs, TextArea } from '@utilities';

import Public from './components/Public';

import { attemptToShare, getCommentText, tabOptions } from './utilities/helpers';

import styles from './_index.module.scss';

const ModalShare = ({
  ids,
  isFolder,
  hasAllColleagues,
  hasFindColleagues,
  hasSharePublicly,
  onAddNewComment,
  onSubmit,
  setShowModalShare,
  shareFoldersWithClients,
  shareFoldersWithUsers,
  shareItemWithClients,
  shareItemWithUsers,
  tabId,
}) => {
  const [activeTab, setActiveTab] = useState('');
  const [allUsersSelection, setAllUsersSelection] = useState();
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState();
  const [users, setUsers] = useState([]);

  const { clientInfo, id } = useSelector((state) => state?.user?.data);

  const isSubmitDisabled =
    activeTab === tabOptions.SHARE_PUBLICLY ||
    (activeTab === tabOptions.FIND_COLLEAGUES && (!selectedUsers || selectedUsers.length === 0)) ||
    (activeTab === tabOptions.ALL_COLLEAGUES &&
      (!allUsersSelection || allUsersSelection.length === 0));
  const tabOptionsArray = [
    ...(hasFindColleagues ? [{ label: 'Find Colleagues', value: 'find-colleagues' }] : []),
    ...(hasAllColleagues ? [{ label: 'All Colleagues', value: 'all-colleagues' }] : []),
    ...(hasSharePublicly && !isFolder
      ? [{ label: 'Share Publicly', value: 'share-publicly' }]
      : []),
  ];

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await readClientUsers();
        const usersData = response?.data?.map((item) => ({
          label: item.display,
          value: item.id,
        }));
        setUsers(usersData?.filter((userData) => userData?.value !== id));
      } catch (err) {
        setError(err);
        console.warn(error);
      }
    };
    getUsers();
  }, [setUsers]);

  useEffect(() => {
    setActiveTab(tabOptionsArray[0].value);
  }, []);

  const onShareSubmit = async () => {
    setIsLoading(true);
    try {
      const allUserIds = users.map((answer) => answer.value);
      const selectedUserIds = selectedUsers ? selectedUsers.map((answer) => answer.value) : [];
      const selectedValue = allUsersSelection?.value;
      const commentText = getCommentText({ activeTab, comment, selectedUsers, selectedValue });
      await attemptToShare({
        activeTab,
        allUserIds,
        clientId: clientInfo?.id,
        isFolder,
        ids,
        selectedUserIds,
        selectedValue,
        shareFoldersWithClients,
        shareFoldersWithUsers,
        shareItemWithClients,
        shareItemWithUsers,
      });

      if (tabId) {
        const commentResponse = await createReportTabComment({ tabId, text: commentText });
        onAddNewComment(commentResponse?.data);
      }

      onSubmit();
      setShowModalShare(false);
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      buttons={
        activeTab !== tabOptions.SHARE_PUBLICLY
          ? [
              <Button
                data-testid="modal-share-cancel"
                key="cancel"
                onClick={() => setShowModalShare(false)}
                text="Cancel"
                variant="secondary"
              />,
              <Button
                data-testid="modal-share-submit"
                isDisabled={isSubmitDisabled}
                isLoading={isLoading}
                key="submit"
                onClick={onShareSubmit}
                text="Share"
              />,
            ]
          : []
      }
      isActive
      onClose={() => setShowModalShare(false)}
      title="Share with people"
    >
      <div className={styles['modal-share']}>
        {tabOptionsArray.length > 1 && (
          <Tabs onChange={setActiveTab} options={tabOptionsArray} value={activeTab} />
        )}
        {activeTab === tabOptions.FIND_COLLEAGUES && (
          <>
            <Select
              className={styles['modal-share-select']}
              data-testid="modal-share-select"
              isMulti={true}
              onChange={setSelectedUsers}
              options={users}
              placeholder="Find colleagues"
              value={selectedUsers}
            />
            {tabId && (
              <TextArea
                className={styles['modal-share-textarea']}
                data-testid="modal-share-textarea"
                onChange={setComment}
                placeholder="Enter a comment... (optional)"
                value={comment}
              />
            )}
          </>
        )}
        {activeTab === tabOptions.ALL_COLLEAGUES && (
          <>
            <Select
              className={styles['modal-share-select']}
              data-testid="modal-share-select"
              onChange={setAllUsersSelection}
              options={[
                { label: `All ${users.length} colleagues at ${clientInfo?.name}`, value: 1 },
                { label: `All current and future colleagues at ${clientInfo?.name}`, value: 2 },
              ]}
              value={allUsersSelection}
            />
            {tabId && (
              <TextArea
                className={styles['modal-share-textarea']}
                data-testid="modal-share-textarea"
                onChange={setComment}
                placeholder="Enter a comment... (optional)"
                value={comment}
              />
            )}
          </>
        )}
        {activeTab === tabOptions.SHARE_PUBLICLY && <Public id={ids?.[0]} />}
      </div>
    </Modal>
  );
};

ModalShare.defaultProps = {
  isFolder: false,
  hasAllColleagues: true,
  hasFindColleagues: true,
  hasSharePublicly: true,
  onAddNewComment: () => {},
  onSubmit: () => {},
};

ModalShare.propTypes = {
  ids: PropTypes.array,
  isFolder: PropTypes.bool,
  hasAllColleagues: PropTypes.bool,
  hasFindColleagues: PropTypes.bool,
  hasSharePublicly: PropTypes.bool,
  onAddNewComment: PropTypes.func,
  onSubmit: PropTypes.func,
  setShowModalShare: PropTypes.func,
  shareFoldersWithClients: PropTypes.func,
  shareFoldersWithUsers: PropTypes.func,
  shareItemWithClients: PropTypes.func,
  shareItemWithUsers: PropTypes.func,
  tabId: PropTypes.number,
};

export default ModalShare;
