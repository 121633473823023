import PropTypes from 'prop-types';

import styles from './_index.module.scss';

import { LINEAR, NONLINEAR } from '../../utilities/options';

const StepIndicator = ({ activeIndex, navigationType, totalSteps }) => {
  if (!totalSteps) {
    return;
  } // prevent divide by zero
  const stepSize = +(100 / totalSteps).toFixed(2);
  return (
    <div
      className={styles['step-indicator']}
      aria-valuemax={totalSteps}
      aria-valuemin="1"
      aria-valuenow={activeIndex + 1}
      role="progressbar"
      style={{
        left: navigationType === LINEAR ? 0 : `${stepSize * activeIndex}%`,
        width: navigationType === LINEAR ? `${stepSize * (activeIndex + 1)}%` : `${stepSize}%`,
      }}
    />
  );
};

StepIndicator.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  navigationType: PropTypes.oneOf([LINEAR, NONLINEAR]).isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default StepIndicator;
