import PropTypes from 'prop-types';

import { Button, ModalV2 as Modal } from '@utilities';

const ModalSaveAsDraft = ({ isActive, isSaving, onClose, onSubmit }) => {
  return (
    <Modal
      buttons={[
        <Button key="modal-cancel-button" onClick={onClose} text="Cancel" variant="secondary" />,
        <Button key="modal-save-button" isLoading={isSaving} onClick={onSubmit} text="Save" />,
      ]}
      isActive={isActive}
      onClose={onClose}
      title="Save as Draft"
    >
      <p>You can come back to this survey later from your saved drafts.</p>
    </Modal>
  );
};

ModalSaveAsDraft.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ModalSaveAsDraft;
