/**
 * filters breakouts based on seach value
 * @param  {Array} options.breakouts
 * @param  {String} options.value
 * @return {Array}
 */
export const getFilterBreakouts = ({ breakouts, value }) => {
  const cleanedValue = value.replace(/[^\w]/gi, '');
  return breakouts?.filter((breakout) => {
    const hasTitleMatch = breakout?.title?.toLowerCase().includes(value.toLowerCase());
    const hasDefinitionMatch = breakout?.definition
      ?.toLowerCase()
      .includes(cleanedValue.toLowerCase());
    const hasConditionsMatch = breakout?.conditionalDefinitionAttributes?.conditions?.some(
      (condition) => {
        return condition?.values?.some((conditionTitle) =>
          Object.keys(conditionTitle)[0].toLowerCase().includes(value.toLowerCase())
        );
      }
    );

    return hasTitleMatch || hasDefinitionMatch || hasConditionsMatch;
  });
};
