import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import Mousetrap from 'mousetrap';

import { CloseBoldIcon, RepromptIcon, SearchIcon } from '../../icons';
import Input from '../Input';

import styles from './_index.module.scss';

const Search = ({
  className,
  hasAutoFocus,
  isClearable,
  isSearching,
  isScrollable,
  onChange,
  placeholder,
  results,
  value,
}) => {
  const resultsEl = useRef(null);
  const [currentFocus, setCurrentFocus] = useState(0);

  const hasResults = value && results?.length > 0;

  useEffect(() => {
    if (hasResults && currentFocus < results?.length - 1) {
      Mousetrap.bind(['down'], (e) => {
        e.preventDefault();
        setCurrentFocus(currentFocus + 1);
      });
    }

    if (hasResults && currentFocus > 0) {
      Mousetrap.bind(['up'], (e) => {
        e.preventDefault();
        setCurrentFocus(currentFocus - 1);
      });
    }

    if (hasResults) {
      Mousetrap.bind(['enter'], () => {
        resultsEl?.current?.childNodes[currentFocus].childNodes[0].click();
      });
    }

    if (hasResults) {
      Mousetrap.bind(['esc'], () => {
        onChange('');
      });
    }

    if (!hasResults) {
      Mousetrap.reset();

      return () => {
        Mousetrap.reset();
      };
    }
  }, [currentFocus, hasResults, onChange, results]);

  useEffect(() => {
    setCurrentFocus(0);
  }, [value]);

  useEffect(() => {
    return () => {
      Mousetrap.reset();
    };
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={results ? () => onChange('') : () => {}}>
      <div className={classNames(styles['search'], className)}>
        <div className={styles['searchbar']} data-testid="searchbar">
          {isSearching ? (
            <RepromptIcon className={styles['searchbar-loading-icon']} />
          ) : (
            <SearchIcon className={styles['searchbar-search-icon']} />
          )}
          <Input
            autoFocus={hasAutoFocus}
            className="mousetrap"
            onChange={onChange}
            placeholder={placeholder}
            value={value}
          />
          {value && isClearable && !isSearching && (
            <CloseBoldIcon
              className={styles['searchbar-close-icon']}
              onClick={() => onChange('')}
            />
          )}
        </div>
        {hasResults && (
          <ul
            className={classNames(styles['search-results'], {
              [styles['is-scrollable']]: isScrollable,
            })}
            data-testid="search-results"
            ref={resultsEl}
          >
            {results.map((result, index) => (
              <li
                className={index === currentFocus ? styles['is-active'] : ''}
                key={`result-${index}`}
              >
                {result?.node}
              </li>
            ))}
          </ul>
        )}
      </div>
    </OutsideClickHandler>
  );
};

Search.defaultProps = {
  isClearable: true,
  isScrollable: false,
  onChange: () => {},
  placeholder: 'Search',
};

Search.propTypes = {
  className: PropTypes.string,
  hasAutoFocus: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearching: PropTypes.bool,
  isScrollable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  results: PropTypes.array,
  value: PropTypes.string,
};

export default Search;
