import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MAX_NAME_LENGTH } from '../../helpers/validations';

import styles from './_index.module.scss';

const EditableTitle = ({ autoFocus, className, error, isDisabled, onBlur, onChange, value }) => {
  const onKeyDown = (event) => {
    if (event?.key === 'Enter') {
      onChange(event?.target.value);
      event.target.blur();
    }
  };

  return (
    <div
      className={classNames(styles['editable-title'], className, {
        [styles['has-error']]: error,
        [styles['is-disabled']]: isDisabled,
      })}
    >
      <div className={styles['editable-title-input-wrapper']}>
        <h1>{value}</h1>
        <input
          aria-label="editable-title"
          autoFocus={autoFocus}
          className={classNames({ [styles['has-error']]: error })}
          data-testid="editable-title"
          disabled={isDisabled}
          maxLength={MAX_NAME_LENGTH}
          onBlur={(event) => onBlur(event.target.value)}
          onChange={(event) => onChange(event.target.value)}
          onFocus={(event) => event.target.select()}
          onKeyDown={(event) => onKeyDown(event)}
          type="text"
          value={value}
        />
      </div>
      {error && <p className={styles['editable-title-error']}>{error}</p>}
    </div>
  );
};

export default EditableTitle;

EditableTitle.defaultProps = {
  isDisabled: false,
  onBlur: () => {},
  onChange: () => {},
  value: '',
};

EditableTitle.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};
