import { createSlice } from '@reduxjs/toolkit';

import { updateOrAppendItem } from './helpers';

const initialState = {
  isConnected: false,
  customGroups: [],
  notification: {
    isToast: false,
    message: '',
    type: null,
  },
  reports: [],
  tabs: [],
};

const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    websocketConnect: (state) => state,
    websocketConnected: (state) => {
      state.isConnected = true;
    },
    websocketDisconnect: (state) => state,
    websocketDisconnected: (state) => {
      return initialState;
    },
    updateCustomGroupStatus: (state, action) => {
      const { data } = action.payload;

      updateOrAppendItem({
        array: state.customGroups,
        data: {
          conditionalDefinitionAttributes: data.conditional_definition_attributes,
          id: data.customgroup_id,
          status: data.customgroup_status,
        },
      });
    },
    updateReportStatus: (state, action) => {
      const { data } = action.payload;

      updateOrAppendItem({
        array: state.reports,
        data: {
          completeJobItemIds: data.complete_job_item_ids,
          id: data.job_id,
          message: data.message,
          numberCompleteTabs: data.complete_job_items,
          numberIncompleteTabs: data.incomplete_job_items,
          status: data.job_status,
          type: data.type,
        },
      });
    },
    updateReportBanner: (state, action) => {
      const { data } = action.payload;

      updateOrAppendItem({
        array: state.reports,
        data: { characteristicsJobId: data.job_id, id: data.job_id },
      });
    },
    updateReportSurveyGptJob: (state, action) => {
      const { data } = action.payload;

      updateOrAppendItem({
        array: state.reports,
        data: { id: data.job_id, surveyGPTJobId: data.surveygpt_job_id },
      });
    },
    updateTabProgress: (state, action) => {
      const { data } = action.payload;

      updateOrAppendItem({
        array: state.tabs,
        data: {
          documentId: data.document_id,
          id: data.job_id,
          progress: data.progress,
          reportId: data.job_id,
          tabId: data.job_item_id,
          type: data.type,
        },
      });
    },
    updateNotificationCenter: (state, action) => {
      const { data } = action.payload;
      state.notification.isToast = data.is_toast;
      state.notification.message = data.message;
      state.notification.type = data.type;
    },
  },
});

export const {
  updateCustomGroupStatus,
  updateNotificationCenter,
  updateReportBanner,
  updateReportStatus,
  updateReportSurveyGptJob,
  updateTabProgress,
  websocketConnect,
  websocketConnected,
  websocketDisconnect,
  websocketDisconnected,
} = websocketSlice.actions;

export default websocketSlice.reducer;
