import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LogRocket from 'logrocket';

import { PageTitle, useTheme, THEMES_DARK } from '@utilities';

import Dashboard from './pages/Dashboard';
import Interceptors from './components/Interceptors';
import Login from './pages/Login';
import Plugins from './components/Plugins';
import Share from './pages/Share';

import 'reset-css';
import '@utilities/_global.scss';

const App = () => {
  const theme = useTheme();

  useEffect(() => {
    document.addEventListener('click', handleLogRocketEvents);
    return () => document.removeEventListener('click', handleLogRocketEvents);
  }, []);

  useEffect(() => {
    if (theme === THEMES_DARK.value) {
      document.body.classList.add(THEMES_DARK.value);
    } else {
      document.body.classList.remove(THEMES_DARK.value);
    }
  }, [theme]);

  const handleLogRocketEvents = ({ target }) => {
    const element = target?.closest('[data-log]');
    if (element) {
      const eventName = element.dataset.log;
      let eventProperties = element.dataset.logProperties;
      if (eventName && eventProperties) {
        try {
          eventProperties = JSON.parse(eventProperties);
          LogRocket.track(eventName, eventProperties);
        } catch (error) {
          console.error(error);
          LogRocket.track(eventName, { error: true });
        }
      } else if (eventName) {
        LogRocket.track(eventName);
      }
    }
  };

  return (
    <div id="app">
      <PageTitle title="Numerator Insights" />

      <Routes>
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/share/:token/:tab_id" element={<Share />} />
        <Route path="/share/:token/*" element={<Share />} />
        <Route path="/*" element={<Navigate to="/dashboard" replace />} />
      </Routes>

      <Interceptors />
      <Plugins />
    </div>
  );
};

export default App;
