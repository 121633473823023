import classNames from 'classnames';
import PropTypes from 'prop-types';

import { questionTypes } from '@api/surveys';

import { Checkbox, FolderIcon } from '@utilities';

import styles from './_index.module.scss';

const Results = ({
  maxSelectableQuestions,
  onSelectQuestion,
  questions,
  questionsToAdd,
  searchQuery,
  selectedQuestions = [],
}) => {
  if (!questions.length) {
    return (
      <p>
        {searchQuery ? 'Nothing matches your search criteria.' : 'No questions in this folder.'}
      </p>
    );
  }

  return (
    <ul className={styles['results-container']}>
      {questions.map((question) => (
        <li key={question.id}>
          {question.isFolder && (
            <button className={styles['results-folder']} onClick={() => onSelectQuestion(question)}>
              <FolderIcon /> {question.text}
            </button>
          )}
          {!question.isFolder && (
            <div className={styles['results-question']}>
              <div>
                <div className={styles['results-checkbox-wrapper']}>
                  <Checkbox
                    isChecked={
                      questionsToAdd.some(({ id }) => id === question.id) ||
                      selectedQuestions.some(({ id }) => id === question.id)
                    }
                    isDisabled={
                      (!questionsToAdd.some(({ id }) => id === question.id) &&
                        questionsToAdd.length >= maxSelectableQuestions) ||
                      selectedQuestions.some(({ id }) => id === question.id)
                    }
                    label={question.text}
                    onChange={() => onSelectQuestion(question)}
                    value={question.id}
                  />
                </div>
                <span
                  className={classNames(styles['results-caption'], {
                    [styles['is-disabled']]: selectedQuestions.some(({ id }) => id === question.id),
                  })}
                >
                  {question.type === questionTypes.essay.value
                    ? 'Open-ended response'
                    : question.caption}
                </span>
              </div>
              {question.image?.cdnUrl && (
                <img
                  alt="Uploaded File"
                  className={styles['results-image']}
                  src={question.image.cdnUrl}
                />
              )}
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

Results.propTypes = {
  maxSelectableQuestions: PropTypes.number.isRequired,
  onSelectQuestion: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  questionsToAdd: PropTypes.array.isRequired,
  searchQuery: PropTypes.string,
  selectedQuestions: PropTypes.array,
};

export default Results;
