import axios from './api';
import axiosDefault from 'axios';

import { transformImageObject } from './utilities/helpers';

/**
 * create image object
 * @param {String} options.objectType
 * @return {Promise}
 */
export const createImageObject = async ({ objectType: object_type }) => {
  return axios({
    data: { object_type },
    method: 'post',
    url: '/image',
  }).then(({ data }) => transformImageObject(data));
};

/**
 * upload image to S3
 * @param {File} options.file
 * @param {String} options.url
 * @return {Promise}
 */
export const uploadImageToS3 = async ({ file, url }) => {
  return axiosDefault.put(url, file, { headers: { 'Content-Type': file.type } });
};
