import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Toolbar from '../../../../components/Builder/components/Toolbar';

import styles from './_index.module.scss';

const Welcome = ({ isReportCompleted, reportId }) => {
  const { firstName } = useSelector((state) => state?.user?.data);

  return (
    <div className={styles['welcome']}>
      <Toolbar
        canReprompt={isReportCompleted}
        hasFindColleagues={false}
        hasAllColleagues={false}
        isCompleted
        isDownloadAvailable={isReportCompleted}
        reportId={reportId}
        repromptUrl={`/dashboard/create-report/homepage?job_id=${reportId}`}
      >
        <h1>
          <strong>Welcome back, {firstName}</strong>
        </h1>
      </Toolbar>
      <p>
        Here is your Insights Homepage. We set up this page for you with key metrics. It’s updated
        weekly.
      </p>
    </div>
  );
};

Welcome.propTypes = {
  isReportCompleted: PropTypes.bool,
  reportId: PropTypes.number,
};

export default Welcome;
