import PropTypes from 'prop-types';

import styles from './_index.module.scss';

const Metadata = ({ children, header }) => {
  return (
    <div className={styles[`metadata`]}>
      <h3>{header}</h3>
      <ul>{children}</ul>
    </div>
  );
};

Metadata.propTypes = {
  children: PropTypes.array,
  header: PropTypes.string,
};

export default Metadata;
