import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Search, Select, useDebounce } from '@utilities';
import { readProxyAccounts } from '@api/user';
import { switchToBaseUser, switchToProxyUser } from '@redux/slices/user';

import styles from './_index.module.scss';

const Info = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [proxyAccountOptions, setProxyAccountOptions] = useState([]);
  const [searchQuery, setSearchQuery, { signal, isDebouncing }] = useDebounce('');
  const user = useSelector((state) => state?.user);
  const { baseAccountUser, email, firstName, id, lastName, panel, permissions, surveyInfo } =
    user?.data;
  const [selectedAccount, setSelectedAccount] = useState(
    baseAccountUser
      ? {
          label: email,
          value: id,
        }
      : null
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchRef = useRef(null);

  useEffect(() => {
    if (!baseAccountUser) setSelectedAccount(null);
    if (searchRef?.current) {
      searchRef.current.abort();
    }
    searchRef.current = new AbortController();

    const getData = async () => {
      setIsLoading(true);
      try {
        const response = await readProxyAccounts({
          searchQuery,
          signal: searchRef.current.signal,
        });
        const results =
          response?.data?.accounts?.map((account) => ({
            node: <div onClick={() => setSelectedAccount(account)}>{account?.label}</div>,
          })) || [];
        setIsLoading(false);
        setProxyAccountOptions(results);
      } catch (error) {
        console.error(error, `Encountered an issue on trying to fetch proxy account options`);
        setIsLoading(false);
      }
    };

    if (permissions?.canLoginAsProxy && searchQuery.trim() !== '') {
      getData();
    }
  }, [signal]);

  const applyChanges = async () => {
    try {
      const baseAccountId = baseAccountUser?.id;
      if (baseAccountId) {
        await dispatch(switchToBaseUser({ baseAccountId, navigate }));
      } else if (selectedAccount?.value) {
        await dispatch(switchToProxyUser({ navigate, proxyAccountId: selectedAccount?.value }));
      }
    } catch (error) {
      console.error(error, `Could not switch user.`);
    }
  };

  return (
    <ul className={styles['info']}>
      <li>
        <h4>Name</h4>
        <p>{`${firstName} ${lastName}`}</p>
      </li>
      {permissions?.canLoginAsProxy && (
        <li>
          <h4>Account</h4>
          {baseAccountUser ? (
            <Select
              data-testid="info-select-switch-account"
              isLoading={isLoading}
              onChange={setSelectedAccount}
              options={[{ label: 'Personal Account', value: baseAccountUser }]}
              value={selectedAccount}
            />
          ) : (
            <Search
              isSearching={(isDebouncing || isLoading) && searchQuery !== ''}
              onChange={setSearchQuery}
              placeholder="Search for an account"
              results={selectedAccount ? [] : proxyAccountOptions}
              value={selectedAccount ? selectedAccount.label : searchQuery}
            />
          )}
        </li>
      )}
      <li>
        <h4>Panel Country</h4>
        <p>{`${panel?.title} ${panel?.emoji}`}</p>
      </li>
      <li>
        <h4>Email</h4>
        <p>{email}</p>
      </li>
      {permissions?.canCreateSurveys && (
        <li>
          <h4>Survey Credits</h4>
          <p>{surveyInfo?.userCredits}</p>
        </li>
      )}

      <div className={styles['info-actions']}>
        <div className={styles['info-actions-toolbar']}>
          <Button
            data-testid="info-apply-changes"
            isDisabled={!selectedAccount?.value || email === selectedAccount?.label}
            onClick={applyChanges}
            text="Save"
          />
        </div>
      </div>
    </ul>
  );
};

export default Info;
