import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getPromptPillTooltip, getPromptPillValue } from '@helpers';
import {
  MinusPlusButtons,
  OverlayTriggerTooltip,
  Pill,
  truncateTextWithEllipsis,
} from '@utilities';

import { getMadlibRowCount, isRequiredAdditionalPrompt } from './utilities/helpers';

import styles from './_index.module.scss';

const Madlibs = ({
  activePrompt,
  answers,
  isCompleted,
  madlib,
  madlibAdditional,
  madlibAdditionalLimit,
  onClearAnswers,
  prompts,
  setActivePrompt,
}) => {
  const madlibRowCount = getMadlibRowCount(answers);

  const [madlibRows, setMadlibRows] = useState([
    madlib,
    ...Array(madlibRowCount).fill(madlibAdditional),
  ]);

  const addMadlibRow = () => {
    setMadlibRows([...madlibRows, madlibAdditional]);
  };
  const removeMadlibRow = ({ index, prompts }) => {
    const numberOfPrompts = [...(madlibAdditional?.match(/{x}/g) || [])].length;
    const start = index * numberOfPrompts;
    const end = start + numberOfPrompts;
    const promptIds = prompts.slice(start, end).map((prompt) => prompt?.id);
    onClearAnswers({ promptIds });

    setMadlibRows(madlibRows.slice(0, -1));
  };

  const regex = /(\s?[\w'’;?,><)()"]+\s?)+|{{1}x}{1}/g; // eslint-disable-line security/detect-unsafe-regex
  let parts = [];
  let promptIndex = 0;

  const className = {
    [styles['is-complete']]: isCompleted,
  };

  return (
    <div className={classNames(styles['madlibs'], className)}>
      {madlibRows?.map((madlibRow, index) => {
        parts = madlibRow?.match(regex);

        const hasMinusButton = index === madlibRows.length - 1 && index > 1;
        const hasPlusButton = index === madlibRows.length - 1 && index < madlibAdditionalLimit;
        const hasQuestionMark = madlibAdditional && index === madlibRows.length - 1;

        return (
          <p key={`madlib-row-${index}`}>
            <span className={styles['madlib-wrapper']}>
              {parts?.map((part, partIndex) => {
                let prompt;
                let tooltip;
                let value;

                if (part === '{x}') {
                  prompt = prompts[promptIndex++];
                  tooltip = getPromptPillTooltip({ answers, prompt });
                  value = truncateTextWithEllipsis({
                    text: getPromptPillValue({ answers, prompt }),
                  });
                }

                return (
                  <Fragment key={`madlib-pill-${partIndex}`}>
                    {prompt ? (
                      <OverlayTriggerTooltip content={tooltip} isFullWidth>
                        <Pill
                          data-testid={`madlib-pill-${prompt?.id}`}
                          isActive={prompt === activePrompt}
                          isCompleted={isCompleted}
                          isRequired={
                            prompt?.isRequired ||
                            isRequiredAdditionalPrompt({
                              madlibRowIndex: index,
                              prompts,
                              promptId: prompt?.id,
                            })
                          }
                          isModified={answers?.hasOwnProperty(prompt?.id)}
                          onClick={() => setActivePrompt(prompt)}
                          onClose={() => onClearAnswers({ promptId: prompt?.id })}
                          value={value}
                        />
                      </OverlayTriggerTooltip>
                    ) : (
                      <span>{part}</span>
                    )}
                  </Fragment>
                );
              })}
            </span>
            <span>{hasQuestionMark && ' ?'}</span>
            {madlibAdditional && !isCompleted && (
              <span className={styles['madlib-buttons']}>
                <MinusPlusButtons
                  hasMinusButton={hasMinusButton}
                  hasPlusButton={hasPlusButton}
                  onClickMinus={() => removeMadlibRow({ index, prompts })}
                  onClickPlus={addMadlibRow}
                />
              </span>
            )}
          </p>
        );
      })}
    </div>
  );
};

Madlibs.propTypes = {
  activePrompt: PropTypes.object,
  answers: PropTypes.object,
  isCompleted: PropTypes.bool,
  madlib: PropTypes.string,
  madlibAdditional: PropTypes.string,
  madlibAdditionalLimit: PropTypes.number,
  onClearAnswers: PropTypes.func,
  prompts: PropTypes.array,
  setActivePrompt: PropTypes.func,
};

export default Madlibs;
