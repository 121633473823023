import { useCallback } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { LINEAR } from './utilities/options';
import Step from './components/Step';
import StepIndicator from './components/StepIndicator';

import styles from './_index.module.scss';

const Stepper = ({ activeIndex, className, navigationType, onChange, steps, ...props }) => {
  const handleSelect = useCallback((index) => {
    if ((navigationType === LINEAR && index >= activeIndex) || steps[index].isDisabled) {
      return;
    }
    if (onChange) {
      onChange(index);
    }
  });

  return (
    <ol className={classNames(styles['stepper'], className)} {...props}>
      <StepIndicator
        activeIndex={activeIndex}
        navigationType={navigationType}
        totalSteps={steps.length}
      />
      {steps.map((step, index) => (
        <li key={`step-${index}`}>
          <Step
            activeIndex={activeIndex}
            index={index}
            isComplete={step.isComplete}
            isDisabled={(navigationType === LINEAR && index > activeIndex) || step.isDisabled}
            label={step.label}
            onClick={handleSelect}
          />
        </li>
      ))}
    </ol>
  );
};

Stepper.defaultProps = {
  navigationType: 'linear',
};

Stepper.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  className: PropTypes.string,
  navigationType: PropTypes.oneOf(['linear', 'non-linear']),
  onChange: PropTypes.func,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      isComplete: PropTypes.bool,
      isDisabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Stepper;
