import { COLOR_BANTAMWEIGHT, COLOR_PINK, COLOR_MINT } from '../../../helpers/colors';

/**
 * scatter regression line chart specific configurations
 * @type {Object}
 */
export const scatterRegressionLineChartConfiguration = {
  colors: [COLOR_BANTAMWEIGHT, COLOR_PINK, COLOR_MINT],
  tooltip: {
    shared: true,
    useHTML: true,
  },
};

/**
 * modifies options based on data
 * @param  {Object} options
 * @return {Object}
 */
export const setModifiedData = (options) => {
  options.series[0].color = options.colors[0];
  options.series[0].enableMouseTracking = false;
  options.series[0].lineWidth = 1;
  options.series[0].marker = {
    enabled: false,
  };
  options.series[0].showInLegend = false;
  options.series[0].states = {
    inactive: {
      opacity: 1,
    },
  };
  options.series[0].type = 'line';

  options?.series?.forEach((group) => {
    if (!group.type) {
      group.type = 'scatter';
      group.marker = { symbol: 'circle' };
    }

    group.data.forEach((point) => {
      if (point?.pointColor === 'red') {
        group.color = options.colors[1];
      } else if (point?.pointColor === 'green') {
        group.color = options.colors[2];
      }
    });
  });

  return options;
};
