/* eslint-disable react/prop-types */
import { Tag } from '@utilities';
import { ErrorIcon, ExclamationTriangleIcon } from '@utilities/icons';

export const FILTER_OPTIONS = [
  {
    label: 'Automate',
    options: [
      {
        label: 'Active',
        value: 'is_active_item',
      },
      {
        label: 'Inactive',
        value: 'is_inactive_item',
      },
      {
        label: 'Expired',
        value: 'is_expired_item',
      },
    ],
  },
];

export const ITEM_COLUMNS = [
  {
    accessor: 'name',
    Cell: ({ row }) => {
      return <>{row?.original?.name}</>;
    },
    Header: 'Name',
    width: 350,
  },
  {
    accessor: 'status',
    Cell: ({ row }) => {
      switch (row?.original?.status) {
        case 'is_active':
          return 'Active';
        case 'is_inactive':
          return <Tag icon={<ExclamationTriangleIcon />} label="Inactive" />;
        case 'is_expired':
          return <Tag icon={<ErrorIcon />} label="Expired" />;
        default:
          return <Tag icon={<ErrorIcon />} label="Error" />;
      }
    },
    disableSortBy: true,
    Header: 'Status',
    width: 100,
  },
  {
    accessor: 'previousRunDatetime',
    Cell: ({ row }) => {
      return row?.original?.previousRunDatetime
        ? new Date(row?.original?.previousRunDatetime).toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })
        : '--';
    },
    Header: 'Most Recent',
    width: 100,
  },
  {
    accessor: 'nextRunDatetime',
    Cell: ({ row }) => {
      return row?.original?.nextRunDatetime
        ? new Date(row?.original?.nextRunDatetime).toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })
        : '--';
    },
    Header: 'Next',
    width: 100,
  },
];
