/**
 * gets label based on various situations
 * @param  {String} options.label
 * @param  {Date}   options.selectedEndDate
 * @param  {Date}   options.selectedStartDate
 * @return {String}
 */
export const getLabel = ({ label, selectedDate }) => {
  if (label) return label;

  const defaultLabel = 'Select Date';

  return defaultLabel;
};
