import axios from '@api';

/**
 * create pinned answer
 * @param  {String} documentId
 * @param  {Object} option
 * @param  {String} promptId
 * @return {Promise}
 */
export const createPinnedAnswer = ({ documentId, option, promptId }) => {
  return axios({
    data: {
      document_id: documentId,
      ...option,
      prompt_id: promptId,
    },
    method: 'post',
    url: `/v2/api/pinned-answers`,
    withCredentials: true,
  });
};

/**
 * read prompt search results
 * @param  {Array} attributeIds   
 * @param  {String} documentId
 * @param  {String} entryId  
 * @param  {String} promptId
 * @param  {Object} searchAnswers
 * @param  {Array} searchPrompts
 * @param  {String} searchQuery 
 * @param  {String} signal AbortController signal
= * @return {Promise}
 */
export const readSearchResults = ({
  attributeIds,
  documentId,
  entryId,
  promptId,
  searchAnswers = {},
  searchPrompts = [],
  searchQuery,
  signal,
}) => {
  const answers = searchPrompts?.flat().includes(promptId) ? JSON.stringify(searchAnswers) : {};
  return axios({
    method: 'get',
    params: {
      answers,
      attributeId: attributeIds.join(','),
      documentId,
      parent_id: entryId,
      search: searchQuery,
    },
    signal,
    url: `/api/prompts/${promptId}/search`,
    withCredentials: true,
  });
};

/**
 * read pinned answers
 * @param  {String} documentId
 * @param  {String} signal
 * @return {Promise}
 */
export const readPinnedAnswers = ({ documentId, signal }) => {
  return axios({
    method: 'get',
    params: {
      document_id: documentId,
    },
    signal,
    url: `/v2/api/pinned-answers/`,
    withCredentials: true,
  });
};

/**
 * delete pinned answer
 * @param  {String} pinnedAnswerId
 * @return {Promise}
 */
export const deletePinnedAnswer = ({ pinnedAnswerId }) => {
  return axios({
    method: 'delete',
    url: `/v2/api/pinned-answers/${pinnedAnswerId}`,
    withCredentials: true,
  });
};
