import error403 from '../assets/403Image@2x.png';
import error404 from '../assets/Error_Missing.png';
import error500 from '../assets/500Image@2x.png';
import errorClientImage from '../assets/ClientError_BrokenBottle@2x.png';
import errorExpired from '../assets/Error_Expired.png';
import errorReportCancelled from '../assets/Error_ReportCanceled_EmptyShelf.png';

/**
 * gets templated description, header, and image based on status
 * @param  {String} options.description
 * @param  {String} options.header
 * @param  {Number} options.status
 * @return {Object}
 */
export const getTemplate = ({ description, header, status }) => {
  switch (status) {
    case 403:
    case 'access-denied':
      return {
        descriptionText:
          description ||
          'Sorry, but you do not have permission to access this page. It may be something simple, please reach out to your consultant.',
        headerText: header || 'Access Denied',
        imageSource: error403,
      };
    case 404:
    case 'page-not-found':
      return {
        descriptionText:
          description ||
          "Sorry, but the page you are looking for was moved, removed, renamed, or may have never existed... Let's just say it’s missing.",
        headerText: header || 'Page Not Found',
        imageSource: error404,
      };
    case 409:
    case 'account-error':
      return {
        descriptionText:
          description ||
          "This is awkward. There's more than one user signed into this Numerator Insights account.",
        headerText: header || 'Account Error',
        imageSource: error403,
      };
    case 500:
    case 'internal-server-error':
      return {
        descriptionText:
          description ||
          'Sorry, seems like something is broken. Your request could not be completed. If the problem persists please contact your consultant.',
        headerText: header || 'Internal Server Error',
        imageSource: error500,
      };
    case 'client':
    case 'client-error':
      return {
        descriptionText:
          description ||
          "Something's not quite right. If this error persists, please contact your consultant.",
        headerText: header || 'Client Error',
        imageSource: errorClientImage,
      };
    case 'cancelled-report':
    case 'failed-report':
      return {
        descriptionText:
          description ||
          'Curious...these shelves appear empty. We’re not quite sure why. Numerator recommends reprompting this report',
        headerText: header || 'Report Failed',
        imageSource: errorReportCancelled,
      };
    case 'outdated-report':
      return {
        descriptionText:
          description ||
          "Oh no! This report is out of date! It's been updated recently, so it would be a good idea to run a brand new report.",
        headerText: header || 'Out Of Date',
        imageSource: errorExpired,
      };
    case 'under-construction':
      return {
        descriptionText:
          description || 'Sorry, but the page you are looking for is under construction.',
        headerText: header || 'Under Construction',
        imageSource: error403,
      };
    default:
      return {
        descriptionText: description || 'Sorry, seems like something is broken.',
        headerText: header || 'Something went wrong',
        imageSource: error500,
      };
  }
};
