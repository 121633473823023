import { Link } from 'react-router-dom';

import rocketLaunch from './assets/rocket-launch.svg';

import buttonStyles from '@utilities/components/Button/_index.module.scss';
import styles from './_index.module.scss';

const Confirmation = () => {
  return (
    <div className={styles['confirmation']}>
      <img src={rocketLaunch} alt="Rocket Launch" />
      <h1>Survey Launched</h1>
      <h2>Your survey is now in our review queue</h2>
      <p>
        Our team will be reviewing your survey for quality control before it is sent to our
        panelists. You will receive an email notification once it starts collecting responses.
      </p>
      <div>
        <Link to={`/dashboard/workspace/surveys`}>
          <div className={buttonStyles['button-primary']}>Go to My Surveys</div>
        </Link>
      </div>
    </div>
  );
};

export default Confirmation;
