import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './_index.module.scss';

const Fill = ({ as: Component, children, className, ...props }) => (
  <Component className={classNames(styles['fill'], className)} {...props}>
    {children}
  </Component>
);

Fill.defaultProps = {
  as: 'div',
};

Fill.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Fill;
