import PropTypes from 'prop-types';

import { Announcement, Button, ModalV2 as Modal } from '@utilities';
import { undulatingWave } from '@utilities/assets';

import styles from './_index.module.scss';

const ModalConfirmAskWhy = ({
  isSubmitting,
  onClose,
  onSubmit,
  panelistCount,
  paymentKey,
  submitError,
  submitText,
}) => {
  return (
    <Modal
      buttons={[
        <Button
          data-log="Checkout_MakeChanges"
          id="modal-confirm-ask-why-cancel"
          isDisabled={isSubmitting}
          key="cancel-btn"
          onClick={onClose}
          text="Make Changes"
          variant="secondary"
        />,
        <Button
          data-log={`Checkout_Confirm_${paymentKey}`}
          id="modal-confirm-ask-why-continue"
          isLoading={isSubmitting}
          key="submit-btn"
          onClick={onSubmit}
          text={submitText}
        />,
      ]}
      className={styles['modal-confirm-ask-why']}
      id="modal-confirm-ask-why"
      isActive
      onClose={onClose}
      title="Send to Panelists"
    >
      {submitError && (
        <Announcement
          text={submitError?.response?.data?.error || 'There was an error creating the survey.'}
          variant="error"
        />
      )}
      <div className={styles['modal-confirm-ask-why-image']} role="presentation">
        <img alt="" data-testid="wave" src={undulatingWave} />
      </div>
      <p>
        <strong>You are about to send questions to {panelistCount} panelists.</strong> As soon as
        they start coming in, responses will appear in the "AskWhy" tab of your Insights Report.
        This report will be shared with your colleagues that have Insights access, available in
        their My Workspace &gt; My Reports.
      </p>
      <p>Select "{submitText}" if you do not have any additional changes.</p>
    </Modal>
  );
};

ModalConfirmAskWhy.defaultProps = {
  panelistCount: 100,
  submitText: 'Continue',
};

ModalConfirmAskWhy.propTypes = {
  isSubmitting: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  panelistCount: PropTypes.number,
  paymentKey: PropTypes.string.isRequired,
  submitError: PropTypes.object,
  submitText: PropTypes.string,
};

export default ModalConfirmAskWhy;
