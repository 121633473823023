import axios from '@api';

/**
 * Fetches a jwt service token to be used with independent isc docker services (instant_survey).
 * @returns {Promise}
 */
export const readApiServiceToken = () => {
  return axios({
    method: 'get',
    url: '/v2/api/tokens/api-service-token',
    withCredentials: true,
  }).then(({ data }) => data?.token);
};
