import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  createReportFolder,
  deleteReportFolders,
  deleteReports,
  readReportDetails,
  readReportFolders,
  readReportItems,
  readReportPrompts,
  shareReportFolderWithClientGroup,
  shareReportFolderWithUsers,
  shareReportWithClientGroup,
  shareReportWithUsers,
  updateReportFavorite,
  updateReportFolder,
  updateReportFolderName,
  updateReportName,
} from '@api/reports';

import ItemAnswers from '../../components/ItemAnswers';
import ListPage from '../../components/ListPage';

import AddToFolder from '../../components/ListPage/components/ActionsToolbar/components/AddToFolder';
import Automate from '../../components/ListPage/components/ActionsToolbar/components/Automate';
import BulkDownload from '../../components/ListPage/components/ActionsToolbar/components/BulkDownload';
import Delete from '../../components/ListPage/components/ActionsToolbar/components/Delete';
import Details from '../../components/ListPage/components/ActionsToolbar/components/Details';
import Download from '../../components/ListPage/components/ActionsToolbar/components/Download';
import Favorite from '../../components/ListPage/components/ActionsToolbar/components/Favorite';
import Rename from '../../components/ListPage/components/ActionsToolbar/components/Rename';
import Reprompt from '../../components/ListPage/components/ActionsToolbar/components/Reprompt';
import Share from '../../components/ListPage/components/ActionsToolbar/components/Share';

import { FOLDER_COLUMNS, getFilterOptions, ITEM_COLUMNS } from './utilities/helpers';

const Reports = () => {
  const canAccessAskWhy = useSelector((state) => state?.user?.data?.permissions?.canAccessAskWhy);
  const canShareReportsPublicly = useSelector(
    (state) => state?.user?.data?.permissions?.canShareReportsPublicly
  );
  const filterOptions = getFilterOptions({ canAccessAskWhy });
  const navigate = useNavigate();

  return (
    <ListPage
      createFolder={createReportFolder}
      filterOptions={filterOptions}
      folderActions={[
        <Rename type="Report" updateName={updateReportFolderName} />,
        <Share
          shareWithClient={shareReportFolderWithClientGroup}
          shareWithUsers={shareReportFolderWithUsers}
        />,
        <BulkDownload />,
        <Delete deleteItems={deleteReportFolders} />,
      ]}
      folderColumns={FOLDER_COLUMNS}
      itemActions={[
        <Details />,
        <Rename type="Report" updateName={updateReportName} />,
        <AddToFolder
          createFolder={createReportFolder}
          readFolders={readReportFolders}
          updateFolder={updateReportFolder}
        />,
        <Download />,
        <Favorite updateFavorite={updateReportFavorite} />,
        <Share
          hasSharePublicly={canShareReportsPublicly}
          shareWithClient={shareReportWithClientGroup}
          shareWithUsers={shareReportWithUsers}
        />,
        <Automate />,
        <Reprompt />,
        <BulkDownload />,
        <Delete deleteItems={deleteReports} />,
      ]}
      itemColumns={ITEM_COLUMNS}
      onCreate={() => navigate('/dashboard/reports/')}
      onExpandItemRow={() => {}}
      readFolders={readReportFolders}
      readItems={readReportItems}
      renderRowExpanded={({ row }) => (
        <ItemAnswers
          item={row?.original}
          readItemAnswers={readReportDetails}
          readPrompts={readReportPrompts}
          type="Report"
        />
      )}
      type="Report"
      workspace="Reports"
    />
  );
};

export default Reports;
