/* eslint-disable react/prop-types */

import { surveyStatus } from '@api/surveys';

import { ErrorIcon } from '@utilities/icons';
import { Tag } from '@utilities';

export const FILTER_OPTIONS = [
  {
    label: 'Drafts',
    options: [
      {
        label: 'Drafts',
        value: 'is_draft_item',
      },
    ],
  },
  {
    label: 'Launched',
    options: [
      {
        label: 'Issuing',
        value: 'is_issuing_item',
      },
      {
        label: 'Active',
        value: 'is_active_item',
      },
      {
        label: 'Complete',
        value: 'is_complete_item',
      },
    ],
  },
];

export const ITEM_COLUMNS = [
  {
    accessor: 'title',
    Header: 'Name',
    width: 250,
  },
  {
    accessor: 'instantSurveyTypeName',
    disableSortBy: true,
    Header: 'Type',
    width: 100,
  },
  {
    accessor: 'status',
    Cell: ({ row }) => {
      switch (row?.original?.status) {
        case surveyStatus.CREATED:
        case surveyStatus.FIELDED:
        case surveyStatus.FIELDING:
        case surveyStatus.PUBLISHING:
        case surveyStatus.PUBLISHED:
          return 'Issuing';
        case surveyStatus.EXCEPTION:
        case surveyStatus.INCOMPLETE:
        case surveyStatus.IN_REVIEW:
        case surveyStatus.LOW_SAMPLE:
          return 'In Review';
        case surveyStatus.MET_SAMPLE:
          return 'Active';
        case surveyStatus.COMPLETED:
          return 'Complete';
        case surveyStatus.DRAFT:
          return 'Draft';
        default:
          return <Tag icon={<ErrorIcon />} label="Error" />;
      }
    },
    disableSortBy: true,
    Header: 'Status',
    width: 100,
  },
  {
    accessor: 'creator',
    disableSortBy: true,
    Header: 'Creator',
    width: 100,
  },
  {
    accessor: 'created_datetime',
    Cell: ({ row }) => {
      return new Date(row?.original?.created_datetime).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    Header: 'Created',
    width: 100,
  },
];
