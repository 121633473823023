export const sanitizeCommentText = ({ text, usersList }) => {
  const mentionRegex = /@\{\{[^}]+\}\}/gi;
  const mentionsList = text?.match(mentionRegex) || [];
  if (!mentionsList) return text;
  const allSharedUsersMentions = [
    { display: 'Shared with all current colleagues', id: 'all-current-users' },
    {
      display: 'Shared with all current and future colleagues',
      id: 'all-current-and-future-users',
    },
  ];
  let newObject = [text];

  for (const mention of mentionsList) {
    const splitObject = newObject.pop().split(mention);
    const openingBracketIndex = 3;
    const closingBracketIndex = -2;
    const userId = mention.slice(openingBracketIndex, closingBracketIndex);
    const expandedUserList = [...usersList, ...allSharedUsersMentions];
    const userName = expandedUserList?.find((user) => user?.id.toString() === userId)?.display;
    const newDiv = <div key={`mentionedUserId_${userId}`}>{userName}</div>;
    newObject = newObject.concat(splitObject[0], newDiv, splitObject[1]);
  }

  return newObject;
};
