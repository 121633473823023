import axios from '@api';

import { transformNotification } from './utilities/helpers';

/**
 * read all notifications
 * @return {Promise}
 */
export const readAllNotifications = () => {
  return axios({
    method: 'get',
    url: '/api/notification/read_all',
    withCredentials: true,
  });
};

/**
 * get notifications
 * @param  {String} page
 * @return {Promise}
 */
export const readNotifications = ({ page }) => {
  return axios({
    method: 'get',
    transformResponse: [(data) => transformNotification(JSON.parse(data))],
    url: `/api/notification?page=${page}`,
    withCredentials: true,
  });
};
