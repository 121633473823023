import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './_index.module.scss';

const Sidebar = ({ as: Component, children, className, ...props }) => {
  return (
    <Component className={classNames(styles['sidebar'], className)} {...props}>
      {children}
    </Component>
  );
};

Sidebar.defaultProps = {
  as: 'div',
};

Sidebar.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Sidebar;
