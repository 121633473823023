/**
 * transforms and formats automated report item data
 * @param  {Object} data
 * @return {Object}
 */
export const transformAutomatedReportItemData = (data) => {
  return {
    createdDatetime: new Date(data?.created_datetime).toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
    entries: data?.entries?.map((entry) => {
      return {
        endDate: entry?.data_end_date,
        id: entry?.id,
        name: entry?.name,
        runDatetime: entry?.run_datetime ? new Date(entry?.run_datetime) : '--',
        scheduledJob: entry?.scheduled_job,
        startDate: entry?.data_start_date,
      };
    }),
    id: data?.id,
    name: data?.name,
    nextRunDatetime: data?.next_run_datetime,
    originalReportId: data?.original_job,
    previousRunDatetime: data?.previous_run_datetime,
    protected: data?.protected,
    reportDestination: data?.report_destination,
    status:
      data?.status === 1
        ? 'is_active'
        : data?.status === 2
        ? 'is_inactive'
        : data?.status === 3
        ? 'is_expired'
        : '',
    type: data?.report_type,
  };
};

/**
 * transforms and formats automated report list items
 * @param  {Object} data
 * @param {String} [options.order]
 * @param {String} [options.query]
 * @param {String} [options.sortBy]
 * @param {Array} [options.tags]
 * @return {Object}
 */
export const transformAutomatedReportsData = (data, { order = 'desc', query, sortBy, tags }) => {
  const searchLowercase = query?.toLowerCase();
  let searchResults = data
    ?.filter(({ name }) => {
      if (!query) {
        return true;
      }
      return name?.toLowerCase().includes(searchLowercase);
    })
    ?.reverse()
    .map(transformAutomatedReportItemData);

  if (tags.length) {
    searchResults = searchResults?.filter((item) => tags?.includes(item.status));
  }

  if (sortBy) {
    searchResults?.sort((resultA, resultB) => {
      const valueA = resultA[sortBy];
      const valueB = resultB[sortBy];
      if (valueA === null && valueB === null) {
        return 0;
      } else if (valueA === null) {
        return -1;
      } else if (valueB === null) {
        return 1;
      }
      return valueA > valueB ? -1 : 1;
    });
    if (order === 'asc') {
      searchResults?.reverse();
    }
  }

  return {
    items: searchResults,
  };
};
