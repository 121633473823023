import PropTypes from 'prop-types';

import Checkbox from '../../../../components/Checkbox';

import styles from './_index.module.scss';

const MultiOption = ({ data, isSelected, label, selectOption }) => {
  return (
    <div className={styles['multi-option']}>
      <Checkbox isChecked={isSelected} label={label} onChange={selectOption} value={data} />
    </div>
  );
};

MultiOption.propTypes = {
  data: PropTypes.object,
  isSelected: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  selectOption: PropTypes.func,
};

export default MultiOption;
