import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { LoaderSkeleton, OverlayTriggerTooltip } from '@utilities';
import { readHomepageRecentReports } from '@api/reports';

import ReportName from './components/ReportName';
import styles from './_index.module.scss';

const RecentReports = () => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recentReports, setRecentReports] = useState([]);

  useEffect(() => {
    const getRecentReports = async () => {
      try {
        const response = await readHomepageRecentReports();
        setRecentReports(response?.data?.items);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setError(error);
        setIsLoading(false);
      }
    };
    getRecentReports();
  }, []);

  return (
    <div className={styles['recent-reports']}>
      <h2>
        <strong>Recent Reports</strong>
      </h2>
      <div>
        Here's a list of reports you've run recently. Want to see{' '}
        <Link to="/dashboard/workspace/reports">
          <span>all of them?</span>
        </Link>
      </div>
      <hr />
      {isLoading && (
        <LoaderSkeleton height={155}>
          <rect x="0" y="5" rx="2" ry="2" width="200" height="15" />
          <rect x="0" y="30" rx="2" ry="2" width="550" height="15" />
          <rect x="0" y="60" rx="2" ry="2" width="200" height="15" />
          <rect x="0" y="85" rx="2" ry="2" width="550" height="15" />
          <rect x="0" y="115" rx="2" ry="2" width="200" height="15" />
          <rect x="0" y="140" rx="2" ry="2" width="550" height="15" />
        </LoaderSkeleton>
      )}
      {recentReports?.length > 0 && !error && (
        <ul>
          {recentReports.map((recentReport, index) => {
            return (
              <li key={index}>
                <ReportName data={recentReport} />
                <OverlayTriggerTooltip
                  id="recent-reports-madlib-tooltip"
                  content={recentReport?.madlib}
                  position="top"
                >
                  {recentReport?.madlib}
                </OverlayTriggerTooltip>
              </li>
            );
          })}
        </ul>
      )}
      {!isLoading && recentReports?.length === 0 && !error && (
        <div>You haven't run any reports yet! </div>
      )}
      {error && (
        <div>
          There has been an issue displaying your recent reports here. Please go to{' '}
          <Link to="/dashboard/workspace/reports">My Workspace</Link> to see your reports.
        </div>
      )}
    </div>
  );
};

export default RecentReports;
