/**
 * max name character length
 * @type {Number}
 */
export const MAX_NAME_LENGTH = 128;

/**
 * email regex
 * @type {RegExp}
 */
// eslint-disable-next-line security/detect-unsafe-regex
const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

/**
 * email validation
 * @param  {String} email
 * @return {Boolean}
 */
export const isValidEmailAddress = (email) => email.match(validEmailRegex);

/**
 * name validation (report, people groups, trip groups, etc.)
 * @param  {String} name
 * @return {Boolean}
 */
export const isMaxNameLength = (name) => name !== '' && name?.length > MAX_NAME_LENGTH;
