import PropTypes from 'prop-types';

import { Button, ModalV2 as Modal } from '@utilities';

import styles from './_index.module.scss';

const ModalValidationErrors = ({ onClose, survey, validationErrors }) => {
  return (
    <Modal
      buttons={[<Button key="button" onClick={onClose} text="Okay, Got it" />]}
      className={styles['modal-validation-errors']}
      isActive
      title="Invalid Selection"
    >
      <ul className={styles['modal-validation-errors-list']}>
        {!validationErrors || validationErrors.length === 0 ? (
          <li>
            <p>• Some prompt selection was invalid.</p>
          </li>
        ) : (
          validationErrors.map(({ id: groupId, ...fields }) => {
            return (
              <li key={`group-${groupId}`}>
                <h3>{survey.groups.find(({ id }) => groupId === id)?.name}</h3>
                {Object.entries(fields).map(([field, errors]) => (
                  <div
                    className={styles['modal-validation-errors-list-field']}
                    key={`group-${groupId}-${field}`}
                  >
                    <h4>{field.replace('_', ' ')}</h4>
                    <ul>
                      {errors.map((errorMessage, errorIndex) => (
                        <li
                          key={`group-${groupId}-${field}-${errorIndex}`}
                        >{`• ${errorMessage}`}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </li>
            );
          })
        )}
      </ul>
    </Modal>
  );
};

ModalValidationErrors.propTypes = {
  onClose: PropTypes.func.isRequired,
  survey: PropTypes.object.isRequired,
  validationErrors: PropTypes.array,
};

export default ModalValidationErrors;
