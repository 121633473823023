import React from 'react';
import PropTypes from 'prop-types';

import { Button, ModalV2 as Modal, Pill } from '@utilities';

import styles from './_index.module.scss';

const ModalValidation = ({ invalidAnswers, isInvalid, setActivePrompt, setIsInvalid }) => {
  return (
    <Modal
      buttons={[<Button key="button" onClick={() => setIsInvalid(false)} text="Okay, Got it" />]}
      className={styles['modal-validation']}
      isActive={isInvalid}
      title="Missing Selection"
    >
      <p>
        Oops. We hit a snag. To process this report you will need to provide a little more
        information. Please enter values for the following prompts:
      </p>
      <ul>
        {invalidAnswers.map((prompt, index) => {
          return (
            <li key={`required-prompt-${index}`}>
              <Pill
                isRequired
                onClick={() => {
                  setActivePrompt(prompt);
                  setIsInvalid(false);
                }}
                value={prompt?.emptyValue}
              />
            </li>
          );
        })}
      </ul>
    </Modal>
  );
};

ModalValidation.propTypes = {
  invalidAnswers: PropTypes.array,
  isInvalid: PropTypes.bool,
  setActivePrompt: PropTypes.func,
  setIsInvalid: PropTypes.func,
};

export default ModalValidation;
