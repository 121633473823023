import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {
  Announcement,
  Error as ErrorComponent,
  Footnote,
  FootnoteItem,
  Layout,
  Legend,
  LoaderSkeleton,
  Select,
} from '@utilities';

import { STORAGE } from '@constants';

import Charts from './components/Charts';
import Table from './components/Table';

import { getUpdatedSelectedFilters } from './utilities/helpers';

import styles from './_index.module.scss';

const Insights = ({
  hasSharedLocalFilters,
  readBarChartData,
  readChartsFiltersData,
  readGlobalFiltersData,
  readLineChartData,
  readTableData,
  readTableFiltersData,
  tab,
}) => {
  const [barChartData, setBarChartData] = useState(null);
  const [chartsFilters, setChartsFilters] = useState([]);
  const [chartsTitle, setChartsTitle] = useState('');
  const [error, setError] = useState(null);
  const [globalFilters, setGlobalFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingGlobal, setIsUpdatingGlobal] = useState(false);
  const [isUpdatingCharts, setIsUpdatingCharts] = useState(false);
  const [isUpdatingChartsFilters, setIsUpdatingChartsFilters] = useState(false);
  const [isUpdatingTable, setIsUpdatingTable] = useState(false);
  const [isUpdatingTableFilters, setIsUpdatingTableFilters] = useState(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState('');
  const [legendItems, setLegendItems] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [resetFiltersHistory, setResetFiltersHistory] = useState(false);
  const [selectedChartsFilters, setSelectedChartsFilters] = useState({});
  const [selectedGlobalFilters, setSelectedGlobalFilters] = useState({});
  const [selectedTableFilters, setSelectedTableFilters] = useState({});
  const [tableData, setTableData] = useState(null);
  const [tableFilters, setTableFilters] = useState([]);
  const [tableTitle, setTableTitle] = useState('');
  const filtersStorageKey = STORAGE.filters;
  const [filtersHistory, setFiltersHistory] = useState(
    JSON.parse(localStorage.getItem(filtersStorageKey)) || {}
  );

  const chartsFiltersRef = useRef(null);
  const globalFiltersRef = useRef(null);
  const tableFiltersRef = useRef(null);

  const handleResetFiltersHistory = () => {
    const updatedFiltersHistory = { ...filtersHistory };
    delete updatedFiltersHistory[tab];

    setFiltersHistory(updatedFiltersHistory);
    setResetFiltersHistory(true);
  };

  const hasFiltersHistory = filtersHistory.hasOwnProperty(tab);

  const getBarChartData = async ({ globalFilters, chartsFilters, signal }) => {
    if (!readBarChartData) return;

    const response = await readBarChartData({ chartsFilters, globalFilters, signal });

    setBarChartData(response?.data);
  };

  const getGlobalFiltersData = async ({ signal }) => {
    const response = await readGlobalFiltersData({ signal });

    const filters = response?.data?.filters;
    const selectedFilters = !isEmpty(filtersHistory?.[tab]?.globalFilters)
      ? filtersHistory?.[tab]?.globalFilters
      : getUpdatedSelectedFilters({ filters });

    setGlobalFilters(filters);
    setLastUpdatedDate(response?.data?.lastUpdated);
    setSelectedGlobalFilters(selectedFilters);
  };

  const getLineChartData = async ({ globalFilters, chartsFilters, signal }) => {
    if (!readLineChartData) return;

    const response = await readLineChartData({ chartsFilters, globalFilters, signal });

    setLineChartData(response?.data);
  };

  const getChartsFiltersData = async ({
    chartsFilters,
    globalFilters,
    isFirstLoad = false,
    signal,
    updatedChartsFilter,
  }) => {
    if (!readChartsFiltersData) return;

    const response = await readChartsFiltersData({ chartsFilters, globalFilters, signal });

    const filters = response?.data?.filters;
    const title = response?.data?.title;

    const selectedFilters =
      !isEmpty(filtersHistory?.[tab]?.chartsFilters) && isFirstLoad
        ? filtersHistory?.[tab]?.chartsFilters
        : getUpdatedSelectedFilters({
            filters,
            selectedFilters: chartsFilters,
            updatedFilter: updatedChartsFilter,
          });

    setChartsFilters(filters);
    setChartsTitle(title);
    setSelectedChartsFilters(selectedFilters);

    return selectedFilters;
  };

  const getTableData = async ({ globalFilters, signal, tableFilters }) => {
    if (!readTableData) return;

    const response = await readTableData({ globalFilters, tableFilters, signal });

    setTableData(response?.data);
  };

  const getTableFiltersData = async ({
    globalFilters,
    isFirstLoad = false,
    signal,
    tableFilters,
    updatedTableFilter,
  }) => {
    if (!readTableFiltersData) return;

    const response = await readTableFiltersData({ globalFilters, tableFilters, signal });

    const filters = response?.data?.filters;
    const title = response?.data?.title;

    const selectedFilters =
      !isEmpty(filtersHistory?.[tab]?.tableFilters) && isFirstLoad
        ? filtersHistory?.[tab]?.tableFilters
        : getUpdatedSelectedFilters({
            filters,
            selectedFilters: tableFilters,
            updatedFilter: updatedTableFilter,
          });

    setTableFilters(filters);
    setTableTitle(title);
    setSelectedTableFilters(selectedFilters);

    return selectedFilters;
  };

  const handleChartCallback = (chart) => {
    setLegendItems(
      chart?.series
        ?.filter((series) => !series?.area)
        ?.map(({ color, name }) => ({ color, name })) || []
    );
  };

  const handleOnSelectedChartsFilterChange = async (updatedChartsFilter) => {
    const updatedSelectedChartsFilters = {
      ...selectedChartsFilters,
      [updatedChartsFilter?.id]: updatedChartsFilter?.value,
    };

    setSelectedChartsFilters(updatedSelectedChartsFilters);

    setIsUpdatingCharts(true);
    if (hasSharedLocalFilters) setIsUpdatingTable(true);

    try {
      chartsFiltersRef?.current?.abort();
      const controller = new AbortController();
      chartsFiltersRef.current = controller;
      const signal = chartsFiltersRef?.current?.signal;

      let chartsFilters = updatedSelectedChartsFilters;

      if (updatedChartsFilter?.resetDefaults) {
        setIsUpdatingChartsFilters(true);

        chartsFilters = await getChartsFiltersData({
          chartsFilters: updatedSelectedChartsFilters,
          globalFilters: selectedGlobalFilters,
          updatedChartsFilter,
        });

        setIsUpdatingChartsFilters(false);
      }

      setFiltersHistory({
        ...filtersHistory,
        [tab]: {
          ...filtersHistory[tab],
          chartsFilters,
          globalFilters: selectedGlobalFilters,
          tableFilters: hasSharedLocalFilters ? chartsFilters : selectedTableFilters,
        },
      });

      await Promise.all([
        getBarChartData({ chartsFilters, globalFilters: selectedGlobalFilters, signal }),
        getLineChartData({ chartsFilters, globalFilters: selectedGlobalFilters, signal }),
        hasSharedLocalFilters
          ? getTableData({
              globalFilters: selectedGlobalFilters,
              signal,
              tableFilters: chartsFilters,
            })
          : Promise.resolve(),
      ]);

      setIsUpdatingCharts(false);
      setIsUpdatingTable(false);
      chartsFiltersRef.current = null;
    } catch (error) {
      if (error?.response?.status > 200) {
        console.error(error);
        setError(error);
        setIsUpdatingCharts(false);
      }
    }
  };

  const handleOnSelectedGlobalFilterChange = async (updatedGlobalFilter) => {
    const updatedSelectedGlobalFilters = {
      ...selectedGlobalFilters,
      [updatedGlobalFilter?.id]: updatedGlobalFilter?.value,
    };

    setSelectedGlobalFilters(updatedSelectedGlobalFilters);

    setIsUpdatingGlobal(true);

    try {
      globalFiltersRef?.current?.abort();
      const controller = new AbortController();
      globalFiltersRef.current = controller;
      const signal = globalFiltersRef?.current?.signal;

      const [updatedChartsFilters, updatedTableFilters] = await Promise.all([
        getChartsFiltersData({ globalFilters: updatedSelectedGlobalFilters, signal }),
        getTableFiltersData({ globalFilters: updatedSelectedGlobalFilters, signal }),
      ]);

      setFiltersHistory({
        ...filtersHistory,
        [tab]: {
          ...filtersHistory[tab],
          chartsFilters: updatedChartsFilters,
          globalFilters: updatedSelectedGlobalFilters,
          tableFilters: hasSharedLocalFilters ? updatedChartsFilters : updatedTableFilters,
        },
      });

      await Promise.all([
        getBarChartData({
          chartsFilters: updatedChartsFilters,
          globalFilters: updatedSelectedGlobalFilters,
          signal,
        }),
        getLineChartData({
          chartsFilters: updatedChartsFilters,
          globalFilters: updatedSelectedGlobalFilters,
          signal,
        }),
        getTableData({
          globalFilters: updatedSelectedGlobalFilters,
          signal,
          tableFilters: hasSharedLocalFilters ? updatedChartsFilters : updatedTableFilters,
        }),
      ]);

      setIsUpdatingGlobal(false);
      globalFiltersRef.current = null;
    } catch (error) {
      if (error?.response?.status > 200) {
        console.error(error);
        setError(error);
        setIsUpdatingGlobal(false);
      }
    }
  };

  const handleOnSelectedTableFilterChange = async (updatedTableFilter) => {
    const updatedSelectedTableFilters = {
      ...selectedTableFilters,
      [updatedTableFilter?.id]: updatedTableFilter?.value,
    };

    setSelectedTableFilters(updatedSelectedTableFilters);

    setIsUpdatingTable(true);

    try {
      tableFiltersRef?.current?.abort();
      const controller = new AbortController();
      tableFiltersRef.current = controller;
      const signal = tableFiltersRef?.current?.signal;

      let tableFilters = updatedSelectedTableFilters;

      if (updatedTableFilter?.resetDefaults) {
        setIsUpdatingTableFilters(true);

        tableFilters = await getTableFiltersData({
          globalFilters: selectedGlobalFilters,
          updatedTableFilter,
        });
        setIsUpdatingTableFilters(false);
      }

      setFiltersHistory({
        ...filtersHistory,
        [tab]: {
          ...filtersHistory[tab],
          globalFilters: selectedGlobalFilters,
          tableFilters,
        },
      });

      await getTableData({
        globalFilters: selectedGlobalFilters,
        signal,
        tableFilters: hasSharedLocalFilters ? selectedChartsFilters : tableFilters,
      });

      setIsUpdatingTable(false);
      tableFiltersRef.current = null;
    } catch (error) {
      if (error?.response?.status > 200) {
        setError(error);
        setIsUpdatingTable(false);
      }
    }
  };

  const fetchData = async (signal) => {
    try {
      const filters = filtersHistory?.[tab];
      const chartsFilters = filters?.chartsFilters;
      const globalFilters = filters?.globalFilters;
      const tableFilters = filters?.tableFilters;

      await Promise.all([
        getBarChartData({ chartsFilters, globalFilters, signal }),
        getGlobalFiltersData({ signal }),
        getLineChartData({ chartsFilters, globalFilters, signal }),
        getChartsFiltersData({ chartsFilters, globalFilters, isFirstLoad: true, signal }),
        getTableData({
          globalFilters,
          signal,
          tableFilters: hasSharedLocalFilters ? chartsFilters : tableFilters,
        }),
        getTableFiltersData({
          globalFilters,
          isFirstLoad: true,
          signal,
          tableFilters: hasSharedLocalFilters ? chartsFilters : tableFilters,
        }),
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchData(signal);

    return () => controller.abort();
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    localStorage.setItem(filtersStorageKey, JSON.stringify(filtersHistory));

    const getData = async () => {
      setIsLoading(true);
      await fetchData(signal);
    };

    if (!hasFiltersHistory && resetFiltersHistory) {
      getData();
      setResetFiltersHistory(false);
    }
  }, [filtersHistory]);

  useEffect(() => {
    setError(null);
  }, [selectedChartsFilters, selectedGlobalFilters, selectedTableFilters]);

  if (isLoading)
    return (
      <LoaderSkeleton height={725}>
        <rect x="0" y="15" rx="4" ry="4" width="250" height="40" />
        <rect x="0" y="70" rx="4" ry="4" width="250" height="40" />
        <rect x="0" y="125" rx="4" ry="4" width="250" height="40" />
        <rect x="0" y="200" rx="2" ry="2" width="15" height="15" />
        <rect x="20" y="200" rx="2" ry="2" width="200" height="15" />
        <rect x="0" y="225" rx="2" ry="2" width="15" height="15" />
        <rect x="20" y="225" rx="2" ry="2" width="175" height="15" />
        <rect x="0" y="250" rx="2" ry="2" width="15" height="15" />
        <rect x="20" y="250" rx="2" ry="2" width="225" height="15" />
        <rect x="0" y="275" rx="2" ry="2" width="15" height="15" />
        <rect x="20" y="275" rx="2" ry="2" width="185" height="15" />
        <rect x="275" y="15" rx="4" ry="4" width="250" height="40" />
        <rect x="550" y="15" rx="4" ry="4" width="250" height="40" />
        <rect x="275" y="70" rx="4" ry="4" width="500" height="200" />
        <rect x="800" y="70" rx="4" ry="4" width="500" height="200" />
        <rect x="275" y="325" rx="4" ry="4" width="250" height="40" />
        <rect x="550" y="325" rx="4" ry="4" width="250" height="40" />
        <rect x="275" y="380" rx="4" ry="4" width="1333" height="30" />
        <rect x="275" y="425" rx="4" ry="4" width="1333" height="30" />
        <rect x="275" y="470" rx="4" ry="4" width="1333" height="30" />
        <rect x="275" y="515" rx="4" ry="4" width="1333" height="30" />
        <rect x="275" y="560" rx="4" ry="4" width="1333" height="30" />
        <rect x="275" y="605" rx="4" ry="4" width="1333" height="30" />
      </LoaderSkeleton>
    );

  if (error?.response?.status === 403) {
    return <ErrorComponent status={403} />;
  }

  return (
    <>
      {error && (
        <Announcement
          text="Something went wrong. Please refresh the page or try again."
          variant="error"
        />
      )}
      <Layout.Flex className={styles['insights']}>
        <Layout.Sidebar>
          <div className={styles['insights-global-filters-and-legend']}>
            {globalFilters?.map((filter, index) => {
              return (
                <Select
                  data-testid={`global-filter-${filter?.id}`}
                  key={`global-filter-${index}`}
                  label={filter?.label}
                  onChange={(value) => handleOnSelectedGlobalFilterChange({ ...filter, value })}
                  options={filter?.options}
                  value={selectedGlobalFilters[filter?.id]}
                />
              );
            })}
            {legendItems && (
              <Legend
                isUpdating={isUpdatingGlobal || isUpdatingCharts || isUpdatingChartsFilters}
                items={legendItems}
              />
            )}
          </div>
        </Layout.Sidebar>
        <Layout.Fill className={styles['insights-charts-and-table']}>
          {(readBarChartData || readLineChartData) && (
            <Charts
              barChartData={barChartData}
              chartsFilters={chartsFilters}
              chartsTitle={chartsTitle}
              hasFiltersHistory={hasFiltersHistory}
              handleResetFiltersHistory={handleResetFiltersHistory}
              isUpdatingCharts={isUpdatingCharts}
              isUpdatingChartsFilters={isUpdatingChartsFilters}
              isUpdatingGlobal={isUpdatingGlobal}
              lineChartData={lineChartData}
              onChartCallback={handleChartCallback}
              onSelectedChartsFilterChange={handleOnSelectedChartsFilterChange}
              selectedChartsFilters={selectedChartsFilters}
            />
          )}
          {readTableData && (
            <Table
              hasSharedLocalFilters={hasSharedLocalFilters}
              isUpdatingGlobal={isUpdatingGlobal}
              isUpdatingTable={isUpdatingTable}
              isUpdatingTableFilters={isUpdatingTableFilters}
              onSelectedTableFilterChange={handleOnSelectedTableFilterChange}
              selectedTableFilters={selectedTableFilters}
              tableData={tableData}
              tableFilters={tableFilters}
              tableTitle={tableTitle}
            />
          )}
          {lastUpdatedDate && (
            <Footnote>
              <FootnoteItem text={lastUpdatedDate} />
            </Footnote>
          )}
        </Layout.Fill>
      </Layout.Flex>
    </>
  );
};

Insights.defaultProps = {
  hasSharedLocalFilters: false,
};

Insights.propTypes = {
  hasSharedLocalFilters: PropTypes.bool,
  readBarChartData: PropTypes.func,
  readChartsFiltersData: PropTypes.func,
  readGlobalFiltersData: PropTypes.func,
  readLineChartData: PropTypes.func,
  readTableData: PropTypes.func,
  readTableFiltersData: PropTypes.func,
  tab: PropTypes.string,
};

export default Insights;
