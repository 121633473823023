import PropTypes from 'prop-types';

import classNames from 'classnames';

import ScreenReaderOnly from '../../../../components/ScreenReaderOnly';

import styles from './_index.module.scss';

const Step = ({ activeIndex, index, isComplete, isDisabled, label, onClick }) => {
  const isActive = activeIndex === index;
  const isPast = activeIndex > index;
  return (
    <div
      className={classNames(styles['step'], {
        [styles['is-active']]: isActive,
        [styles['is-complete']]: isComplete,
        [styles['is-disabled']]: isDisabled,
        [styles['is-past']]: isPast,
      })}
      onClick={() => onClick(index)}
      tabIndex={isDisabled ? '-1' : '0'}
    >
      <span className={styles['step-icon']} aria-hidden="true">
        <span>{index + 1}</span>
      </span>
      {isActive && <ScreenReaderOnly>Current:</ScreenReaderOnly>}
      {isPast && isComplete && <ScreenReaderOnly>Completed:</ScreenReaderOnly>}
      <span className={styles['step-label']}>{label}</span>
    </div>
  );
};

Step.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isComplete: PropTypes.bool,
  isDisabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Step;
