import PropTypes from 'prop-types';

import { getPromptPillTooltip, getPromptPillValue } from '@helpers';
import { Accordion, OverlayTriggerTooltip, Pill } from '@utilities';

import styles from './_index.module.scss';

const AdvancedPrompts = ({
  activePrompt,
  advancedPrompts,
  answers,
  isCompleted,
  onClearAnswer,
  setActivePrompt,
}) => {
  return (
    <Accordion
      className={styles['advanced-prompts']}
      id="advanced-prompts-header"
      label="Advanced Options"
    >
      <ul className={styles['advanced-prompts-list']}>
        {advancedPrompts.map((prompt, index) => {
          const tooltip = getPromptPillTooltip({ answers, prompt });
          const value = getPromptPillValue({ answers, prompt });

          return (
            <li key={`advanced-prompt-${index}`}>
              <span>{prompt?.name}:</span>{' '}
              <span>
                <OverlayTriggerTooltip content={tooltip} isFullWidth>
                  <Pill
                    isActive={prompt === activePrompt}
                    isDisabled={prompt?.isReadOnly}
                    isCompleted={isCompleted}
                    isModified={answers?.hasOwnProperty(prompt?.id)}
                    isRequired={prompt?.isRequired}
                    onClick={() => setActivePrompt(prompt)}
                    onClose={() => onClearAnswer(prompt?.id)}
                    value={value}
                  />
                </OverlayTriggerTooltip>
              </span>
            </li>
          );
        })}
      </ul>
    </Accordion>
  );
};

AdvancedPrompts.propTypes = {
  activePrompt: PropTypes.object,
  advancedPrompts: PropTypes.array,
  answers: PropTypes.object,
  isCompleted: PropTypes.bool,
  onClearAnswer: PropTypes.func,
  setActivePrompt: PropTypes.func,
};

export default AdvancedPrompts;
