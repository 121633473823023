import { useState } from 'react';
import PropTypes from 'prop-types';

import { OverlayPopover, PlusIcon } from '@utilities';

import ModalSmartPeopleGroup from '../../../../../ModalSmartPeopleGroup';

import styles from './_index.module.scss';

const SmartPeopleGroup = ({
  answers,
  isSmartPeopleGroupDropdownActive,
  peopleGroupTypes,
  reportId,
  reportName,
  setIsSmartPeopleGroupDropdownActive,
  target,
}) => {
  const [selectedPeopleGroup, setSelectedPeopleGroup] = useState(false);

  return (
    <>
      <OverlayPopover
        className={styles['smart-people-group']}
        header="Create Smart People Group"
        isActive={isSmartPeopleGroupDropdownActive && !selectedPeopleGroup}
        onHide={() => setIsSmartPeopleGroupDropdownActive(false)}
        target={target}
      >
        <ul data-testid="smart-people-group-popover">
          {peopleGroupTypes?.map((peopleGroupType, index) => (
            <li key={`people-group-type-${index}`}>
              <button
                data-testid="smart-people-group-button"
                onClick={() => setSelectedPeopleGroup(peopleGroupType)}
              >
                <PlusIcon />
                <span>{peopleGroupType?.name}</span>
              </button>
            </li>
          ))}
        </ul>
      </OverlayPopover>
      {selectedPeopleGroup && (
        <ModalSmartPeopleGroup
          answers={answers}
          reportId={reportId}
          peopleGroup={selectedPeopleGroup}
          reportName={reportName}
          setShowModalSmartPeopleGroup={() => {
            setSelectedPeopleGroup(null);
            setIsSmartPeopleGroupDropdownActive(false);
          }}
        />
      )}
    </>
  );
};

SmartPeopleGroup.propTypes = {
  answers: PropTypes.object,
  isSmartPeopleGroupDropdownActive: PropTypes.bool,
  peopleGroupTypes: PropTypes.array,
  reportId: PropTypes.number,
  reportName: PropTypes.string,
  setIsSmartPeopleGroupDropdownActive: PropTypes.func,
  target: PropTypes.object,
};

export default SmartPeopleGroup;
