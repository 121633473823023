import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './_index.module.scss';

/**
 * ScreenReaderOnly
 * Visually hides an element (defaulting to span). For accessibility purposes, the wrapped
 * text/children are still available when using a screen reader. If a naturally focusable
 * element like `a` or `button` is used or if a `tabIndex` property is provided, the component
 * can be navigated to by tabbing and will remove the styles that hide the element.
 */
const ScreenReaderOnly = ({ as: Component, children, className, ...props }) => {
  return (
    <Component className={classNames(styles['screen-reader-only'], className)} {...props}>
      {children}
    </Component>
  );
};

ScreenReaderOnly.defaultProps = {
  as: 'span',
};

ScreenReaderOnly.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ScreenReaderOnly;
