import PropTypes from 'prop-types';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';

import { barTornadoChartConfiguration } from './utilities/helpers';

const BarTornadoChart = ({ series, xAxis, ...props }) => {
  return (
    <Chart
      options={merge({}, barTornadoChartConfiguration, {
        series,
        type: 'bar',
        xAxis,
        ...props,
      })}
      {...props}
    />
  );
};

BarTornadoChart.propTypes = {
  series: PropTypes.array,
  xAxis: PropTypes.array,
};

export default BarTornadoChart;
