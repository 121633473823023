import PropTypes from 'prop-types';

import styles from './_index.module.scss';

const QuestionPlaceholder = ({ onCreateQuestion }) => {
  return (
    <div className={styles['question-placeholder-container']} onClick={onCreateQuestion}>
      <div className={styles['question-placeholder-body']}>
        <div className={styles['question-placeholder-number']}>1</div>
        <div className={styles['question-placeholder-text']}>Click to create a question</div>
      </div>
    </div>
  );
};

QuestionPlaceholder.propTypes = {
  onCreateQuestion: PropTypes.func,
};

export default QuestionPlaceholder;
