import PropTypes from 'prop-types';

import { CloseBoldIcon } from '@utilities';

import styles from './_index.module.scss';

const Selections = ({ handleProductChange, products, title }) => {
  return (
    <div className={styles['selections']}>
      <h3>
        <strong>{title}</strong>
      </h3>
      <ul>
        {products.map((product, index) => {
          return (
            <li key={`product-${index}`}>
              <div>
                <div>
                  <p>{product?.name}</p>
                </div>
                <button
                  onClick={() => {
                    handleProductChange(product);
                  }}
                >
                  <CloseBoldIcon />
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Selections.propTypes = {
  handleProductChange: PropTypes.func,
  products: PropTypes.array,
  title: PropTypes.string,
};

export default Selections;
