import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { logout, switchToBaseUser } from '@redux/slices/user';

import { OverlayPopover, SettingsIcon, SignoutIcon } from '@utilities';

const AccountDropdown = ({ isAccountDropdownActive, setActiveDropdown, target }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data);

  if (!isAccountDropdownActive) return null;

  const baseAccountId = user?.baseAccountUser?.id;
  const fullName = user?.firstName + ' ' + user?.lastName;

  return (
    <OverlayPopover
      data-testid="account-dropdown"
      header={fullName}
      isActive={isAccountDropdownActive}
      onHide={() => setActiveDropdown('')}
      target={target}
    >
      <ul>
        <li>
          <Link to="/dashboard/user" onClick={() => setActiveDropdown('')}>
            <SettingsIcon />
            <span>Settings</span>
          </Link>
        </li>
      </ul>
      <hr />
      {baseAccountId && (
        <ul>
          <li>
            <button onClick={() => dispatch(switchToBaseUser({ baseAccountId, navigate }))}>
              <SignoutIcon />
              <span>Switch to Personal Account</span>
            </button>
          </li>
        </ul>
      )}
      {!baseAccountId && (
        <ul>
          <li>
            <button onClick={() => dispatch(logout({ navigate }))}>
              <SignoutIcon />
              <span>Sign Out</span>
            </button>
          </li>
        </ul>
      )}
    </OverlayPopover>
  );
};

AccountDropdown.propTypes = {
  isAccountDropdownActive: PropTypes.bool,
  setActiveDropdown: PropTypes.func,
  target: PropTypes.object,
};

export default AccountDropdown;
