import numbro from 'numbro';

/**
 * format number using numbro library and format https://numbrojs.com/old-format.html // TOOD: Update to new library
 * @param  {Number} options.format
 * @param  {String} options.value
 * @return {Number}
 */
export const formatData = ({ format = '0,0', value }) => {
  if (format === null) {
    return value;
  }
  if (value === 'INF_SENTINEL' || value === 'NAN_SENTINEL') {
    return '-';
  }

  if (format === 'url') {
    return (
      <a href={value} target="blank">
        {value}
      </a>
    );
  }

  const containsDecimalPoints = format.includes('.');
  const isFormatToPercentage = format.includes('%');
  const isGBP = format.includes('£');

  if ((isNaN(value) || !isFormatToPercentage) && (typeof value === 'string' || format === 'text')) {
    return value;
  }

  if (!containsDecimalPoints) {
    value = isFormatToPercentage ? value.toFixed(2) : Math.round(value);
  }

  return isGBP
    ? numbro(value).formatCurrency({
        currencySymbol: '£',
        thousandSeparated: true,
        mantissa: 2,
      })
    : numbro(value).format(format);
};

/**
 * get information about user's locale
 * @param {string} countryCode abbreviated name for the country
 * @returns {Object}
 */
export const getLocaleInfo = (countryCode) => {
  switch (countryCode) {
    case 'CA':
      return {
        locale: 'en-ca',
        numberLocale: 'en-CA',
        createdDateFormat: 'YYYY MMM D',
      };
    case 'UK':
      return {
        locale: 'en-gb',
        numberLocale: 'en-GB',
        createdDateFormat: 'D MMM YYYY',
      };
    case 'US':
      return {
        locale: 'en',
        numberLocale: 'en-US',
        createdDateFormat: 'MMM D, YYYY',
      };

    default:
      return {};
  }
};

/**
 * truncate text with ellipsis
 * @param {number} options.length
 * @param {string} options.text
 * @returns {string}
 */
export const truncateTextWithEllipsis = ({ length = 60, text }) => {
  return text?.length > length ? text.substring(0, length) + '...' : text;
};
