import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  ActionArea,
  AsteriskIcon,
  Button,
  EditableTitle,
  LoaderPage,
  ModalV2 as Modal,
} from '@utilities';

import {
  readCustomGroup,
  readCustomGroupHierarchy,
  updateCustomGroupBreakouts,
} from '@api/custom_groups';

import { formatConditions, getOperators } from './utilities/helpers';
import Conditions from './components/Conditions';
import ResultTable from './components/ResultTable';
import styles from './_index.module.scss';

const CreateBreakout = () => {
  const location = useLocation();

  const action = location?.state?.action;
  const {
    allDefinitions,
    conditionalDefinitionAttributes: { conditions: breakoutConditions, logicalOperators } = {},
    title,
  } = location?.state?.breakout ?? {};
  const defaultName = action === 'copy' ? `Copy of ${title}` : title;

  const [conditions, setConditions] = useState(null);
  const [customGroup, setCustomGroup] = useState(null);
  const [hierarchy, setHierarchy] = useState({});
  const [isUpdatingTable, setIsUpdatingTable] = useState(false);
  const [name, setName] = useState(defaultName || 'Create a Breakout');
  const [promptError, setPromptError] = useState({ message: '', type: '' });
  const [tableData, setTableData] = useState([]);

  const { custom_group_id: id } = useParams();
  const navigate = useNavigate();
  const operators = getOperators();

  const isEditing = action === 'edit';
  const itemsPage = location?.state?.itemsPage;
  const itemsRow = location?.state?.itemsRow;
  const type = location?.state?.type;

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const getHierarchy = async () => {
      try {
        const response = await readCustomGroupHierarchy({ signal, type });
        setHierarchy(response?.data);
        mapConditions({ hierarchyLevel: response?.data });
      } catch (error) {
        console.error(error);
      }
    };

    getHierarchy();

    return () => controller.abort();
  }, [breakoutConditions, logicalOperators]);

  useEffect(() => {
    const getItem = async () => {
      const controller = new AbortController();
      const signal = controller.signal;

      try {
        const { data } = await readCustomGroup({ id, signal });

        setCustomGroup(data);
      } catch (error) {
        console.error(error);
      }
    };
    getItem();
  }, []);

  const mapConditions = ({ hierarchyLevel }) => {
    let mappedConditions = [];
    breakoutConditions?.forEach((item, index) => {
      const level = hierarchyLevel?.attributes?.find((level) =>
        item['attribute'].includes(level.name)
      );

      const mergedElements = item['values'].map((element) => {
        const elementName = Object.keys(element)[0];
        const elementId = Object.values(element)[0];

        return {
          id: `${level.id}:${elementId}`,
          name: elementName,
        };
      });

      const currentCondition = {
        lineId: index + 1,
        level: level,
        operator: item['operator'].toLowerCase().includes('not')
          ? operators.find((operator) => operator['name'] === 'NOT IN')
          : operators.find((operator) => operator['name'] === 'IN'),
        elements: mergedElements,
      };
      mappedConditions.push(currentCondition);
    });

    const logicalOperatorsWithLineId = logicalOperators?.map((logicalOperator, index) => ({
      ...logicalOperator,
      lineId: index + 1,
    }));

    setConditions({
      breakoutConditions: mappedConditions || [],
      logicalOperators: logicalOperatorsWithLineId || [],
    });
  };

  const hasBreakoutConditionsElements = conditions?.breakoutConditions?.some(
    (condition) => condition?.elements?.length > 0
  );

  const isDisabled =
    !hasBreakoutConditionsElements ||
    conditions?.breakoutConditions?.length === 0 ||
    name === '' ||
    name.trim().length === 0;

  const handleSaveBreakout = async () => {
    const definitions = allDefinitions || customGroup?.definition;
    const isUpdatingEditTitle =
      isEditing && title && title !== name && allDefinitions.hasOwnProperty(title);

    const isInvalidName =
      (!isEditing && definitions.hasOwnProperty(name)) ||
      (isUpdatingEditTitle && definitions.hasOwnProperty(name));
    const isMissingName = name.trim() === 'Create a Breakout';

    if (isInvalidName) {
      setPromptError({
        message: `A Breakout with the same name already exists in your list: ${name}. You can specify another name and try again.`,
        type: 'Invalid Selection',
      });
      return;
    }

    if (isMissingName) {
      setPromptError({
        message: 'To save this group, please name your breakout selection',
        type: 'Missing Name',
      });
      return;
    }

    if (isUpdatingEditTitle) {
      delete allDefinitions[title];
    }

    await updateCustomGroupBreakouts({
      definition: {
        ...definitions,
        [name]: formatConditions({
          conditions: conditions.breakoutConditions,
          logicalOperators: conditions.logicalOperators,
        }),
      },
      id,
    });
    navigate({
      pathname: `/dashboard/workspace/${type}-groups`,
      search: new URLSearchParams({ itemsPage, itemsRow }).toString(),
    });
  };

  if (!conditions) return <LoaderPage />;

  return (
    <ActionArea
      buttons={[
        <Button
          data-testid="cancel-button"
          key="cancel-btn"
          onClick={() =>
            navigate(-1, { search: new URLSearchParams({ itemsPage, itemsRow }).toString() })
          }
          text="Cancel"
          variant="secondary"
        />,
        <Button
          data-testid="save-button"
          isDisabled={isDisabled}
          key="save-btn"
          onClick={handleSaveBreakout}
          text="Save"
        />,
      ]}
    >
      <EditableTitle autoFocus onChange={setName} value={name} />
      <Conditions
        attributes={hierarchy?.attributes}
        conditions={conditions}
        hasBreakoutConditionsElements={hasBreakoutConditionsElements}
        setConditions={setConditions}
        setIsUpdatingTable={setIsUpdatingTable}
        setTableData={setTableData}
        type={type}
      />
      <ResultTable
        conditions={conditions}
        isLoading={isUpdatingTable}
        tableData={tableData}
        type={type}
      />
      <Modal
        buttons={[
          <Button
            key="button"
            onClick={() => setPromptError({ message: '', type: '' })}
            text="Okay, Got it"
          />,
        ]}
        className={styles['create-breakout-modal-prompt']}
        isActive={promptError.message !== ''}
        title={promptError.type}
      >
        <p>
          <AsteriskIcon />
          {promptError.message}
        </p>
      </Modal>
    </ActionArea>
  );
};

export default CreateBreakout;
