import { formatData } from '../data_format';

/**
 * formats the chart tooltip
 * @param  {Object} data
 * @param  {String} format
 * @return {Node}
 */
export const tooltipFormatter = ({ data, format }) => {
  return `<b>${data.x}</b><br/><span style="color:${data.color}">\u25CF</span> ${
    data.series.name
  }: <b>${formatData({ format: format, value: data.y })}</b>`;
};

/**
 * formats the chart tooltip for multiple series
 * @param  {Object} data
 * @param  {String} format
 * @return {Node}
 */
export const tooltipMultiPointFormatter = ({ data, format }) => {
  let tooltipContent = [];

  data?.points?.forEach((point, i) => {
    if (point?.series?.type !== 'arearange') {
      let content = `<span style="color:${point.color}">\u25CF</span>${
        point?.series?.name
      }: <b>${formatData({ format, value: point.y })}</b>`;

      const nextPoint = data?.points?.[i + 1];
      if (nextPoint && nextPoint?.point?.low && nextPoint?.point?.high) {
        content += `(Low: <b>${formatData({
          format,
          value: nextPoint.point.low,
        })}</b>, High: <b>${formatData({ format, value: nextPoint.point.high })}</b>)`;
      }
      tooltipContent.push(content);
    }
  });

  return `<b>${data.x}</b><br/>` + tooltipContent.join('<br/>');
};
