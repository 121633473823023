import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Announcement,
  Button,
  Checkbox,
  DateRangePickerV2,
  getFormattedDate,
  getPreviousDay,
  Input,
  isMaxNameLength,
  ModalV2 as Modal,
  Select,
} from '@utilities';

import { createPeopleGroup, readStaticGroups } from '@api/people_groups';

import { transformPeopleGroupError } from './utilities/helpers';

import styles from './_index.module.scss';

const ModalSmartPeopleGroup = ({
  answers,
  peopleGroup,
  reportId,
  reportName,
  setShowModalSmartPeopleGroup,
}) => {
  const { id, name: peopleGroupName } = peopleGroup;
  const { date_range: reportDateRange = [null, null] } = answers;

  const [dateRange, setDateRange] = useState(reportDateRange);
  const [isFrozen, setIsFrozen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState(`${reportName} - ${peopleGroupName}`);
  const [promptErrors, setPromptErrors] = useState([]);
  const [selectedStaticGroup, setSelectedStaticGroup] = useState(null);
  const [staticGroups, setStaticGroups] = useState([]);

  const options = staticGroups.map((staticGroup) => ({
    label: staticGroup?.promptDesc,
    value: staticGroup?.id,
  }));

  const [startDate, endDate] = dateRange;

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      await createPeopleGroup({
        answers,
        dateRange,
        id,
        isFrozen,
        isSmart: true,
        name,
        reportId,
        staticGroup: selectedStaticGroup?.value.split(':').pop(),
      });

      setPromptErrors([]);
      setShowModalSmartPeopleGroup(false);
    } catch (error) {
      const data = transformPeopleGroupError(error?.response?.data);
      setPromptErrors(data);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const getStaticGroups = async () => {
      try {
        const response = await readStaticGroups();
        setStaticGroups(response?.data);
        const reportStaticGroup = response?.data?.find(
          (item) => item?.id === answers?.static_group?.[0]?.id
        );

        setSelectedStaticGroup({
          label: reportStaticGroup ? reportStaticGroup?.promptDesc : response?.data[0]?.promptDesc,
          value: reportStaticGroup ? reportStaticGroup?.id : response?.data[0]?.id,
        });

        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    getStaticGroups();
  }, []);

  return (
    <Modal
      buttons={[
        <Button
          data-testid="cancel-button"
          key="cancel-btn"
          onClick={() => setShowModalSmartPeopleGroup(false)}
          text="Cancel"
          variant="secondary"
        />,
        <Button
          data-testid="submit-button"
          isDisabled={
            (isFrozen && (!startDate || !endDate)) || !name?.trim() || isMaxNameLength(name)
          }
          isLoading={isSubmitting}
          key="submit-btn"
          onClick={handleSubmit}
          text="Save"
        />,
      ]}
      className={styles['modal-smart-people-group']}
      isActive
      onClose={() => setShowModalSmartPeopleGroup(false)}
      title="Create Smart People Group"
    >
      <div className={styles['modal-smart-people-group-content']}>
        {promptErrors && Array.isArray(promptErrors) && promptErrors?.length > 0 && (
          <Announcement text={promptErrors[0]} variant="error" />
        )}
        {peopleGroup?.isDefaultFrozen && (
          <Announcement
            text="This people group is frozen by default using the report’s date and static selections."
            variant="warn"
          />
        )}
        <Input
          data-testid="smart-people-group-name"
          error={
            isMaxNameLength(name)
              ? 'Maximum number of characters reached. Try something shorter.'
              : ''
          }
          label="Name"
          onChange={setName}
          value={name}
        />
        {!peopleGroup?.isDefaultFrozen && (
          <Checkbox
            isChecked={isFrozen}
            label="Make this a frozen people group"
            onChange={() => setIsFrozen((prev) => !prev)}
            value={isFrozen}
          />
        )}
        {isFrozen && (
          <>
            <Select
              className={styles['modal-smart-people-group-select']}
              data-testid="smart-people-group-panel-select"
              isLoading={isLoading}
              label="Static Panel"
              onChange={setSelectedStaticGroup}
              options={options}
              value={selectedStaticGroup}
            />
            <DateRangePickerV2
              data-testid="smart-people-group-date-range"
              endDate={endDate}
              label="Included Date Range"
              maxDate={getFormattedDate(getPreviousDay(new Date()))}
              onDatesChange={setDateRange}
              startDate={startDate}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

ModalSmartPeopleGroup.propTypes = {
  answers: PropTypes.object,
  peopleGroup: PropTypes.object,
  reportId: PropTypes.number,
  reportName: PropTypes.string,
  setShowModalSmartPeopleGroup: PropTypes.func,
};

export default ModalSmartPeopleGroup;
