import Loader from '../Loader';

import styles from './_index.module.scss';

const LoaderPage = () => {
  return (
    <div className={styles['loader-page']}>
      <Loader isCentered />
    </div>
  );
};

export default LoaderPage;
