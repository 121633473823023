import { questionTypes } from '@api/surveys';

import { toast } from '@utilities';

/**
 * Checks to see if any question logic needs to be changed
 * @param {Boolean} options.hasQuestionBeenRemoved
 * @param {Object} options.question
 * @param {Number} options.questionIndex
 * @param {Object} options.questionLogic
 * @param {Function} options.setQuestionLogic
 * @param {Object} options.updatedQuestion
 */
export const checkForLogicChanges = ({
  hasQuestionBeenRemoved,
  question,
  questionIndex,
  questionLogic,
  setQuestionLogic,
  updatedQuestion,
}) => {
  const modifiedQuestionLogic = { ...questionLogic };
  let hasLogicChanges = false;

  // when a question has been removed
  if (hasQuestionBeenRemoved && modifiedQuestionLogic[question.id]) {
    // @Devon unable to delete entire top level property
    // delete updatedLogic[question.id];
    delete modifiedQuestionLogic[question.id].disqualify;
    delete modifiedQuestionLogic[question.id].skip;
  }

  // when a question has been edited
  if (updatedQuestion) {
    if (questionIndex < 2 && modifiedQuestionLogic[question.id]?.disqualify) {
      if (question.type !== updatedQuestion.type && question.type === questionTypes.essay.value) {
        // remove disqualify logic that references new essay question
        delete modifiedQuestionLogic[question.id].disqualify;
      } else {
        modifiedQuestionLogic[question.id].disqualify.answers = [];
      }
      hasLogicChanges = true;
    }
  }

  for (const questionId in modifiedQuestionLogic) {
    const skipLogic = modifiedQuestionLogic[questionId].skip?.logic;
    if (skipLogic) {
      const skipQuestionIndex = skipLogic.findIndex((row) => row.question.id === question.id);
      if (skipQuestionIndex >= 0) {
        if (
          hasQuestionBeenRemoved ||
          (question.type !== updatedQuestion?.type && question.type === questionTypes.essay.value)
        ) {
          // remove skip logic that references new essay question
          if (skipLogic.length === 1) {
            delete modifiedQuestionLogic[questionId].skip;
          } else {
            skipLogic.splice(skipQuestionIndex, 1);
          }
        } else {
          skipLogic[skipQuestionIndex].question = question;
          skipLogic[skipQuestionIndex].answers = [];
        }
        hasLogicChanges = true;
      }
    }
  }
  setQuestionLogic(modifiedQuestionLogic);

  if (hasLogicChanges) {
    displayQuestionLogicUpdatedToast();
  }
};

const displayQuestionLogicUpdatedToast = () => {
  toast(
    <p>
      The flow of this questionnaire has been updated due to a change to a custom survey question.
    </p>
  );
};
