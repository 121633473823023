import axios from '@api';

import { transformSurveyGPT } from './utilities/helpers';

/**
 * create survey GPT feedback
 * @param {Object} options
 * @param {String} options.feedback NEGATIVE | POSITIVE
 * @param {String} [options.note]
 * @param {Number} [options.signal] AbortController signal
 * @param {Number} options.surveyGPTJobId
 * @return {Promise}
 */
export const createSurveyGPTFeedback = async ({ feedback, note, signal, surveyGPTJobId }) => {
  return axios({
    data: {
      feedback,
      note,
    },
    method: 'post',
    signal,
    url: `/api/surveygpt/${surveyGPTJobId}/feedback`,
    withCredentials: true,
  });
};

/**
 * read survey GPT
 * @param {Object} options
 * @param {AbortSignal} [options.signal] AbortController signal
 * @param {Number} options.surveyGPTJobId
 * @param {Number} [options.userId]
 * @return {Promise}
 */
export const readSurveyGPT = async ({ signal, surveyGPTJobId, userId: user_id }) => {
  return axios({
    method: 'get',
    params: {
      user_id,
    },
    signal,
    url: `/api/surveygpt/${surveyGPTJobId}`,
    withCredentials: true,
  }).then(({ data }) => transformSurveyGPT(data));
};
