/**
 * Formats conditions into a string
 * @param {Object} options.conditions
 * @param {Object} options.logicalOperators
 * @returns {string}
 */
export const formatConditions = ({ conditions, logicalOperators }) => {
  if (!conditions || conditions.length === 0) {
    return '';
  }

  const formattedConditions = conditions
    .filter((condition) => condition?.elements?.length > 0)
    .map((condition, index) => {
      const { level, operator, elements } = condition;
      const { id: levelId } = level;
      const { name: operatorName } = operator;
      const elementIds = elements.map((element) => `"${element.id.split(':')[1]}"`).join(', ');

      let formattedCondition = `${levelId} ${operatorName} (${elementIds})`;

      if (index < conditions.length - 1 && index <= logicalOperators.length) {
        formattedCondition = `${formattedCondition} ${logicalOperators[index].name}`;
      }

      return formattedCondition;
    });

  return formattedConditions
    .join(' ')
    .replace(/\s+(OR|And)$/i, '')
    .trim();
};

/**
 * Retrieves the operators
 * @returns {Array}
 */
export const getOperators = () => [
  { id: 'in', name: 'IN' },
  { id: 'not-in', name: 'NOT IN' },
];

/**
 * Retrieves the value associated with an operator
 * @param {string} operatorId
 * @returns {string}
 */
export const getOperatorValue = (operatorId) => {
  if (operatorId === 'not-in') {
    return 'Exclude';
  } else {
    return 'Include';
  }
};

/**
 * Retrieves the logical operators
 * @returns {Array}
 */
export const getLogicalOperators = () => [
  { id: 'and', name: 'AND' },
  { id: 'or', name: 'OR' },
];
