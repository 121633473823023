import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Input, Select } from '@utilities';

import styles from './_index.module.scss';

const Metrics = ({ metric, onSetAnswerMetric, operators }) => {
  const [operatorInfo, setOperatorInfo] = useState(metric?.operatorInfo || operators[0]);
  const [end, setEnd] = useState(Array.isArray(metric?.value) ? metric?.value[1] : null);
  const [start, setStart] = useState(Array.isArray(metric?.value) ? metric?.value[0] : null);
  const [value, setValue] = useState(metric?.value);

  useEffect(() => {
    const updatedMetric = {
      ...metric,
      id: metric?.id,
      operator: operatorInfo.value,
      operatorInfo,
      value: operatorInfo.multiple ? [start, end] : value,
    };
    onSetAnswerMetric(updatedMetric);
  }, [end, operatorInfo, start, value]);

  return (
    <div className={styles['metrics']}>
      <Select onChange={setOperatorInfo} options={operators} value={operatorInfo} />
      <span>value of</span>
      {operatorInfo?.multiple ? (
        <>
          <Input onChange={setStart} type="decimal" value={start} />
          and
          <Input onChange={setEnd} type="decimal" value={end} />
        </>
      ) : (
        <span>
          <Input onChange={setValue} type="decimal" value={value} />
        </span>
      )}
    </div>
  );
};

Metrics.propTypes = {
  metric: PropTypes.object.isRequired,
  onSetAnswerMetric: PropTypes.func.isRequired,
  operators: PropTypes.array,
};

export default Metrics;
