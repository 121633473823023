import PropTypes from 'prop-types';

import AdvancedPrompts from './components/AdvancedPrompts';
import Madlibs from './components/Madlibs';
import styles from './_index.module.scss';

const Prompts = ({
  activePrompt,
  advancedPrompts,
  answers,
  isCompleted,
  madlib,
  madlibAdditional,
  madlibAdditionalLimit,
  onClearAnswer,
  onClearAnswers,
  prompts,
  setActivePrompt,
  status,
}) => {
  return (
    <div className={styles['prompts']}>
      <Madlibs
        activePrompt={activePrompt}
        answers={answers}
        isCompleted={isCompleted}
        madlib={madlib}
        madlibAdditional={madlibAdditional}
        madlibAdditionalLimit={madlibAdditionalLimit}
        onClearAnswer={onClearAnswer}
        onClearAnswers={onClearAnswers}
        prompts={prompts}
        setActivePrompt={setActivePrompt}
      />
      <div>
        {advancedPrompts.length > 1 && (
          <AdvancedPrompts
            activePrompt={activePrompt}
            advancedPrompts={advancedPrompts}
            answers={answers}
            isCompleted={isCompleted}
            onClearAnswer={onClearAnswer}
            setActivePrompt={setActivePrompt}
          />
        )}
        <div>{status}</div>
      </div>
      <hr />
    </div>
  );
};

Prompts.defaultProps = {
  onUpdateName: () => {},
};

Prompts.propTypes = {
  activePrompt: PropTypes.object,
  advancedPrompts: PropTypes.array,
  answers: PropTypes.object,
  isCompleted: PropTypes.bool,
  madlib: PropTypes.string,
  madlibAdditional: PropTypes.string,
  madlibAdditionalLimit: PropTypes.number,
  onClearAnswer: PropTypes.func,
  onClearAnswers: PropTypes.func,
  prompts: PropTypes.array,
  setActivePrompt: PropTypes.func,
  status: PropTypes.node,
};

export default Prompts;
