import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { RepromptIcon } from '@utilities';
import { customGroupStatus } from '@helpers';

import { getCustomGroupStatusMessage } from './utilities/helpers';

import styles from './_index.module.scss';

const CustomGroupStatus = ({ group }) => {
  const [data, setData] = useState(group);

  const groups = useSelector((state) => state?.websocket?.customGroups);

  const completedStatus = customGroupStatus.COMPLETED;

  useEffect(() => {
    const group = groups?.find((group) => group?.id === data?.id);

    if (group) {
      setData(group);
    }
  }, [groups]);

  if (data?.status < completedStatus) {
    return (
      <div className={styles['custom-group-status']}>
        <RepromptIcon className={styles['custom-group-status-loading-icon']} />
        {getCustomGroupStatusMessage({ data })}
        ...
      </div>
    );
  }
  return getCustomGroupStatusMessage({ data });
};

CustomGroupStatus.propTypes = {
  group: PropTypes.object.isRequired,
};

export default CustomGroupStatus;
