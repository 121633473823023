import {
  readStoresGlobalFiltersData,
  readStoresLineChart,
  readStoresLocalFiltersData,
  readStoresTable,
} from '@api/forecasting';

import Insights from '../../../../components/Insights';

const Stores = () => {
  return (
    <Insights
      hasSharedLocalFilters={true}
      readChartsFiltersData={readStoresLocalFiltersData}
      readGlobalFiltersData={readStoresGlobalFiltersData}
      readLineChartData={readStoresLineChart}
      readTableData={readStoresTable}
      tab="forecasting-stores"
    />
  );
};

export default Stores;
