import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Input } from '@utilities';

import { login } from '@redux/slices/user';

import { containers } from '../../utilities/helpers';

import styles from './_index.module.scss';

const Consultant = ({ next, setContainer }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { error, isAuthenticating } = useSelector((state) => state?.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = (event) => {
    event.preventDefault();

    dispatch(
      login({
        data: {
          email,
          password,
        },
        next,
        navigate,
      })
    );
  };

  return (
    <div className={styles['consultant']}>
      <h2>Consultant Sign In</h2>
      <form onSubmit={handleLogin}>
        <Input
          autoComplete="email"
          data-testid="consultant-email-address"
          label="Email Address"
          onChange={setEmail}
          value={email}
        />
        <Input
          autoComplete="current-password"
          data-testid="consultant-password"
          error={error ? 'Your e-mail or password was incorrect.' : ''}
          label="Password"
          onChange={setPassword}
          type="password"
          value={password}
        />
        <Button
          data-testid="consultant-submit"
          isLoading={isAuthenticating}
          text="Log In"
          type="submit"
          variant="tertiary"
        />
      </form>
      <div className={styles['consultant-actions']}>
        <button data-testid="consultant-action-back" onClick={() => setContainer(containers.SSO)}>
          Back
        </button>
      </div>
    </div>
  );
};

Consultant.defaultProps = {
  next: '/',
};

Consultant.propTypes = { next: PropTypes.string, setContainer: PropTypes.func.isRequired };

export default Consultant;
