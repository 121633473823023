import { useMemo } from 'react';
import { useExpanded, useFlexLayout, useTable } from 'react-table';

import classNames from 'classnames';
import Papa from 'papaparse';
import PropTypes from 'prop-types';

import { CopyToClipboard } from '@utilities';

import styles from './_index.module.scss';

const OtherPleaseSpecifyTable = ({ columns, data }) => {
  const { getTableProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data,
    },
    useExpanded,
    useFlexLayout
  );

  const copyValue = useMemo(() => {
    const dataAsRows = data.map(({ subRows }) => subRows.map(({ text }) => text));
    const maxRowLength = Math.max(...dataAsRows.map((row) => row.length));
    const dataAsColumns = new Array(maxRowLength)
      .fill()
      .map((_, index) => dataAsRows.map((row) => row[index]));

    const header = Papa.unparse(
      { fields: data.map(({ text }) => text), data: [] },
      { delimiter: '\t', header: true }
    );
    const body = Papa.unparse(dataAsColumns, { delimiter: '\t', header: false });
    return header + body;
  }, [data]);

  if (!data?.length) return null;

  return (
    <div>
      <div className={styles['other-please-specify-table-actions']}>
        <CopyToClipboard text="Copy Table" value={copyValue} />
      </div>
      <table className={styles['other-please-specify-table']} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {rows?.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                className={classNames({
                  [styles['can-expand']]: row?.canExpand,
                  [styles['is-sub-row']]: row?.depth > 0,
                })}
                {...row.getRowProps()}
                key={index}
              >
                {row?.cells?.map((cell) => (
                  <td onClick={row.getToggleRowExpandedProps().onClick} {...cell.getCellProps({})}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

OtherPleaseSpecifyTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
};

export default OtherPleaseSpecifyTable;
