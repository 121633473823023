import { COLOR_ERROR, COLOR_NUMERATOR_GREEN, COLOR_YELLOW } from '../../../helpers/colors';

/**
 * bar tornado chart specific configurations
 * @type {Object}
 */
export const barTornadoChartConfiguration = {
  colors: [COLOR_NUMERATOR_GREEN, COLOR_ERROR, COLOR_YELLOW],
  plotOptions: {
    series: {
      groupPadding: 0.1,
      pointPadding: 0.1,
      stacking: 'normal',
    },
  },
};
