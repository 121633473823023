import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { Breadcrumbs, Button, Search, Select, TableList, useDebounce } from '@utilities';

import ActionsToolbar from './components/ActionsToolbar';
import CreateFolder from './components/CreateFolder';
import CreateItem from './components/CreateItem';

import styles from './_index.module.scss';

const ListPage = ({
  createFolder,
  createItem,
  filterOptions,
  folderActions,
  folderColumns,
  folderSize,
  isCreateModalActive,
  itemActions,
  itemColumns,
  itemSize,
  onCreate,
  onExpandItemRow,
  readItems,
  readFolders,
  renderRowExpanded,
  setIsCreateModalActive,
  type,
  workspace,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSortBy = [{ desc: true, id: 'created_datetime' }];
  const pageNumber = parseInt(searchParams.get('itemsPage'), 10);
  const rowNumber = parseInt(searchParams.get('itemsRow'), 10);
  const [expandedFolder, setExpandedFolder] = useState(null);
  const [filters, setFilters] = useState(
    searchParams
      .get('filters')
      ?.split(',')
      .map((value) => {
        let option;
        filterOptions.some((filterGroup) => {
          return filterGroup.options.some((filterOption) => {
            if (filterOption.value === value) {
              option = filterOption;
              return true;
            }
            return false;
          });
        });
        return option;
      })
      .filter((value) => !!value) || []
  );
  const [folders, setFolders] = useState([]);
  const [foldersPage, setFoldersPage] = useState(1);
  const [foldersPageCount, setFoldersPageCount] = useState(0);
  const [foldersSortBy, setFoldersSortBy] = useState(initialSortBy);
  const [initialExpandedRow, setInitialExpandedRow] = useState(
    Number.isNaN(rowNumber) ? null : rowNumber
  );
  const [isFoldersLoading, setIsFoldersLoading] = useState(false);
  const [isItemsLoading, setIsItemsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [itemsPage, setItemsPage] = useState(pageNumber || 1);
  const [itemsPageCount, setItemsPageCount] = useState(0);
  const [itemsSortBy, setItemsSortBy] = useState(initialSortBy);
  const [searchQuery, setSearchQuery, { signal, isDebouncing }] = useDebounce(
    searchParams.get('search') || ''
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const [shouldExpandFirstRow, setShouldExpandFirstRow] = useState(false);
  const folderControllerRef = useRef(null);
  const isSelectedFolders = selectedRows.some((selectedRow) => selectedRow?.isFolder);
  const isSelectedItems = selectedRows.some((selectedRow) => selectedRow?.isItem);
  const itemControllerRef = useRef(null);

  const getFolders = async () => {
    if (!readFolders) {
      return;
    }
    setIsFoldersLoading(true);
    folderControllerRef?.current?.abort();
    const controller = new AbortController();
    folderControllerRef.current = controller;

    try {
      const response = await readFolders({
        order: foldersSortBy[0]?.desc ? 'desc' : 'asc',
        page: foldersPage,
        query: searchQuery,
        signal: folderControllerRef?.current?.signal,
        size: folderSize,
        sortBy: foldersSortBy[0]?.id,
        tags: filters
          ?.filter((option) => option?.value.includes('_folder'))
          .map((option) => option?.value.replace('_folder', '')),
      });

      setFoldersPageCount(Math.ceil(response?.data?.total / folderSize));
      setFolders(response?.data?.folders);
      setIsFoldersLoading(false);
      folderControllerRef.current = null;
    } catch (error) {
      if (error?.response?.status > 200) {
        console.error(error);
        setIsFoldersLoading(false);
      }
    }
  };

  const getItems = async ({ expandItemsFirstRow = false }) => {
    setIsItemsLoading(true);
    setShouldExpandFirstRow(false);
    if (Number.isNaN(rowNumber)) {
      setInitialExpandedRow(null);
    }

    itemControllerRef?.current?.abort();
    const controller = new AbortController();
    itemControllerRef.current = controller;

    try {
      const response = await readItems({
        folderId: expandedFolder?.id,
        order: itemsSortBy[0]?.desc ? 'desc' : 'asc',
        page: itemsPage,
        query: searchQuery,
        signal: itemControllerRef?.current?.signal,
        size: itemSize,
        sortBy: itemsSortBy[0]?.id,
        tags: filters
          ?.filter((option) => option?.value.includes('_item'))
          .map((option) => option?.value.replace('_item', '')),
      });

      let itemsList = response?.data.items;

      if (expandItemsFirstRow) {
        setInitialExpandedRow(0);
      }

      setItemsPageCount(Math.ceil(response?.data?.total / itemSize));
      setItems(itemsList);

      setIsItemsLoading(false);
      itemControllerRef.current = null;
    } catch (error) {
      if (error?.response?.status > 200) {
        console.error(error);
        setIsItemsLoading(false);
      }
    }
  };

  const handleSubmit = ({
    refreshFolders = false,
    refreshItems = false,
    resetFoldersPage = false,
    resetItemsPage = false,
    resetItemsPageExpandFirstRow = false,
  }) => {
    setSelectedRows([]);

    if (refreshFolders || (resetFoldersPage && foldersPage === 1)) {
      getFolders();
    }

    if (refreshItems || (resetItemsPage && itemsPage === 1)) {
      getItems({});
    }

    if (resetFoldersPage) {
      setFoldersPage(1);
    }

    if (resetItemsPage) {
      setItemsPage(1);
    }

    if (resetItemsPageExpandFirstRow) {
      if (itemsPage === 1) {
        getItems({ expandItemsFirstRow: true });
      } else {
        setShouldExpandFirstRow(true);
        setItemsPage(1);
      }
    }
  };

  useEffect(() => {
    setSearchParams({
      ...(filters.length > 0 ? { filters: filters.map((filter) => filter.value).join(',') } : {}),
      ...(searchQuery ? { search: searchQuery } : {}),
    });
  }, [filters, signal]);

  useEffect(() => {
    setFoldersPage(1);
  }, [foldersSortBy, signal]);

  useEffect(() => {
    if (!pageNumber) setItemsPage(1);
  }, [expandedFolder, itemsSortBy, signal]);

  useEffect(() => {
    getFolders();
  }, [filters, foldersPage, foldersSortBy, signal]);

  useEffect(() => {
    if (expandedFolder) {
      setSearchQuery('');
    }
  }, [expandedFolder]);

  useEffect(() => {
    getItems({ expandItemsFirstRow: shouldExpandFirstRow });
  }, [expandedFolder, filters, itemsPage, itemsSortBy, signal]);

  return (
    <>
      <div className={styles['list-page-header']}>
        <div className={styles['list-page-search']}>
          <Search
            isSearching={(isDebouncing || isItemsLoading || isFoldersLoading) && searchQuery !== ''}
            onChange={setSearchQuery}
            placeholder={
              workspace === 'Reports'
                ? 'Search by report name, report answer, or creator'
                : 'Search by name or creator'
            }
            value={searchQuery}
          />
          <Button data-testid="create-button" onClick={onCreate} text={`Create ${type}`} />
        </div>
        <div className={styles['list-page-filters']}>
          <div>
            <Select
              isMulti
              label="Filters"
              onChange={setFilters}
              options={filterOptions}
              placeholder="Filter by"
              value={filters}
            />
          </div>
          {createFolder && (
            <CreateFolder
              createFolder={createFolder}
              onSubmit={() => handleSubmit({ resetFoldersPage: true })}
            />
          )}
        </div>
      </div>
      {!expandedFolder && folderColumns && (
        <TableList
          columns={folderColumns}
          currentPage={foldersPage}
          data={folders}
          expandedRow={expandedFolder}
          renderEmptyMessage={() =>
            searchQuery !== '' || filters?.length > 0 ? (
              <>
                <h3>No results found.</h3>
                <p>Try refining your search or filters.</p>
              </>
            ) : (
              <>
                <h3>Folders will appear here.</h3>
                <p>Use them to stay organized or share them with colleagues.</p>
              </>
            )
          }
          initialSortBy={initialSortBy}
          isDisabled={isSelectedItems}
          isLoading={isDebouncing || isFoldersLoading}
          onExpandRow={(row) =>
            setExpandedFolder((prevState) => (prevState?.id === row?.id ? null : row))
          }
          pageCount={foldersPageCount}
          renderRowHovered={({ row, setVisibleActionsCount }) => (
            <ActionsToolbar
              actions={folderActions}
              isHoverAction={true}
              onSubmit={handleSubmit}
              selectedRows={[row?.original]}
              setVisibleActionsCount={setVisibleActionsCount}
              type={`${type} Folder`}
            />
          )}
          selectedRows={selectedRows}
          setPage={setFoldersPage}
          setSelectedRows={setSelectedRows}
          setSortBy={setFoldersSortBy}
        />
      )}
      {expandedFolder && (
        <Breadcrumbs
          options={[
            {
              label: `All ${type}s`,
              onClick: () => {
                setExpandedFolder(null);
                setSearchQuery('');
              },
            },
            { label: expandedFolder?.name, value: expandedFolder?.id },
          ]}
        />
      )}
      <TableList
        columns={itemColumns}
        currentPage={itemsPage}
        data={items}
        initialSortBy={initialSortBy}
        isDisabled={isSelectedFolders}
        isLoading={isDebouncing || isItemsLoading}
        initialExpandedRow={initialExpandedRow}
        onExpandRow={onExpandItemRow}
        pageCount={itemsPageCount}
        renderEmptyMessage={() =>
          searchQuery !== '' || filters?.length > 0 ? (
            <>
              <h3>No results found.</h3>
              <p>Try refining your search or filters.</p>
            </>
          ) : (
            <>
              <h3>{type}s will appear here.</h3>
              <p> Get started by creating one from the top right.</p>
            </>
          )
        }
        renderRowExpanded={({ row }) => renderRowExpanded({ row, itemsPage, handleSubmit })}
        renderRowHovered={({ row, setVisibleActionsCount }) => (
          <ActionsToolbar
            actions={itemActions}
            expandedFolder={expandedFolder}
            isHoverAction={true}
            itemsPage={itemsPage}
            itemsRow={row?.id}
            onSubmit={handleSubmit}
            selectedRows={[row?.original]}
            row={row}
            setVisibleActionsCount={setVisibleActionsCount}
            type={type}
          />
        )}
        selectedRows={selectedRows}
        setPage={setItemsPage}
        setSelectedRows={setSelectedRows}
        setSortBy={setItemsSortBy}
        size={itemSize}
      />

      {selectedRows.length >= 1 && (
        <ActionsToolbar
          actions={isSelectedFolders ? folderActions : itemActions}
          expandedFolder={expandedFolder}
          itemsPage={itemsPage}
          onClear={() => setSelectedRows([])}
          onSubmit={handleSubmit}
          selectedRows={selectedRows}
          type={isSelectedFolders ? `${type} Folder` : type}
        />
      )}
      {createItem && (
        <CreateItem
          createItem={createItem}
          isCreateModalActive={isCreateModalActive}
          onSubmit={() => {
            handleSubmit({
              resetItemsPageExpandFirstRow: true,
            });
          }}
          setIsCreateModalActive={setIsCreateModalActive}
          type={type}
        />
      )}
    </>
  );
};

ListPage.defaultProps = {
  folderSize: 5,
  itemSize: 10,
  renderRowExpanded: () => {},
};

ListPage.propTypes = {
  createFolder: PropTypes.func,
  createItem: PropTypes.func,
  filterOptions: PropTypes.array,
  folderActions: PropTypes.array,
  folderColumns: PropTypes.array,
  folderSize: PropTypes.number,
  isCreateModalActive: PropTypes.bool,
  itemActions: PropTypes.array,
  itemColumns: PropTypes.array.isRequired,
  itemSize: PropTypes.number,
  onCreate: PropTypes.func,
  onExpandItemRow: PropTypes.func,
  readFolders: PropTypes.func,
  readItems: PropTypes.func.isRequired,
  renderRowExpanded: PropTypes.func,
  setIsCreateModalActive: PropTypes.func,
  type: PropTypes.string.isRequired,
  workspace: PropTypes.string,
};

export default ListPage;
