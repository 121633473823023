import PropTypes from 'prop-types';
import HighchartsMore from 'highcharts/highcharts-more';
import merge from 'lodash/merge';

import Chart from '../../charts/Chart';

import { bubbleChartConfiguration } from './utilities/helpers';

const BubbleChart = ({ series, ...props }) => {
  return (
    <Chart
      highchartsModule={HighchartsMore}
      options={merge({}, bubbleChartConfiguration, {
        series,
        type: 'bubble',
        ...props,
      })}
      {...props}
    />
  );
};

BubbleChart.propTypes = {
  series: PropTypes.array,
};

export default BubbleChart;
