import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Error, numeratorInsightsLogoImage } from '@utilities';

import { readReportPublicToken } from '@api/reports';

import Footer from '../../components/Footer';
import Report from '@src/app/pages/Dashboard/pages/Report';

import styles from './_index.module.scss';

const Share = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);

  const { token } = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await readReportPublicToken({ publicToken: token });

        setData(response?.data);
      } catch (error) {
        console.error(error);
        setError(error);
      }
    };

    getData();
  }, []);

  if (error) return <Error status={error?.response?.status} />;

  if (!data) return null;

  return (
    <div className={styles['share']}>
      <img alt="Numerator Logo" data-testid="share-logo" src={numeratorInsightsLogoImage} />
      <div className={styles['share-body']}>
        <Report id={data} publicToken={token} />
      </div>
      <Footer />
    </div>
  );
};

export default Share;
