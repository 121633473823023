import PropTypes from 'prop-types';
import { FavoriteIcon, IconCTA, OverlayTriggerTooltip } from '@utilities';

const Favorite = ({
  hasException,
  hasExpiration,
  isHoverAction,
  isSelectedOwned,
  onSubmit,
  selectedRows,
  updateFavorite,
}) => {
  const isFavorite = selectedRows[0]?.isFavorite;
  const isVisible = selectedRows.length === 1 && !hasException && !hasExpiration && isSelectedOwned;

  if (!isVisible) return null;

  const handleUpdateFavorite = async () => {
    try {
      await updateFavorite({ id: selectedRows[0]?.id, isFavorite: !isFavorite });
      onSubmit({ refreshItems: true });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <OverlayTriggerTooltip content={isHoverAction ? 'Favorite' : ''}>
      <IconCTA
        data-testid="favorite-cta"
        icon={<FavoriteIcon />}
        onClick={handleUpdateFavorite}
        text={isHoverAction ? '' : 'Favorite'}
      />
    </OverlayTriggerTooltip>
  );
};

Favorite.propTypes = {
  hasException: PropTypes.bool,
  hasExpiration: PropTypes.bool,
  isHoverAction: PropTypes.bool,
  isSelectedOwned: PropTypes.bool,
  onSubmit: PropTypes.func,
  selectedRows: PropTypes.array,
  updateFavorite: PropTypes.func,
};

export default Favorite;
