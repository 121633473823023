import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './user';
import websocketReducer from './websocket';

const rootReducer = combineReducers({
  user: userReducer,
  websocket: websocketReducer,
});

export default rootReducer;
