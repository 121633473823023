import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { MentionsInput as Mentions, Mention } from 'react-mentions';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  CloseIcon,
  CloseBoldIcon,
  ExclamationTriangleIcon,
  ImageIcon,
  RepromptIcon,
  SerifXIcon,
} from '../../icons';
import OverlayTriggerTooltip from '../OverlayTriggerTooltip';
import SuggestionsWrapper from '../MentionsInput/components/SuggestionsWrapper';

import styles from './_index.module.scss';

const RichTextInput = ({
  autoFocus,
  className,
  error,
  hasImageError,
  id = nanoid(),
  imageUrl,
  isDisabled,
  isImageLoading,
  label,
  mentions,
  onChange,
  onImageRemove,
  onImageUpload,
  onPaste,
  setHasImageError,
  suggestions,
  value,
  ...props
}) => {
  const [file, setFile] = useState();
  const [isFocused, setIsFocused] = useState();

  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, []);

  const handleFileChange = async (event) => {
    if (event.target.files) {
      if (onImageUpload) {
        try {
          await onImageUpload(event.target.files[0]);
        } catch (error) {
          return;
        }
      }
      setFile(event.target.files[0]);
    }
  };

  const handleFileRemove = () => {
    if (onImageRemove) {
      onImageRemove();
    }
    setFile();
  };

  const handleInputChange = (event) => {
    onChange(event?.target?.rawValue || event?.target?.value);
  };

  const handleKeyDown = ({ code }) => {
    if (isDisabled) {
      return;
    }
    if (code === 'Tab') {
      setIsFocused(false);
    }
  };

  const handlePaste = (event) => {
    if (onPaste) {
      const value = (event.clipboardData || window.clipboardData).getData('text');
      onPaste(event, value);
    }
  };

  const openMentionsProgrammatically = () => {
    const newValue = value.split('');
    const { selectionEnd, selectionStart } = inputRef.current;

    let trigger = mentions[0]?.trigger;
    if (newValue[selectionStart - 1] && newValue[selectionStart - 1] !== ' ') {
      trigger = ' ' + trigger;
    }

    let spaceAfter = '';
    if (newValue.length > selectionEnd && newValue[selectionEnd] !== ' ') {
      spaceAfter = ' ';
    }

    newValue.splice(selectionStart, selectionEnd - selectionStart, trigger, spaceAfter);
    onChange(newValue.join(''));

    setTimeout(() => {
      inputRef.current.selectionStart = inputRef.current.selectionEnd =
        selectionStart + trigger.length;
      inputRef.current.focus({ preventScroll: true });
    }, 100);
  };

  return (
    <div
      className={classNames(styles['rich-text-input'], {
        [styles['is-disabled']]: isDisabled,
      })}
      onFocus={() => setIsFocused(true)}
      onKeyDown={handleKeyDown}
    >
      {label && (
        <label className={styles['rich-text-input-label']} htmlFor={id}>
          {label}
        </label>
      )}
      <OutsideClickHandler onOutsideClick={() => setIsFocused(false)}>
        <div
          className={classNames(styles['rich-text-input-container'], {
            [styles['is-focused']]: isFocused,
          })}
        >
          {isFocused && (
            <>
              <div className={styles['rich-text-input-tools']}>
                <OverlayTriggerTooltip content={isDisabled ? null : 'Add Image'} position="bottom">
                  <label
                    aria-disabled={isDisabled}
                    aria-label="Add Image"
                    htmlFor={`${id}-file-input`}
                    role="button"
                  >
                    <ImageIcon />
                  </label>
                  <input
                    accept="image/gif, image/jpeg, image/png"
                    aria-hidden
                    className={styles['rich-text-input-file']}
                    disabled={isDisabled}
                    id={`${id}-file-input`}
                    onChange={handleFileChange}
                    tabIndex={-1}
                    type="file"
                  />
                </OverlayTriggerTooltip>
                {mentions.length > 0 && (
                  <OverlayTriggerTooltip
                    content={isDisabled ? null : 'Insert Variable'}
                    position="bottom"
                  >
                    <button
                      aria-label="Insert Variable"
                      disabled={isDisabled}
                      onClick={openMentionsProgrammatically}
                    >
                      <SerifXIcon />
                      <span>Variable</span>
                    </button>
                  </OverlayTriggerTooltip>
                )}
              </div>
              <div className={styles['rich-text-input-divider']} />
            </>
          )}

          {mentions.length ? (
            <Mentions
              className="mentions"
              classNames={styles}
              customSuggestionsContainer={(children) => (
                <SuggestionsWrapper children={children} {...suggestions} />
              )}
              disabled={isDisabled}
              id={id}
              inputRef={inputRef}
              onChange={(event, newValue, newPlainTextValue) => onChange(newPlainTextValue)}
              onPaste={handlePaste}
              singleLine
              value={value}
              {...props}
            >
              {mentions.map((mentionConfig) => (
                <Mention {...mentionConfig} />
              ))}
            </Mentions>
          ) : (
            <input
              disabled={isDisabled}
              id={id}
              onChange={handleInputChange}
              onPaste={handlePaste}
              ref={inputRef}
              type="text"
              value={value}
              {...props}
            />
          )}

          {(hasImageError || isImageLoading || file || imageUrl) && (
            <div className={styles['rich-text-input-image-container']}>
              {hasImageError && (
                <div className={styles['rich-text-input-image-error']}>
                  <div className={styles['rich-text-input-image-error-text']}>
                    <ExclamationTriangleIcon /> Image upload failed: please try again.
                  </div>
                  <div
                    className={styles['rich-text-input-image-error-close-icon']}
                    onClick={() => setHasImageError(false)}
                  >
                    <CloseBoldIcon />
                  </div>
                </div>
              )}
              {isImageLoading && (
                <div className={styles['rich-text-input-image-loading']}>
                  <RepromptIcon /> Image is uploading...
                </div>
              )}
              {!isImageLoading && !hasImageError && (file || imageUrl) && (
                <div className={styles['rich-text-input-image-preview']}>
                  <img alt="Uploaded File" src={file ? URL.createObjectURL(file) : imageUrl} />
                  <div className={styles['rich-text-input-remove-image-container']}>
                    <OverlayTriggerTooltip content="Remove Image" position="bottom">
                      <div
                        className={styles['rich-text-input-remove-image-button']}
                        onClick={handleFileRemove}
                      >
                        <CloseIcon className={styles['rich-text-input-remove-image-icon']} />
                      </div>
                    </OverlayTriggerTooltip>
                  </div>
                  <div className={styles['rich-text-input-image-preview-overlay']} />
                </div>
              )}
            </div>
          )}
        </div>
      </OutsideClickHandler>
      {error && <p className={styles['rich-text-input-error']}>{error}</p>}
    </div>
  );
};

RichTextInput.defaultProps = {
  autoFocus: false,
  hasImageError: false,
  isDisabled: false,
  isImageLoading: false,
  mentions: [],
  suggestions: {},
  onChange: () => {},
};

RichTextInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  hasImageError: PropTypes.bool,
  id: PropTypes.string,
  imageUrl: PropTypes.string,
  isDisabled: PropTypes.bool,
  isImageLoading: PropTypes.bool,
  label: PropTypes.string,
  mentions: PropTypes.array,
  onChange: PropTypes.func,
  onImageRemove: PropTypes.func,
  onImageUpload: PropTypes.func,
  onPaste: PropTypes.func,
  setHasImageError: PropTypes.func,
  suggestions: PropTypes.object,
  value: PropTypes.string,
};

export default RichTextInput;
