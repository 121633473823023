import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './_index.module.scss';

const ActionArea = ({ buttons, children, className }) => {
  return (
    <div className={classNames(styles['action-area'], className)}>
      {children}
      {buttons.length && (
        <div className={styles['action-area-footer']}>
          <div className={styles['action-area-footer-container']}>{buttons}</div>
        </div>
      )}
    </div>
  );
};

ActionArea.defaultProps = {
  buttons: [],
};

ActionArea.propTypes = {
  buttons: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ActionArea;
