import { useEffect, useState } from 'react';
import LogRocket from 'logrocket';
import PropTypes from 'prop-types';

import { Error, LoaderView, Select } from '@utilities';

import buttonStyles from '@utilities/components/Button/_index.module.scss';
import styles from './_index.module.scss';

const AskWhyTab = ({ askWhyInstantSurveys }) => {
  const selectOptions = askWhyInstantSurveys.map((askWhyInstantSurvey) => ({
    label: askWhyInstantSurvey.title,
    value: askWhyInstantSurvey,
  }));
  const [selectedAskWhy, setSelectedAskWhy] = useState(selectOptions[0]);

  useEffect(() => {
    LogRocket.track('AskWhy_Tab');
  }, []);

  if (askWhyInstantSurveys.length === 0) {
    return <Error status={404} />;
  }

  return (
    <div className={styles['ask-why-tab']}>
      <Select
        label="Panelists"
        onChange={setSelectedAskWhy}
        options={selectOptions}
        value={selectedAskWhy}
      />

      {!selectedAskWhy?.value?.publicUrl && (
        <LoaderView
          heading="Status: Processing..."
          messages={['Your report is being created and will be available soon.']}
        />
      )}
      {selectedAskWhy?.value?.publicUrl && (
        <>
          <div className={styles['ask-why-tab-link']}>
            <a
              className={buttonStyles['button-secondary']}
              href={selectedAskWhy.value.publicUrl}
              rel="noreferrer"
              target="_blank"
            >
              Open as New Tab
            </a>
          </div>
          <iframe
            className={styles['ask-why-tab-iframe']}
            height="1200"
            src={selectedAskWhy.value.publicUrl}
            title={selectedAskWhy.value.title}
          />
        </>
      )}
    </div>
  );
};

AskWhyTab.defaultProps = {
  askWhyInstantSurveys: [],
};

AskWhyTab.propTypes = {
  askWhyInstantSurveys: PropTypes.array,
};

export default AskWhyTab;
