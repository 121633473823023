import { useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import { Announcement, Button, Input, ModalV2 as Modal } from '@utilities';

import { DEFAULT_GROUP_NAME } from '@api/instant_survey';

import styles from './_index.module.scss';

const ModalDuplicateGroup = ({ group, groups, onClose, onSubmit, remainingAvailableResponses }) => {
  const [newGroup, setNewGroup] = useState({
    ...group,
    id: nanoid(),
    name: `${group.name} (duplicate)`,
    quotaLimit: Math.min(group.quotaLimit, remainingAvailableResponses),
  });

  const isNameUnique = !groups.some(({ name }) => name === newGroup.name.trim());

  return (
    <Modal
      buttons={[
        <Button
          id="modal-duplicate-group-cancel"
          key="cancel-btn"
          onClick={onClose}
          text="Cancel"
          variant="secondary"
        />,
        <Button
          id="modal-duplicate-group-continue"
          isDisabled={!newGroup.name.trim() || !isNameUnique}
          key="submit-btn"
          onClick={() => onSubmit(newGroup)}
          text="Create"
        />,
      ]}
      id="modal-duplicate-group"
      isActive
      onClose={onClose}
      title="Duplicate Quota Group"
    >
      {group.quotaLimit !== newGroup.quotaLimit && (
        <Announcement
          text={`Responses for this group will be set to ${newGroup.quotaLimit} as you will reach the maximum allowed.`}
          variant="warn"
        />
      )}
      <p className={styles['modal-duplicate-group-p']}>
        The name for this quota group will appear on your report: it cannot be edited once the
        survey is set active.
      </p>
      <Input
        className={styles['modal-duplicate-group-input']}
        error={isNameUnique ? '' : 'A group with that name already exists'}
        label="Name"
        onChange={(value) => setNewGroup({ ...newGroup, name: value })}
        placeholder={DEFAULT_GROUP_NAME}
        value={newGroup.name}
      />
    </Modal>
  );
};

ModalDuplicateGroup.propTypes = {
  group: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  remainingAvailableResponses: PropTypes.number,
};

export default ModalDuplicateGroup;
