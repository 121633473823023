import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import OverlayPopover from '../OverlayPopover';
import OverlayTriggerTooltip from '../OverlayTriggerTooltip';
import Pill from '../Pill';

const PillOverlayPopover = ({
  children,
  container,
  hasArrow,
  overlayClassName,
  pillClassName,
  pillTestId,
  onHidePopover,
  overlayTestId,
  overlayPlacement,
  tooltipContent,
  tooltipPlacement,
  ...props
}) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const buttonRef = useRef();

  return (
    <>
      <span ref={buttonRef}>
        <OverlayTriggerTooltip
          content={!isOverlayOpen ? tooltipContent : null}
          placement={tooltipPlacement}
        >
          <Pill
            className={pillClassName}
            data-testid={pillTestId}
            isActive={isOverlayOpen}
            onClick={() => {
              setIsOverlayOpen(true);
            }}
            {...props}
          />
        </OverlayTriggerTooltip>
      </span>
      <OverlayPopover
        className={overlayClassName}
        container={container}
        hasArrow={hasArrow}
        isActive={isOverlayOpen}
        onHide={() => {
          setIsOverlayOpen(false);
          onHidePopover();
        }}
        placement={overlayPlacement}
        target={buttonRef.current}
        testId={overlayTestId}
        {...props}
      >
        {children}
      </OverlayPopover>
    </>
  );
};

PillOverlayPopover.defaultProps = {
  hasArrow: false,
  onHidePopover: () => {},
  overlayPlacement: 'bottom-start',
};

PillOverlayPopover.propTypes = {
  children: PropTypes.node,
  container: PropTypes.object,
  hasArrow: PropTypes.bool,
  onHidePopover: PropTypes.func,
  overlayClassName: PropTypes.string,
  overlayPlacement: PropTypes.string,
  overlayTestId: PropTypes.string,
  pillClassName: PropTypes.string,
  pillTestId: PropTypes.string,
  tooltipContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tooltipPlacement: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
};

export default PillOverlayPopover;
