import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import JsxParser from 'react-jsx-parser';
import { Link, useNavigate } from 'react-router-dom';

import { getPromptPillValue } from '@helpers';
import { Button, LoaderSkeleton, Pill } from '@utilities';

import styles from './_index.module.scss';

const ItemAnswers = ({ item, readItemAnswers, readPrompts, type }) => {
  const [answers, setAnswers] = useState(null);
  const [detailsString, setDetailsString] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const [prompts, setPrompts] = useState(null);

  const navigate = useNavigate();
  const ref = useRef(null);
  const thresholdHeight = 225;

  useEffect(() => {
    if (ref.current) {
      setOffsetHeight(ref.current.offsetHeight);
    }
  }, [answers, prompts]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const getItemAnswers = async () => {
      try {
        const response = await readItemAnswers({ id: item?.id, item, signal });

        setAnswers(response?.data?.answers);
        setDetailsString(response?.data?.detailsString);
      } catch (error) {
        console.error(error);
      }
    };

    const getPrompts = async () => {
      try {
        const response = await readPrompts(item);
        setPrompts(response?.data);
      } catch (error) {
        console.error(error);
      }
    };

    getPrompts();
    getItemAnswers();

    return () => controller.abort();
  }, []);

  if (!answers || !prompts)
    return (
      <LoaderSkeleton height={20} width={2000}>
        <rect x="0" y="0" rx="2" ry="2" width="100%" height="20" />
      </LoaderSkeleton>
    );

  if (item?.isFrozen || item?.isSmart || item?.isJoin)
    return (
      <small>
        <JsxParser
          components={{
            a: ({ children, href }) => <Link to={href}>{children}</Link>,
          }}
          jsx={detailsString}
          renderInWrapper={false}
        />
      </small>
    );

  if (!answers || isEmpty(answers)) {
    if (type === 'People Group') {
      return <small>This people group includes all shoppers.</small>;
    }
    if (type === 'Trip Group') {
      return <small>This is a regular trip group.</small>;
    }
  }

  return (
    <>
      <div
        className={classNames(styles['item-answers'], {
          [styles['is-expanded']]: isExpanded,
          [styles['is-expandable']]: offsetHeight >= thresholdHeight,
        })}
        ref={ref}
        style={{ maxHeight: !isExpanded ? thresholdHeight : 'none' }}
      >
        {Object.keys(answers).map((answer, index) => {
          const values =
            answer === 'date_range' || answer === 'date_range_only_custom'
              ? [answers[answer]]
              : answers[answer];

          const promptExists = prompts?.[answer];
          return (
            promptExists && (
              <div key={`answer-${index}`}>
                <h4>{prompts[answer]?.name}</h4>
                <ul>
                  {values.map((value, valueIndex) => {
                    return (
                      <li key={`value-${valueIndex}`}>
                        <Pill
                          icon={value?.icon}
                          isCompleted={!value?.href}
                          value={getPromptPillValue({
                            answers: {
                              [prompts[answer]?.id]: Array.isArray(value) ? value : [value],
                            },
                            prompt: prompts[answer],
                          })}
                          onClick={value?.href ? () => navigate(value?.href) : () => {}}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )
          );
        })}
      </div>
      {offsetHeight >= thresholdHeight && (
        <div className={styles['item-answers-expanded']}>
          <Button
            data-testid="item-answers-show-more"
            onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
            text={isExpanded ? 'Collapse' : 'Expand'}
            variant="secondary"
          />
        </div>
      )}
    </>
  );
};

ItemAnswers.propTypes = {
  item: PropTypes.object.isRequired,
  readItemAnswers: PropTypes.func.isRequired,
  readPrompts: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default ItemAnswers;
