import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import Button from '../../../../components/Button';
import { DownloadIcon } from '../../../../icons';
import OverlayPopover from '../../../../components/OverlayPopover';

import ModalCustomExport from './components/ModalCustomExport';

import { exportMenuOptions } from './utilities/helpers';

import styles from './_index.module.scss';

const ExportMenu = ({ chartOptions, chartRef, hasExportButtonText, renderChart }) => {
  const [isActive, setIsActive] = useState(false);
  const [isModalCustomExportActive, setIsModalCustomExportActive] = useState(false);

  const buttonRef = useRef(null);

  return (
    <div className={styles['export-menu']}>
      <Button
        aria-label="Download Chart"
        buttonRef={buttonRef}
        data-testid="export-menu-button"
        icon={<DownloadIcon />}
        onClick={() => {
          setIsActive(true);
        }}
        text={hasExportButtonText ? 'Download Chart' : null}
        variant="secondary"
      />
      <OverlayPopover
        hasArrow={false}
        isActive={isActive}
        onHide={() => setIsActive(false)}
        target={buttonRef.current}
      >
        <ul>
          {exportMenuOptions.map((exportMenuOption) => (
            <li key={exportMenuOption.description}>
              <button
                onClick={() => {
                  setIsActive(false);
                  exportMenuOption.onClick(chartRef);
                }}
              >
                <span>{exportMenuOption.description}</span>
              </button>
            </li>
          ))}
        </ul>
        <hr />
        <ul>
          <li>
            <button
              onClick={() => {
                setIsActive(false);
                setIsModalCustomExportActive(true);
              }}
            >
              Customize Export
            </button>
          </li>
        </ul>
      </OverlayPopover>
      {isModalCustomExportActive && (
        <ModalCustomExport
          chartOptions={chartOptions}
          isActive={isModalCustomExportActive}
          onClose={() => setIsModalCustomExportActive(false)}
          renderChart={renderChart}
        />
      )}
    </div>
  );
};

ExportMenu.defaultProps = {
  hasExportButtonText: true,
};

ExportMenu.propTypes = {
  chartOptions: PropTypes.object,
  chartRef: PropTypes.object,
  hasExportButtonText: PropTypes.bool,
  renderChart: PropTypes.func,
};

export default ExportMenu;
