import moment from 'moment';

/**
 * get tooltip content for custom date group
 * @param  {Object} custom date group
 * @return {String}
 */
export const getTooltipContent = (customDateGroup) => {
  const DATE_FORMAT = 'MM/DD/YYYY';

  const [startDate, endDate] = customDateGroup?.date_range;

  return `${moment(startDate).format(DATE_FORMAT)} to ${moment(endDate).format(DATE_FORMAT)}`;
};
