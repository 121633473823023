import PropTypes from 'prop-types';

const FootnoteItem = ({ text }) => {
  return <li>{text}</li>;
};

FootnoteItem.propTypes = {
  text: PropTypes.string,
};

export default FootnoteItem;
