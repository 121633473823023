import { COLOR_BANTAMWEIGHT } from '../../../helpers/colors';

/**
 * bubble chart specific configurations
 * @type {Object}
 */
export const bubbleChartConfiguration = {
  yAxis: {
    lineColor: COLOR_BANTAMWEIGHT,
    lineWidth: 1,
  },
};
