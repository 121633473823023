import { ReactComponent as AdvancedShopperProfile } from '../assets/advanced-shopper-profile.svg';
import { ReactComponent as AdvancedTrendedMetricsScorecard } from '../assets/advanced-trended-metrics-scorecard.svg';
import { ReactComponent as BasketAffinity } from '../assets/basket-affinity.svg';
import { ReactComponent as BasketIDFetch } from '../assets/basket-id-fetch.svg';
import { ReactComponent as Binder } from '../assets/binder.svg';
import { ReactComponent as BrandCircuits } from '../assets/brand-circuits.svg';
import { ReactComponent as BrandDiagnostics } from '../assets/brand-diagnostics.svg';
import { ReactComponent as BrandRadar } from '../assets/brand-radar.svg';
import { ReactComponent as BrandStrength } from '../assets/brand-strength.svg';
import { ReactComponent as BrandTree } from '../assets/brand-tree.svg';
import { ReactComponent as BricksClicks } from '../assets/bricks-clicks.svg';
import { ReactComponent as BuyerLoyaltyFlow } from '../assets/buyer-loyalty-flow.svg';
import { ReactComponent as CategoryStrategy } from '../assets/category-strategy.svg';
import { ReactComponent as CategoryWatch } from '../assets/category-watch.svg';
import { ReactComponent as ConsecutivePurchasing } from '../assets/consecutive-purchasing.svg';
import { ReactComponent as ContentInsights } from '../assets/content-insights.svg';
import { ReactComponent as CountReport } from '../assets/count-report.svg';
import { ReactComponent as CrossPurchase } from '../assets/cross-purchase.svg';
import { ReactComponent as CustomerLifetimeValue } from '../assets/customer-lifetime-value.svg';
import { ReactComponent as Dashboard } from '../assets/dashboard.svg';
import { ReactComponent as DataExplorer } from '../assets/data-explorer.svg';
import { ReactComponent as DemographicsProfile } from '../assets/demographics-profile.svg';
import { ReactComponent as EarlyRead } from '../assets/early-read.svg';
import { ReactComponent as EBSOVA } from '../assets/ebsova.svg';
import { ReactComponent as EventAnalysis } from '../assets/event-analysis.svg';
import { ReactComponent as ExternalLink } from '../assets/external-link.svg';
import { ReactComponent as GenericPlaceholder } from '../assets/generic-placeholder.svg';
import { ReactComponent as HelpCenter } from '../assets/help-center.svg';
import { ReactComponent as HighMedLow } from '../assets/high-med-low.svg';
import { ReactComponent as HouseholdAffinity } from '../assets/household-affinity.svg';
import { ReactComponent as LapsedRepeatNew } from '../assets/lapsed-repeat-new.svg';
import { ReactComponent as LeakageTree } from '../assets/leakage-tree.svg';
import { ReactComponent as MediaConsumption } from '../assets/media-consumption.svg';
import { ReactComponent as Messenger } from '../assets/messenger.svg';
import { ReactComponent as MomentsOfTruth } from '../assets/moments-of-truth.svg';
import { ReactComponent as NewBuyersAnalysis } from '../assets/new-buyers-analysis.svg';
import { ReactComponent as NewItemSovaV2 } from '../assets/new-item-sova-v2.svg';
import { ReactComponent as NewItemSova } from '../assets/new-item-sova.svg';
import { ReactComponent as NewItemTracker } from '../assets/new-item-tracker.svg';
import { ReactComponent as NumeratorUniversity } from '../assets/numerator-university.svg';
import { ReactComponent as OmnicharacteristicsCoverageExplorer } from '../assets/omnicharacteristics-coverage-explorer.svg';
import { ReactComponent as OnlineInterests } from '../assets/online-interests.svg';
import { ReactComponent as OrderDelivery } from '../assets/order-delivery.svg';
import { ReactComponent as PanelSize } from '../assets/panel-size.svg';
import { ReactComponent as PeopleExplorer } from '../assets/people-explorer.svg';
import { ReactComponent as PeopleScorecard } from '../assets/people-scorecard.svg';
import { ReactComponent as PlacementInsights } from '../assets/placement-insights.svg';
import { ReactComponent as PlacementMetrics } from '../assets/placement-metrics.svg';
import { ReactComponent as PointOfEntry } from '../assets/point-of-entry.svg';
import { ReactComponent as PortfolioExplorer } from '../assets/portfolio-explorer.svg';
import { ReactComponent as PortfolioOptimizer } from '../assets/portfolio-optimizer.svg';
import { ReactComponent as PremiumPeopleProfile } from '../assets/premium-people-profile.svg';
import { ReactComponent as ProductHierarchyExplorer } from '../assets/product-hierarchy-explorer.svg';
import { ReactComponent as PromoBrandSwitching } from '../assets/promo-brand-switching.svg';
import { ReactComponent as PromoDrivers } from '../assets/promo-drivers.svg';
import { ReactComponent as PromoOptimization } from '../assets/promo-optimization.svg';
import { ReactComponent as PromoShopperConversion } from '../assets/promo-shopper-conversion.svg';
import { ReactComponent as PromoStoreSwitching } from '../assets/promo-store-switching.svg';
import { ReactComponent as PromotionEffectiveness } from '../assets/promotion-effectiveness.svg';
import { ReactComponent as PromotionScorecard } from '../assets/promotion-scorecard.svg';
import { ReactComponent as Psychographics } from '../assets/psychographics.svg';
import { ReactComponent as PurchaseJourney } from '../assets/purchase-journey.svg';
import { ReactComponent as RetailerCircularAnalysis } from '../assets/retailer-circular-analysis.svg';
import { ReactComponent as RetailerPeopleScorecard } from '../assets/retailer-people-scorecard.svg';
import { ReactComponent as RetailerScorecard } from '../assets/retailer-scorecard.svg';
import { ReactComponent as RetailerShareWallet } from '../assets/retailer-share-wallet.svg';
import { ReactComponent as ShareExplorer } from '../assets/share-explorer.svg';
import { ReactComponent as ShareStomach } from '../assets/share-stomach.svg';
import { ReactComponent as ShopperComparison } from '../assets/shopper-comparison.svg';
import { ReactComponent as ShopperHistogram } from '../assets/shopper-histogram.svg';
import { ReactComponent as ShopperMetrics } from '../assets/shopper-metrics.svg';
import { ReactComponent as ShopperProfile } from '../assets/shopper-profile.svg';
import { ReactComponent as ShopperSentiment } from '../assets/shopper-sentiment.svg';
import { ReactComponent as StoreDiagnostics } from '../assets/store-diagnostics.svg';
import { ReactComponent as StoreHierarchyExplorer } from '../assets/store-hierarchy-explorer.svg';
import { ReactComponent as StoreLoyaltyFlow } from '../assets/store-loyalty-flow.svg';
import { ReactComponent as SystemStatus } from '../assets/system-status.svg';
import { ReactComponent as TrendedMetrics } from '../assets/trended-metrics.svg';
import { ReactComponent as TrialRepeat } from '../assets/trial-repeat.svg';
import { ReactComponent as TripCircuits } from '../assets/trip-circuits.svg';
import { ReactComponent as TripFeedback } from '../assets/trip-feedback.svg';
import { ReactComponent as TripTypeProfile } from '../assets/trip-type-profile.svg';
import { ReactComponent as UserIDFetch } from '../assets/user-id-fetch.svg';

/**
 * gets report icon based on id
 * @param  {String} id
 * @return {Node}   react component
 */
export const getReportIcon = (id) => {
  switch (id) {
    case 'advanced-shopper-profile':
      return <AdvancedShopperProfile />;
    case 'advanced-trended-metrics-scorecard':
      return <AdvancedTrendedMetricsScorecard />;
    case 'basket-affinity':
      return <BasketAffinity />;
    case 'basket-id-fetch':
      return <BasketIDFetch />;
    case 'binder':
      return <Binder />;
    case 'brand-circuits':
      return <BrandCircuits />;
    case 'brand-diagnostics':
      return <BrandDiagnostics />;
    case 'brand-radar':
      return <BrandRadar />;
    case 'brand-strength':
      return <BrandStrength />;
    case 'brand-tree':
      return <BrandTree />;
    case 'bricks-clicks':
      return <BricksClicks />;
    case 'buyer-loyalty-flow':
      return <BuyerLoyaltyFlow />;
    case 'category-strategy':
      return <CategoryStrategy />;
    case 'category-watch':
      return <CategoryWatch />;
    case 'consecutive-purchasing':
      return <ConsecutivePurchasing />;
    case 'content-insights':
      return <ContentInsights />;
    case 'count-report':
      return <CountReport />;
    case 'cross-purchase':
      return <CrossPurchase />;
    case 'customer-lifetime-value':
      return <CustomerLifetimeValue />;
    case 'dashboard':
      return <Dashboard />;
    case 'data-explorer':
      return <DataExplorer />;
    case 'demographics-profile':
      return <DemographicsProfile />;
    case 'early-read':
      return <EarlyRead />;
    case 'ebsova':
      return <EBSOVA />;
    case 'event-analysis':
      return <EventAnalysis />;
    case 'external-link':
      return <ExternalLink />;
    case 'generic-placeholder':
      return <GenericPlaceholder />;
    case 'heavy-medium-light':
      return <HighMedLow />;
    case 'help-center':
      return <HelpCenter />;
    case 'high-med-low':
      return <HighMedLow />;
    case 'household-affinity':
      return <HouseholdAffinity />;
    case 'dashboards-numerator-university':
    case 'data-literacy-numerator-university':
    case 'insights-101-numerator-university':
    case 'survey-101-numerator-university':
    case 'survey-help-center':
    case 'survey-product-page-numerator-university':
      return <NumeratorUniversity />;
    case 'lapsed-repeat-new':
      return <LapsedRepeatNew />;
    case 'leakage-tree':
      return <LeakageTree />;
    case 'media-consumption':
      return <MediaConsumption />;
    case 'messenger':
      return <Messenger />;
    case 'moments-of-truth':
      return <MomentsOfTruth />;
    case 'new-buyers-analysis':
      return <NewBuyersAnalysis />;
    case 'new-item-sova-v2':
      return <NewItemSovaV2 />;
    case 'new-item-sova':
      return <NewItemSova />;
    case 'new-item-tracker':
      return <NewItemTracker />;
    case 'numerator-university':
      return <NumeratorUniversity />;
    case 'omnicharacteristics-coverage-explorer':
      return <OmnicharacteristicsCoverageExplorer />;
    case 'online-interests':
      return <OnlineInterests />;
    case 'order-delivery':
      return <OrderDelivery />;
    case 'panel-size':
      return <PanelSize />;
    case 'people-explorer':
      return <PeopleExplorer />;
    case 'people-scorecard':
      return <PeopleScorecard />;
    case 'placement-insights':
      return <PlacementInsights />;
    case 'placement-metrics':
      return <PlacementMetrics />;
    case 'point-of-entry':
      return <PointOfEntry />;
    case 'portfolio-engagement':
      return <NewBuyersAnalysis />;
    case 'portfolio-explorer':
      return <PortfolioExplorer />;
    case 'portfolio-optimizer':
      return <PortfolioOptimizer />;
    case 'premium-people-profile':
      return <PremiumPeopleProfile />;
    case 'product-hierarchy-explorer':
      return <ProductHierarchyExplorer />;
    case 'promo-brand-switching':
      return <PromoBrandSwitching />;
    case 'promo-drivers':
      return <PromoDrivers />;
    case 'promo-optimization':
      return <PromoOptimization />;
    case 'promo-shopper-conversion':
      return <PromoShopperConversion />;
    case 'promo-store-switching':
      return <PromoStoreSwitching />;
    case 'promotion-effectiveness':
      return <PromotionEffectiveness />;
    case 'promotion-scorecard':
      return <PromotionScorecard />;
    case 'psychographics':
      return <Psychographics />;
    case 'purchase-journey':
      return <PurchaseJourney />;
    case 'retailer-circular-analysis':
      return <RetailerCircularAnalysis />;
    case 'retailer-people-scorecard':
      return <RetailerPeopleScorecard />;
    case 'retailer-scorecard':
      return <RetailerScorecard />;
    case 'retailer-share-wallet':
      return <RetailerShareWallet />;
    case 'share-explorer':
      return <ShareExplorer />;
    case 'share-stomach':
      return <ShareStomach />;
    case 'shopper-comparison':
      return <ShopperComparison />;
    case 'shopper-histogram':
      return <ShopperHistogram />;
    case 'shopper-metrics':
      return <ShopperMetrics />;
    case 'shopper-profile':
      return <ShopperProfile />;
    case 'shopper-sentiment':
      return <ShopperSentiment />;
    case 'store-diagnostics':
      return <StoreDiagnostics />;
    case 'store-hierarchy-explorer':
      return <StoreHierarchyExplorer />;
    case 'store-loyalty-flow':
      return <StoreLoyaltyFlow />;
    case 'system-status':
      return <SystemStatus />;
    case 'trended-metrics':
      return <TrendedMetrics />;
    case 'trial-repeat':
      return <TrialRepeat />;
    case 'trip-circuits':
      return <TripCircuits />;
    case 'trip-feedback':
      return <TripFeedback />;
    case 'trip-type-profile':
      return <TripTypeProfile />;
    case 'user-id-fetch':
      return <UserIDFetch />;
    default:
      return <GenericPlaceholder />;
  }
};
