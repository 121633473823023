import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import classNames from 'classnames';

import { RadioIcon, RadioFilledIcon } from '../../icons';

import styles from './_index.module.scss';

const Radio = ({ isChecked, label, name, onChange, value }) => {
  const id = nanoid();

  const handleChange = () => {
    onChange(value);
  };

  return (
    <div className={classNames(styles['radio'], { [styles['is-checked']]: isChecked })}>
      <input checked={isChecked} id={id} name={name} onChange={handleChange} type="radio" />
      <label htmlFor={id}>
        <div className={styles['radio-icon']}>
          {isChecked ? <RadioFilledIcon /> : <RadioIcon />}
        </div>
        {label}
      </label>
    </div>
  );
};

Radio.defaultProps = {
  isChecked: false,
  onChange: () => {},
};

Radio.propTypes = {
  isChecked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
};

export default Radio;
